import React, { useEffect, useState } from 'react';
import ProgressBar from './Progressbar/ProgressBar';
import MainLogo from 'SmallLayoutComponents/MainLogo';
import { Link, useHistory } from 'react-router-dom';
import ErrorBar from './ErrorBar';
import SMPopup from 'Dialogs/SMPopup';
import { useSelector } from 'react-redux';
import PlatformLanguage from 'Components/HeaderMenuDropdowns/PlatformLanguage';
import { preventDefaultDrag } from 'Utils/utils';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as Share } from 'Images/svg/sm-share.svg';
import { isMobile, isMobileOnly } from 'react-device-detect';
import classnames from 'classnames';

const Header = () => {
	const history = useHistory();
	const isPasswordRecoveryPage =
		history.location.pathname.includes('password-recovery');
	const url = history.location.pathname;

	//redux

	const eventInfo = useSelector((state) => state.event.eventInfo);
	const translation = useSelector(
		(state) => state.languages.translations[state.languages.eventLanguage]
	);
	const defaultTranslation = useSelector(
		(state) => state.languages.translations['en']
	);
	const currentLanguage = useSelector((state) => state.languages.eventLanguage);
	const langArabic = currentLanguage === 'ar';
	const eventSlug = useSelector((state) => state.event.eventSlug);

	const [sharePopup, setSharePopup] = useState(false);
	const [isAtTop, setIsAtTop] = useState(true);

	const openSharePopup = () => setSharePopup(true);
	const closeSharePopup = () => setSharePopup(false);

	const isPreviewMode = window.location.href.includes('preview=true');

	const isEventSlugInUrl = history.location.pathname.includes('event/');

	const isRegistration =
		url.includes('/participant-registration') ||
		url.includes('/exhibitor-registration') ||
		url.includes('/scholar-registration') ||
		url.includes('registerAsScholar=true') ||
		url.includes('registerAsExhibitor=true') ||
		url.includes('registerAsParticipant=true');

	const showErrorBar = localStorage.getItem('showErrorBar') === 'true';

	const isSelectedRolePageAndMobile = isMobile && url.includes('/select-role');

	const logoUrl = eventSlug ? `/event/${eventSlug}` : 'https://unstoppabl.ai';

	useEffect(() => {
		localStorage.removeItem('showErrorBar');
	}, []);

	useEffect(() => {
		const generalHeaderContainer = document.getElementById(
			'general-header-container'
		);
		if (showErrorBar && isMobileOnly)
			generalHeaderContainer.style.cssText = 'padding-bottom:14px !important';
	}, [showErrorBar]);

	useEffect(() => {
		const handleScroll = () => {
			if (window.pageYOffset === 0) {
				setIsAtTop(true);
			} else {
				setIsAtTop(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<div
				className={classnames('login-registration-header', 'd-flex-column', {
					'scroll-at-top': isAtTop && isMobile,
					'select-role-mobile': isSelectedRolePageAndMobile,
					'lang-ar': langArabic,
				})}
				id={'general-header-container'}
			>
				<div className={`logo-and-social-and-lang`}>
					{eventSlug ? (
						<Link to={logoUrl}>
							<MainLogo colored={true} />
						</Link>
					) : (
						<a href={logoUrl} rel="noopener noreferrer">
							<MainLogo colored={true} />
						</a>
					)}
					{!isPasswordRecoveryPage && (
						<div onDragStart={preventDefaultDrag} className={`menu-wrapper`}>
							{process.env.REACT_APP_SM_SHARE === 'true' && (
								<IconButton onClick={openSharePopup}>
									<Share fill={'#000000'} className="sm-share-icon" />
								</IconButton>
							)}
							{!isPreviewMode && isEventSlugInUrl && <PlatformLanguage />}
						</div>
					)}
				</div>

				{isRegistration && !showErrorBar && <ProgressBar />}
			</div>

			{showErrorBar && <ErrorBar />}

			<SMPopup
				open={sharePopup}
				closeConfirm={closeSharePopup}
				dialogTitle={
					translation?.socialMediaShare?.dialogTitleGeneral ||
					defaultTranslation?.socialMediaShare?.dialogTitleGeneral
				}
				eventName={eventInfo?.name}
				popupLanguageLevel="event"
				targetURL={window.location.href}
				facebookQuote={`${
					translation?.socialMediaShare?.imAttending ||
					defaultTranslation?.socialMediaShare?.imAttending
				} ${`"${eventInfo?.name}" ${
					translation?.socialMediaShare?.event ||
					defaultTranslation?.socialMediaShare?.event
				}.`} ${
					translation?.socialMediaShare?.interestedClick ||
					defaultTranslation?.socialMediaShare?.interestedClick
				}`}
				twitterMessage={`${
					translation?.socialMediaShare?.imAttending ||
					defaultTranslation?.socialMediaShare?.imAttending
				} ${`"${eventInfo?.name}" ${
					translation?.socialMediaShare?.event ||
					defaultTranslation?.socialMediaShare?.event
				}.`} ${
					translation?.socialMediaShare?.interestedClick ||
					defaultTranslation?.socialMediaShare?.interestedClick
				}`}
				emailSubject={`${process.env.REACT_APP_PLATFORM_NAME} - ${eventInfo?.name}`}
				emailBody={`${
					translation?.socialMediaShare?.imAttending ||
					defaultTranslation?.socialMediaShare?.imAttending
				} ${`"${eventInfo?.name}" ${
					translation?.socialMediaShare?.event ||
					defaultTranslation?.socialMediaShare?.event
				}.`} ${
					translation?.socialMediaShare?.interestedClick ||
					defaultTranslation?.socialMediaShare?.interestedClick
				} ${window.location.href}`}
			/>
		</>
	);
};

export default Header;
