import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';

import BackgroundImage from '../../Images/background.jpg';

const useStyles = makeStyles({
	pageWrapper: {
		margin: 'auto',
		flexDirection: 'column',
	},
	header: {
		fontSize: '24px',
		color: '#007DBB',
		lineHeight: '140%',
		fontWeight: '600',
	},
	info: {
		fontSize: '24px',
		lineHeight: '140%',
		color: '#F5F5F5',
		fontWeight: '600',
		marginTop: '40px',
		textAlign: 'center',
	},
	button: {
		marginTop: '64px',
	},
});

const SuccessIframeRegistration = () => {
	const classes = useStyles();
	const eventSlug = useSelector((state) => state.event.eventSlug);

	const handleOpenEvent = () => {
		window.open(`${window.location.origin}/event/${eventSlug}/login`, '_blank');
	};

	return (
		<div>
			<div className="register-participant-step-1">
				<div className="full-background-container p-relative d-flex">
					<img
						src={BackgroundImage}
						className="full-background-img img-cover p-absolute w-100 h-100"
						alt="background"
					/>
					<div className="full-background-overlay p-absolute w-100 h-100"></div>
					<div className="header-spacing-container"></div>
					<div
						className={cx(
							'w-100 h-100 d-flex justify-content-center align-items-center',
							classes.pageWrapper
						)}
					>
						<Typography className={classes.header}>
							Registration Successful
						</Typography>
						<Typography className={classes.info}>
							You have successfully registered for <br /> {eventSlug}
						</Typography>
						<Button
							variant={'contained'}
							color={'secondary'}
							onClick={handleOpenEvent}
							className={classes.button}
						>
							Take me to the event
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SuccessIframeRegistration;
