import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { Utils } from 'avcore/client';
import { EVENT } from 'avcore';
import useForceUpdate from 'use-force-update';

import VideoStreamButtons from '../components/VideoStreamButtons/VideoStreamButtons';

import { startCapture, startPlayback } from '../../../Utils/videoConference';
import { useInterpretator } from './VideoContainer.hooks';
import { useSelector } from 'react-redux';
import {
	isMobileSafari,
	isOpera,
	isSafari,
	isMobile,
	isIOS,
} from 'react-device-detect';
import './VideoContainer.scss';
// import {getSessionData, setEventProtectedMenu} from '../../../store/actions';
import { preventDefaultDrag } from '../../../Utils/utils';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { ReactComponent as RequestMic } from '../../../Images/icons/request-mic.svg';
import { useHistory } from 'react-router-dom';

const FullscreenExitIconComponent = React.forwardRef(
	function MyComponent(props, ref) {
		//  Spread the props to the underlying DOM element.
		return <FullscreenExitIcon fontSize="inherit" {...props} ref={ref} />;
	}
);

const FullscreenIconComponent = React.forwardRef(
	function MyComponent(props, ref) {
		//  Spread the props to the underlying DOM element.
		return <FullscreenIcon fontSize="inherit" {...props} ref={ref} />;
	}
);

const VideoContainer = ({
	session,
	user,
	className,
	interpretationSourceLanguage,
	withInterpretation,
	liveStreamEnded,
	liveStreamStarted,
	isAuditoriumPlayer,
	handleRegisterSession,
	waitingRegistrationApproval,
	translation,
	defaultTranslation,
}) => {
	const forceUpdate = useForceUpdate();
	const selfVidRef = useRef(null);

	const history = useHistory();

	const [capture, setCapture] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [fullscreen, setFullscreen] = useState(false);
	const [muted, setMuted] = useState(
		history.action === 'POP' || (isMobile && isIOS) ? true : false
	);
	const [showAudioOverlay, setShowAudioOverlay] = useState(
		history.action === 'POP' || (isMobile && isIOS) ? true : false
	);

	const captureRef = useRef();
	const playbackRef = useRef();
	const eventUserRoles = useSelector((state) => state.user.eventRoles);
	const liveSessionUser = useSelector(
		(state) => state.liveSession.liveSessionUser
	);

	// added by UNS to be able to access the previous sessionId (like prevProps in componentDidUpdate)
	const prevSessionIdRef = useRef();
	useEffect(() => {
		prevSessionIdRef.current = session?._id;
	});
	const prevSessionId = prevSessionIdRef.current;

	const setInterpretationMediaStream = useInterpretator(
		selfVidRef.current,
		interpretationSourceLanguage,
		withInterpretation
	);

	const startVideo = async (videoRef, playback) => {
		try {
			const mediaStream = await playback.subscribe();
			videoRef.current.srcObject = mediaStream;
			if (Utils.isSafari) {
				const onStreamChange = () => {
					try {
						if (!videoRef || !videoRef.current) return;
						const _mediaStream = new MediaStream(mediaStream.getTracks());
						videoRef.current.srcObject = _mediaStream;
						videoRef.current.play();
						forceUpdate();
					} catch (err) {}
				};
				playback
					.on('addtrack', onStreamChange)
					.on('removetrack', onStreamChange);
			} else if (Utils.isFirefox) {
				const onPause = () => {
					if (videoRef && videoRef.current) videoRef.current.play();
				};
				videoRef.current.addEventListener('pause', onPause);
			}
			await videoRef.current.play();
		} catch {}
	};

	useEffect(() => {
		captureRef.current = capture;
	}, [capture]);

	useEffect(() => {
		const start = async () => {
			if (user) {
				const capture = await startCapture(user.userId);
				await setCapture(capture);
			}

			// make sure that when the session changes, we close the previous session stream
			if (prevSessionId) {
				if (captureRef.current) captureRef.current.close();
				if (playbackRef.current) playbackRef.current.close();
			}

			const stream = session.type === 'zoom' ? session._id + 'Z' : session._id;
			const playback = await startPlayback(stream);

			playbackRef.current = playback;

			await startVideo(selfVidRef, playback);
			if (playback) {
				playback.api._client.on(EVENT.STREAM_STARTED, ({ kind }) => {
					playback.mediaStream.onactive = (mediaStreamEvent) => {
						const audioTracks = mediaStreamEvent.currentTarget.getAudioTracks();
						if (audioTracks.length)
							setInterpretationMediaStream(mediaStreamEvent.currentTarget);
					};
					if (liveStreamStarted) {
						liveStreamStarted();
					}
				});
				playback.api._client.on(EVENT.STREAM_STOPPED, ({ kind }) => {
					// liveStreamEnded is a function passed from Auditorium so that I can display the timeslot has ended Image
					if (liveStreamEnded) {
						liveStreamEnded();
					}
				});
			} else {
			}
		};
		start();

		return () => {
			if (captureRef.current) captureRef.current.close();
			if (playbackRef.current) playbackRef.current.close();
		};
	}, [session._id]); // eslint-disable-line react-hooks/exhaustive-deps

	const toggleFullScreen = () => {
		const video = document.querySelector('#stream-playback-video');
		if (video.requestFullScreen) {
			video.requestFullScreen();
		} else if (video.webkitRequestFullScreen) {
			video.webkitRequestFullScreen();
		} else if (video.mozRequestFullScreen) {
			video.mozRequestFullScreen();
		} else if (video.webkitEnterFullscreen) {
			video.webkitEnterFullscreen(); //for iphone this code worked
		}
	};

	const toggleSound = () => {
		setMuted(!muted);
		if (showAudioOverlay === true) setShowAudioOverlay(false);
	};

	return (
		<>
			<div
				className={cn('module-video_container', 'VideoContainer', className)}
			>
				<video
					width={'100%'}
					height={'100%'}
					className="video VideoContainer__video"
					ref={selfVidRef}
					id="stream-playback-video"
					controls={false}
					playsInline={true}
					muted={muted}
				/>
				{!!capture && (
					<VideoStreamButtons
						user={user}
						session={session}
						capture={capture}
						setCapture={setCapture}
						streamName={user.userId}
					/>
				)}

				{
					<div
						className={`auditoriumOverlayPlayer ${
							showAudioOverlay ? 'audio-overlay' : ''
						}`}
						onClick={toggleSound}
					>
						{showAudioOverlay && (
							<VolumeUpIcon
								style={{ zIndex: 9, cursor: 'pointer' }}
								fontSize="inherit"
							/>
						)}
					</div>
				}
			</div>
			{isAuditoriumPlayer && (
				<div
					onDragStart={preventDefaultDrag}
					className={`video-actions-container ${
						waitingRegistrationApproval && !eventUserRoles.isOrganizer
							? 'session-buttons'
							: ''
					}`}
					id="videoButtons"
				>
					<button
						type="button"
						className="isNotDesktop"
						onClick={toggleFullScreen}
					>
						{fullscreen ? (
							<FullscreenExitIconComponent />
						) : (
							<FullscreenIconComponent />
						)}
					</button>
					{session.canRequestMic &&
						!waitingRegistrationApproval &&
						!eventUserRoles.isOrganizer &&
						session?.type === 'live' &&
						!liveSessionUser && (
							<button
								type="button"
								onClick={handleRegisterSession}
								className="request-mic-button"
							>
								<RequestMic />
								<span>
									{translation?.sessions.requestMic ||
										defaultTranslation?.sessions.requestMic}
								</span>
							</button>
						)}
					<button type="button" onClick={toggleSound}>
						{muted ? (
							<VolumeOffIcon fontSize="inherit" />
						) : (
							<VolumeUpIcon fontSize="inherit" />
						)}
					</button>
				</div>
			)}
		</>
	);
};

export default VideoContainer;
