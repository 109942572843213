import moment from 'moment';
import timeStrings from './Translations/time';
import { languagesArray } from './Translations/availableLanguages';
import { EXHIBITOR_TYPES, INVITATION_ERROR_CODES } from './constants/shared';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useRef } from 'react';
import { isEmailValid } from './validationRules';
import { emailTemplate } from 'Pages/Admin/Legal/Components/general-template';
import { organizerEmailTemplate } from 'Dialogs/Organizer/generic-organizer-email-template';

export const checkIfEventHasEnded = (event, timezone) => {
	let now = new Date();
	let now_utc = getUtcTimestamp(now);

	if (event !== null) {
		// create an array with all the timeslots end hours, from all auditoriums
		let eventTimeslotsEndHours = [];
		event.auditoriums.forEach((auditorium) => {
			auditorium.dailyProgram.forEach((auditoriumDayProgram) => {
				let auditoriumDayProgramDate = new Date(auditoriumDayProgram.date);
				let auditoriumDayProgramYear =
					auditoriumDayProgramDate.getUTCFullYear();
				let auditoriumDayProgramMonth = auditoriumDayProgramDate.getUTCMonth();
				let auditoriumDayProgramDay = auditoriumDayProgramDate.getUTCDate();

				auditoriumDayProgram.program.forEach((timeslot) => {
					let timeslotEndsAt = timeslot.end;
					timeslotEndsAt = timeslotEndsAt.split(':');
					let timeslotEndsAtHour = timeslotEndsAt[0];
					let timeslotEndsAtMinute = timeslotEndsAt[1];
					// get the UTC timeStamp of the last video of the event
					eventTimeslotsEndHours.push(
						new Date(
							auditoriumDayProgramYear,
							auditoriumDayProgramMonth,
							auditoriumDayProgramDay,
							timeslotEndsAtHour,
							timeslotEndsAtMinute
						)
					);
				});
			});
		});

		// we sort the array so that the first item in the array is the
		// latest hour from all auditoriums
		if (eventTimeslotsEndHours.length >= 2) {
			eventTimeslotsEndHours.sort((a, b) => {
				return b.getTime() - a.getTime();
			});
		}

		timezone = timezone === undefined ? 0 : timezone;

		// if the organizer didn't add any timeslots to the program, we consider that the event didn't end
		if (eventTimeslotsEndHours.length === 0) {
			return false;
		} else {
			eventTimeslotsEndHours =
				eventTimeslotsEndHours[0].getTime() - timezone * 60 * 60 * 1000;
			return now_utc > eventTimeslotsEndHours;
		}
	}
};

export const checkIfAuditoriumProgramHasEnded = (
	event,
	timezone,
	auditoriumIndex
) => {
	let now = new Date();
	let now_utc = getUtcTimestamp(now);

	if (event !== null) {
		// create an array with all the timeslots, for all days, of the current auditorium
		let auditoriumTimeslots = [];
		cloneDeep(event.auditoriums[auditoriumIndex].dailyProgram).forEach(
			(dayProgram) => {
				const currentProgramDay = dayProgram.date;
				dayProgram.program.forEach((timeslot) => {
					timeslot.date = currentProgramDay;
					auditoriumTimeslots.push(timeslot);
				});
			}
		);

		// if there are no timslots, we always send the "auditoriumHasEnded" = true
		let auditoriumProgramLength = auditoriumTimeslots.length;
		if (auditoriumProgramLength === 0) {
			return true;
		}

		// if there are timeslots added
		const auditoriumProgramLastVideoIndex = auditoriumProgramLength - 1;
		const auditoriumProgramLastVideo =
			auditoriumTimeslots[auditoriumProgramLastVideoIndex];

		// get the year, month, day, hour, minute when of the program last video
		const auditoriumProgramLastVideoFullDate = new Date(
			auditoriumProgramLastVideo.date
		);
		const auditoriumProgramLastVideoYear =
			auditoriumProgramLastVideoFullDate.getUTCFullYear();
		const auditoriumProgramLastVideoMonth =
			auditoriumProgramLastVideoFullDate.getUTCMonth();
		const auditoriumProgramLastVideoDay =
			auditoriumProgramLastVideoFullDate.getUTCDate();

		let auditoriumProgramLastVideoEndsAt = auditoriumProgramLastVideo.end;
		auditoriumProgramLastVideoEndsAt =
			auditoriumProgramLastVideoEndsAt.split(':');
		let auditoriumProgramLastVideoEndHour = auditoriumProgramLastVideoEndsAt[0];
		let auditoriumProgramLastVideoEndMinute =
			auditoriumProgramLastVideoEndsAt[1];

		// get the UTC timeStamp of the last video of the event
		let auditoriumProgramEnd = new Date(
			auditoriumProgramLastVideoYear,
			auditoriumProgramLastVideoMonth,
			auditoriumProgramLastVideoDay,
			auditoriumProgramLastVideoEndHour,
			auditoriumProgramLastVideoEndMinute
		);

		timezone = timezone === undefined ? 0 : timezone;
		auditoriumProgramEnd =
			auditoriumProgramEnd.getTime() - timezone * 60 * 60 * 1000;

		return now_utc > auditoriumProgramEnd;
	}
};

export const getTimeslotStartTimestamp = (date, timeslot, timezone) => {
	let programDate = new Date(date);

	const programYear = programDate.getUTCFullYear();
	const programMonth = programDate.getUTCMonth();
	const programDay = programDate.getUTCDate();

	let timeSlotStart = timeslot.start.split(':');
	let timeSlotStartHour = timeSlotStart[0];
	let timeSlotStartMinute = timeSlotStart[1];

	let timeSlotStartTimestamp = new Date(
		programYear,
		programMonth,
		programDay,
		timeSlotStartHour,
		timeSlotStartMinute
	);

	timezone = timezone === undefined ? 0 : timezone;
	timeSlotStartTimestamp =
		timeSlotStartTimestamp.getTime() - timezone * 60 * 60 * 1000;

	return timeSlotStartTimestamp;
};

export const getTimeslotEndTimestamp = (date, timeslot, timezone) => {
	let programDate = new Date(date);

	const programYear = programDate.getUTCFullYear();
	const programMonth = programDate.getUTCMonth();
	const programDay = programDate.getUTCDate();

	let timeSlotEnd = timeslot.end.split(':');
	let timeSlotEndHour = timeSlotEnd[0];
	let timeSlotEndMinute = timeSlotEnd[1];

	let timeSlotEndTimestamp = new Date(
		programYear,
		programMonth,
		programDay,
		timeSlotEndHour,
		timeSlotEndMinute
	);

	timezone = timezone === undefined ? 0 : timezone;
	timeSlotEndTimestamp =
		timeSlotEndTimestamp.getTime() - timezone * 60 * 60 * 1000;

	return timeSlotEndTimestamp;
};

// we expect to have as parameters the logged in user privateChats and the targetUserId with which we want to open a private chat
export const getPrivateChatId = (privateChats, targetUserId) => {
	let privateChat = privateChats.find(
		(privateChat) =>
			privateChat.chat.user_1?._id === targetUserId ||
			privateChat.chat.user_2?._id === targetUserId
	);

	// if we don't find a private chat we return false;
	// else we return the private chat ID
	return privateChat === undefined ? false : privateChat.chat._id;
};

export const getUserIdFromParticipantId = (
	eventParticipantsArray,
	participantId
) => {
	const participant = eventParticipantsArray.find((participant) => {
		return participant._id === participantId;
	});
	return participant ? participant.user : null;
};

export const getUserIdFromExhibitorId = (eventExhibitorArray, exhibitorId) => {
	const exhibitor = eventExhibitorArray.find((exhibitor) => {
		return exhibitor._id === exhibitorId;
	});
	return exhibitor ? exhibitor.user._id : null;
};

export const checkUserIsParticipant = (eventParticipantsArray, userId) => {
	const participantIndex = eventParticipantsArray.findIndex((participant) => {
		return participant.user._id === userId;
	});
	return participantIndex !== -1;
};

export const getParticipantId = (eventParticipantsArray, userId) => {
	const participant = eventParticipantsArray.find((participant) => {
		return participant.user._id === userId;
	});
	return participant ? participant._id : null;
};

export const getParticipantData = (eventParticipantsArray, userId) => {
	const participant = eventParticipantsArray.find((participant) => {
		return participant.user === userId;
	});
	return participant ? participant : null;
};

export const checkUserIsExhibitor = (eventExhibitorsArray, userId) => {
	const exhibitorIndex = eventExhibitorsArray.findIndex((exhibitor) => {
		return exhibitor.user._id === userId;
	});
	return exhibitorIndex !== -1;
};

export const getExhibitorData = (eventExhibitorsArray, userId) => {
	const exhibitor = eventExhibitorsArray.find((exhibitor) => {
		return exhibitor.user === userId;
	});
	return exhibitor ? exhibitor : null;
};

export const checkUserIsOrganizer = (eventOwnerId, userId) => {
	return eventOwnerId === userId;
};

export const checkUserIsExhibitorRepresentative = (
	eventExhibitorsArray,
	userId
) => {
	const exhibitorIndex = eventExhibitorsArray.findIndex((exhibitor) => {
		const booth = exhibitor.booth;
		const isMedalionRepresentative = booth.medalionRepresentatives.find(
			(representative) => representative.user === userId
		);
		const isLeftDeskRepresentative =
			booth.deskRepresentativeLeft.user === userId;
		const isRightDeskRepresentative =
			booth.deskRepresentativeRight.user === userId;

		return (
			isMedalionRepresentative ||
			isLeftDeskRepresentative ||
			isRightDeskRepresentative
		);
	});
	return exhibitorIndex !== -1;
};

export const getTimeAgoString = (fromTime, language = 'en') => {
	let timeAgoString = '';

	let messageTime = new Date(fromTime);
	messageTime = messageTime.getTime();

	let currentTime = new Date();
	currentTime = currentTime.getTime();

	let timeAgoSeconds = Math.abs((currentTime - messageTime) / 1000);
	timeAgoSeconds = Math.floor(timeAgoSeconds);

	if (timeStrings[`${language}`] === undefined) language = 'en';

	if (timeAgoSeconds < 60) {
		timeAgoString = timeStrings[`${language}`].justNow;
	} else if (timeAgoSeconds < 60 * 60) {
		let minutes = Math.floor(timeAgoSeconds / 60);
		timeAgoString =
			minutes +
			' ' +
			(minutes > 1
				? timeStrings[`${language}`].minutes
				: timeStrings[`${language}`].minute);
	} else if (timeAgoSeconds < 60 * 60 * 24) {
		let hours = Math.floor(timeAgoSeconds / (60 * 60));
		timeAgoString =
			hours +
			' ' +
			(hours > 1
				? timeStrings[`${language}`].hours
				: timeStrings[`${language}`].hour);
	} else if (timeAgoSeconds < 60 * 60 * 24 * 30) {
		let days = Math.floor(timeAgoSeconds / (60 * 60 * 24));
		timeAgoString =
			days +
			' ' +
			(days > 1
				? timeStrings[`${language}`].days
				: timeStrings[`${language}`].day);
	} else if (timeAgoSeconds < 60 * 60 * 24 * 30 * 12) {
		let months = Math.floor(timeAgoSeconds / (60 * 60 * 24 * 30));
		timeAgoString =
			months +
			' ' +
			(months > 1
				? timeStrings[`${language}`].months
				: timeStrings[`${language}`].month);
	} else {
		let years = Math.floor(timeAgoSeconds / (60 * 60 * 24 * 30 * 12));
		timeAgoString =
			years +
			' ' +
			(years > 1
				? timeStrings[`${language}`].years
				: timeStrings[`${language}`].year);
	}
	return timeAgoString;
};

export const getNotReadChatMessages = (privateChats, groupChats) => {
	// {unread} is destructuring, we have the property unread on each chat

	let unreadPrivateChatMessages = privateChats.reduce(
		(sum, { unread }) => sum + unread,
		0
	);

	let unreadGroupChatMessages = groupChats.reduce(
		(sum, { unread }) => sum + unread,
		0
	);

	let totalMessagesUnread = unreadPrivateChatMessages + unreadGroupChatMessages;
	return totalMessagesUnread;
};

export const getSortedNotifications = (notifications) => {
	let orderedNotifications = notifications.slice(0).reverse();
	let filteredNotifications = orderedNotifications.filter(
		(notification) =>
			notification.type !== 'newPrivateChatMessage' &&
			notification.type !== 'newGroupChatMessage'
	);
	return filteredNotifications;
};

export const getUtcTimestamp = (date) => {
	const year = date.getUTCFullYear();
	const month = date.getUTCMonth();
	const day = date.getUTCDate();
	const hour = date.getUTCHours();
	const minute = date.getUTCMinutes();
	const seconds = date.getUTCSeconds();

	let timeStamp = new Date(year, month, day, hour, minute, seconds);
	timeStamp = timeStamp.getTime();
	return timeStamp;
};

export const getVideoIdFromYoutubeUrl = (url) => {
	let ID = '';
	url = url
		.replace(/(>|<)/gi, '')
		.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
	if (url[2] !== undefined) {
		ID = url[2].split(/[^0-9a-z_-]/i);
		ID = ID[0];
	} else {
		ID = url;
	}
	return ID;
};

export const urlifyString = (text) => {
	const decodedString = text
		.replace(/&#x2F;/g, '/')
		.replace(/&#x5C;/g, '\\')
		.replace(/&amp;/g, '&')
		.replace(/&gt;/g, '>')
		.replace(/&lt;/g, '<')
		.replace(/&quot;/g, '"')
		.replace(/&#x27;/g, `'`)
		.replace(/&apos;/g, `'`)
		.replace(/&#96;/g, '`');
	const urlRegex = /(https?:\/\/[^\s]+)/g;

	let parts = decodedString.split(urlRegex);
	for (let i = 1; i < parts.length; i += 2) {
		parts[i] = (
			<a
				rel="noopener noreferrer"
				key={'link' + i}
				href={parts[i]}
				target="_blank"
			>
				{parts[i]}
			</a>
		);
	}
	return parts;
};

export const linkify = (stringURL) => {
	// eslint-disable-next-line no-useless-escape
	const urlPattern =
		/\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;
	// www. sans http:// or https://
	// eslint-disable-next-line no-useless-escape
	const pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
	// Email addresses
	const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;
	return stringURL
		.replace(
			urlPattern,
			'<a target="_blank" rel="noopener noreferrer" href="$&">$&</a>'
		)
		.replace(
			pseudoUrlPattern,
			'$1<a target="_blank" rel="noopener noreferrer" href="http://$2">$2</a>'
		)
		.replace(
			emailAddressPattern,
			'<a target="_blank" rel="noopener noreferrer" href="mailto:$&">$&</a>'
		);
};

export const sortExhibitors = (exhibitors) => {
	// we first sort and display the exhibitors of type company
	let updatedExhibitors = [];
	const companyExhibitors = exhibitors.filter(
		(exhibitor) => exhibitor.type === EXHIBITOR_TYPES.company
	);
	const scholarExhibitors = exhibitors.filter(
		(exhibitor) => exhibitor.type === EXHIBITOR_TYPES.scholar
	);

	companyExhibitors.sort(function (x, y) {
		return x.company.toLowerCase() < y.company.toLowerCase() ? -1 : 1;
	});
	scholarExhibitors.sort(function (x, y) {
		return x?.user?.last?.toLowerCase() < y?.user?.last?.toLowerCase() ? -1 : 1;
	});
	updatedExhibitors = companyExhibitors.concat(scholarExhibitors);
	return updatedExhibitors;
};

export const getEventDaysArray = (eventStartDate, eventEndDate) => {
	// had a bug with this imlementation where it duplicated a day: 28.03
	// const oneDay = 24 * 60 * 60 * 1000;
	// const startDate = new Date(eventStartDate);
	// const endDate = new Date(eventEndDate);
	// const numberOfEventDays = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1;
	//
	// let eventDays = [];
	// for (let i = 0; i < numberOfEventDays; i++) {
	//     let newDate = new Date(eventStartDate);
	//     newDate.setDate(startDate.getDate() + i);
	//     let formatedDate = newDate.toISOString().slice(0, 10);
	//     eventDays.push(formatedDate);
	// }

	// it looks like moment implementation is OK
	let eventDays = [];
	while (moment(eventStartDate) <= moment(eventEndDate)) {
		eventDays.push(eventStartDate);
		eventStartDate = moment(eventStartDate).add(1, 'days').format('YYYY-MM-DD');
	}
	return eventDays;
};

export const getOngoingEventDays = (event) => {
	// Extract, sort and filter the days from the daily program of the ongoing event
	const days =
		event.auditoriums[0]?.dailyProgram?.map((dayProgram) => dayProgram.date) ||
		event.auditoriums[0]?.displayProgram?.map(
			(dayProgram) => dayProgram.date
		) ||
		[];
	const sortedDays = days.sort((a, b) => a.localeCompare(b));
	const daysSet = new Set(sortedDays);

	// Get event day tabs based on the sorted days and filter them
	let eventDays = getEventDayTabs(
		sortedDays[0],
		sortedDays[sortedDays.length - 1],
		event.auditoriums
	);

	return eventDays.filter((tab) => daysSet.has(tab.name));
};
export const getEventDayTabs = (eventStartDate, eventEndDate, auditoriums) => {
	const eventDays = [];
	let dayIndex = 0;
	while (moment(eventStartDate) <= moment(eventEndDate)) {
		eventDays.push({
			path: eventStartDate,
			name: eventStartDate,
			label: `Day ${dayIndex + 1} (${getFormatedDate(eventStartDate)})`,
			subTabs: auditoriums.map((auditorium) => ({
				path: auditorium._id,
				name: auditorium._id,
				label: auditorium.name,
			})),
		});
		eventStartDate = moment(eventStartDate).add(1, 'days').format('YYYY-MM-DD');
		dayIndex++;
	}
	return eventDays;
};

export const getFormatedDate = (dateToFormat, fullMonth) => {
	const date = moment(dateToFormat).startOf('day');
	let monthsArray = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	if (fullMonth) {
		monthsArray = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];
	}
	const d = date.date();
	const m = monthsArray[date.month()];
	const y = date.year();

	const formatedDate = (d <= 9 ? '0' + d : d) + ' ' + m + ' ' + y;

	return formatedDate;
};

export const getFormattedMonth = (date, translation) => {
	let months = [
		translation?.time.januaryShort,
		translation?.time.februaryShort,
		translation?.time.marchShort,
		translation?.time.aprilShort,
		translation?.time.mayShort,
		translation?.time.juneShort,
		translation?.time.julyShort,
		translation?.time.augustShort,
		translation?.time.septemberShort,
		translation?.time.octoberShort,
		translation?.time.novemberShort,
		translation?.time.decemberShort,
	];
	const currentDate = new Date(date);
	const m = months[currentDate.getMonth()];
	return m;
};

export const getTranslatedMonths = (platformLanguage, monthsTranslations) => {
	let months;

	if (platformLanguage === 'ua') {
		// for on-demand months should be inclined on UA
		months = [
			'січня',
			'лютого',
			'березня',
			'квітня',
			'травня',
			'червня',
			'липня',
			'серпня',
			'вересня',
			'жовтня',
			'листопада',
			'грудня',
		];
	} else {
		months = [
			monthsTranslations.january,
			monthsTranslations.february,
			monthsTranslations.march,
			monthsTranslations.april,
			monthsTranslations.may,
			monthsTranslations.june,
			monthsTranslations.july,
			monthsTranslations.august,
			monthsTranslations.september,
			monthsTranslations.october,
			monthsTranslations.november,
			monthsTranslations.december,
		];
	}

	return months;
};

export const checkPlatformLanguage = (platformLanguage, languageArray) => {
	return languageArray.indexOf(platformLanguage) !== -1;
};

/**
 *
 * @param currentDay: date to format
 * @param languages: is the entire object from the redux store
 * @returns {string}
 */
export const getProgramDayString = (currentDay, languages) => {
	const { translations, platformLanguage } = languages;

	const monthsTranslations = translations[platformLanguage]?.time;

	let months = getTranslatedMonths(platformLanguage, monthsTranslations);

	let currentDate = moment(currentDay).startOf('day');
	let day = currentDate.date();
	let month = months[currentDate.month()];
	let year = currentDate.year();

	return `${day} ${month} ${year}`;
};

export const timezonesList = [
	{ label: '(GMT-12:00) International Date Line West', value: -12 },
	{ label: '(GMT-11:00) Midway Island, Samoa', value: -11 },
	{ label: '(GMT-10:00) Hawaii', value: -10 },
	{ label: '(GMT-09:00) Alaska', value: -9 },
	{ label: '(GMT-08:00) Pacific Time (US & Canada)', value: -8 },
	{ label: '(GMT-08:00) Tijuana, Baja California', value: -8 },
	{ label: '(GMT-07:00) Arizona', value: -7 },
	{ label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', value: -7 },
	{ label: '(GMT-07:00) Mountain Time (US & Canada)', value: -7 },
	{ label: '(GMT-06:00) Central America', value: -6 },
	{ label: '(GMT-06:00) Central Time (US & Canada)', value: -6 },
	{ label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', value: -5 },
	{ label: '(GMT-05:00) Eastern Time (US & Canada)', value: -5 },
	{ label: '(GMT-05:00) Indiana (East)', value: -5 },
	{ label: '(GMT-04:00) Atlantic Time (Canada)', value: -4 },
	{ label: '(GMT-04:00) Caracas, La Paz', value: -4 },
	{ label: '(GMT-04:00) Manaus', value: -4 },
	{ label: '(GMT-04:00) Santiago', value: -4 },
	{ label: '(GMT-03:30) Newfoundland', value: -3.5 },
	{ label: '(GMT-03:00) Brasilia', value: -3 },
	{ label: '(GMT-03:00) Buenos Aires, Georgetown', value: -3 },
	{ label: '(GMT-03:00) Greenland', value: -3 },
	{ label: '(GMT-03:00) Montevideo', value: -3 },
	{ label: '(GMT-02:00) Mid-Atlantic', value: -2 },
	{ label: '(GMT-01:00) Cape Verde Is.', value: -1 },
	{ label: '(GMT-01:00) Azores', value: -1 },
	{ label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', value: 0 },
	{
		label:
			'(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
		value: 0,
	},
	{
		label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
		value: 1,
	},
	{
		label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
		value: 1,
	},
	{ label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', value: 1 },
	{ label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: 1 },
	{ label: '(GMT+01:00) West Central Africa', value: 1 },
	{ label: '(GMT+02:00) Amman', value: 2 },
	{ label: '(GMT+02:00) Athens, Bucharest, Istanbul', value: 2 },
	{ label: '(GMT+02:00) Beirut', value: 2 },
	{ label: '(GMT+02:00) Cairo', value: 2 },
	{ label: '(GMT+02:00) Harare, Pretoria', value: 2 },
	{
		label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
		value: 2,
	},
	{ label: '(GMT+02:00) Jerusalem', value: 2 },
	{ label: '(GMT+03:00) Minsk', value: 3 },
	{ label: '(GMT+02:00) Windhoek', value: 2 },
	{ label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 3 },
	{ label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', value: 3 },
	{ label: '(GMT+03:00) Nairobi', value: 3 },
	{ label: '(GMT+03:00) Tbilisi', value: 3 },
	{ label: '(GMT+03:30) Tehran', value: 3.5 },
	{ label: '(GMT+04:00) Abu Dhabi, Muscat', value: 4 },
	{ label: '(GMT+04:00) Baku', value: 4 },
	{ label: '(GMT+04:00) Yerevan', value: 4 },
	{ label: '(GMT+04:30) Kabul', value: 4.5 },
	{ label: '(GMT+05:00) Yekaterinburg', value: 5 },
	{ label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: 5 },
	{ label: '(GMT+05:30) Sri Jayawardenapura', value: 5.5 },
	{ label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: 5.5 },
	{ label: '(GMT+05:45) Kathmandu', value: 5.75 },
	{ label: '(GMT+06:00) Almaty, Novosibirsk', value: 6 },
	{ label: '(GMT+06:00) Astana, Dhaka', value: 6 },
	{ label: '(GMT+06:30) Yangon (Rangoon)', value: 6.5 },
	{ label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 7 },
	{ label: '(GMT+07:00) Krasnoyarsk', value: 7 },
	{ label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: 8 },
	{ label: '(GMT+08:00) Kuala Lumpur, Singapore', value: 8 },
	{ label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 8 },
	{ label: '(GMT+08:00) Perth', value: 8 },
	{ label: '(GMT+08:00) Taipei', value: 8 },
	{ label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 9 },
	{ label: '(GMT+09:00) Seoul', value: 9 },
	{ label: '(GMT+09:00) Yakutsk', value: 9 },
	{ label: '(GMT+09:30) Adelaide', value: 9.5 },
	{ label: '(GMT+09:30) Darwin', value: 9.5 },
	{ label: '(GMT+10:00) Brisbane', value: 10 },
	{ label: '(GMT+10:00) Canberra, Melbourne, Sydney', value: 10 },
	{ label: '(GMT+10:00) Hobart', value: 10 },
	{ label: '(GMT+10:00) Guam, Port Moresby', value: 10 },
	{ label: '(GMT+10:00) Vladivostok', value: 10 },
	{ label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: 11 },
	{ label: '(GMT+12:00) Auckland, Wellington', value: 12 },
	{ label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: 12 },
	{ label: "(GMT+13:00) Nuku'alofa", value: 13 },
];

export const timezonesListDST = [
	{ label: '(GMT-12:00) International Date Line West', value: -12 },
	{ label: '(GMT-11:00) Midway Island, Samoa', value: -11 },
	{ label: '(GMT-10:00) Hawaii', value: -10 },
	{ label: '(GMT-08:00) Alaska', value: -8 }, // +1
	{ label: '(GMT-07:00) Arizona', value: -7 },
	{ label: '(GMT-07:00) Pacific Time (US & Canada)', value: -7 }, // +1
	{ label: '(GMT-07:00) Tijuana, Baja California', value: -7 }, // +1
	{ label: '(GMT-06:00) Chihuahua, La Paz, Mazatlan', value: -6 }, // +1
	{ label: '(GMT-06:00) Mountain Time (US & Canada)', value: -6 }, // +1
	{ label: '(GMT-05:00) Central America', value: -5 }, // +1
	{ label: '(GMT-05:00) Central Time (US & Canada)', value: -5 }, // +1
	{ label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', value: -5 },
	{ label: '(GMT-05:00) Eastern Time (US & Canada)', value: -5 },
	{ label: '(GMT-04:00) Caracas, La Paz', value: -4 },
	{ label: '(GMT-04:00) Indiana (East)', value: -4 }, // +1
	{ label: '(GMT-04:00) Manaus', value: -4 },
	{ label: '(GMT-03:00) Atlantic Time (Canada)', value: -3 }, // +1
	{ label: '(GMT-03:00) Brasilia', value: -3 },
	{ label: '(GMT-03:00) Buenos Aires, Georgetown', value: -3 },
	{ label: '(GMT-03:00) Montevideo', value: -3 },
	{ label: '(GMT-03:00) Santiago', value: -3 }, // +1
	{ label: '(GMT-02:30) Newfoundland', value: -2.5 }, // +1
	{ label: '(GMT-02:00) Greenland', value: -2 }, // +1
	{ label: '(GMT-02:00) Mid-Atlantic', value: -2 },
	{ label: '(GMT-01:00) Cape Verde Is.', value: -1 },
	{ label: '(GMT-00:00) Azores', value: 0 }, // +1
	{ label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', value: 0 },
	{
		label:
			'(GMT+01:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
		value: 1,
	}, // +1
	{ label: '(GMT+01:00) West Central Africa', value: 1 },
	{
		label: '(GMT+02:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
		value: 2,
	}, // +1
	{
		label: '(GMT+02:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
		value: 2,
	}, // +1
	{ label: '(GMT+02:00) Brussels, Copenhagen, Madrid, Paris', value: 2 }, // +1
	{ label: '(GMT+02:00) Cairo', value: 2 },
	{ label: '(GMT+02:00) Harare, Pretoria', value: 2 },
	{ label: '(GMT+02:00) Sarajevo, Skopje, Warsaw, Zagreb', value: 2 }, // +1
	{ label: '(GMT+02:00) Windhoek', value: 2 },
	{ label: '(GMT+03:00) Amman', value: 3 }, // +1
	{ label: '(GMT+03:00) Athens, Bucharest, Istanbul', value: 3 }, // +1
	{ label: '(GMT+03:00) Beirut', value: 3 }, // +1
	{
		label: '(GMT+03:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
		value: 3,
	}, // +1
	{ label: '(GMT+03:00) Jerusalem', value: 3 }, // +1
	{ label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 3 },
	{ label: '(GMT+03:00) Minsk', value: 3 },
	{ label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', value: 3 },
	{ label: '(GMT+03:00) Nairobi', value: 3 },
	{ label: '(GMT+03:00) Tbilisi', value: 3 },
	{ label: '(GMT+04:00) Abu Dhabi, Muscat', value: 4 },
	{ label: '(GMT+04:00) Baku', value: 4 },
	{ label: '(GMT+04:00) Yerevan', value: 4 },
	{ label: '(GMT+04:30) Kabul', value: 4.5 },
	{ label: '(GMT+04:30) Tehran', value: 4.5 }, // +1
	{ label: '(GMT+05:00) Yekaterinburg', value: 5 },
	{ label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: 5 },
	{ label: '(GMT+05:30) Sri Jayawardenapura', value: 5.5 },
	{ label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: 5.5 },
	{ label: '(GMT+05:45) Kathmandu', value: 5.75 },
	{ label: '(GMT+06:00) Almaty, Novosibirsk', value: 6 },
	{ label: '(GMT+06:00) Astana, Dhaka', value: 6 },
	{ label: '(GMT+06:30) Yangon (Rangoon)', value: 6.5 },
	{ label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 7 },
	{ label: '(GMT+07:00) Krasnoyarsk', value: 7 },
	{ label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: 8 },
	{ label: '(GMT+08:00) Kuala Lumpur, Singapore', value: 8 },
	{ label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 8 },
	{ label: '(GMT+08:00) Perth', value: 8 },
	{ label: '(GMT+08:00) Taipei', value: 8 },
	{ label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 9 },
	{ label: '(GMT+09:00) Seoul', value: 9 },
	{ label: '(GMT+09:00) Yakutsk', value: 9 },
	{ label: '(GMT+09:30) Darwin', value: 9.5 },
	{ label: '(GMT+10:00) Brisbane', value: 10 },
	{ label: '(GMT+10:00) Guam, Port Moresby', value: 10 },
	{ label: '(GMT+10:00) Vladivostok', value: 10 },
	{ label: '(GMT+10:30) Adelaide', value: 10.5 }, // +1
	{ label: '(GMT+11:00) Canberra, Melbourne, Sydney', value: 11 }, // +1
	{ label: '(GMT+11:00) Hobart', value: 11 }, // +1
	{ label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: 11 },
	{ label: '(GMT+12:00) Auckland, Wellington', value: 12 },
	{ label: '(GMT+13:00) Fiji, Kamchatka, Marshall Is.', value: 13 }, // +1
	{ label: "(GMT+13:00) Nuku'alofa", value: 13 },
];

export const countries = [
	{ name: 'Afghanistan', code: 'AF' },
	{ name: 'Albania', code: 'AL' },
	{ name: 'Algeria', code: 'DZ' },
	{ name: 'Andorra', code: 'AD' },
	{ name: 'Angola', code: 'AO' },
	{ name: 'Antigua and Barbuda', code: 'AG' },
	{ name: 'Argentina', code: 'AR' },
	{ name: 'Armenia', code: 'AM' },
	{ name: 'Australia', code: 'AU' },
	{ name: 'Austria', code: 'AT' },
	{ name: 'Azerbaijan', code: 'AZ' },
	{ name: 'Bahamas', code: 'BS' },
	{ name: 'Bahrain', code: 'BH' },
	{ name: 'Bangladesh', code: 'BD' },
	{ name: 'Barbados', code: 'BB' },
	{ name: 'Belarus', code: 'BY' },
	{ name: 'Belgium', code: 'BE' },
	{ name: 'Belize', code: 'BZ' },
	{ name: 'Benin', code: 'BJ' },
	{ name: 'Bhutan', code: 'BT' },
	{ name: 'Bolivia', code: 'BO' },
	{ name: 'Bosnia and Herzegovina', code: 'BA' },
	{ name: 'Botswana', code: 'BW' },
	{ name: 'Brazil', code: 'BR' },
	{ name: 'Brunei Darussalam', code: 'BN' },
	{ name: 'Bulgaria', code: 'BG' },
	{ name: 'Burkina Faso', code: 'BF' },
	{ name: 'Burundi', code: 'BI' },
	{ name: 'Cabo Verde', code: 'CV' },
	{ name: 'Cambodia', code: 'KH' },
	{ name: 'Cameroon', code: 'CM' },
	{ name: 'Canada', code: 'CA' },
	{ name: 'Central African Republic', code: 'CF' },
	{ name: 'Chad', code: 'TD' },
	{ name: 'Chile', code: 'CL' },
	{ name: 'China', code: 'CN' },
	{ name: 'Colombia', code: 'CO' },
	{ name: 'Comoros', code: 'KM' },
	{ name: 'Congo', code: 'CG' },
	{ name: 'Costa Rica', code: 'CR' },
	{ name: "Côte D'Ivoire", code: 'CI' },
	{ name: 'Croatia', code: 'HR' },
	{ name: 'Cuba', code: 'CU' },
	{ name: 'Cyprus', code: 'CY' },
	{ name: 'Czech Republic', code: 'CZ' },
	{ name: 'DPR of Korea (North)', code: 'KP' },
	{ name: 'Democratic Republic of the Congo', code: 'CD' },
	{ name: 'Denmark', code: 'DK' },
	{ name: 'Djibouti', code: 'DJ' },
	{ name: 'Dominica', code: 'DM' },
	{ name: 'Dominican Republic', code: 'DO' },
	{ name: 'Ecuador', code: 'EC' },
	{ name: 'Egypt', code: 'EG' },
	{ name: 'El Salvador', code: 'SV' },
	{ name: 'Equatorial Guinea', code: 'GQ' },
	{ name: 'Eritrea', code: 'ER' },
	{ name: 'Estonia', code: 'EE' },
	{ name: 'Eswatini', code: 'SZ' },
	{ name: 'Ethiopia', code: 'ET' },
	{ name: 'Fiji', code: 'FJ' },
	{ name: 'Finland', code: 'FI' },
	{ name: 'France', code: 'FR' },
	{ name: 'Gabon', code: 'GA' },
	{ name: 'Gambia', code: 'GM' },
	{ name: 'Georgia', code: 'GE' },
	{ name: 'Germany', code: 'DE' },
	{ name: 'Ghana', code: 'GH' },
	{ name: 'Greece', code: 'GR' },
	{ name: 'Grenada', code: 'GD' },
	{ name: 'Guatemala', code: 'GT' },
	{ name: 'Guinea', code: 'GN' },
	{ name: 'Guinea Bissau', code: 'GW' },
	{ name: 'Guyana', code: 'GY' },
	{ name: 'Haiti', code: 'HT' },
	{ name: 'Honduras', code: 'HN' },
	{ name: 'Hungary', code: 'HU' },
	{ name: 'Iceland', code: 'IS' },
	{ name: 'India', code: 'IN' },
	{ name: 'Indonesia', code: 'ID' },
	{ name: 'Iran', code: 'IR' },
	{ name: 'Iraq', code: 'IQ' },
	{ name: 'Ireland', code: 'IE' },
	{ name: 'Israel', code: 'IL' },
	{ name: 'Italy', code: 'IT' },
	{ name: 'Jamaica', code: 'JM' },
	{ name: 'Japan', code: 'JP' },
	{ name: 'Jordan', code: 'JO' },
	{ name: 'Kazakhstan', code: 'KZ' },
	{ name: 'Kenya', code: 'KE' },
	{ name: 'Kiribati', code: 'KI' },
	{ name: 'Korea (South)', code: 'KR' },
	{ name: 'Kuwait', code: 'KW' },
	{ name: 'Kyrgyzstan', code: 'KG' },
	{ name: 'Laos', code: 'LA' },
	{ name: 'Latvia', code: 'LV' },
	{ name: 'Lebanon', code: 'LB' },
	{ name: 'Lesotho', code: 'LS' },
	{ name: 'Liberia', code: 'LR' },
	{ name: 'Libya', code: 'LY' },
	{ name: 'Liechtenstein', code: 'LI' },
	{ name: 'Lithuania', code: 'LT' },
	{ name: 'Luxembourg', code: 'LU' },
	{ name: 'Madagascar', code: 'MG' },
	{ name: 'Malawi', code: 'MW' },
	{ name: 'Malaysia', code: 'MY' },
	{ name: 'Maldives', code: 'MV' },
	{ name: 'Mali', code: 'ML' },
	{ name: 'Malta', code: 'MT' },
	{ name: 'Marshall Islands', code: 'MH' },
	{ name: 'Mauritania', code: 'MR' },
	{ name: 'Mauritius', code: 'MU' },
	{ name: 'Mexico', code: 'MX' },
	{ name: 'Micronesia', code: 'FM' },
	{ name: 'Moldova', code: 'MD' },
	{ name: 'Monaco', code: 'MC' },
	{ name: 'Mongolia', code: 'MN' },
	{ name: 'Montenegro', code: 'ME' },
	{ name: 'Morocco', code: 'MA' },
	{ name: 'Mozambique', code: 'MZ' },
	{ name: 'Myanmar', code: 'MM' },
	{ name: 'Namibia', code: 'NA' },
	{ name: 'Nauru', code: 'NR' },
	{ name: 'Nepal', code: 'NP' },
	{ name: 'Netherlands', code: 'NL' },
	{ name: 'New Zealand', code: 'NZ' },
	{ name: 'Nicaragua', code: 'NI' },
	{ name: 'Niger', code: 'NE' },
	{ name: 'Nigeria', code: 'NG' },
	{ name: 'North Macedonia', code: 'MK' },
	{ name: 'Norway', code: 'NO' },
	{ name: 'Oman', code: 'OM' },
	{ name: 'Pakistan', code: 'PK' },
	{ name: 'Palau', code: 'PW' },
	{ name: 'Palestine', code: 'PS' },
	{ name: 'Panama', code: 'PA' },
	{ name: 'Papua New Guinea', code: 'PG' },
	{ name: 'Paraguay', code: 'PY' },
	{ name: 'Peru', code: 'PE' },
	{ name: 'Philippines', code: 'PH' },
	{ name: 'Poland', code: 'PL' },
	{ name: 'Portugal', code: 'PT' },
	{ name: 'Qatar', code: 'QA' },
	{ name: 'Romania', code: 'RO' },
	{ name: 'Russia', code: 'RU' },
	{ name: 'Rwanda', code: 'RW' },
	{ name: 'Sahrawi Republic', code: 'EH' },
	{ name: 'Saint Kitts and Nevis', code: 'KN' },
	{ name: 'Saint Lucia', code: 'LC' },
	{ name: 'Saint Vincent and the Grenadines', code: 'VC' },
	{ name: 'Samoa', code: 'WS' },
	{ name: 'San Marino', code: 'SM' },
	{ name: 'Sao Tome and Principe', code: 'ST' },
	{ name: 'Saudi Arabia', code: 'SA' },
	{ name: 'Senegal', code: 'SN' },
	{ name: 'Serbia', code: 'RS' },
	{ name: 'Seychelles', code: 'SC' },
	{ name: 'Sierra Leone', code: 'SL' },
	{ name: 'Singapore', code: 'SG' },
	{ name: 'Slovakia', code: 'SK' },
	{ name: 'Slovenia', code: 'SI' },
	{ name: 'Solomon Islands', code: 'SB' },
	{ name: 'Somalia', code: 'SO' },
	{ name: 'South Africa', code: 'ZA' },
	{ name: 'South Sudan', code: 'SS' },
	{ name: 'Spain', code: 'ES' },
	{ name: 'Sri Lanka', code: 'LK' },
	{ name: 'Sudan', code: 'SD' },
	{ name: 'Suriname', code: 'SR' },
	{ name: 'Sweden', code: 'SE' },
	{ name: 'Switzerland', code: 'CH' },
	{ name: 'Syria', code: 'SY' },
	{ name: 'Tajikistan', code: 'TJ' },
	{ name: 'Tanzania', code: 'TZ' },
	{ name: 'Thailand', code: 'TH' },
	{ name: 'Timor-Leste', code: 'TL' },
	{ name: 'Togo', code: 'TG' },
	{ name: 'Tonga', code: 'TO' },
	{ name: 'Trinidad and Tobago', code: 'TT' },
	{ name: 'Tunisia', code: 'TN' },
	{ name: 'Turkey', code: 'TR' },
	{ name: 'Turkmenistan', code: 'TM' },
	{ name: 'Tuvalu', code: 'TV' },
	{ name: 'Uganda', code: 'UG' },
	{ name: 'Ukraine', code: 'UA' },
	{ name: 'United Arab Emirates', code: 'AE' },
	{ name: 'United Kingdom', code: 'GB' },
	{ name: 'United States of America', code: 'US' },
	{ name: 'Uruguay', code: 'UY' },
	{ name: 'Uzbekistan', code: 'UZ' },
	{ name: 'Vanuatu', code: 'VU' },
	{ name: 'Vatican City State', code: 'VA' },
	{ name: 'Venezuela', code: 'VE' },
	{ name: 'Vietnam', code: 'VN' },
	{ name: 'Yemen', code: 'YE' },
	{ name: 'Zambia', code: 'ZM' },
	{ name: 'Zimbabwe', code: 'ZW' },
];

export const getDayWithSuffix = (i) => {
	let j = i % 10,
		k = i % 100;
	if (j === 1 && k !== 11) {
		return i + 'st';
	}
	if (j === 2 && k !== 12) {
		return i + 'nd';
	}
	if (j === 3 && k !== 13) {
		return i + 'rd';
	}
	return i + 'th';
};

export const stripeCurrencies = [
	{ currencyCode: 'usd', currencyLabel: 'USD', minimumAmmount: 1 },
	{ currencyCode: 'eur', currencyLabel: 'EUR', minimumAmmount: 1 },
	{ currencyCode: 'gbp', currencyLabel: 'GBP', minimumAmmount: 1 },
	{ currencyCode: 'nok', currencyLabel: 'NOK', minimumAmmount: 10 },
	{ currencyCode: 'sek', currencyLabel: 'SEK', minimumAmmount: 10 },
	{ currencyCode: 'dkk', currencyLabel: 'DKK', minimumAmmount: 10 },
];

export const getCurrencySymbol = (currencyCode) => {
	let eventCurrency = '';
	switch (currencyCode) {
		case 'eur':
			eventCurrency = '€';
			break;
		case 'usd':
			eventCurrency = '$';
			break;
		case 'gbp':
			eventCurrency = '£';
			break;
		case 'nok':
			eventCurrency = 'NOK';
			break;
		case 'sek':
			eventCurrency = 'SEK';
			break;
		case 'dkk':
			eventCurrency = 'DKK';
			break;
		default:
			eventCurrency = currencyCode;
	}
	return eventCurrency;
};

export const getLanguageName = (languageCode) => {
	const currentLanguage = languagesArray.find(
		(language) => language.value === languageCode
	);
	return currentLanguage?.label;
};

export const checkIfLanguageExists = (languageCode) => {
	const languageExists = (lang) => lang.value === languageCode;
	return languagesArray.some(languageExists);
};

export const getLanguageFromUrl = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const urlLanguageCode = urlParams.get('lang');

	const languageIsValid = checkIfLanguageExists(urlLanguageCode);

	// if lang paramter exists in URL
	if (urlLanguageCode) {
		if (languageIsValid) {
			return urlLanguageCode;
		} else {
			// if lang parameter exists but is not valid
			// return en to not crash the app (white screen)
			return 'en';
		}
	}
	// by default, if language parameter doesn't exist, we return false
	// because we don't want to update the event language
	return false;
};

export const getUserDataExcelFields = (event, registrationFields) => {
	let excelFields = [
		{ label: 'Name', value: 'fullName' },
		{ label: 'Email', value: 'email' },
		{ label: 'Role', value: 'role' },
		{ label: 'Registration Date', value: 'registrationDate' },
		{ label: 'Registration Type', value: 'registrationType' },
	];

	const { hasAccessManagement, exhibitorHasAccessManagement, closedEvent } =
		event;

	const {
		exhibitorPredefinedRegistrationFields,
		participantPredefinedRegistrationFields,
		exhibitorRegistrationFields,
		participantRegistrationFields,
	} = registrationFields;

	if (hasAccessManagement || exhibitorHasAccessManagement) {
		excelFields.push({ label: 'Access Package', value: 'accessPackage' });
	}
	if (closedEvent) {
		excelFields.push({ label: 'Code', value: 'invitationCode' });
	}

	// for each predefined field that "isEnabled", push it to Excel
	// the predefined fields are the same for Exhibitor or Participant
	// if one is enabled on one of them (participant / exhibitor), we push it to Excel
	Object.keys(exhibitorPredefinedRegistrationFields).map((key) => {
		const exhibitorPredefinedField = exhibitorPredefinedRegistrationFields[key];
		const participantPredefinedField =
			participantPredefinedRegistrationFields[key];
		if (
			exhibitorPredefinedField.isEnabled ||
			participantPredefinedField.isEnabled
		) {
			let fieldLabel = key.charAt(0).toUpperCase() + key.slice(1);
			if (event.scholarExhibitorsType && fieldLabel === 'Company') {
				fieldLabel = 'Company / Affiliation';
			}
			excelFields.push({ label: fieldLabel, value: key });
		}

		return null;
	});

	// custom registration fields
	let customFields = [];
	exhibitorRegistrationFields.map((el) => {
		customFields.push(el.label);
		return null;
	});
	participantRegistrationFields.map((el) => {
		if (!customFields.includes(el.label)) {
			customFields.push(el.label);
		}
		return null;
	});

	customFields.map((el) => {
		let label = el;
		let value = el.toLowerCase();
		let obj = {};
		obj['label'] = label;
		obj['value'] = value;
		excelFields.push(obj);
		return null;
	});

	const data = {
		excelFields: excelFields,
		customFields: customFields,
	};

	return data;
};

export const checkTimeslotHasResources = (timeSlot) => {
	let timeslotHasResources = false;
	if (timeSlot) {
		if (
			('links' in timeSlot && timeSlot.links.length) ||
			('documents' in timeSlot && timeSlot.documents.length) ||
			('description' in timeSlot && timeSlot.description.length)
		) {
			timeslotHasResources = true;
		}
	}
	return timeslotHasResources;
};

export const getFormatedTimeFromSecondsToHMS = (totalSeconds) => {
	if (totalSeconds) {
		let hms = '';
		let hours = Math.floor(totalSeconds / 3600);
		hours = hours < 10 ? `0${hours}` : hours;

		totalSeconds %= 3600;
		let minutes = Math.floor(totalSeconds / 60);
		minutes = minutes < 10 ? `0${minutes}` : minutes;

		let seconds = Math.floor(totalSeconds % 60);
		seconds = seconds < 10 ? `0${seconds}` : seconds;
		hms = `${hours}:${minutes}:${seconds}`;
		return hms;
	} else {
		return '00:00:00';
	}
};

export const getBackgroundImageUrl = (eventInfo, location) => {
	let backgroundImage;

	let smallBackgroundImage =
		eventInfo.brandingData.filesUrl +
		(eventInfo.brandingData[location].resize1080
			? eventInfo.brandingData[location].resize1080
			: eventInfo.brandingData.location);
	let mediumBackgroundImage =
		eventInfo.brandingData.filesUrl +
		(eventInfo.brandingData[location].resize2160
			? eventInfo.brandingData[location].resize2160
			: eventInfo.brandingData[location]);
	let originalBackgroundImage =
		eventInfo.brandingData.filesUrl +
		(eventInfo.brandingData[location].original
			? eventInfo.brandingData[location].original
			: eventInfo.brandingData[location]);

	if (window.innerHeight < 1081) {
		backgroundImage = smallBackgroundImage;
	} else if (window.innerHeight < 2161) {
		backgroundImage = mediumBackgroundImage;
	} else {
		backgroundImage = originalBackgroundImage;
	}

	return backgroundImage;
};

export const getHPBackgroundImageURL = (eventInfo, location) => {
	let backgroundImage;

	let smallBackgroundImage =
		eventInfo.brandingData.filesUrl +
		(eventInfo.brandingData[location].resize768
			? eventInfo.brandingData[location].resize768
			: eventInfo.brandingData.location);
	let mediumBackgroundImage =
		eventInfo.brandingData.filesUrl +
		(eventInfo.brandingData[location].resize900
			? eventInfo.brandingData[location].resize900
			: eventInfo.brandingData[location]);
	let originalBackgroundImage =
		eventInfo.brandingData.filesUrl +
		(eventInfo.brandingData[location].resize1080
			? eventInfo.brandingData[location].resize1080
			: eventInfo.brandingData[location]);

	if (window.innerWidth <= 1024) {
		if (window.innerWidth <= 767) {
			backgroundImage = smallBackgroundImage;
		} else {
			backgroundImage = mediumBackgroundImage;
		}
	} else {
		backgroundImage = originalBackgroundImage;
	}

	return backgroundImage;
};

export const getUserEventRolesString = (user) => {
	let rolesString = '';
	if (user.isCoOrganizer) {
		rolesString += 'Co-Organizer, ';
	}
	if (user.isParticipant) {
		rolesString += 'Participant, ';
	}
	if (user.isExhibitor) {
		rolesString += 'Exhibitor, ';
	}
	if (user.isExhibitorRepresentative) {
		rolesString += 'Exhibitor Representative, ';
	}
	if (user.isOrganizerRepresentative) {
		rolesString += 'Receptionist, ';
	}
	if (user.isScholar) {
		rolesString += 'Poster / Study, ';
	}
	if (user.isScholarRepresentative) {
		rolesString += 'Poster / Study Representative, ';
	}
	if (user.isSpeaker) {
		rolesString += 'Speaker, ';
	}
	if (user?.user?.eventRoles.roles.includes('organizer')) {
		rolesString += 'Organizer, ';
	}

	// we will remove the last 2 strings from the rolesString:  ", "
	rolesString = rolesString.slice(0, -2);
	return rolesString;
};

export const checkBoothOwnerOrRepresentative = (exhibitor, userId) => {
	if (!exhibitor) return false;
	if (!userId) return false;

	if (userId === exhibitor.user) {
		return true;
	}
	const boothData = exhibitor.booth;
	if (boothData['deskRepresentativeLeft']?.user) {
		if (userId === boothData['deskRepresentativeLeft']?.user._id) {
			return true;
		}
	}
	if (boothData['deskRepresentativeRight']?.user) {
		if (userId === boothData['deskRepresentativeRight']?.user._id) {
			return true;
		}
	}
	if (boothData['presentingRepresentative']?.user) {
		if (userId === boothData['presentingRepresentative']?.user._id) {
			return true;
		}
	}
	if (boothData['medalionRepresentatives']?.length > 0) {
		const isMedalionRepresentative = boothData['medalionRepresentatives'].some(
			(representative) => {
				return userId === representative.user._id;
			}
		);
		return isMedalionRepresentative;
	}

	return false;
};

export const preventDefaultDrag = (e) => {
	e.preventDefault();
};

export const stopPropagation = (e) => {
	e.stopPropagation();
};

export const d = (date) => {
	if (date) return new Date(date);
	else {
		return new Date();
	}
};

export const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const days = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

export const DEFAULT_MEDIA_KINDS = ['audio', 'video'];

export const copyToClipboard = (value) => {
	const el = document.createElement('textarea');
	el.value = value;
	el.setAttribute('readonly', '');
	el.style.position = 'absolute';
	el.style.left = '-9999px';
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
};

export const calculatePxByScreenHeight = () => {
	let screenHeight = window.innerHeight;
	return Math.round((screenHeight * 23) / 1000);
};

export const handleModularCoOrganizerMenu = (accessRights, eventProps) => {
	if (accessRights.setup) return 'dashboard/settings';
	if (accessRights.branding) return 'dashboard/branding';
	if (accessRights.auditoriums) return 'dashboard/auditoriums';
	if (accessRights.program) return 'dashboard/program';
	if (accessRights.showfloors) return 'dashboard/showfloor';
	if (accessRights.users) return 'dashboard/participants';
	if (accessRights.statistics) return 'dashboard/statistics';
	if (accessRights.communication) return 'dashboard/communication';
	if (accessRights.archive) return 'dashboard/archived-events';
	if (accessRights.gdpr) return 'dashboard/event-gdpr';
	if (accessRights.accessManagement) return 'dashboard/event-payment';
	if (accessRights.sessions && eventProps.sessionsStreaming)
		return 'dashboard/event-sessions';
};
export let UUID = () => {
	let s4 = () => {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	};
	//return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
	return (
		s4() +
		s4() +
		'-' +
		s4() +
		'-' +
		s4() +
		'-' +
		s4() +
		'-' +
		s4() +
		s4() +
		s4()
	);
};

export const isUUID = (str, version = 'all') => {
	const uuid = {
		3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
		4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
		5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
		all: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
	};
	return uuid[version] && uuid[version].test(str);
};

export const sortFn = (a, b) => {
	if (isNaN(a)) {
		return -1;
	} else if (isNaN(b)) {
		return 1;
	}
	return a === b ? 0 : a < b ? -1 : 1;
};

export const sortABC = (a, b) => {
	if (a.first.toLowerCase() === b.first.toLowerCase()) {
		return a.last.toLowerCase() < b.last.toLowerCase() ? -1 : 1;
	}
	return a.first.toLowerCase() < b.first.toLowerCase() ? -1 : 1;
};

export const getCookie = (cname) => {
	let name = cname + '=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
};

export const formatDecimalHoursToHoursAndMinutes = (hours) => {
	//
	const absHours = Math.abs(+hours);

	// if we have a whole number, without decimals, return it
	if (Number.isInteger(absHours)) {
		return absHours;
	} else {
		let n = new Date(0, 0);
		n.setMinutes(absHours * 60);
		const result = n.toTimeString().slice(0, 5);
		return result;
	}
};

export const formatTimeHHss = (dateObj) => {
	return moment(moment(dateObj).format('HH:mm'), 'HH:mm');
};
export const getSelectedLobbyOrAuditorium = (renderType) => {
	return renderType ? renderType : 'v1';
};

export const capitalizeFirstLetterOfEachWord = (string) => {
	if (!string) return;
	return string
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.substring(1))
		.join(' ');
};

function capitalizeFirstLetter(string) {
	if (!string) return;
	return string[0].toUpperCase() + string.slice(1);
}

export const separateFileNameFromExtension = (file) => {
	const index = file.lastIndexOf('.');
	const fileName = file.slice(0, index);
	const extension = file.slice(index + 1);

	return { fileName, extension };
};

export const fireClickEvent = (element) => {
	const event = new MouseEvent('click', {
		view: window,
		bubbles: true,
		cancelable: true,
	});
	element.dispatchEvent(event);
};

export const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value; //assign the value of ref to the argument
	}, [value]); //this code will run when the value of 'value' changes
	return ref.current; //in the end, return the current ref value.
};

export const checkIfEmailIsValid = (value) => {
	let rule = isEmailValid;
	let match = rule.test(value);

	if (value.length === 0) {
		match = true;
	}
	if (!match) {
		return false;
	}
	return true;
};

// updates <a> tags in text to open hyperlinks in new tab
export const updateHyperlinksFromText = (initialText) => {
	if (!initialText) {
		return '';
	}
	let text = initialText;

	// find each index in text where to insert
	const insertIndexArray = [];
	let startIndex = text.indexOf('<a');
	while (startIndex !== -1) {
		const closeIndex = text.indexOf('>', startIndex);
		const textInsideTag = text.slice(startIndex, closeIndex);
		// check if tag already contains attribute
		const indexOfAttribute = textInsideTag.indexOf('target=');
		if (indexOfAttribute === -1) {
			insertIndexArray.push(closeIndex);
		}
		startIndex = text.indexOf('<a', closeIndex);
	}

	// insert the attribute at each index
	for (let i = insertIndexArray.length - 1; i >= 0; i--) {
		const insertIndex = insertIndexArray[i];
		const firstText = text.slice(0, insertIndex);
		const secondText = text.slice(insertIndex);
		text = firstText + ' target="_blank"' + secondText;
	}

	return text;
};

export const hasOnlyEmptySpaces = (value = '') => {
	if (value.length <= 1) {
		return value !== ' ';
	}
	return value.trim().length !== 0;
};

export const minCharactersAndOptional = (value = '', minLength) => {
	if (!value) {
		return true;
	}
	return value.length >= minLength;
};

export const checkEventRoles = (eventRoles) => {
	return (
		eventRoles.isOrganizer ||
		eventRoles.isCoOrganizer ||
		eventRoles.isExhibitor ||
		eventRoles.isExhibitorRep
	);
};

export const checkIfEventLanguagesIncludeUserLanguage = (
	platformLanguage,
	eventLanguages
) => {
	return eventLanguages.some(
		(eventLanguage) => eventLanguage.language === platformLanguage
	);
};

// removes from a string the following characters: \ / : * ? " < > |
export const cleanProhibitedCharactersInFileName = (initialFileName) => {
	const cleanFileName = initialFileName?.replace(
		/\/|\\|\:|\*|\?|\<|\>|\||"/g,
		''
	);
	return cleanFileName ?? '_';
};

export const setErrorMessageForInvitationCodes = (
	errCode,
	translation,
	defaultTranslation
) => {
	if (errCode === INVITATION_ERROR_CODES.codeInvalid) {
		return (
			translation?.errors?.invitationCodeInvalid ||
			defaultTranslation?.errors?.invitationCodeInvalid
		);
	} else if (errCode === INVITATION_ERROR_CODES.limitReached) {
		return (
			translation?.errors?.invitationCodeLimitReached ||
			defaultTranslation?.errors?.invitationCodeLimitReached
		);
	}
};

export const createEmailTemplate = (body, bgColor, imageLink, title) => {
	let template = emailTemplate.replace('[BODY-TEMPLATE]', body);
	template = template.replace(/\[BG-COLOR\]/g, bgColor);
	template = template.replace('[HEADER-IMAGE]', imageLink);
	template = template.replace('[HEADER-TITLE]', title);
	template = template.replace('[COPYRIGHT-YEAR]', new Date().getFullYear());
	template = template.replace(
		/<a/g,
		'<a style="color: #208918; font-weight: 700; text-decoration: none;"'
	);
	return template;
};

export const createOrganizerGenericTemplate = (bgColorHeader, body, year) => {
	let template = organizerEmailTemplate.replace('[BODY-TEMPLATE]', body);

	template = template.replace(/\[BG-COLOR-HEADER\]/g, bgColorHeader);
	template = template.replace('[BODY_TEXT]', body);
	template = template.replace('[YEAR]', year);

	return template;
};

export const stripHTML = (htmlString) => {
	if (!htmlString) return '';

	let doc = new DOMParser().parseFromString(htmlString, 'text/html');
	return doc.body.textContent || '';
};

export const getDay = (eventDate) => {
	return moment(eventDate).format('DD');
};

export const getShortMonthsTranslated = (languages) => {
	let platformLanguage =
		languages.platformLanguage === 'ar' ? 'en' : languages.platformLanguage;
	return [
		languages.translations[platformLanguage]?.time.januaryShort,
		languages.translations[platformLanguage]?.time.februaryShort,
		languages.translations[platformLanguage]?.time.marchShort,
		languages.translations[platformLanguage]?.time.aprilShort,
		languages.translations[platformLanguage]?.time.mayShort,
		languages.translations[platformLanguage]?.time.juneShort,
		languages.translations[platformLanguage]?.time.julyShort,
		languages.translations[platformLanguage]?.time.augustShort,
		languages.translations[platformLanguage]?.time.septemberShort,
		languages.translations[platformLanguage]?.time.octoberShort,
		languages.translations[platformLanguage]?.time.novemberShort,
		languages.translations[platformLanguage]?.time.decemberShort,
	];
};

export const getAllMonthsTranslated = (languages) => {
	// let platformLanguage = languages.platformLanguage === 'ar' ? 'en' : languages.platformLanguage;
	let platformLanguage = languages.platformLanguage;
	let months = [
		languages.translations[platformLanguage]?.time.january,
		languages.translations[platformLanguage]?.time.february,
		languages.translations[platformLanguage]?.time.march,
		languages.translations[platformLanguage]?.time.april,
		languages.translations[platformLanguage]?.time.may,
		languages.translations[platformLanguage]?.time.june,
		languages.translations[platformLanguage]?.time.july,
		languages.translations[platformLanguage]?.time.august,
		languages.translations[platformLanguage]?.time.september,
		languages.translations[platformLanguage]?.time.october,
		languages.translations[platformLanguage]?.time.november,
		languages.translations[platformLanguage]?.time.december,
	];

	return months;
};
export const dayNames = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

export const getAllDaysTranslated = (languages) => {
	let platformLanguage = languages.platformLanguage;
	if (timeStrings[`${platformLanguage}`] === undefined) platformLanguage = 'en';
	return [
		timeStrings[`${platformLanguage}`].sunday,
		timeStrings[`${platformLanguage}`].monday,
		timeStrings[`${platformLanguage}`].tuesday,
		timeStrings[`${platformLanguage}`].wednesday,
		timeStrings[`${platformLanguage}`].thursday,
		timeStrings[`${platformLanguage}`].friday,
		timeStrings[`${platformLanguage}`].saturday,
	];
};

export const getLongMonthTranslated = (date, languages) => {
	// let platformLanguage = languages.platformLanguage === 'ar' ? 'en' : languages.platformLanguage;

	let platformLanguage = languages.platformLanguage;
	let months = [
		languages.translations[platformLanguage]?.time.january,
		languages.translations[platformLanguage]?.time.february,
		languages.translations[platformLanguage]?.time.march,
		languages.translations[platformLanguage]?.time.april,
		languages.translations[platformLanguage]?.time.may,
		languages.translations[platformLanguage]?.time.june,
		languages.translations[platformLanguage]?.time.july,
		languages.translations[platformLanguage]?.time.august,
		languages.translations[platformLanguage]?.time.september,
		languages.translations[platformLanguage]?.time.october,
		languages.translations[platformLanguage]?.time.november,
		languages.translations[platformLanguage]?.time.december,
	];

	const currentDate = new Date(date);
	const m = months[currentDate.getMonth()];
	return m;
};

export const getShortDaysTranslated = (date, language) => {
	if (timeStrings[`${language}`] === undefined) language = 'en';

	let days = [
		timeStrings[`${language}`].sundayShort,
		timeStrings[`${language}`].mondayShort,
		timeStrings[`${language}`].tuesdayShort,
		timeStrings[`${language}`].wednesdayShort,
		timeStrings[`${language}`].thursdayShort,
		timeStrings[`${language}`].fridayShort,
		timeStrings[`${language}`].saturdayShort,
	];

	const currentDate = new Date(date);
	const d = days[currentDate.getDay()];
	return d;
};

export const getLongDaysTranslated = (date, language) => {
	if (timeStrings[`${language}`] === undefined) language = 'en';

	let days = [
		timeStrings[`${language}`].sunday,
		timeStrings[`${language}`].monday,
		timeStrings[`${language}`].tuesday,
		timeStrings[`${language}`].wednesday,
		timeStrings[`${language}`].thursday,
		timeStrings[`${language}`].friday,
		timeStrings[`${language}`].saturday,
	];

	const currentDate = new Date(date);
	const d = days[currentDate.getDay()];
	return d;
};

export const getProgramFormattedTitleDate = (currentDay, languages) => {
	let day = getDay(currentDay);

	let month = getLongMonthTranslated(currentDay, languages);
	let year = moment(currentDay).year();

	return `${day} ${capitalizeFirstLetter(month)}, ${year}`;
};

export const getProgramFormattedTitleDateMobile = (currentDay, languages) => {
	let months = getShortMonthsTranslated(languages);

	let momentCurrentDay = moment(currentDay);

	let day = getDay(currentDay);
	let longDayName = getLongDaysTranslated(currentDay, languages);
	let month = months[momentCurrentDay.month()];
	let year = moment(currentDay).year();

	return `${capitalizeFirstLetter(longDayName)}, ${day} ${capitalizeFirstLetter(
		month
	)}, ${year}`;
};

export const getEventStartOrEndDate = (eventStartOrEnd, languages) => {
	let months = getShortMonthsTranslated(languages);

	const momentDateStartOrEnd = moment(eventStartOrEnd);

	let eventStartOrEndDay = getDay(eventStartOrEnd);
	let eventStartOrEndMonth = months[momentDateStartOrEnd.month()];
	let eventStartOrEndYear = momentDateStartOrEnd.year();

	return (
		eventStartOrEndDay +
		' ' +
		capitalizeFirstLetter(eventStartOrEndMonth) +
		' ' +
		eventStartOrEndYear
	);
};

export const getTimeslotDate = (timeslotTimestamp, languages) => {
	let months = getShortMonthsTranslated(languages);

	const momentTimeslotDate = moment(timeslotTimestamp);
	let timeslotDay = getDay(timeslotTimestamp);
	let timeslotMonth = months[momentTimeslotDate.month()];
	let timeslotYear = momentTimeslotDate.year();

	return (
		timeslotDay +
		' ' +
		capitalizeFirstLetter(timeslotMonth) +
		' ' +
		timeslotYear
	);
};

export const getEndHourOfLastTimeslot = (event, timezone) => {
	let now = new Date();
	let now_utc = getUtcTimestamp(now);

	if (event !== null) {
		// create an array with all the timeslots end hours, from all auditoriums
		let eventTimeslotsEndHours = [];
		event.auditoriums.forEach((auditorium) => {
			auditorium.displayProgram.forEach((auditoriumDayProgram) => {
				let auditoriumDayProgramDate = new Date(auditoriumDayProgram.date);
				let auditoriumDayProgramYear =
					auditoriumDayProgramDate.getUTCFullYear();
				let auditoriumDayProgramMonth = auditoriumDayProgramDate.getUTCMonth();
				let auditoriumDayProgramDay = auditoriumDayProgramDate.getUTCDate();

				auditoriumDayProgram.program.forEach((timeslot) => {
					let timeslotEndsAt = timeslot.end;
					timeslotEndsAt = timeslotEndsAt.split(':');
					let timeslotEndsAtHour = timeslotEndsAt[0];
					let timeslotEndsAtMinute = timeslotEndsAt[1];
					// get the UTC timeStamp of the last video of the event
					eventTimeslotsEndHours.push(
						new Date(
							auditoriumDayProgramYear,
							auditoriumDayProgramMonth,
							auditoriumDayProgramDay,
							timeslotEndsAtHour,
							timeslotEndsAtMinute
						)
					);
				});
			});
		});

		// we sort the array so that the first item in the array is the
		// latest hour from all auditoriums
		if (eventTimeslotsEndHours.length >= 2) {
			eventTimeslotsEndHours.sort((a, b) => {
				return b.getTime() - a.getTime();
			});
		}

		timezone = timezone === undefined ? 0 : timezone;

		// if the organizer didn't add any timeslots to the program, we consider that the event didn't end
		if (eventTimeslotsEndHours.length === 0) {
			return '';
		} else {
			eventTimeslotsEndHours = eventTimeslotsEndHours[0].getTime();

			return (
				moment(eventTimeslotsEndHours).format('HH') +
				':' +
				moment(eventTimeslotsEndHours).format('mm')
			);
		}
	}
};
export const getFirstOrLastTimeslot = (event, firstOrLast) => {
	let sortedTimeslots = [];
	event?.auditoriums.forEach((auditorium, auditoriumIndex) => {
		auditorium.displayProgram.forEach((displayProgramDay) => {
			displayProgramDay.program.map((timeslot) => {
				sortedTimeslots.push(cloneDeep(timeslot));
			});
		});
	});

	sortedTimeslots.sort((a, b) => {
		const aStartTimestamp = new Date(a.startTimestamp).getTime();
		const bStartTimestamp = new Date(b.startTimestamp).getTime();
		if (aStartTimestamp > bStartTimestamp) {
			return 1;
		}
		return -1;
	});

	if (firstOrLast === 'first') {
		return sortedTimeslots[0];
	} else {
		return sortedTimeslots[sortedTimeslots.length - 1];
	}
};

export const getStartHourOfFirstTimeslot = (event, timezone) => {
	let now = new Date();
	let now_utc = getUtcTimestamp(now);

	if (event !== null) {
		// create an array with all the timeslots start hours, from all auditoriums
		let eventTimeslotsStartHours = [];
		event.auditoriums.forEach((auditorium) => {
			auditorium.displayProgram.forEach((auditoriumDayProgram) => {
				let auditoriumDayProgramDate = new Date(auditoriumDayProgram.date);
				let auditoriumDayProgramYear =
					auditoriumDayProgramDate.getUTCFullYear();
				let auditoriumDayProgramMonth = auditoriumDayProgramDate.getUTCMonth();
				let auditoriumDayProgramDay = auditoriumDayProgramDate.getUTCDate();

				auditoriumDayProgram.program.forEach((timeslot) => {
					let timeslotStartsAt = timeslot.start;
					timeslotStartsAt = timeslotStartsAt.split(':');
					let timeslotStartsAtHour = timeslotStartsAt[0];
					let timeslotStartsAtMinute = timeslotStartsAt[1];
					// get the UTC timeStamp of the last video of the event
					eventTimeslotsStartHours.push(
						new Date(
							auditoriumDayProgramYear,
							auditoriumDayProgramMonth,
							auditoriumDayProgramDay,
							timeslotStartsAtHour,
							timeslotStartsAtMinute
						)
					);
				});
			});
		});

		// we sort the array so that the first item in the array is the
		// first hour from all auditoriums
		if (eventTimeslotsStartHours.length >= 2) {
			eventTimeslotsStartHours.sort((a, b) => {
				return a.getTime() - b.getTime();
			});
		}

		// if the organizer didn't add any timeslots to the program, we consider that the event didn't end
		if (eventTimeslotsStartHours.length === 0) {
			return '';
		} else {
			eventTimeslotsStartHours = eventTimeslotsStartHours[0].getTime();
			return (
				moment(eventTimeslotsStartHours).format('HH') +
				':' +
				moment(eventTimeslotsStartHours).format('mm')
			);
		}
	}
};

export const getTimezoneValueOfEvent = (timezoneName) => {
	return timezoneName.slice(4, 10).replace(':', '');
};

export const getTimezoneValueOfEventOrUser = (user, event) => {
	const selectedTimezone = user.timezoneName || event.timezoneName;
	return selectedTimezone.slice(4, 10).replace(':', '');
};

export const getHomepageEventTitle = (
	brandingTranslationsObjects,
	userLanguage
) => {
	let eventHomepageTitle;

	for (let brandingTranslation of brandingTranslationsObjects) {
		if (brandingTranslation.language === userLanguage) {
			eventHomepageTitle = brandingTranslation.homepageEventName;
		}
	}
	return eventHomepageTitle;
};

export const getHomepageEventDescription = (
	brandingTranslationsObject,
	userLanguage
) => {
	let eventHomepageDescription;

	for (let brandingTranslation of brandingTranslationsObject) {
		if (brandingTranslation.language === userLanguage) {
			eventHomepageDescription = brandingTranslation.homepageDescription;
		}
	}
	return eventHomepageDescription;
};

export const getEventStartTimeForCalendarData = (event, timezoneValue) => {
	return `${event.eventStartDate}${
		getStartHourOfFirstTimeslot(event, event.timezoneValue)
			? ` ${getStartHourOfFirstTimeslot(
					event,
					event.timezoneValue
			  )} ${timezoneValue}`
			: ' 00:00'
	}`;
};

export const getEventEndTimeForCalendarData = (event, timezoneValue) => {
	return `${event.eventEndDate}${
		getEndHourOfLastTimeslot(event, event.timezoneValue)
			? ` ${getEndHourOfLastTimeslot(
					event,
					event.timezoneValue
			  )} ${timezoneValue}`
			: ' 23:59'
	}`;
};

export const getEventStartTimeStampForCalendarData = (event, timezoneValue) => {
	let eventStartTimeStamp;
	const hasStartHour = !!getStartHourOfFirstTimeslot(
		event,
		event.timezoneValue
	);
	if (hasStartHour) {
		eventStartTimeStamp =
			event.eventStartDate +
			'T' +
			getStartHourOfFirstTimeslot(event, event.timezoneValue) +
			':00.000' +
			timezoneValue;
	} else {
		eventStartTimeStamp =
			event.eventStartDate + 'T00:00:00.000' + timezoneValue;
	}
	return eventStartTimeStamp;
};

export const getEventEndTimeStampForCalendarData = (event, timezoneValue) => {
	let eventEndTimeStamp;
	const hasEndHour = !!getEndHourOfLastTimeslot(event, event.timezoneValue);
	if (hasEndHour) {
		eventEndTimeStamp =
			event.eventEndDate +
			'T' +
			getEndHourOfLastTimeslot(event, event.timezoneValue) +
			':00.000' +
			timezoneValue;
	} else {
		eventEndTimeStamp = event.eventEndDate + 'T23:59:00.000' + timezoneValue;
	}
	return eventEndTimeStamp;
};

export const createExternalEventCalendarData = (
	event,
	languages,
	user,
	eventStartDate,
	eventEndDate
) => {
	let eventLanguage = event.language;
	let platformLanguage = languages.platformLanguage;
	let title = checkIfEventLanguagesIncludeUserLanguage(
		platformLanguage,
		event.brandingTranslations
	)
		? getHomepageEventTitle(event.brandingTranslations, platformLanguage)
		: getHomepageEventTitle(event.brandingTranslations, eventLanguage);
	let timezoneValue = getTimezoneValueOfEvent(event.timezoneName);

	const eventCalendarData = {
		title: title,
		description: checkIfEventLanguagesIncludeUserLanguage(
			platformLanguage,
			event.brandingTranslations
		)
			? getHomepageEventDescription(
					event.brandingTranslations,
					platformLanguage
			  )
			: getHomepageEventDescription(event.brandingTranslations, eventLanguage),
		location: `${window.location.origin}/event/${event.slug}/`,
		start: getEventStartTimeStampForCalendarDataBasedOnTimezone(
			event,
			user,
			timezoneValue,
			eventStartDate
		),
		end: getEventEndTimeStampForCalendarDataBasedOnTimezone(
			event,
			user,
			timezoneValue,
			eventEndDate
		),
	};

	return eventCalendarData;
};

export const getEventDescriptionFormatted = (
	event,
	eventStartDate,
	eventEndDate,
	startHourOfFirstTimeslot,
	endHourOfLastTimeSlot,
	user
) => {
	return `${eventStartDate}${
		startHourOfFirstTimeslot ? ', ' + startHourOfFirstTimeslot : ''
	} - 
        ${eventEndDate}${
					endHourOfLastTimeSlot ? ', ' + endHourOfLastTimeSlot : ''
				} 
        ${getTimezoneFromEventOrUser(user, event)}`;
};

export const getTimezoneFromEventOrUser = (user, event) => {
	const selectedTimezone = user.timezoneValue || event.timezoneValue;
	return `(GMT ${selectedTimezone > 0 ? '+' : ''}${selectedTimezone})`;
};

export const getTimeslotDescriptionFormatted = (timeslot, translation) => {
	return `${timeslot.title}${
		timeslot.speakers?.length ? ' - ' + timeslot.speakers + '[VALUE]' : ''
	} ${timeslot.description ? timeslot.description : ''}`;
};

export const getTimeslotDuration = (timeslot, languages, event, user) => {
	return `${getTimeslotDate(timeslot.startTimestamp, languages)} ${
		timeslot.start
	} - ${timeslot.end}  ${getTimezoneFromEventOrUser(user, event)}`;
};

export const calculateTimezoneAddEventToCalendar = () => {};

export const redirectToPublicPages = () => {
	if (window.location.hostname === 'app.uns.onvent.online') {
		return window.location.replace('https://uns.onvent.online');
	}
	if (window.location.hostname === 'app.unstoppabl.ai') {
		return window.location.replace('https://unstoppabl.ai');
	}
	return window.location.replace('https://unstoppabl.ai');
};

export const addHours = (numOfHours, date = new Date()) => {
	date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
	return date;
};

// gets all the standard arrows and replaces them with the ones from the design
// also need to apply class scaled-select-arrow to scale arrow to be the right size in selects
export const changeSelectArrows = () => {
	const arrows = document.querySelectorAll('[d="M7 10l5 5 5-5z"]');
	arrows.forEach((arrow) =>
		arrow.setAttribute(
			'd',
			'M15.88 9.29L12 13.17 8.12 9.29a.9959.9959 0 00-1.41 0c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z'
		)
	);
};

export const getAuditoriumNameById = (id, auditoriums) => {
	return auditoriums?.find((auditorium) => auditorium._id === id)?.name;
};

export const checkIfProgramHasTimeslots = (event) => {
	const eventAuditoriums = event?.auditoriums;
	const timeslots = [];

	eventAuditoriums.forEach((auditorium) => {
		auditorium.displayProgram.forEach((displayProgramDay) => {
			displayProgramDay.program.map((timeslot) => {
				timeslots.push(timeslot);
			});
		});
	});

	return !!timeslots.length;
};

export const getEventDescriptionFormattedBasedOnTimezoneValue = (
	user,
	event,
	eventStartDate,
	eventEndDate,
	startHourOfFirstTimeslot,
	endHourOfLastTimeSlot,
	languages
) => {
	// case check if event has program
	if (checkIfProgramHasTimeslots(event)) {
		// case user timezone === event timezone
		if (user.timezoneValue === event.timezoneValue) {
			return getEventDescriptionFormatted(
				event,
				eventStartDate,
				eventEndDate,
				startHourOfFirstTimeslot,
				endHourOfLastTimeSlot,
				user
			);
		} else {
			let calendarStartDate = eventStartDate;
			let calendarEndDate = eventEndDate;
			let calendarStartHours = 'T00:00:00.000Z';
			let calendarEndHours = 'T23:59:59.000Z';

			// defaults - we calculate the dates based on timezone difference
			// by default we start from the assumption that first and last days of event don't have timeslots
			let recalculatedStartDate = event.eventStartDate + calendarStartHours;

			let eventStartDateObj = recalculateEventDateByTimezone(
				recalculatedStartDate,
				event,
				user
			);
			calendarStartDate = eventStartDateObj.calculatedDate;
			calendarStartDate = getEventStartOrEndDate(calendarStartDate, languages);

			let firstTimeslot = getFirstOrLastTimeslot(event, 'first');
			// firstTimeslot.startTimestamp = event.firstTimeslotStart;
			firstTimeslot = recalculateTimeslotByTimezone(firstTimeslot, event, user);
			calendarStartHours = firstTimeslot.start;

			// end date
			let recalculatedEndDate = event.eventEndDate + calendarEndHours;

			let eventEndDateObj = recalculateEventDateByTimezone(
				recalculatedEndDate,
				event,
				user
			);
			calendarEndDate = eventEndDateObj.calculatedDate;
			calendarEndDate = getEventStartOrEndDate(calendarEndDate, languages);

			let lastTimeslot = getFirstOrLastTimeslot(event, 'last');
			// lastTimeslot.endTimestamp = event.lastTimeslotEnd;
			lastTimeslot = recalculateTimeslotByTimezone(lastTimeslot, event, user);
			calendarEndHours = lastTimeslot.end;

			if (checkIfStartDateOfEventIsSameWithFirstTimeslot(event)) {
				let firstTimeslot = getFirstOrLastTimeslot(event, 'first');
				// firstTimeslot.startTimestamp = event.firstTimeslotStart;
				firstTimeslot = recalculateTimeslotByTimezone(
					firstTimeslot,
					event,
					user
				);
				calendarStartDate = getEventStartOrEndDate(
					firstTimeslot.startTimestamp,
					languages
				);
				calendarStartHours = firstTimeslot.start;
			}

			if (checkIfEndDateOfEventIsSameWithEndTimeslot(event)) {
				let lastTimeslot = getFirstOrLastTimeslot(event, 'last');
				// lastTimeslot.endTimestamp = event.lastTimeslotEnd;
				lastTimeslot = recalculateTimeslotByTimezone(lastTimeslot, event, user);
				calendarEndDate = getEventStartOrEndDate(
					lastTimeslot.endTimestamp,
					languages
				);
				calendarEndHours = lastTimeslot.end;
			}

			return getEventDescriptionFormatted(
				event,
				calendarStartDate,
				calendarEndDate,
				calendarStartHours,
				calendarEndHours,
				user
			);
		}
	}
};

export const getEventStartTimeStampForCalendarDataBasedOnTimezone = (
	event,
	user,
	timezoneValue,
	eventStartDate
) => {
	if (checkIfProgramHasTimeslots(event)) {
		let calendarStartDate = eventStartDate;
		let calendarStartHours = 'T00:00:00.000Z';

		let firstTimeslot = getFirstOrLastTimeslot(event, 'first');
		// firstTimeslot.startTimestamp = event.firstTimeslotStart;
		firstTimeslot = recalculateTimeslotByTimezone(firstTimeslot, event, user);

		if (checkIfStartDateOfEventIsSameWithFirstTimeslot(event)) {
			calendarStartDate = firstTimeslot.startTimestamp;
		} else {
			let timezoneDifference = calculateTimezoneDifference(user, event);
			let timestampWithoutLocalTimezone = moment
				.utc(calendarStartDate + calendarStartHours)
				.format('l LT');

			/*
            let timezoneValueToBeAdded = timezoneDifference;
*/

			let calculatedDate = moment(timestampWithoutLocalTimezone).add(
				timezoneDifference,
				'hours'
			);

			calendarStartDate = firstTimeslot.startTimestamp;
			calendarStartDate = calendarStartDate.date(calculatedDate.date());
			calendarStartDate = calendarStartDate.month(calculatedDate.month());
			calendarStartDate = calendarStartDate.year(calculatedDate.year());
		}

		return calendarStartDate;
	}
};

export const getEventEndTimeStampForCalendarDataBasedOnTimezone = (
	event,
	user,
	timezoneValue,
	eventEndDate
) => {
	if (checkIfProgramHasTimeslots(event)) {
		let calendarEndDate = eventEndDate;
		let calendarEndHours = 'T23:59:59.000Z';

		let lastTimeslot = getFirstOrLastTimeslot(event, 'last');
		// lastTimeslot.endTimestamp = event.lastTimeslotEnd;
		lastTimeslot = recalculateTimeslotByTimezone(lastTimeslot, event, user);

		if (checkIfEndDateOfEventIsSameWithEndTimeslot(event)) {
			calendarEndDate = lastTimeslot.endTimestamp;
		} else {
			let timezoneDifference = calculateTimezoneDifference(user, event);
			let timestampWithoutLocalTimezone = moment
				.utc(calendarEndDate + calendarEndHours)
				.format('l LT');

			/*
            let timezoneValueToBeAdded = timezoneDifference;
*/

			let calculatedDate = moment(timestampWithoutLocalTimezone).add(
				timezoneDifference,
				'hours'
			);

			calendarEndDate = lastTimeslot.endTimestamp;
			calendarEndDate = calendarEndDate.date(calculatedDate.date());
			calendarEndDate = calendarEndDate.month(calculatedDate.month());
			calendarEndDate = calendarEndDate.year(calculatedDate.year());
		}
		return calendarEndDate;
	}
};

export const checkIfStartDateOfEventIsSameWithFirstTimeslot = (event) => {
	const startDateOfEvent = event.eventStartDate;

	let firstTimeslot = getFirstOrLastTimeslot(event, 'first');
	const startDateOfFirstTimeslot = firstTimeslot.startTimestamp.split('T')[0];
	return startDateOfEvent === startDateOfFirstTimeslot;
};

export const checkIfEndDateOfEventIsSameWithEndTimeslot = (event) => {
	const endDateOfEvent = event.eventEndDate;

	let lastTimeslot = getFirstOrLastTimeslot(event, 'last');
	const endDateOfLastTimeslot = lastTimeslot.endTimestamp.split('T')[0];
	return endDateOfEvent === endDateOfLastTimeslot;
};

const calculateTimezoneDifference = (user, event) => {
	const userTimezoneValue = user?.timezoneValue;
	let timezoneDifference = 0;
	if (userTimezoneValue) {
		timezoneDifference = userTimezoneValue - event?.timezoneValue;
	}
	return timezoneDifference;
};

const recalculateTimeslotByTimezone = (timeslot, event, user) => {
	let timezoneDifference = calculateTimezoneDifference(user, event);
	let startTimestampWithoutLocalTimezone = moment
		.utc(timeslot.startTimestamp)
		.format('l LT');
	let endTimestampWithoutLocalTimezone = moment
		.utc(timeslot.endTimestamp)
		.format('l LT');
	let timezoneValueToBeAdded = +event?.timezoneValue + timezoneDifference;

	timeslot.startTimestamp = moment(startTimestampWithoutLocalTimezone).add(
		timezoneValueToBeAdded,
		'hours'
	);
	timeslot.endTimestamp = moment(endTimestampWithoutLocalTimezone).add(
		timezoneValueToBeAdded,
		'hours'
	);

	timeslot.start = moment(timeslot.startTimestamp).format('HH:mm');
	timeslot.end = moment(timeslot.endTimestamp).format('HH:mm');

	return timeslot;
};

export const recalculateEventDateByTimezone = (startOrEndDate, event, user) => {
	let eventDateObj = {};
	let timezoneDifference = calculateTimezoneDifference(user, event);
	let timestampWithoutLocalTimezone = moment.utc(startOrEndDate).format('l LT');

	let calculatedDate = moment(timestampWithoutLocalTimezone).add(
		timezoneDifference,
		'hours'
	);

	eventDateObj.calculatedDate = moment(calculatedDate).format('YYYY-MM-DD');
	eventDateObj.calculatedHours = moment(calculatedDate).format('HH:mm');

	return eventDateObj;
};

export const checkIfTimeslotIsLive = (timeslot, eventTimezoneValue) => {
	let currentTime = new Date();
	let currentTimeStamp = getUtcTimestamp(currentTime);

	let timeslotDate = timeslot.startTimestamp.split('T')[0];

	let timeSlotStartTimestamp = getTimeslotStartTimestamp(
		timeslotDate,
		timeslot,
		eventTimezoneValue
	);
	let timeSlotEndTimestamp = getTimeslotEndTimestamp(
		timeslotDate,
		timeslot,
		eventTimezoneValue
	);

	return (
		currentTimeStamp >= timeSlotStartTimestamp &&
		currentTimeStamp < timeSlotEndTimestamp
	);
};

export const elementsOverlap = (el1, el2) => {
	const domRect1 = el1?.getBoundingClientRect() ?? null;
	const domRect2 = el2?.getBoundingClientRect() ?? null;

	if (!domRect1 || !domRect2) {
		return false;
	}

	return !(
		domRect1.top > domRect2.bottom ||
		domRect1.right < domRect2.left ||
		domRect1.bottom < domRect2.top ||
		domRect1.left > domRect2.right
	);
};

export const getWindowView = (photoSide, event) => {
	let photoPath = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/window_view.jpg`;
	if (photoSide === 'left') {
		if (event.brandingData.leftWindowView) {
			photoPath = `${event.brandingData.filesUrl}${event.brandingData.leftWindowView}`;
		}
	} else if (photoSide === 'right') {
		if (event.brandingData.rightWindowView) {
			photoPath = `${event.brandingData.filesUrl}${event.brandingData.rightWindowView}`;
		}
	}
	return photoPath;
};

export const checkIfLeftOrRightWindowIsUploaded = (event) => {
	return !!(
		event.brandingData.leftWindowView.length ||
		event.brandingData.rightWindowView.length
	);
};

export const checkIfLeftPhotoViewIsCustomOrDefault = (event) => {
	return !event.brandingData.leftWindowView.length;
};

export const doesOrganizerHasParticipantProfile = (userEventProfile) =>
	userEventProfile !== null;

/*translationHelperDevTool function->
this is a piece of code that we can copy to another component temporarly to handle translation faster */
/*
 * How to use and steps
 * https://docs.google.com/document/d/1_8sNNROR9YxH4esNFjE5MgudCpcCrUOzdu80YG3f2Cg/edit#heading=h.2t34xglihcuc
 * END
 * */

const translationHelperDevTool = () => {
	let originalTranslationObject = {};
	let originalTranslationValues = Object.values(originalTranslationObject);
	let originalTranslationKeys = Object.keys(originalTranslationObject);

	let translatedTranslations = [];

	let dataWillBeCopyToTranslationFile = originalTranslationKeys.reduce(
		(acc, curr, index) => {
			return { ...acc, [curr]: translatedTranslations[index] };
		},
		{}
	);
};
/*end*/
