/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PlatformMenu from '../../Components/PlatformMenu';
import HeaderMenu from '../../Components/HeaderMenu';
import '../../CSS/otherPages.scss';
import Footer from '../../SmallLayoutComponents/Footer';
import { Link, withRouter } from 'react-router-dom';
import axios from '../../store/axios-instance';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getLanguageFromUrl, preventDefaultDrag } from '../../Utils/utils';
import InfoIcon from '@material-ui/icons/Info';
import SnackbarGlobal from '../../SmallLayoutComponents/Snackbars/SnackbarGlobal';

class Cookies extends React.Component {
	state = {
		gdprText: '',
		language: 'en',
		showDisclaimer: false,
		brandingTranslation: null,
		successSnackbarOpened: false,
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		this.setLanguagesData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.languages.eventLanguage !==
				this.props.languages.eventLanguage ||
			prevProps.location.search !== this.props.location.search
		) {
			this.setLanguagesData();
		}
	}

	setLanguagesData = () => {
		const selectedLang = getLanguageFromUrl() || 'en';
		let selectedTemplate = 'platformCookies';
		this.setState({ language: selectedLang });
		this.getGDPRText(selectedLang, selectedTemplate);

		if (this.props.match.params.eventSlug) {
			const brandingTranslation =
				this.props.eventInfo.brandingTranslations.find(
					(translation) =>
						translation.language === this.props.languages.eventLanguage
				);
			this.setState({
				brandingTranslation: brandingTranslation,
			});
		}
	};

	getGDPRText = (lang, template) => {
		axios({
			method: 'get',
			url: `/gdpr/${lang}/${template}/`,
		})
			.then((response) => {
				this.setState({
					gdprText: response.data.content,
					showDisclaimer: response.data.showDisclaimer,
				});
				return response.data.content;
			})
			.catch((error) => {});
	};

	handleOpenSnackbar = () => {
		this.setState({ successSnackbarOpened: true });
	};

	handleCloseSnackbar = async () => {
		await this.setState({
			successSnackbarOpened: !this.state.successSnackbarOpened,
		});
		if (this.state.successSnackbarOpened) {
			setTimeout(() => {
				this.setState({ successSnackbarOpened: false });
			}, 5000);
		}
	};
	render() {
		const {
			gdprText,
			language,
			showDisclaimer,
			brandingTranslation,
			successSnackbarOpened,
		} = this.state;
		return (
			<div onDragStart={preventDefaultDrag} className="gdpr-page">
				<Helmet>
					<title>{`${process.env.REACT_APP_PLATFORM_NAME} - ${
						brandingTranslation
							? brandingTranslation?.homepageEventName + ':'
							: ''
					} ${
						this.props.languages.translations[language]?.gdpr
							.metaTitleCookiesPolicy ||
						this.props.languages.translations['en']?.gdpr.metaTitleCookiesPolicy
					}`}</title>
				</Helmet>

				{this.props.match.params.eventSlug ? (
					<HeaderMenu
						className={'white'}
						coloredLogo
						disabledLanguageSelector
					/>
				) : (
					<PlatformMenu className={'white'} coloredLogo />
				)}

				<div
					onDragStart={preventDefaultDrag}
					className="header-spacing-container"
				>
					<div onDragStart={preventDefaultDrag} className="gdpr-container">
						{showDisclaimer && (
							<React.Fragment>
								<div
									onDragStart={preventDefaultDrag}
									className="translated-ai-info"
								>
									<InfoIcon color="primary" />
									<span>
										{this.props.languages.translations[language].gdpr
											.disclaimerTextFirst ||
											this.props.languages.translations['en'].gdpr
												.disclaimerTextFirst}{' '}
										<Link
											to={{
												pathname: `${this.props.location.pathname}`,
												search: `?lang=en`,
											}}
										>
											<span>
												{this.props.languages.translations[language].gdpr
													.disclaimerTextSecond ||
													this.props.languages.translations['en'].gdpr
														.disclaimerTextSecond}
											</span>
										</Link>
									</span>
								</div>
							</React.Fragment>
						)}
						<div
							onDragStart={preventDefaultDrag}
							dangerouslySetInnerHTML={{ __html: gdprText }}
						/>
						<div className="cookie-links">
							<h3>
								{this.props.languages.translations[language].gdpr
									.updateCookieConsent ||
									this.props.languages.translations['en'].gdpr
										.updateCookieConsent}
							</h3>
							<div>
								<a href="javascript: Cookiebot.renew()">
									{this.props.languages.translations[language].gdpr
										.changeYourConsent ||
										this.props.languages.translations['en'].gdpr
											.changeYourConsent}
								</a>
								<a
									href="javascript: Cookiebot.withdraw()"
									onClick={this.handleOpenSnackbar}
								>
									{this.props.languages.translations[language].gdpr
										.withdrawYourConsent ||
										this.props.languages.translations['en'].gdpr
											.withdrawYourConsent}
								</a>
							</div>
						</div>
					</div>
				</div>

				<SnackbarGlobal
					snackbarOpen={successSnackbarOpened}
					message={
						this.props.languages.translations[language].gdpr
							.withdrawSnackbarText ||
						this.props.languages.translations['en'].gdpr.withdrawSnackbarText
					}
					handleCloseSnackbar={this.handleCloseSnackbar}
				/>

				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		languages: state.languages,
		eventInfo: state.event.eventInfo,
	};
};

export default withRouter(connect(mapStateToProps)(Cookies));
