import { useHistory } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../images/back.svg';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { logoutUser } from 'store/actions';
import { useDispatch } from 'react-redux';

const PreviousStepButton = ({ privateFnLogin, showRecoverPasswordDialog }) => {
	const prevPath = localStorage.getItem('previousPath');
	//redux
	const defaultTranslation = useSelector(
		(state) => state.languages.translations['en']
	);
	const translation = useSelector(
		(state) => state.languages.translations[state.languages.eventLanguage]
	);
	const event = useSelector((state) => state.event);

	const {
		restrictExhibitorRegistration,
		restrictParticipantRegistration,
		restrictScholarRegistration,
		companyExhibitorsType,
		scholarExhibitorsType,
	} = event?.eventInfo || {};

	const history = useHistory();
	const dispatch = useDispatch();

	const [buttonName, setButtonName] = useState('');

	const pathName = showRecoverPasswordDialog
		? 'forgot-password'
		: history.location.pathname.split('/').pop();
	const eventSlug = useSelector((state) => state.event.eventSlug);

	const handleLogout = () => {
		dispatch(logoutUser());
		if (eventSlug) {
			localStorage.clear();
			window.location.href = `/event/${eventSlug}`;
		} else {
			//go to home page
			localStorage.removeItem('token');
			window.location.href = 'https://unstoppabl.ai/';
		}
	};
	const reDirectArr = [
		restrictParticipantRegistration,
		!(!restrictExhibitorRegistration && companyExhibitorsType),
		!(!restrictScholarRegistration && scholarExhibitorsType),
	];
	const falseCount = reDirectArr.filter((item) => !item).length;
	const handleBack = () => {
		switch (pathName) {
			case 'login':
				if (eventSlug) {
					history.push(`/event/${eventSlug}`);
				} else {
					window.location.href = 'https://unstoppabl.ai/';
				}

				break;
			case 'forgot-password':
				if (eventSlug) {
					history.push(`/event/${eventSlug}`);
				} else {
					history.push('/');
				}
				break;
			case 'register':
				if (prevPath?.includes('login')) {
					history.push('/login');
				} else if (eventSlug) {
					history.push(`/event/${eventSlug}`);
				}
				break;
			case 'select-role':
				handleLogout();
				break;
			case 'participant-registration':
				if (falseCount <= 1) {
					handleLogout();
				} else {
					history.push(`/event/${eventSlug}/select-role`);
				}
				break;
			case 'scholar-registration':
				if (falseCount <= 1) {
					handleLogout();
				} else {
					history.replace(`/event/${eventSlug}/select-role`);
				}
				break;
			case 'exhibitor-registration':
				if (falseCount <= 1) {
					handleLogout();
				} else {
					history.replace(`/event/${eventSlug}/select-role`);
				}

				break;
			default:
				history.goBack();
				break;
		}
	};

	useEffect(() => {
		setButtonName(
			translation.generalText.back || defaultTranslation.generalText.back
		);
	}, [defaultTranslation.generalText.back, translation.generalText.back]);

	useEffect(() => {
		if (history.location.pathname.includes('/register')) {
			setButtonName(
				translation.newLoginRegistrations.changeEmail ||
					defaultTranslation.newLoginRegistrations.changeEmail
			);
		} else if (history.location.pathname.includes('/select-role')) {
			setButtonName(
				translation.newLoginRegistrations.logout ||
					defaultTranslation.newLoginRegistrations.logout
			);
		} else if (
			history.location.pathname.includes('/participant-registration') ||
			history.location.pathname.includes('/scholar-registration') ||
			history.location.pathname.includes('/exhibitor-registration')
		) {
			setButtonName(
				falseCount <= 1
					? translation.newLoginRegistrations.logout ||
							defaultTranslation.newLoginRegistrations.logout
					: translation.newLoginRegistrations.changeRole ||
							defaultTranslation.newLoginRegistrations.changeRole
			);
		} else {
			setButtonName(
				translation.generalText.back || defaultTranslation.generalText.back
			);
		}
	}, [
		defaultTranslation.generalText.back,
		history.location.pathname,
		prevPath,
		translation.generalText.back,
	]);

	return (
		<div className={`one-step-back-btn-container`}>
			<Button onClick={handleBack}>
				<BackArrow />
				{buttonName}
			</Button>
		</div>
	);
};

export default PreviousStepButton;
