const de = {
	generalText: {
		upload: 'Hochladen',
		save: 'Speichern',
		add: 'Hinzufügen',
		remove: 'Entfernen',
		close: 'SCHLIEßEN',
		update: 'AKTUALISIEREN',
		cancel: 'ABBRECHEN',
		confirm: 'Bestätigen',
		download: 'Herunterladen',
		refresh: 'Aktualisieren',
		back: 'Zurück',
		submit: 'Einreichen',
		send: 'Senden',
		leave: 'Verlassen ',
		step1: 'Schritt 1',
		step2: 'Schritt 2',
		or: 'oder',
		delete: 'Löschen',
		please: 'Bitte',
		before: 'vor',
		approve: 'Zulassen',
		decline: 'Ablehnen',
		start: 'Start',
		stop: 'Stopp',
		resetFilter: 'Filter zurücksetzen',
		apply: 'Anwenden',
		filters: 'Filter',
		unsavedChangesTitle: 'Ungespeicherte Änderungen',
		unsavedChangesText:
			'Sie haben ungespeicherte Änderungen. Wollen Sie diese speichern?',
		selectAll: 'Alle auswählen',
		unselectAll: 'Alle verwerfen',
	},
	time: {
		justNow: 'Nicht registriert',
		minute: 'Anmeldung nicht möglich',
		minutes: 'Onvent ist nicht verfügbar',
		hour: 'Das von Ihnen gesuchte Ereignis ist nicht verfügbar. Es wurde entweder vom Organisator geschlossen oder von einem Admin deaktiviert.',
		hours: 'TAGUNGEN',
		day: 'Treffen mit',
		days: 'Ausstehend',
		month: 'Akzeptiert',
		months: 'Annulliert',
		yesterday: 'Gestern',
		today: 'Heute',
		tomorrow: 'Morgen',
		monday: 'Montag',
		tuesday: 'Dienstag',
		wednesday: 'Mittwoch',
		thursday: 'Donnerstag',
		friday: 'Freitag',
		saturday: 'Samstag',
		sunday: 'Sonntag',
		january: 'Januar',
		february: 'Februar',
		march: 'März',
		april: 'April',
		may: 'Mai',
		june: 'Juni',
		july: 'Juli',
		august: 'August',
		september: 'September',
		october: 'Oktober',
		november: 'November',
		december: 'Dezember',
		januaryShort: 'Jan.',
		februaryShort: 'Feb.',
		marchShort: 'März',
		aprilShort: 'Apr.',
		mayShort: 'Mai',
		juneShort: 'Jun.',
		julyShort: 'Jul.',
		augustShort: 'Aug.',
		septemberShort: 'Sept.',
		octoberShort: 'Okt.',
		novemberShort: 'Nov.',
		decemberShort: 'Dez.',
	},
	errors: {
		noFile: 'Keine Datei ausgewählt',
		required: 'Feld erforderlich',
		emailNotValid: 'E-Mail nicht gültig',
		passwordMinLength: 'Das Passwort muss mindestens 8 Zeichen lang sein',
		passwordMismatch: 'Passwort-Fehlanpassung',
		passwordIsSame: 'Ihr neues Passwort muss ein anderes sein',
		noInvitationCode: 'Bitte geben Sie Ihren Einladungscode ein',
		imageFileNotSupported:
			'Der Dateityp wird nicht unterstützt. Bitte verwenden Sie eine der folgenden Möglichkeiten:',
		phoneInvalid:
			'Bitte geben Sie eine gültige Telefonnummer ein. Zum Beispiel: +447517663928',
		linkInvalid: 'Bitte geben Sie einen gültigen Link ein (https://site.com)',
		facebookLinkInvalid:
			'Bitte geben Sie einen gültigen Link ein (https://facebook.com/)',
		linkedinLinkInvalid:
			'Bitte geben Sie einen gültigen Link ein (https://linkedin.com/)',
		twitterLinkInvalid:
			'Bitte geben Sie einen gültigen Link ein (https://twitter.com/)',
		youtubeLinkInvalid:
			'Bitte geben Sie einen gültigen Link ein (https://www.youtube.com/)',
		youtubeOrVimeoLinkInvalid:
			'Bitte geben Sie einen gültigen YouTube (https://www.youtube.com/) oder Vimeo (https://vimeo.com/) Link ein',
		googleScholarLinkInvalid:
			'Bitte geben Sie einen gültigen Link ein (https://scholar.google.com/)',
		researchGateLinkInvalid:
			'Bitte geben Sie einen gültigen Link ein (https://www.researchgate.net/)',
		connectionProblemTextFirst:
			'Es scheint ein Verbindungsproblem zu geben. Bitte',
		connectionProblemButton: 'KLICK',
		connectionProblemTextSecond: 'oder aktualisieren Sie die Seite.',
		fileTooLarge: 'Datei zu groß. Maximal 2 MB Dateigröße.',
		fileTooLargeTextFirst: 'Datei zu groß.',
		fileTooLargeTextSecond: 'Maximale Dateigröße.',
		fileNameTooLongTextFirst: 'Dateiname zu lang. Das Limit beträgt',
		fileNameTooLongTextSecond: 'Zeichen.',
		maxWallInputLength: 'Sie haben das Maximum an 1200 Zeichen erreicht.',
	},
	footer: {
		cookiesText:
			'Diese Website verwendet wichtige Cookies, die für ihre Funktion erforderlich sind. Es werden auch Cookies von Drittanbietern verwendet, die für das Funktionieren einiger Eigenschaften unserer Website erforderlich sind. Wenn Sie mehr erfahren oder Ihre Zustimmung zu einigen Cookies widerrufen möchten, lesen Sie bitte unsere',
		cookiesLink: 'Cookie-Richtlinie',
		cookiesButton: 'ANNEHMEN',
		privacyPolicy: 'Datenschutzerklärung',
		cookies: 'Cookies',
		termsAndConditions: 'Geschäftsbedingungen',
		socialLinksTitle: 'Folge uns:',
	},
	menu: {
		logIn: 'ANMELDEN',
		logOut: 'ABMELDEN',
		scholarRegistration: 'Als Poster/Studie teilnehmen',
		exhibitorRegistration: 'Aussteller sein',
		participantRegistration: 'REGISTRIEREN',
		createEvent: 'Veranstaltung organisieren',
		loginDisabledInfoText:
			'Der Veranstalter hat den Login geschlossen. Versuchen Sie es später erneut',
	},
	homepage: {
		organizedBy: 'ORGANISIERT VON',
		date: 'DATUM',
		location: 'DOMAIN & LAGE',
		price: 'PREIS',
		tabIntro: 'EINFÜHRUNG',
		tabProgram: 'PROGRAMM',
		tabSponsors: 'SPONSOREN',
		tabPartners: 'PARTNER',
		tabExhibitors: 'AUSSTELLER',
		previousEventsSectionTitle: 'FRÜHERE VERANSTALTUNGEN',
	},
	socialButtons: {
		linkedinButton: 'Mit LinkedIn anmelden',
		loginTabLinkedinButton: 'mit LinkedIn',
		facebookButton: 'Mit Facebook anmelden',
		loginTabFacebookButton: 'mit Facebook',
		googleButton: 'Mit Google anmelden',
		loginTabGoogleButton: 'mit Google',
		twitterButton: 'Mit Twitter anmelden',
		loginTabTwitterButton: 'mit Twitter',
		socialButtonsLogInTitle: 'Mit Social-Media-Profil teilnehmen',
		socialButtonsSignUpTitle: 'Mit Social-Media-Profil registrieren',
	},
	login: {
		inputEmail: 'E-Mail',
		inputPassword: 'Kennwort',
		rememberMeButton: 'Erinnern Sie mich daran',
		forgotPasswordButton: 'Passwort vergessen?',
		loginButton: 'ANMELDEN',
		loginClosedButton: 'Login geschlossen',
		loginTitleLowerCase: 'einloggen',
		recoverPasswordDialogTitle: 'PASSWORT WIEDERHERSTELLEN',
		recoverPasswordDialogRecoverButton: 'WIEDERHERSTELLEN',
		errorIncorrectDates: 'E-Mail-Adresse oder Passwort falsch',
		orLogInWithEmail: 'oder mit E-Mail-Adresse einloggen',
		logInAsParticipantSecondText: 'als Teilnehmer',
		logInAsExhibitorSecondText: 'als Aussteller',
		logInAsPosterSecondText: 'als Poster/Studie',
		errorAccountNotFound:
			'Wir konnten Ihren Unstoppabl.ai-Account nicht finden',
	},
	register: {
		inputFirstName: 'Vorname',
		inputLastName: 'Nachname',
		inputEmail: 'E-Mail',
		inputPassword: 'Kennwort',
		inputConfirmPassword: 'Passwort bestätigen',
		gdprTextFirst: 'Indem Sie fortfahren, geben Sie ihre Zustimmung zu',
		gdprTextTerms: 'den Nutzungsbedingungen',
		gdprTextSecond: 'und bestätigen Sie, dass durchlesen unserer',
		gdprTextCookies: 'Cookie',
		gdprTextPrivacy: 'Datenschutzerklärung',
		gdprTextAgree: 'Bitte stimmen Sie der Datenschutzerklärung zu',
		registerButton: 'SICH ANMELDEN',
		signUpTitleLowerCase: 'anmelden',
		invitationCode: 'Einladungscode',
		registerNotAllowed: 'Registrierung geschlossen',
		logInTitleFirst: 'Teilnehmen',
		logInTitleSecond: 'Event',
		signUpTitleTextFirst: 'Das erste Mal bei',
		signUpTitleTextSecond: 'Unstoppabl.ai',
		orFillInTheForm: 'oder füllen Sie das folgende Formular aus',
		uploadProfilePicture: 'Profilbild hochladen',
		editProfilePicture: 'Profilbild bearbeiten',
		errorAlreadyHaveMOAccount: 'Sie sind bereits bei Unstoppabl.ai registriert',
		step1From2Text: '1/2 Schritten',
		step2From2Text: '2/2 Schritten',
		metaTitleScholarRegistration: 'Registrierung Poster/Studie',
	},
	newLoginRegistrations: {
		joinEvent: 'EVENT BEITRETEN',
		loginWelcome:
			'Willkommen bei Unstoppabl.ai! Um fortzufahren, wählen Sie eine Anmeldemethode',
		continueButton: 'Weiter',
		seemsLikeYouDontHaveMyOnventAccount:
			'Es scheint, als hätten Sie kein Unstoppabl.ai-Konto',
		uploadProfilePictureOptional: 'Profilbild hochladen (optional)',
		createPlatformAccount: 'Plattform-Konto erstellen',
		platformAccount: 'Plattform-Konto',
		selectRole: 'Rolle auswählen',
		eventAccount: 'Veranstaltungskonto',
		password: 'Passwort',
		confirmPassword: 'Passwort bestätigen',
		email: 'E-Mail',
		passwordMustContain: 'Das Passwort muss enthalten:',
		minimum8Characters: 'Mindestens 8 Zeichen',
		aLowerCaseLetter: 'Ein Kleinbuchstabe',
		anUppercaseLetter: 'Ein Großbuchstabe',
		aNumber: 'Eine Zahl',
		aSpecialCharacter: 'Ein Sonderzeichen',
		firstName: 'Vorname',
		lastName: 'Nachname',
		weHaveSentAVerificationCodeToYourEmail:
			'Wir haben einen Bestätigungscode an Ihre E-Mail gesendet',
		continueRegistration: 'Um die Registrierung fortzusetzen,',
		enterCodeBelow: 'geben Sie den Bestätigungscode unten ein:',
		codeWillExpire: 'Dieser Code läuft ab in',
		codeExpired: 'Der Code ist abgelaufen',
		codeNotReceived:
			'Haben Sie den Code nicht erhalten? Haben Sie im Spam-Ordner nachgesehen?',
		resendTimer: 'Erneut senden in:',
		resendLabel: 'Erneut senden',
		resendCodeLabel: 'Code erneut senden',
		codeError: 'Eingegebener Code ist falsch.',
		codeErrorFix:
			'Geben Sie den richtigen Code aus Ihrer E-Mail erneut ein oder senden Sie einen neuen Code erneut',
		congrats: 'Herzlichen Glückwunsch!',
		emailConfirmed: 'Ihre E-Mail wurde bestätigt:',
		imHereAs: 'Ich bin hier als:',
		participant: 'Teilnehmer',
		participantText:
			'Um Streaming zu schauen, zu kommunizieren, Aussteller und Poster/Studien zu besuchen.',
		exhibitor: 'Aussteller',
		exhibitorText:
			'Um Ihr Unternehmen zu präsentieren und mit Veranstaltungsteilnehmern zu kommunizieren.',
		poster: 'Poster/Studie',
		posterText:
			'Um Forschungsergebnisse für eine akademische oder berufliche Konferenz vorzustellen.',
		emailIsNotAllowed:
			'Diese E-Mail darf sich nicht für diese Veranstaltung registrieren.',
		didntReceiveEmail: 'Hast du die E-Mail nicht erhalten?',
		sendAgain: 'Erneut senden',
		incorrectPassword: 'Falsches Passwort',
		fileTypeError:
			'Dateityp wird nicht unterstützt. Bitte verwenden Sie einen der folgenden: jpeg, jpg, jfif, gif oder png.',
		fileSizeError: 'Datei zu groß. Maximal zulässige Dateigröße: 2 MB.',
		pleaseCompleteAllRequiredFields: 'Bitte füllen Sie alle Pflichtfelder aus',
		joinTheEvent: 'Am Event teilnehmen',
		joinTheEventSub: 'Um Ihr virtuelles Online-Event-Erlebnis zu beginnen',
		changeEmail: 'EMAIL ÄNDERN',
		changeRole: 'ROLLE ÄNDERN',
		logout: 'ABMELDEN',
		headingUser: 'Willkommen zurück',
		subHeadingUser: 'Es scheint, dass Sie bereits ein Plattformkonto haben',
		forgotPasswordHeadline: 'Passwort vergessen',
		forgotPasswordText: 'Keine Sorge, wir senden Ihnen weitere Anweisungen',
		checkYourEmail: 'Überprüfen Sie Ihre E-Mail',
		checkYourEmailText:
			'Wir haben einen Link zum Zurücksetzen des Passworts an Ihre E-Mail-Adresse gesendet',
		headingNoUser: 'Anmelden',
		subHeadingNoUser:
			'Willkommen bei Unstoppabl.ai! Um fortzufahren, wählen Sie eine Anmeldeoption',
		esetPasswordButton: 'Passwort zurücksetzen',
		createNewPassword: 'Neues Passwort erstellen',
		createNewPasswordText:
			'Ihr neues Passwort muss sich von zuvor verwendeten Passwörtern unterscheiden',
		passwordReset: 'Passwort zurückgesetzt',
		passwordResetText:
			'Ihr Passwort wurde erfolgreich zurückgesetzt. Klicken Sie unten, um sich erneut anzumelden.',
		registrationIsClosed: 'Die Registrierung für alle Rollen ist geschlossen.',
		chooseAnOption: 'Wählen Sie eine Option',
		yourAnswer: 'Deine Antwort',
		firstNameError: 'Vorname ist zu kurz. Mindestlänge: 2',
		lastNameError: 'Nachname ist zu kurz. Mindestlänge: 2',
		recoveryPassword: 'Passwort wiederherstellen',
		incorrectPasswordError:
			'Falsches Passwort. Versuchen Sie es erneut oder klicken Sie auf "Passwort vergessen", um es zu ändern.',
		optional: 'Optional',
		spamEmailError:
			'Es ist ein Problem beim Erstellen Ihres Kontos aufgetreten. Bitte versuchen Sie es später erneut.',
		invalidInput:
			"Ungültige Eingabe. Verwenden Sie nur Buchstaben (A-Z, a-z), Zahlen (0-9), Punkt (.), Apostroph ('), Bindestrich (-) und Leerzeichen. Das erste Zeichen muss ein Buchstabe sein.",
	},
	emailUpdate: {
		changeEmail: 'E-Mail ändern',
		changeEmailDescription:
			'Bevor wir die neue E-Mail-Adresse Ihrem Konto zuweisen, möchten wir sicherstellen, dass es sich um eine neue E-Mail-Adresse handelt, die niemandem gehört.',
		emailTakenError:
			'Diese E-Mail-Adresse wird bereits verwendet. Bitte versuchen Sie es mit einer anderen.',
		enterTheConfirmationCode: 'Bestätigungscode eingeben',
		verificationCodeSent: 'Bestätigungscode gesendet:',
		checkYourSpamFolder:
			'Code nicht gefunden? Überprüfen Sie Ihren Spam-Ordner',
		emailSuccess: 'Erfolg! Sie haben die E-Mail-Adresse Ihres Kontos geändert',
		emailError: 'Fehler. Bitte versuchen Sie es später erneut',
		confirmPassword: 'Kennwort bestätigen',
		confirmPasswordDescription:
			'Um die E-Mail-Adresse zu ändern, bestätigen Sie bitte Ihr Kennwort:',
		newEmail: 'Neue E-Mail-Adresse',
		password: 'Passwort',
		confirm: 'Bestätigen',
		cancel: 'Abbrechen',
		change: 'Ändern',
	},

	agendaTranslations: {
		myAgenda: 'MEIN AGENDA',
		all: 'Alle',
		sessions: 'Sitzungen',
		meetings: 'Meetings',
		pastSlots: 'Vergangene Termine',
		meetingWith: 'Meeting mit',
		pending: 'Ausstehend',
		accepted: 'Akzeptiert',
		requested: 'Angefragt',
		canceled: 'Abgesagt',
		now: 'Jetzt',
		live: 'Live',
		noMeetings: 'Noch keine Termine geplant',
		noSessions: 'Noch keine Sitzungen in Ihrem Kalender.',
		noUpcomingSessions: 'Keine bevorstehenden Sitzungen in Ihrem Kalender.',
		noUpcomingMeetings: 'Keine bevorstehenden Meetings in Ihrem Kalender.',
		noUpcomingAgenda: 'Noch nichts geplant in Ihrem Kalender.',
		noAgendaItem: 'Noch nichts in Ihrem Kalender.',
		meetingRequests: 'MEETINGANFRAGEN',
		finished: 'Abgeschlossen',
		past: 'Vergangen',
		goToMeeting: 'Zum Meeting gehen',
		addToMyAgenda: 'Zu meinem Kalender hinzufügen',
		addedToYourAgenda: 'Zu Ihrem Kalender hinzugefügt',
		acceptedAllMeetings: 'Alle Meetings akzeptieren',
	},
	participantRegistration: {
		metaTitle: 'Teilnehmer-Registrierung ',
		choosePackageTitle: 'WÄHLEN SIE DAS ZUGRIFFSPAKET',
		noPackageText: 'Bitte wählen Sie ein Paket',
		gdprTextFirst: 'Indem Sie fortfahren, geben Sie ihre Zustimmung zu',
		gdprTextPrivacy: 'Datenschutzerklärung',
		gdprTextSecond: 'und',
		gdprTextTerms: 'Veranstaltungsbedingungen',
		gdprTextAgree: 'Bitte stimmen Sie der Datenschutzerklärung zu',
		registerButtonFree: 'Vollständige Registrierung',
		registerButtonPay: 'Weiter zur Zahlung',
	},
	exhibitorRegistration: {
		metaTitle: 'Aussteller-Registrierung',
		choosePackageTitle: 'WÄHLEN SIE DAS ZUGRIFFSPAKET',
		gdprTextFirst: 'Indem Sie fortfahren, geben Sie ihre Zustimmung zu',
		gdprTextPrivacy: 'Datenschutzbestimmungen für Veranstaltungen',
		gdprTextSecond: 'und',
		gdprTextTerms: 'Veranstaltungsbedingungen',
		gdprTextAgree: 'Bitte stimmen Sie der Datenschutzerklärung zu',
		registerButtonFree: 'Vollständige Registrierung',
		registerButtonPay: 'Weiter zur Zahlung',
	},
	stripePayment: {
		cardNumber: 'Kartennummer',
		expirationDate: 'Ablaufdatum (MM/JJ)',
		cvc: 'CVC',
		disclaimerText:
			'Dieser Zahlungsvorgang wird Ende-zu-Ende verschlüsselt. Sicherheit und Datenschutz werden von Stripe Services garantiert.',
		disclaimerTextLink: 'Klick hier um mehr zu erfahren.',
		cancelButton: 'ABBRECHEN',
		payButton: 'Zahlen',
	},
	recoverPassword: {
		recoverTextSend:
			'E-Mail für Passwort-Wiederherstellung erfolgreich gesendet.',
	},
	unsubscribe: {
		unsubscribeTitle: 'Abmelden?',
		unsubscribeHomepage: 'Zur Homepage gehen',
	},
	notAvailable: {
		title: 'Das Event ist nicht verfügbar',
		description:
			'Das von Ihnen gesuchte Event ist nicht verfügbar. Es wurde entweder vom Veranstalter geschlossen oder von einem Administrator deaktiviert',
		fullOverlayEvents: 'Sehen Sie sich unsere anderen Events an',
		fullOverlayNoEvents: 'Es konnten keine anderen Events gefunden werden',
	},
	notFound: {
		notFoundDescription: 'Ups ... Keine Veranstaltung hier zu finden!',
		notFoundHomepage: 'Zur Homepage gehen',
	},
	eventMenu: {
		organizerButton: 'VERANSTALTER(IN)',
		coOrganizerButton: 'VERWALTEN',
		exhibitorButton: 'AUSSTELLER',
		posterStudyButton: 'POSTER/STUDIE',
		boothButton: 'STAND',
		boothsButton: 'STÄNDE',
		homeButton: 'STARTSEITE',
		contactsButton: 'KONTAKTE',
		meetingsButton: 'TREFFENS',
		chatsButton: 'CHATS',
		programButton: 'PROGRAMM',
		archiveButton: 'ARCHIVIEREN',
		myAccountButton: 'MEIN KONTO',
		lobbyButton: 'Lobby',
		roomsButton: 'RÄUME',
		connectionProblemTextFirst:
			'Es scheint ein Verbindungsproblem zu geben. Bitte klicke',
		connectionProblemButton: 'HIER',
		connectionProblemTextSecond: 'oder aktualisieren Sie die Seite.',
		sessionProblemTextFirst: 'Ihre Sitzung ist abgelaufen. Bitte klicke',
		sessionProblemButton: 'HIER',
		sessionProblemTextSecond: 'um sich erneut anzumelden.',
	},
	myEventsDropdown: {
		title: 'MEINE VERANSTALTUNGEN',
		createButtonMobile: 'SCHAFFEN',
	},
	myBoothsDropdown: {
		title: 'MEINE STÄNDE',
		emptyStudyTitle: 'Stand Poster/Studie',
	},
	contactsDropdown: {
		title: 'IHRE ANSPRECHPARTNER',
		requestsTitle: 'Kontakt-Anfragen',
		rejectButton: 'ABLEHNEN',
		acceptButton: 'ANNEHMEN',
		pendingButton: 'ausstehend',
		addButton: 'Hinzufügen',
		noContacts:
			'Sie haben noch keine Kontakte. Bitte besuchen Sie den Netzwerkbereich, um mit der Erstellung Ihrer Kontaktliste zu beginnen.',
	},
	meetingsDropdown: {
		meetingWith: 'Treffen mit',
		pending: 'Ausstehend',
		accepted: 'Akzeptiert',
		canceled: 'Annulliert',
		noMeetings: 'Noch kein Treffen geplant',
		finished: 'Fertig',
	},
	programDropdown: {
		stages: 'Bühnen',
		with: 'mit',
		noProgram: 'Derzeit sind keine Programmpunkte verfügbar.',
		fullProgramButton: 'Vollständiges Programm', // new translation
		addToCalendarButton: 'Zum Kalender hinzufügen', // new translation
		addedToYourAgenda: 'ZUM PROGRAMM HINZUGEFÜGT',
		addEventButton: 'Event hinzufügen', // new translation
		addToMyAgenda: 'Zu meinem Programm hinzufügen', // new translation
		addEventToYourCalendarDialogTitle: 'Event zum Kalender hinzufügen', // new translation
		addTimeslotToYourCalendar: 'Zeitfenster zum Kalender hinzufügen', // new translation
		tip: 'TIPP: ', // new translation
		tipDescription:
			'Sie können auch separate Zeitfenster zum Kalender hinzufügen, indem Sie auf das Kalendersymbol neben jedem Zeitfenster klicken.', // new translation
		tipTimeslotDescription:
			'Sie können auch das gesamte Event hinzufügen, indem Sie auf die Schaltfläche "Zum Kalender hinzufügen" klicken.', // new translation
		durationEventTitle: 'Dauer des Events: ', // new translation
		timeslotTitle: 'Titel: ', // new translation
		slotScheduled: 'Zeitfenster geplant: ', // new translation
		addSlotToCalendar: 'Zeitfenster zum Kalender hinzufügen', // new translation
		speakers: 'Referenten', // new translation
		auditoriumName: 'Name des Auditoriums', // new translation
		timezoneTooltipText:
			'Das Programm wird in der Zeitzone des Veranstalters angezeigt. Sie können die Anzeigezeitzone ändern, um die Sitzungen in Ihrer bevorzugten Zeitzone anzuzeigen.', // new translation
		liveLabel: 'Live', // new translation
		liveLabelTooltipText: 'Dieses Zeitfenster ist momentan live im Auditorium', // new translation
		openAuditorium: 'Auditorium öffnen', // new translation
	},

	notificationsDropdown: {
		title: 'Benachrichtigungen',
		contactRequestReceived:
			'hat Ihnen eine Kontaktanfrage gesendet. Schau es dir an!',
		contactRequestAccepted:
			'hat Ihre Kontaktanfrage angenommen. Fangen Sie an zu chatten!',
		meetingRequestReceived:
			'Eine Treffensanfrage Ihnen geschickt wurde. Überprüfen Sie!',
		meetingRequestAccepted:
			'Ihre Treffensanfrage akzeptiert wurde. Überprüfen Sie!',
		meetingRequestCanceled:
			'Eine Treffensanfrage annuliert wurde. Überprüfen Sie!',
		meetingStarting: 'Ein Treffen wird bald beginnen. Überprüfen Sie!',
		newBoothPost: 'hat in Ihrer Standwand gepostet. Schau es dir an!',
		noNotifications: 'Sie haben noch keine Benachrichtigungen.',
		seeAll: 'ALLE SEHEN',
		hideAll: 'ALLE AUSBLENDEN',
		meetingWillStartTextSecond: 'wird bald beginnen. Schau es Dir an!',
	},
	chatsDropdown: {
		title: 'CHATS',
		newChatProvideText:
			'Bitte geben Sie einen Namen für Ihren Chat an. Sie können den Namen anschließend bearbeiten:',
		noChats:
			'Erstellen Sie mindestens einen Chat, um mit Veranstaltungsteilnehmern zu sprechen',
		members: 'Mitglieder',
		noOpenedChatText:
			'Klicken Sie auf einen Chat, um das Gespräch fortzusetzen.',
		noMessagesInChatTextFirst: 'Das Eis brechen.',
		noMessagesInChatTextSecond: 'Sei derjenige, der dieses Gespräch beginnt.',
		newChatNameInput: 'Chat-Name (optional)',
		newChatAddMembers:
			'Fügen Sie Kontakte und andere Teilnehmer zu diesem Chat hinzu:',
		editChatAddMembers:
			'Bitte suchen Sie Benutzer aus diesem Ereignis, die Sie dem Gruppenchat hinzufügen möchten.',
		newChatSearchInput: 'Benutzer suchen',
		newChatNoUsersMessage: 'Keine Benutzer gefunden',
		newChatRemoveUserButton: 'Entfernen',
		newChatCancelButton: 'SCHLIEßEN',
		newChatCreateButton: 'SCHAFFEN',
		membersLeaveButton: 'VERLASSEN',
		membersUpdateButton: 'AKTUALISIEREN',
		membersChatButton: 'Chat',
		chatSettingsButton: 'Einstellungen',
		newMessageInput: 'Nachricht hier eingeben',
		newVideoCallTooltipText: 'Klicken Sie hier, um einen Video-Chat zu starten',
		leaveVideoMeetingTitle: 'Video-Meeting verlassen',
		leaveVideoMeetingDescription:
			'Sie sind dabei, das Video-Meeting zu verlassen. Sind Sie sicher, dass Sie fortfahren möchten?',
		leaveChatConfirmationTitle: 'Video-Anruf verlassen',
		leaveChatConfirmationText:
			'Sind Sie sicher, dass Sie den derzeitigen Video-Anruf verlassen möchten?',
	},
	myAccountDropdown: {
		title: 'MEIN KONTO',
		viewProfileButton: 'Profil anzeigen',
		logoutButton: 'ABMELDEN',
	},
	myAccountPage: {
		changePasswordButton: 'PASSWORT ÄNDERN',
		deleteAccountButton: 'MEIN KONTO LÖSCHEN',
		platformLanguage: 'Plattformsprache:',
		privacyPolicyLink: 'Datenschutzerklärung/-richtlinie',
		cookiesLink: 'Cookies',
		termsAndConditionsLink: 'Geschäftsbedingungen',
		eventProfileSectionTitle: 'Veranstaltungsprofil',
		eventPackage: 'Veranstaltungspaket',
		unregisterFromEventButton: 'Abmeldung von einer Veranstaltung',
		unregisterCurrentEventButton: 'Abmeldung von der aktuellen Veranstaltung',
		unregisterCurrentEventConfirmation:
			'Bitte bestätigen Sie, dass Sie sich von der Veranstaltung abmelden möchten',
		enableParticipantProfile: 'Teilnehmerprofil aktivieren',
		editUserDialogTitle: 'Benutzerdetails bearbeiten',
		editExhibitorDialogTitle:
			'Angaben zur Kontaktperson des Unternehmens bearbeiten',
		editScholarDialogTitle:
			'Daten der Kontaktperson für Poster/Studie bearbeiten',
		editParticipantDialogTitle: 'Teilnehmer-Details bearbeiten',
		firstName: 'Vorname',
		lastName: 'Nachname',
		uploadImage: 'Bild hochladen',
		removeImage: 'Bild entfernen',
		deleteError:
			'Sie können Ihr Konto nicht löschen. Bitte löschen Sie alle Ereignisse, die erstellt wurden, um Ihr Konto löschen zu können.',
		deleteSuccess: 'Ihr Konto wurde gelöscht.',
		reviewEventPrivacyPolicy:
			'Lesen Sie unsere Veranstaltungs-Datenschutzbestimmungen:',
		title: 'Titel',
		company: 'Unternehmen',
		phone: 'Telefon',
		platformProfileSectionTitleTextSecond: 'Profil',
		createParticipantProfile: 'Teilnehmerprofil erstellen',
		reviewPlatformConditions: 'Unsere Plattformbedingen erneut einsehen',
		reviewEventConditions: 'Unsere Veranstaltungsbedingungen erneut einsehen',
	},
	changePassword: {
		title: 'PASSWORT ÄNDERN',
		oldPassword: 'Altes Passwort',
		oldPasswordError: 'Altes Passwort stimmt nicht überein!',
		inputPassword: 'Neues Passwort',
		inputConfirmPassword: 'Passwort bestätigen',
	},
	roomsDropdown: {
		auditoriumButton: 'Auditorium',
		archiveButton: 'ARCHIVIEREN',
		onDemandButton: 'ON-DEMAND',
	},
	sideMenu: {
		liveWall: 'Live-Wand',
		networking: 'Vernetzung',
		audience: 'PUBLIKUM',
		info: 'INFO',
		videoWall: 'Videowand',
		booth: 'STAND',
		boothWall: 'Standwand',
		networkingTooltip:
			'Sie haben leider keinen Zugriff auf alle Netzwerkfunktionen.',
		attendees: 'Teilnehmer',
	},
	wall: {
		sortBy: 'Sortieren nach:',
		popularity: 'BELIEBTHEIT',
		time: 'ZEIT',
		emptyWallText: 'Sei der Erste, der hier einen Beitrag hinzufügt',
		newPostInput: 'Was haben Sie auf dem Herzen?',
		newCommentInput: 'Kommentar',
		resourcesVideoDescription: 'VIDEOBESCHREIBUNG',
		resourcesLinks: 'Links',
		resourcesFiles: 'Dateien',
		pinTitle: 'Pinnen',
		unPinTitle: 'Pin verwerfen',
		deleteDialogTitle: 'Post löschen?',
		pinDialogDescription:
			'Es gibt bereits einen gepinnten Post. Es kann nur ein Post zur Zeit gepinnt werden. Wenn Sie fortfahren, wird der Pin für den bestehenden Post automatisch verworfen.',
		edited: 'Bearbeitet',
		escapeToCancel: 'Zum Abbrechen drücken Sie die ESC-Taste',
		enterToSave: 'Zum Speichern drücken Sie die Enter-Taste',
		editingState: 'Nachricht wird bearbeitet',
		bannerModeration:
			'Die Posts und Kommentare an dieser Pinnwand werden moderiert, um die Bereitstellung hochwertiger Beiträge zu gewährleisten',
		pendingApproval: 'Freigabe ausstehend',
		approvePost: 'Post freigeben',
		approvePostComment: 'Kommentar freigeben',
		commentsPendingApproval: 'Freigabe für Kommentare steht aus',
	},
	networking: {
		searchInput: 'Suche nach Benutzer',
		emptyNetworkingSearchResults:
			'Die von Ihnen gesuchte Person hat sich nicht für dieses Online-Event registriert',
		emptyAuditoriumNetworkingSearchResults:
			'Die von Ihnen gesuchte Person hat an dieser Präsentation noch nicht teilgenommen',
		alphabetical: 'ABC',
		online: 'Online',
		matching: 'Treffer',
		matchingAlgo:
			'Trefferalgorithmus aktiviert. Geben Sie uns kurz Zeit, während wir die besten Treffer für Sie zusammenstellen!',
		searchFilters: 'Suchfilter',
		applyFilterTooltip:
			'Um Filter anzuwenden, wählen Sie mindestens eine Option aus',
		chat: 'Chat',
	},
	closedAuditoriumDialog: {
		title: 'Informationen zum Auditorium',
		contentPackage: 'Ihr Zugangspaket hat keinen Zugang zu Auditorien.',
	},
	closedShowfloorDialog: {
		title: 'Präsentationsbühnen-Information',
		contentPackage: 'Ihr Zugangspaket hat keinen Zugang zur Präsentationsbühne',
		button: 'OK',
	},
	auditorium: {
		willStart: 'Die nächste Sitzung beginnt in ...',
		noAccess: 'Ihr Zugangspaket hat keinen Zugang zu diesem Auditorium.',
		noVideos: 'In diesem Auditorium sind keine Videos zum Abspielen geplant',
		stageTab: 'BÜHNE',
		videoWallButton: 'Videowand',
		resourcesButton: 'RESSOURCEN',
		slotEndsTextFirst: 'Diese Sendung endet in',
		slotEndsTextSecondUpdated: 'Die Präsentation wird on-demand verfügbar sein',
		slotTitleWith: 'mit',
		allAuditoriums: 'Alle Auditorien',
		onDemandButton: 'ON-DEMAND',
		hide: 'Ausblenden',
		leavePopupTitle: 'Bühne verlassen?',
		leavePopupDescription:
			'Wenn Sie das Auditorium verlassen, werden Sie aus der Sitzung entfernt',
		leaveSession: 'Bühne verlassen?',
		cancelDescription:
			'Durch Verlassen werden Sie als Sprecher entfernt. Wollen Sie wirklich fortfahren?',
		settings: 'Einstellungen',
		minimize: 'Minimieren',
		microphone: 'Mikrofon',
		camera: 'Kamera',
		auditoriums: 'Auditorien',
	},
	auditoriumArchive: {
		title: 'Auditorium Archiv',
		titleEvent: 'Veranstaltungsarchiv',
		with: 'mit',
		noVideosAvailable: 'Alle Sitzungen beendet',
	},
	videoPlayer: {
		videoNotPlayingError: 'Haben Sie Probleme mit dem Videostream?',
		videoEndedTextFirst: 'Video ist beendet. Danke fürs Zuschauen!',
		videoEndedTextSecond: 'Die Videowand ist offen für Fragen und Antworten.',
		tooltipPlay: 'Abspielen',
		tooltipPause: 'Pause',
		tooltipMinimize: 'Minimieren',
		tooltipFullscreen: 'Vollbildschirm',
		tooltipMute: 'Stummschaltung',
		tooltipUnmute: 'Stummschaltung aufheben',
		videoStreamNotStarted: 'Es geht gleich los. Bleiben Sie dran!',
	},
	showfloor: {
		allExhibitorsButton: 'ALLE AUSSTELLER',
		featuredExhibitorsText: 'DARGESTELLTE AUSSTELLER',
		boothLinksDialogTitle: 'STAND-LINKS',
		boothFilesDialogTitle: 'STANDDATEIEN',
		boothVideoDialogTitle: 'VIDEO',
		mobileTabChat: 'Chat',
		mobileTabPoster: 'POSTER',
		mobileTabFiles: 'Dateien',
		mobileTabLinks: 'Links',
		mobileTabVideo: 'VIDEO',
		noExhibitorsTextFirst: 'DIE AUSSTELLER ARBEITEN AN IHREN STÄNDEN',
		noPresentersTextFirst: 'Die Präsentierenden arbeiten an ihren Ständen',
		noExhibitorsTextSecond: 'BLEIB DRAN',
		showShowfloorsButton: 'ALLE AUSSTELLUNGSRÄUME',
		showShowfloorsPosterButton: 'ALLE POSTER/STUDIEN',
		showShowfloorsExhibitorButton: 'ALLE AUSSTELLER',
		searchExhibitors: 'Aussteller suchen',
		searchPosters: 'Autoren anhand des Titels oder Namens suchen',
		searchExhibitorsMultiple: 'Aussteller in diesem Ausstellungsraum suchen',
		searchPostersMultiple:
			'Autoren anhand des Titels oder Namens in diesem Ausstellungsraum suchen',
		showfloorsTitle: 'Ausstellungsräume',
		collapseList: 'Liste zusammenklappen',
		seeFullList: 'Vollständige Liste anzeigen',
		emptyExhibitorsResults:
			'Der gesuchte Stand ist nicht anwesend, aber wir werden sie für das nächste Mal einladen',
		emptyPostersResults:
			'Die gesuchte Poster/Studie ist nicht anwesend, aber wir werden sie für das nächste Mal einladen',
		posters: 'Poster',
		allPostersStudiesTitle: 'Alle Poster/Studien',
		authors: 'Autoren',
		titleNotAvailable: 'Titel nicht verfügbar',
		abstractNotAvailable: 'Zusammenfassung nicht verfügbar',
		authorsNotAvailable: 'Autoren nicht verfügbar',
		openPosterFile: 'Offene Poster Datei',
		fileIsNotAvailable: 'Datei nicht verfügbar',
		posterBooth: {
			study: 'Poster/Studie',
			authors: 'Autoren',
			highlights: 'Zusammenfassung',
			scrollInfoText:
				'SCROLLEN SIE NACH UNTEN, UM DIE VOLLSTÄNDIGE ZUSAMMENFASSUNG ZU LESEN',
		},
	},
	eventArchive: {
		title: 'Video-Archiv',
		searchText: 'Tippen Sie auf, um zu suchen',
		noArchivedEvents:
			'Derzeit sind keine archivierten Veranstaltungen vorhanden.',
	},
	exhibitorDashboard: {
		event: 'Veranstaltung',
		title: 'Verwalten Sie Ihren Stand',
		visitorsButton: 'Standbesucher',
		goToBoothPreview: 'Stand-Vorschau',
		previewButton: 'Vorschau',
		tabStyle: 'Stand-Stil',
		tabLogo: 'Logo',
		tabStudyDetails: 'Poster/Studie',
		tabFiles: 'Dateien',
		tabStudyFile: 'Datei',
		tabLinks: 'Links',
		tabAvatars: 'Avatare',
		tabStudyAuthors: 'Autoren',
		tabVideo: 'Video',
		shareText: 'URL KOPIEREN',
		theBoothUrlCopiedText:
			'Die Booth-URL wurde erfolgreich in Ihre Zwischenablage kopiert',
		optionDisabledText:
			'Diese Option ist derzeit für diesen Stand nicht verfügbar',
		boothStyleTab: {
			optionOne: 'Option 1 - Skandinavisch',
			optionTwo: 'Option 2 - Modern',
			optionThree: 'Option 3 - Dynamisch',
			optionFour: 'Option 4 - Benutzerdefiniert',
			optionScholarOne: 'Option 1 – Einstein',
			optionScholarTwo: 'Option 2 – Curie',
			optionScholarThree: 'Option 3 – da Vinci',
		},
		logoTab: {
			fileUploadButton: 'Zum Hochladen hier klicken',
			noFile: 'Keine Datei ausgewählt',
		},
		posterStudyTab: {
			posterStudyTitle: 'Titel',
			studyTitleInputLabel: 'Titel Poster/Studie',
			highlightAbstractTitle: 'Highlight/Zusammenfassung',
			studyHighlightsInputLabel: 'Forschungshighlights / Zusammenfassung',
			highlightAbstractError:
				'Sie haben die maximale Anzahl von Zeichen erreicht! (500 Zeichen)',
			authorNameLabel: 'Autorenname',
			authorAffiliationLabel: 'Zugehörigkeit',
		},
		filesTab: {
			title: 'Dokumente:',
			fileNameInput: 'Dokumentname (für alle Teilnehmer sichtbar)',
			chooseFileButton: 'Datei auswählen',
			noDocAdded: 'Keine hinzugefügten Dokumente',
			fileError: 'Bitte fügen Sie einen Dokumentnamen hinzu',
		},
		fileStudyTab: {
			title: 'PDF-Datei hochladen',
			description:
				'Poster hochladen: An Ihrem Stand verfügbares Paper oder Präsentation (20 MB Dateilimit)',
			uploadPdfInputLabel: 'PDF-Datei hochladen',
			uploadNewPdfInputLabel: 'Neue PDF-Datei hochladen',
			uploadedFile: 'Hochgeladen',
			dragContainerTitle: 'Ziehen Sie die Datei einfach an diese Stelle',
			dragContainerNewTitle:
				'Ziehen Sie die neue Datei einfach an diese Stelle',
		},
		linksTab: {
			social: {
				title: 'Soziale Medien Links',
				subtitle: '(sichtbar für alle Teilnehmer)',
				facebookInput: 'Fügen Sie Ihren Facebook-Link ein',
				linkedinInput: 'Fügen Sie Ihren LinkedIn-Link ein',
				twitterInput: 'Fügen Sie Ihren Twitter-Link ein',
				googleScholarInput: 'Fügen Sie Ihren Google Scholar-Link ein',
				researchGateInput: 'Fügen Sie Ihren Research Gate-Link ein',
			},
			other: {
				linkInput: 'Neuen Link einfügen',
				linkLabel: 'Neues Link-Label einfügen',
			},
		},
		linksStudyTab: {
			authorProfiles: 'Autorenprofile',
			additionalLinks: 'Zusätzliche Links',
		},
		avatarsTab: {
			mainRepresentatives: {
				title: 'Standvertreter',
				leftRepresentative: 'Linker Vertreter',
				rightRepresentative: 'Rechter Vertreter',
				addDialog: {
					title: 'Avatar hinzufügen',
					titleScholar: 'Autor hinzufügen',
					editAvatarsTitle: 'Avatar bearbeiten',
					editAuthorTitle: 'Autor bearbeiten',
					contentLine1: 'Avatar aus der Galerie unten auswählen',
					contentLine2: 'Eigenen personalisierten Avatar hochladen',
					contentLine3: 'Brauchen Sie Hilfe? Kontaktieren Sie',
					assignButtonText: 'Zuweisen',
					assignText:
						'Bitte weisen Sie einen Veranstaltungsteilnehmer zu, der mit dem oben genannten Vertreter in Verbindung steht.',
					assignErrorBooth:
						'Nutzer wurde bereits als Standvertreter hinzugefügt',
					assignErrorAdditional:
						'Nutzer wurde bereits als zusätzlicher Vertreter hinzugefügt',
					input: 'Hier nach Namen suchen',
				},
			},
			additionalRepresentatives: {
				title: 'Zusätzliche Vertreter',
				subtitle: '(Optional, wird mit ihrem Profilbild angezeigt)',
				addDialog: {
					title: 'Wählen Sie einen zusätzlichen Vertreter',
					titleScholar: 'Co-Autor auswählen',
					assignText:
						'Bitte suchen Sie nach einem Veranstaltungsteilnehmer, um ihn/sie als Vertreter hinzuzufügen.',
					assignError: 'Benutzer ist bereits als Standvertreter hinzugefügt!',
				},
			},
			author: {
				title: 'Präsentierender Autor',
				subtitle:
					'Fügen Sie nur einen Autor hinzu, der für das Event registriert ist. Sie können 1 Autor hinzufügen',
				mainRepresentative: 'Hauptvertreter',
			},
			coAuthors: {
				title: 'Co-Autoren',
				singularTitle: 'Co-Autor',
				subtitle:
					'Fügen Sie nur Co-Autoren hinzu, die für dieses Event registriert sind. Sie können bis zu 4 Co-Autoren hinzufügen',
			},
		},
		videoTab: {
			video: {
				title: 'Standvideo',
				subtitle: '(Nur YouTube-Videolink)',
				youtubeOrVimeoOnly: '(Nur YouTube- oder Vimeo-Link)',
				input: 'YouTube-URL eingeben',
				inputYoutubeOrVimeo: 'URL für YouTube- oder Vimeo-Video eingeben',
			},
			image: {
				subtitle:
					'(Das Bild wird in Ihrem Stand angezeigt, falls Sie im obigen Abschnitt keinen Videolink hinzugefügt haben.)',
			},
		},
	},
	boothDashboard: {
		event: 'Veranstaltung',
		title: 'Ihr Messestand',
		manage: 'Verwalten',
		statistics: 'Statistiken',
		uniqueVisitors: 'Unieke Bezoekers', // new translation
		currentOnlineVisitors: 'Huidige Online Bezoekers', // new translation
		totalNumberOfVisits: 'Totaal Aantal Bezoeken', // new translation
		totalDocumentClicks: 'Totaal Aantal Documentklikken', // new translation
		totalLinkClicks: 'Totaal Aantal Klikken Op Links', // new translation
		totalRepresentativesClicks: 'Totaal Vertegenwoordigers Klikken', // new translation
		totalVideoViews: 'Totaal Aantal Videoweergaven', // new translation
		totalPostsCount: 'Totaal Aantal Berichten', // new translation
		totalLikesCount: 'Totaal Aantal Likes', // new translation
		totalCommentsCount: 'Totaal Aantal Reacties', // new translation
		showUsers: 'Toon Gebruikers', // new translation
		dataType: 'Data Type', // new translation
		value: 'Waarde', // new translation
		posts: 'Berichten', // new translation
		likes: 'Houdt Van', // new translation
		comments: 'Opmerkingen', // new translation
		topPostLikes: 'Populairste Bericht-vind-ik-leuks', // new translation
		topPostComments: 'Top Reacties Op Berichten', // new translation
		totalVotes: 'Totaal Aantal Stemmen', // new translation
	},
	polls: {
		polls: 'UMFRAGEN',
		noPolls: 'Es wurden noch keine Umfragen hinzugefügt.',
		pollProgress: 'Umfrage läuft',
		pollClosed: 'Umfrage geschlossen',
		pollVoted: 'Teilnehmer stimmten ab',
	},
	onDemandVideos: {
		sectionTitle: 'ON-DEMAND',
		noOnDemandVideos: 'Es wurden noch keine On-demand-Videos hinzugefügt',
	},
	meetings: {
		meeting: 'TREFFENS',
		createDialog: {
			createTitle: 'Um ein Treffen ersuchen  mit',
			inputDate: 'Datum',
			inputStart: 'Beginnt',
			inputEnd: 'Endet',
			inputTimezone: 'Zeitzone',
			inputNote:
				'Hinweis: Es kann jeweils nur ein Treffen mit derselben Person angesetzt werden.',
			meetingError:
				'Ungültiges Datum oder Uhrzeit der Besprechung. Bitte erneut prüfen!',
			buttonDisabled:
				'Sie können eine Besprechung planen, nachdem Sie das Gespräch begonnen haben!',
		},
		banner: {
			pending: 'Treffensanfrage',
			accepted: 'Treffen geplant für',
			progress: 'Laufendes Treffen',
			cancelTitle: 'Treffen annulieren?',
			cancelDescription: 'Wollen Sie wirklich  dieses Treffen annulieren?',
			startCall: 'ANRUF STARTEN',
			joinCall: 'ANMELDEN',
			pendingOwner: 'Ausstehende Besprechungsanfrage für',
			videoCallStarted: 'Die Videobesprechung hat begonnen',
			videoCallStartedInfo:
				'Video-Meetings funktionieren am besten mit bis zu 10 Personen',
		},
		title: 'Ihre Besprechungen',
		meetingsInfo:
			'Besprechungen können über Chats geplant werden. Bitte starten Sie einen Chat mit dem Benutzer, mit dem Sie eine Besprechung planen möchten.',
	},
	socialMediaShare: {
		event: 'Event',
		shareLabel: 'Teilen',
		dialogTitleGeneral: 'Mit Ihrem Netzwerk teilen',
		dialogTitlePost: 'Ihren Post teilen',
		linkedinLabel: 'LinkedIn',
		facebookLabel: 'Facebook',
		twitterLabel: 'Twitter',
		emailLabel: 'E-Mail',
		clipboardLabel: 'Zwischenablage',
		clipboardSnackbarMessage: 'Link in Zwischenablage kopiert',
		linkedinButtonTitle: 'Bei LinkedIn teilen',
		facebookButtonTitle: 'Bei Facebook teilen',
		twitterButtonTitle: 'Bei Twitter teilen',
		emailButtonTitle: 'Per E-Mail teilen',
		clipboardButtonTitle: 'In Zwischenablage kopieren',
		imAttending: 'Ich nehme teil an',
		interestedClick: 'Lust, teilzunehmen? Klicken Sie hier:',
	},
	videoConferences: {
		fullScreen: 'VOLLBILDSCHIRM ÖFFNEN',
		minimizeScreen: 'BILDSCHIRM MINIMIEREN',
		startedVideoChat: 'Video-Anruf beginnen',
		timeAgo: 'vor',
		videoCallStarted: 'Video-Anruf wird gestartet …',
	},
	sessions: {
		edit: 'BEARBEITEN',
		speakers: 'SPRECHER',
		speakerSingular: 'Sprecher',
		moderators: 'MODERATOREN',
		moderatorSingular: 'Moderator',
		video: 'VIDEO',
		startStream: 'STREAM STARTEN',
		stopStream: 'STREAM STOPPEN',
		sessionChat: 'CHAT-SITZUNG',
		speakerUrl: 'Sprecher-URL',
		moderatorUrl: 'Moderator-URL',
		copyUrl: 'URL kopieren',
		noSpeakersJoined: 'Es sind noch keine Sprecher anwesend',
		allFilter: 'alle',
		waitingRoom: 'Warteraum',
		isReady: 'Ist bereit',
		invitationAccepted: 'Hat die Einladung angenommen',
		requestPending: 'Anfrage ausstehend',
		gettingReady: 'Bereit machen',
		inviteToSpeak: 'Zum Sprechen einladen',
		fromAuditorium: 'vom Auditorium',
		mic: 'Mikrofon',
		screen: 'Bildschirm',
		rec: 'aufnehmen',
		sessionHasEnded: 'Diese Sitzung ist beendet',
		sessionLinks: 'SITZUNG-LINKS',
		sessionSettings: 'Einstellungen',
		noAvailableChat:
			'Kein Chat verfügbar. Um den Chat aufzurufen, weisen Sie diese Sitzung bitte einem Zeitfenster zu.',
		startLiveSessionStreamTitle: 'Live-Session-Stream starten',
		startLiveSessionDescription:
			'Die Aufnahme der Sitzung beginnt automatisch, wenn Sie den Stream starten',
		stopLiveSessionStreamTitle: 'Live-Session-Stream beenden',
		stopLiveSessionStreamDescription:
			'Durch Beenden des Live-Session-Streams wird die Aufnahme ebenso automatisch beendet. Dieser Live-Session-Stream kann nicht fortgesetzt werden',
		thumbnailImage: 'Vorschaubild',
		uploadThumbnail: 'Vorschaubild hochladen',
		clickToUpload: 'Zum Hochladen hier klicken',
		requestMic: 'MIKROFON ANFRAGEN',
		acceptInvitationTitle: 'Sie haben die Einladung',
		acceptInvitationSubTitle:
			'Ein Moderator hat sie als Sprecher eingeladen. Was halten Sie davon?',
		acceptInvitationButton: 'Einladung annehmen',
		requestPendingTitle: 'Anfrage ausstehend …',
		requestPendingSubTitle:
			'Bitte warten Sie, bis der Moderator Sie in den Stream aufnimmt',
		cancelRequest: 'Anfrage rückgängig machen',
		approvedTitle:
			'Bitte überprüfen Sie Ihr Mikrofon und Ihre Kamera und klicken Sie auf Bin bereit',
		approvedSubTitle:
			'Diese Sitzung wird aufgezeichnet. Durch Teilnahme an dieser Sitzung stimmen Sie der Verarbeitung Ihrer persönlichen Daten zu.',
		connectedToTheBackstage:
			'Sie streamen jetzt live und sind mit dem Backstage-Bereich verbunden',
		removedFromTheSession:
			'Sie sind aus der Sitzung entfernt worden. Kontaktieren Sie bitte einen Moderator.',
		imReady: 'Bin bereit',
		isTryingToRegister: 'versucht Sie zu registrieren als',
		hasLeftTheSession: 'hat die Sitzung verlassen',
		stopVideo: 'Video anhalten',
		startVideo: 'Video starten',
		disabledScreenSharing:
			'Deaktivieren Sie das Teilen Ihres Bildschirms vorm Starten Ihres Videos',
		disabledByModerator: 'Von Moderator deaktiviert',
		disabledUntilReady: 'Deaktiviert bis bereit',
		stopShareScreen: 'Bildschirmteilen beenden',
		shareScreen: 'Bildschirm teilen',
		disabledVideoSharing:
			'Schalten Sie vorm Teilen Ihres Bildschirms Ihr Video aus',
		anotherUserIsScreenSharing: 'Jemand anderes teilt schon seinen Bildschirm',
		removeRoleDescription:
			'Wollen Sie folgenden Teilnehmer wirklich entfernen:',
		declinedInvitation: 'hat die Einladung als Sprecher abgelehnt',
	},
	gdpr: {
		disclaimerTextFirst:
			'Das Dokument wurde mithilfe von Künstlicher Intelligenz übersetzt. Falls Sie Unstimmigkeiten feststellen, richten Sie sich bitte nach der englischen Version dieses Dokuments',
		disclaimerTextSecond: 'HIER',
		metaTitlePrivacyPolicy: 'Datenschutzerklärung',
		metaTitleCookiesPolicy: 'Cookie-Richtlinie',
		metaTitleTerms: 'Allgemeine Geschäftsbedingungen',
		updateCookieConsent: 'Cookie-Zustimmung aktualisieren',
		changeYourConsent: 'Zustimmung ändern',
		withdrawYourConsent: 'Zustimmung widerrufen',
		withdrawSnackbarText: 'Cookie-Zustimmung erfolgreich widerrufen',
	},
	infoMessages: {
		imageExplain420x200:
			'Empfohlenes Bildverhältnis 1,8:1 (querformatig, z. B. 420 x 200 Pixel)',
		imageExplainTB230x320:
			'Empfohlenes Bildverhältnis 1:1,4 (hochformatig, z.B. 230 x 320 Pixel) mit transparentem Hintergrund',
		imageExplainTB230x875:
			'Empfohlenes Bildverhältnis 1:3,8 (hochformatig, z. B. 230 x 875 Pixel) mit transparentem Hintergrund',
	},
	matching: {
		matchingQuestionnaireTitle: 'Fragebogen zum Ermitteln von Treffern',
		matchingQuestionnaireIntro:
			'Wir helfen Ihnen, die für Sie am relevantesten Menschen zu treffen! Bei Zusammentreffen der passenden Geister können bedeutende Dinge entstehen. Füllen Sie einfach den Fragebogen aus, um zu beginnen',
		matchingQuestionnaireIntroSkip:
			'Wenn Sie den Fragebogen überspringen, können wir Sie für das heutige Event nicht mit den restlichen Teilnehmern verbinden. Sie können den Fragebogen immer noch später während der Networking-Sitzung ausfüllen. Alle Fragen können über Mein Konto bearbeitet werden',
		doItLaterBtn: 'SPÄTER MACHEN',
		nextBtn: 'Weiter',
		backBtn: 'Zurück',
		finishBtn: 'Fertig',
		seeMatchesBtn: 'ALLE TREFFER ANZEIGEN',
		tryMatchingBtn: 'INTELLIGENTE TREFFERERMITTLUNG AUSPROBIEREN',
		optionalQuestion: 'Optional ',
		requiredQuestion: 'Pflicht',
		singleSelect: 'Einzelauswahl',
		multiSelect: 'Mehrfachauswahl',
		mediumMatch: 'Mittlerer Treffer',
		highMatch: 'Hoher Treffer',
		matchLabel: 'Treffer',
		matchesLabel: 'Treffer',
		infoTooltipJump: 'Springen Sie hier hin, um Ihre Treffer anzuzeigen',
		infoTooltipClickToSee1: 'Klicken Sie auf',
		infoTooltipClickToSee2: 'um zu sehen, mit wem Sie sich verbinden sollten!',
		loadingAlgoText:
			'Treffer-Algorithmus aktiviert. Geben Sie uns kurz Zeit, während wir die besten Übereinstimmungen für Sie zusammenstellen!',
		matchingDetailsFormTitle: 'Details zu intelligenten Treffern',
		tryMatchingBanner:
			'Knüpfen Sie bedeutsame Kontakte, indem Sie Sie unser intelligentes Treffer-Feature ausprobieren!',
		matchingBannerClosed:
			'Sie können das Formular zum Ermitteln intelligenter Treffer weiterhin im Bereich Networking unter Treffer ausfüllen',
		resultsTitle: 'Hier sind Ihre Top-Treffer',
		resultsNoteLabel: 'HINWEIS:',
		resultsNoteText: 'Sie finden alle Treffer im Networking-Bereich.',
		resultsNoteMyAccount:
			'Sie können alle Antworten im Mein Konto-Menü bearbeiten.',
		btwResultsResponsive:
			'Übrigens finden Sie alle Treffer im NETWORKING Bereich',
		noMoreMatches:
			'Ups! Wir können momentan keine weiteren Treffer für Sie finden. Bitte versuchen Sie es später erneut, wenn weitere Teilnehmer dazugekommen sind',
		noResultsTitle:
			'Herzlichen Glückwunsch, Sie haben es geschafft! Wir suchen nach den besten Treffern für Sie!',
		noResults:
			'Danke, dass Sie die intelligente Trefferermittlung nutzen! Wir suchen die besten Treffer für Sie und verbinden Sie, sobald weitere Teilnehmer zu dem Event hinzugekommen sind. Bitte überprüfen Sie den „Treffer"-Reiter im Bereich „Networking".',
		clickStartChat: 'Klicken Sie auf Chat, um ein Gespräch zu beginnen',
		closeMatchingTitle: 'Treffer-Formular schließen?',
		closeMatchingTextA:
			'Wenn Sie das Formular vor dem vollständigen Ausfüllen schließen, werden keine Details gespeichert und Sie können die Ergebnisse aus der intelligenten Trefferermittlung nicht einsehen.',
		closeMatchingTextB:
			'Ihr Profil wird nicht in den Prozess der intelligenten Trefferermittlung einbezogen und niemand wird mit Ihnen verbunden.',
		matchingTooltip:
			'Klicken Sie auf Treffer, um zu sehen, mit wem Sie sich verbinden sollten!',
		myAccountMatchingSectionTitle: 'Details intelligente Trefferermittlung',
		matchingRequiredError: 'Sie müssen alle Pflichtfragen beantworten',
		rejectMatchingTitle: 'Teilnahme an Trefferermittlung ablehnen?',
		rejectMatchingText:
			'Ihre Antworten werden gelöscht und es werden unter den Teilnehmern keine Treffer für Sie ermittelt. Sie können den Fragebogen zur Trefferermittlung jederzeit weiter ausfüllen.',
		rejectMatchingBtn: 'TEILNAHME ABLEHNEN',
	},
	welcomeScreen: {
		goToEvent: 'Zum Event gehen',
	},
	emailCodeVerification: {
		codeSent: 'Wir haben Ihnen einen Verifikationscode per E-Mail zugesandt:',
		continueRegistration: 'Um die Registrierung abzuschließen,',
		enterCodeBelow: 'geben Sie unten den Verifikationscode ein:',
		codeWillExpire: 'Dieser Code läuft ab in',
		codeExpired: 'Der Code ist abgelaufen',
		codeNotReceived:
			'Code nicht erhalten? Haben Sie Ihren Spam-Ordner geprüft?',
		resendTimer: 'Neu versenden in:',
		resendLabel: 'Neu versenden',
		resendCodeLabel: 'Code neu versenden',
		codeError: 'Der eingegebene Code ist ungültig.',
		codeErrorFix:
			'Geben Sie den korrekten Code aus Ihrer E-Mail ein oder fordern Sie einen neuen Code an',
		congrats: 'Herzlichen Glückwunsch!',
		emailConfirmed: 'Ihre E-Mail ist bestätigt:',
	},
	privacyPolicyUpdates: {
		learnMore: 'Learn more', // new translation
		lastUpdated: 'Last updated', // new translation
		publishedBy: 'Published by', // new translation
		ppuLinkLabel: 'Updated privacy policy', // new translation
		ppuEventLinkLabel: 'Updated event privacy policy', // new translation
		acceptAllButtonLabel: 'Accept all updates', // new translation
	},
	voting: {
		votingText: 'WÄHLEN',
		enableVoting: 'ABSTIMMUNG AKTIVIEREN',
		enableVotingDescription:
			'Klicken Sie auf das Symbol „Aktualisieren“, um die genauesten Ergebnisse zu erhalten',
		displayVotingNumbersForExhibitors:
			'ABSTIMMUNGSZAHLEN FÜR AUSSTELLER & POSTER/STUDIE ANZEIGEN',
		displayVotingNumbersForExhibitorsDescription:
			'Wird im Aussteller-Dashboard angezeigt',
		displayVotingNumbersForParticipants:
			'ABSTIMMUNGSZAHLEN FÜR TEILNEHMER ANZEIGEN',
		displayVotingNumbersForParticipantsDescription:
			'Wird für alle Teilnehmer angezeigt',
		characterError80:
			'Sie haben die maximal zulässige Zeichenanzahl erreicht (80 Zeichen)',
		characterDescription: `Text ist auf 80 Zeichen begrenzt. Der Text erscheint als Haupttitel im Abstimmungsband. Machen Sie es für die Teilnehmer sinnvoll`,
		deleteAllVotes: 'ALLE STIMMEN LÖSCHEN',
		downloadVotingResults: 'ABSTIMMERGEBNISSE HERUNTERLADEN',
		voteText: 'Abstimmung',
		votedText: 'Abgestimmt',
		votesText: 'Stimmen',
		participant: 'teilnehmer',
		participants: 'teilnehmer',
		deleteVotesTextFirst: 'WARNUNG:',
		deleteVotesTextSecond: 'Dies ist eine nicht umkehrbare Aktion.',
		deleteVotesTextThird:
			'Vergessen Sie nicht, die Abstimmungsergebnisse herunterzuladen, bevor Sie sie löschen.',
		checkTheBoxToCastYourVote:
			'Aktivieren Sie das Kontrollkästchen, um Ihre Stimme abzugeben',
		toVotePleaseEnableParticipantProfile:
			'Um abzustimmen, aktivieren Sie bitte Ihr Teilnehmerprofil',
	},
};

export default de;
