const tr = {
	generalText: {
		upload: 'Yükle',
		save: 'Kaydet',
		add: 'Ekle',
		remove: 'Kaldır',
		close: 'KAPAT',
		update: 'GÜNCELLE',
		cancel: 'İPTAL ET',
		confirm: 'Onayla',
		download: 'İndir',
		refresh: 'Yenile',
		back: 'Geri',
		submit: 'Gönder',
		send: 'Gönder',
		leave: 'Bırak',
		step1: 'Adım 1',
		step2: 'Adım 2',
		or: 'veya',
		delete: 'Sil',
		please: 'Lütfen',
		before: 'önceki',
		approve: 'Onayla',
		decline: 'Reddet',
		start: 'Başla',
		stop: 'Dur',
		resetFilter: 'Filtreyi Sıfırla',
		apply: 'Uygula',
		filters: 'Filtreler',
		unsavedChangesTitle: 'Kaydedilmemiş değişiklikler',
		unsavedChangesText:
			'Kaydedilmemiş değişiklikleriniz mevcut. Kurtarmak istiyor musun?',
		selectAll: 'Hepsini Seç',
		unselectAll: 'Tümünün Seçimini Kaldır',
	},
	time: {
		justNow: 'şu anda',
		minute: 'dakika',
		minutes: 'dakika',
		hour: 'saat',
		hours: 'saat',
		day: 'gün',
		days: 'gün',
		month: 'ay',
		months: 'ay',
		yesterday: 'dün',
		today: 'bugün',
		tomorrow: 'yarın',
		monday: 'pazartesi',
		tuesday: 'salı',
		wednesday: 'çarşamba',
		thursday: 'perşembe',
		friday: 'cuma',
		saturday: 'cumartesi',
		sunday: 'pazar',
		january: 'ocak',
		february: 'şubat',
		march: 'mart',
		april: 'nisan',
		may: 'may',
		june: 'haziran',
		july: 'temmuz',
		august: 'ağustos',
		september: 'eylül',
		october: 'ekim',
		november: 'kasım',
		december: 'aralık',
		januaryShort: 'oca',
		februaryShort: 'şub',
		marchShort: 'mar',
		aprilShort: 'nis',
		mayShort: 'may',
		juneShort: 'haz',
		julyShort: 'tem',
		augustShort: 'ağu',
		septemberShort: 'eyl',
		octoberShort: 'eki',
		novemberShort: 'kas',
		decemberShort: 'ara',
	},
	errors: {
		noFile: 'Dosya seçilmedi',
		required: 'Alanın doldurulması gerekir',
		emailNotValid: 'Eposta geçersiz',
		passwordMinLength: 'Şifre en az 8 karakterden oluşmalıdır',
		passwordMismatch: 'Şifre eşleşmiyor',
		passwordIsSame: 'Yeni şifreniz farklı olmalı',
		noInvitationCode: 'Lütfen davet kodunuzu girin',
		imageFileNotSupported:
			'Dosya türü desteklenmiyor. Lütfen aşağıdakilerden birini deneyin:',
		phoneInvalid:
			'Lütfen geçerli bir telefon numarası girin. Örneğin: +447517663928',
		linkInvalid: 'Lütfen geçerli bir link girin (https://site.com/)',
		facebookLinkInvalid:
			'Lütfen geçerli bir link girin (https://facebook.com/)',
		linkedinLinkInvalid:
			'Lütfen geçerli bir link girin (https://linkedin.com/)',
		twitterLinkInvalid: 'Lütfen geçerli bir link girin (https://twitter.com/)',
		youtubeLinkInvalid:
			'Lütfen geçerli bir YouTube linki girin (https://www.youtube.com)',
		youtubeOrVimeoLinkInvalid:
			'Lütfen geçerli bir YouTube (https://www.youtube.com) veya Vimeo (https://vimeo.com/) linki girin.',
		googleScholarLinkInvalid:
			'Lütfen geçerli bir link girin (https://scholar.google.com/)',
		researchGateLinkInvalid:
			'Lütfen geçerli bir link girin (https://www.researchgate.net/)',
		connectionProblemTextFirst:
			'Bir bağlantı sorunu varmış gibi gözüküyor. Lütfen',
		connectionProblemButton: 'TIKLAYIN',
		connectionProblemTextSecond: 'veya sayfayı yenileyin.',
		fileTooLarge: 'Dosya çok büyük. Maks. dosya boyutu 2Mb.',
		fileTooLargeTextFirst: 'Dosya çok büyük.',
		fileTooLargeTextSecond: 'Maks. dosya boyutu.',
		fileNameTooLongTextFirst: 'Dosya adı çok uzun. Sınır',
		fileNameTooLongTextSecond: 'karakter.',
		maxWallInputLength:
			'1200 karakter olan maksimum karakter sayısına ulaştınız.',
	},
	footer: {
		cookiesText:
			'Bu web sitesi, çalışması için gerekli olan temel çerezleri kullanmaktadır. Ayrıca, web sitemizin bazı özelliklerinin çalışması için gerekli olan üçüncü taraf çerezlerini kullanmaktadır. Daha fazla bilgi edinmek veya bazı çerezlere olan onayınızı geri çekmek istiyorsanız, lütfen şuraya bakın',
		cookiesLink: 'çerezler politikası',
		cookiesButton: 'KABUL ET',
		privacyPolicy: 'Gizlilik Politikası',
		cookies: 'Çerezler',
		termsAndConditions: 'Hükümler ve Koşullar',
		socialLinksTitle: 'Bizi Takip Edin:',
	},
	menu: {
		logIn: 'GİRİŞ YAP',
		logOut: 'ÇIKIŞ YAP',
		scholarRegistration: 'Poster/çalışma olarak katıl',
		exhibitorRegistration: 'Sergici olarak katıl',
		participantRegistration: 'Katılımcı olarak katıl',
		createEvent: 'Etkinlik düzenleyin',
		loginDisabledInfoText:
			'Organizatör oturum açmayı kapattı. Daha sonra kontrol et',
	},
	homepage: {
		organizedBy: 'DÜZENLEYEN',
		date: 'TARİH',
		location: 'ALAN VE KONUM',
		price: 'FİYAT',
		tabIntro: 'GİRİŞ',
		tabProgram: 'PROGRAM',
		tabSponsors: 'SPONSORLAR',
		tabPartners: 'ORTAKLAR',
		tabExhibitors: 'SERGİCİLER',
		previousEventsSectionTitle: 'ÖNCEKİ ETKİNLİKLER',
	},
	socialButtons: {
		linkedinButton: 'LinkedIn ile Giriş Yapın',
		loginTabLinkedinButton: 'LinkedIn ile',
		facebookButton: 'Facebook ile Giriş Yapın',
		loginTabFacebookButton: 'Facebook ile ',
		googleButton: 'Google ile Giriş Yapın',
		loginTabGoogleButton: 'Google ile',
		twitterButton: 'Twitter ile Giriş Yapın',
		loginTabTwitterButton: 'Twitter ile',
		socialButtonsLogInTitle: 'Sosyal medya profilinizle katılın',
		socialButtonsSignUpTitle: 'Sosyal medya profilinizle kaydolun',
	},
	login: {
		inputEmail: 'Eposta',
		inputPassword: 'Şifre',
		rememberMeButton: 'Beni hatırla',
		forgotPasswordButton: 'Şifrenizi mi unuttunuz?',
		loginButton: 'GİRİŞ YAP',
		loginClosedButton: 'Oturum açma kapatıldı',
		loginTitleLowerCase: 'Oturum aç',
		recoverPasswordDialogTitle: 'ŞİFRE KURTAR',
		recoverPasswordDialogRecoverButton: 'KURTAR',
		errorIncorrectDates: 'yanlış eposta adresi veya şifre',
		orLogInWithEmail: 'veya e-posta ile giriş yapın',
		logInAsParticipantSecondText: 'katılımcı olarak',
		logInAsExhibitorSecondText: 'sergici olarak',
		logInAsPosterSecondText: 'poster/çalışma olarak',
		errorAccountNotFound: 'Unstoppabl.ai hesabınızı bulamadık',
	},
	register: {
		inputFirstName: 'İsim',
		inputLastName: 'Soyisim',
		inputEmail: 'Eposta',
		inputPassword: 'Şifre',
		inputConfirmPassword: 'Şifreyi Onayla',
		gdprTextFirst: 'Devam ederek, aşağıdakileri kabul etmiş olursunuz',
		gdprTextTerms: 'Kullanım Koşulları',
		gdprTextSecond: 've çerez politikamızı',
		gdprTextCookies: 'okuduğunuzu onaylayın',
		gdprTextPrivacy: 'Gizlilik Politikası',
		gdprTextAgree: 'Lütfen gizlilik politikasını onaylayın',
		registerButton: 'Kaydol',
		signUpTitleLowerCase: 'kaydol',
		invitationCode: 'Davet kodu',
		registerNotAllowed: 'Kayıt kapandı',
		logInTitleFirst: 'Katıl',
		logInTitleSecond: 'etkinlik',
		signUpTitleTextFirst: 'İlk kez',
		signUpTitleTextSecond: 'Unstoppabl.ai',
		orFillInTheForm: 'veya aşağıdaki formu doldurun',
		uploadProfilePicture: 'Profil resmi yükle',
		editProfilePicture: 'Profil resmini düzenle',
		errorAlreadyHaveMOAccount: "Unstoppabl.ai'te zaten bir hesabınız var",
		step1From2Text: '1/2 aşama',
		step2From2Text: '2/2 aşama',
		metaTitleScholarRegistration: 'Poster/Çalışma kaydı',
	},
	newLoginRegistrations: {
		joinEvent: 'ETKİNLİĞE KATIL',
		loginWelcome:
			"Unstoppabl.ai'e hoş geldiniz! Devam etmek için giriş yöntemi seçin",
		continueButton: 'Devam',
		seemsLikeYouDontHaveMyOnventAccount:
			'Unstoppabl.ai hesabınız yok gibi görünüyor',
		uploadProfilePictureOptional: 'Profil resmi yükle (isteğe bağlı)',
		createPlatformAccount: 'Platform Hesabı Oluştur',
		platformAccount: 'Platform Hesabı',
		selectRole: 'Rol seçin',
		eventAccount: 'Etkinlik hesabı',
		password: 'Şifre',
		confirmPassword: 'Şifreyi onayla',
		email: 'E-posta',
		passwordMustContain: 'Şifre aşağıdakileri içermelidir:',
		minimum8Characters: 'En az 8 karakter',
		aLowerCaseLetter: 'Bir küçük harf',
		anUppercaseLetter: 'Bir büyük harf',
		aNumber: 'Bir sayı',
		aSpecialCharacter: 'Bir özel karakter',
		firstName: 'Adınız',
		lastName: 'Soyadınız',
		weHaveSentAVerificationCodeToYourEmail:
			'E-postanıza bir doğrulama kodu gönderdik',
		continueRegistration: 'Kaydı tamamlamak için',
		enterCodeBelow: 'aşağıdaki doğrulama kodunu girin:',
		codeWillExpire: 'Bu kodun geçerlilik süresi',
		codeExpired: 'Kodun süresi doldu',
		codeNotReceived: 'Kodu almadınız mı? Spam klasörünü kontrol ettiniz mi?',
		resendTimer: 'Yeniden gönderme:',
		resendLabel: 'Yeniden gönder',
		resendCodeLabel: 'Kodu yeniden gönder',
		codeError: 'Girilen kod yanlış.',
		codeErrorFix:
			'E-postanızdan doğru kodu yeniden girin veya yeni bir kod gönderin',
		congrats: 'Tebrikler!',
		emailConfirmed: 'E-postanız doğrulandı:',
		imHereAs: 'Buradayım olarak:',
		participant: 'Katılımcı',
		participantText:
			'Akışı izlemek, iletişim kurmak, standları ve posterleri ziyaret etmek için.',
		exhibitor: 'Katılımcı',
		exhibitorText:
			'Şirketinizi tanıtmak ve etkinlik katılımcılarıyla iletişim kurmak için',
		poster: 'Poster/ Çalışma',
		posterText:
			'Akademik veya profesyonel konferanslar için araştırmalar sunmak için',
		emailIsNotAllowed: 'Bu e-posta, bu etkinliğe kaydolmaya izin verilmiyor',
		didntReceiveEmail: 'E-postayı almadın mı?',
		sendAgain: 'Tekrar gönder',
		incorrectPassword: 'Yanlış şifre ',
		fileTypeError:
			'Dosya türü desteklenmiyor. Lütfen şunlardan birini kullanın: jpeg, jpg, jfif, gif veya png.',
		fileSizeError: 'Dosya çok büyük. Maksimum dosya boyutu 2 MB.',
		pleaseCompleteAllRequiredFields: 'Lütfen tüm zorunlu alanları doldurun',
		joinTheEvent: 'Etkinliğe katılın',
		joinTheEventSub: 'Sanal çevrimiçi etkinlik deneyiminizi başlatmak için',
		changeEmail: 'E-POSTA DEĞİŞTİR',
		changeRole: 'ROLÜ DEĞİŞTİR',
		logout: 'ÇIKIŞ YAP',
		headingUser: 'Tekrar Hoşgeldin',
		subHeadingUser: 'Platform hesabın olduğu görünüyor',
		forgotPasswordHeadline: 'Şifreni mi unuttun?',
		forgotPasswordText: 'Endişelenme, sana daha fazla talimat göndereceğiz',
		checkYourEmail: 'E-postanı kontrol et',
		checkYourEmailText: 'Şifre sıfırlama bağlantısını gönderdik:',
		headingNoUser: 'Giriş Yap',
		subHeadingNoUser:
			"Unstoppabl.ai'e hoş geldin! Devam etmek için bir giriş yöntemi seçin",
		resetPasswordButton: 'şifreyi sıfırla',
		createNewPassword: 'Yeni şifre oluştur',
		createNewPasswordText:
			'Yeni şifreniz önceki kullanılan şifrelerden farklı olmalıdır',
		passwordReset: 'Şifre sıfırlama',
		passwordResetText:
			'Şifreniz başarıyla sıfırlandı. Tekrar giriş yapmak için aşağıdaki linke tıklayın.',
		registrationIsClosed: 'Tüm roller için kayıt kapalı.',
		chooseAnOption: 'Bir seçenek seçin',
		yourAnswer: 'Senin cevabın',
		firstNameError: 'Ad çok kısa. Minimum uzunluk: 2',
		lastNameError: 'Soyadı çok kısa. Minimum uzunluk: 2',
		recoveryPassword: 'Şifreyi Kurtar',
		incorrectPasswordError:
			'Yanlış şifre. Tekrar deneyin veya değiştirmek için "Şifremi unuttum" seçeneğine tıklayın.',
		optional: 'İsteğe bağlı',
		spamEmailError:
			'Hesabınız oluşturulurken bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
		invalidInput:
			"Geçersiz giriş. Sadece harf (A-Z, a-z), rakam (0-9), nokta (.), apostrof ('), kısa çizgi (-) ve boşlukları kullanın. İlk karakter bir harf olmalı.",
	},
	emailUpdate: {
		changeEmail: 'E-postayı Değiştir',
		changeEmailDescription:
			'Yeni e-postayı hesabınıza atamadan önce, yeni bir e-posta olduğundan ve kimseye ait olmadığından emin olmak istiyoruz.',
		emailTakenError: 'Bu e-posta adresi kullanılıyor. Başka bir tane deneyin.',
		enterTheConfirmationCode: 'Doğrulama kodunu girin',
		verificationCodeSent: 'Doğrulama kodu gönderildi:',
		checkYourSpamFolder:
			'Kodu bulamıyor musunuz? Spam klasörünüzü kontrol edin',
		emailSuccess: 'Başarılı! Hesabınıza atanan e-postayı değiştirdiniz',
		emailError: 'Hata. Daha sonra tekrar deneyin',
		confirmPassword: 'Şifreyi Onayla',
		confirmPasswordDescription:
			'E-postayı değiştirmek için şifrenizi onaylayın:',
		newEmail: 'Yeni e-posta',
		password: 'Şifre',
		confirm: 'Onayla',
		cancel: 'İptal',
		change: 'Değiştir',
	},

	participantRegistration: {
		metaTitle: 'Katılımcı kaydı',
		choosePackageTitle: 'ERİŞİM PAKETİNİ SEÇİN',
		noPackageText: 'Lütfen bir paket seçin',
		gdprTextFirst: 'Devam ederek, aşağıdakileri kabul etmiş olursunuz',
		gdprTextPrivacy: 'Gizlilik Politikası',
		gdprTextSecond: 've',
		gdprTextTerms: 'Etkinlik Hüküm ve Koşulları',
		gdprTextAgree: 'Lütfen gizlilik politikasını onaylayın',
		registerButtonFree: 'Kaydı Tamamla',
		registerButtonPay: 'Ödemeye devam edin',
	},
	exhibitorRegistration: {
		metaTitle: '',
		choosePackageTitle: 'ERİŞİM PAKETİNİ SEÇİN',
		gdprTextFirst: 'Devam ederek, aşağıdakileri kabul etmiş olursunuz',
		gdprTextPrivacy: 'Etkinlik Gizlilik Politikası',
		gdprTextSecond: 've',
		gdprTextTerms: 'Etkinlik Hüküm ve Koşulları',
		gdprTextAgree: 'Lütfen gizlilik politikasını onaylayın',
		registerButtonFree: 'Kaydı Tamamla',
		registerButtonPay: 'Ödemeye devam edin',
	},
	stripePayment: {
		cardNumber: 'Kart Numarası',
		expirationDate: 'Son Kullanma Tarihi (AA / YY)',
		cvc: 'CVC',
		disclaimerText:
			'Bu ödeme işlemi uçtan uca şifrelenmiştir. Güvenlik ve gizlilik, Stripe Services tarafından garanti edilmektedir.',
		disclaimerTextLink: 'Daha fazlasını öğrenmek için tıklayınız.',
		cancelButton: 'İPTAL ET',
		payButton: 'Öde',
	},
	recoverPassword: {
		recoverTextSend: 'Şifre kurtama epostası başarılı bir şekilde gönderildi.',
	},
	unsubscribe: {
		unsubscribeTitle: 'Abonelik iptal edilsin mi?',
		unsubscribeHomepage: 'Ana sayfaya git',
	},
	notAvailable: {
		title: 'Etkinlik kullanılamıyor',
		description:
			'Aradığınız etkinliğe ulaşılamıyor. Organizatör tarafından kapatılmış veya bir yönetici tarafından devre dışı bırakılmış olabilir',
		fullOverlayEvents: 'Diğer etkinliklerinize göz atın',
		fullOverlayNoEvents: 'Başka etkinlik bulunamadı',
	},
	notFound: {
		notFoundDescription: 'Hay aksi Burada bulunacak etkinlik yok!',
		notFoundHomepage: 'Ana sayfaya git',
	},
	eventMenu: {
		organizerButton: 'DÜZENLEYEN',
		coOrganizerButton: 'YÖNET',
		exhibitorButton: 'SERGİCİ',
		posterStudyButton: 'POSTER / ÇALIŞMA',
		boothButton: 'STANT',
		boothsButton: 'STANTLAR',
		homeButton: 'ANA SAYFA',
		contactsButton: 'İRTİBAT KİŞİLERİ',
		meetingsButton: 'TOPLANTILAR',
		chatsButton: 'SOHBETLER',
		programButton: 'PROGRAM',
		archiveButton: 'ARŞİV',
		myAccountButton: 'HESABIM',
		lobbyButton: 'Lobi',
		roomsButton: 'ODALAR',
		connectionProblemTextFirst:
			'Bir bağlantı sorunu varmış gibi gözüküyor. Lütfen buraya',
		connectionProblemButton: 'TIKLAYINIZ',
		connectionProblemTextSecond: 'veya sayfayı yenileyin.',
		sessionProblemTextFirst: 'Oturumunuz sona erdi. Lütfen buraya',
		sessionProblemButton: 'TIKLAYINIZ',
		sessionProblemTextSecond: 'oturum açmak için.',
	},
	myEventsDropdown: {
		title: 'ETKİNLİKLERİM',
		createButtonMobile: 'OLUŞTUR',
	},
	myBoothsDropdown: {
		title: 'STANTLARIM',
		emptyStudyTitle: 'Poster/Çalışma standı',
	},
	contactsDropdown: {
		title: 'İRTİBAT KİŞİLERİNİZ',
		requestsTitle: 'İrtibat Talepleri',
		rejectButton: 'REDDET',
		acceptButton: 'KABUL ET',
		pendingButton: 'Bekliyor',
		addButton: 'Ekle',
		noContacts:
			'Henüz irtibat kişiniz yok. Kişi listenizi oluşturmaya başlamak için lütfen ağ oluşturma bölümünü ziyaret edin.',
	},
	meetingsDropdown: {
		meetingWith: 'İle Toplantı',
		pending: 'Bekliyor',
		accepted: 'Kabul edildi',
		canceled: 'İptal edildi',
		noMeetings: 'Henüz hiçbir toplantı programlanmadı',
		finished: 'Bitti',
	},
	programDropdown: {
		stages: 'Aşamalar',
		with: 'ile',
		noProgram: 'Şu anda program girişleri mevcut değil.',
		fullProgramButton: 'Tüm program', // new translation
		addToCalendarButton: 'Takvime ekle', // new translation
		addedToYourAgenda: 'TAKVİNİNİZE EKLENDİ',
		addEventButton: 'Etkinlik ekle', // new translation
		addToMyAgenda: 'Takvimime ekle', // new translation
		addEventToYourCalendarDialogTitle: 'Etkinliği takviminize ekle', // new translation
		addTimeslotToYourCalendar: 'Zaman dilimini takviminize ekle', // new translation
		tip: 'İPUCU: ', // new translation
		tipDescription:
			'Her bir zaman dilimi yanındaki takvim simgesine tıklayarak ayrı ayrı zaman dilimleri de takviminize ekleyebilirsiniz.', // new translation
		tipTimeslotDescription:
			'Tüm etkinliği "Takvime ekle" düğmesine tıklayarak da ekleyebilirsiniz.', // new translation
		durationEventTitle: 'Etkinlik süresi: ', // new translation
		timeslotTitle: 'Başlık: ', // new translation
		slotScheduled: 'Zaman dilimi planlandı: ', // new translation
		addSlotToCalendar: 'Zaman dilimini takvime ekle', // new translation
		speakers: 'Konuşmacılar', // new translation
		auditoriumName: 'Salon adı', // new translation
		timezoneTooltipText:
			'Program, düzenleyicinin saat diliminde görüntülenir. Tercih ettiğiniz saat diliminde oturumları görmek için görüntüleme saat dilimini değiştirebilirsiniz', // new translation
		liveLabel: 'Canlı', // new translation
		liveLabelTooltipText: "Bu zaman dilimi şu anda canlı olarak Auditorium'da", // new translation
		openAuditorium: 'AUDITORIUMU AÇ', // new translation
	},
	notificationsDropdown: {
		title: 'Bildirimler',
		contactRequestReceived: 'size bir iletişim isteği gönderdi. Bir göz atın!',
		contactRequestAccepted:
			'iletişim talebinizi kabul etti. Sohbet etmeye başlayın!',
		meetingRequestReceived: 'size bir toplantı isteği gönderdi. Bir göz atın!',
		meetingRequestAccepted:
			'sizin toplantı isteğinizi kabul etti. Bir göz atın!',
		meetingRequestCanceled: 'bir toplantı isteğini iptal etti. Bir göz atın!',
		meetingStarting: 'yakında bir toplantı başlayacak. Bir göz atın!',
		newBoothPost: 'standınızın duvarına gönderimde bulundu. Bir göz atın!',
		noNotifications: 'Henüz hiçbir bildiriminiz yok.',
		seeAll: 'HEPSİNİ GÖR',
		hideAll: 'HEPSİNİ SAKLA',
		meetingWillStartTextSecond: 'çok yakında başlayacak. Göz atın!',
	},
	chatsDropdown: {
		title: 'SOHBETLER',
		newChatProvideText:
			'Lütfen sohbetiniz için bir isim girin. Bu ismi daha sonrasında tekrar düzenleyebileceksiniz:',
		noChats:
			'Etkinlik katılımcılarıyla konuşmak için en az bir sohbet oluşturun',
		members: 'Üyeler',
		noOpenedChatText: 'Sohbete devam etmek için bir sohbete tıklayın.',
		noMessagesInChatTextFirst: 'Buzları eritin.',
		noMessagesInChatTextSecond: 'Bu sohbeti başlatan kişi olun.',
		newChatNameInput: 'Sohbet ismi (Opsiyonel)',
		newChatAddMembers:
			'Bu sohbete irtibat kişisi ya da diğer katılımcıları ekleyin:',
		editChatAddMembers:
			'Lütfen grup sohbetine eklemek istediğiniz bu etkinlikten kullanıcıları arayın.',
		newChatSearchInput: 'Kullanıcı ara',
		newChatNoUsersMessage: 'Kullanıcı Bulunamadı',
		newChatRemoveUserButton: 'Kaldır',
		newChatCancelButton: 'KAPAT',
		newChatCreateButton: 'OLUŞTUR',
		membersLeaveButton: 'AYRIL',
		membersUpdateButton: 'GÜNCELLE',
		membersChatButton: 'Sohbet',
		chatSettingsButton: 'Ayarlar',
		newMessageInput: 'Mesajınızı buraya yazın',
		newVideoCallTooltipText: 'Görüntülü sohbet başlatmak için burayı tıklayın',
		leaveVideoMeetingTitle: 'Görüntülü toplantıdan ayrıl',
		leaveVideoMeetingDescription:
			'Görüntülü toplantıdan çıkmak üzeresiniz. Devam etmek istediğinizden emin misiniz?',
		leaveChatConfirmationTitle: 'Görüntülü görüşmeden ayrıl',
		leaveChatConfirmationText:
			'Şuanki görüntülü sohbetten ayrılmak istediğinizden emin misiniz?',
	},
	myAccountDropdown: {
		title: 'HESABIM',
		viewProfileButton: 'Profili Görüntüleyin',
		logoutButton: 'ÇIKIŞ YAP',
	},
	myAccountPage: {
		changePasswordButton: 'ŞİFREYİ DEĞİŞTİR',
		deleteAccountButton: 'HESABIMI SİL',
		platformLanguage: 'Platform dili:',
		privacyPolicyLink: 'Gizlilik bildirimi / politikası',
		cookiesLink: 'Çerezler',
		termsAndConditionsLink: 'Hükümler ve Koşullar',
		eventProfileSectionTitle: 'Etkinlik profili',
		eventPackage: 'Etkinlik Paketi',
		unregisterFromEventButton: 'ETKİNLİKTEN KAYDINIZI SİLİN',
		unregisterCurrentEventButton: 'Mevcut etkinlikten kaydınızı silin',
		unregisterCurrentEventConfirmation:
			'Lütfen etkinlik kaydını iptal etmek istediğinizi onaylayın',
		enableParticipantProfile: 'Katılımcı Profilini Etkinleştir',
		editUserDialogTitle: 'Kullanıcı Bilgilerini Düzenle',
		editExhibitorDialogTitle: 'Şirket İlgili Kişi Bilgilerini Düzenleyin',
		editScholarDialogTitle: 'Poster / Çalışma İlgili Kişi Bilgilerini Düzenle',
		editParticipantDialogTitle: 'Katılımcı Bilgilerini Düzenle',
		firstName: 'İsim',
		lastName: 'Soyisim',
		uploadImage: 'Resim yükleyin',
		removeImage: 'Görseli kaldırın',
		deleteError:
			'Hesabınızı silemezsiniz. Lütfen hesabınızı silebilmek için oluşturulan tüm etkinlikleri silin.',
		deleteSuccess: 'Hesabınız silindi.',
		reviewEventPrivacyPolicy: 'Etkinlik Gizlilik Politikamızı gözden geçirin:',
		title: 'Ünvan',
		company: 'Şirket',
		phone: 'Telefon',
		platformProfileSectionTitleTextSecond: 'profil',
		createParticipantProfile: 'Katılımcı Profili Oluştur',
		reviewPlatformConditions: 'Platform koşullarımızı inceleyin',
		reviewEventConditions: 'Etkinlik koşullarımızı inceleyin',
	},
	changePassword: {
		title: 'ŞİFREYİ DEĞİŞTİR',
		oldPassword: 'Eski Şifre',
		oldPasswordError: 'Eski şifre eşleşmiyor!',
		inputPassword: 'Yeni Şifre',
		inputConfirmPassword: 'Şifreyi Onayla',
	},
	roomsDropdown: {
		auditoriumButton: 'Konferans Salonu',
		archiveButton: 'ARŞİV',
		onDemandButton: 'TALEP ÜZERİNE',
	},
	sideMenu: {
		liveWall: 'Canlı Duvar',
		networking: 'Ağ Kurma',
		audience: 'İZLEYİCİ',
		info: 'BİLGİ',
		videoWall: 'Video Duvarı',
		booth: 'STANT',
		boothWall: 'Stant Duvarı',
		networkingTooltip: 'Tüm ağ işlevlerine erişiminiz bulunmamaktadır.',
		attendees: 'Katılımcılar',
	},
	wall: {
		sortBy: 'Sırala:',
		popularity: 'POPULERLİK',
		time: 'ZAMAN',
		emptyWallText: 'Buraya ilk gönderi ekleyen siz olun',
		newPostInput: 'Aklınızdan ne geçiyor?',
		newCommentInput: 'Yorum',
		resourcesVideoDescription: 'VİDEOLU AÇIKLAMA',
		resourcesLinks: 'Linkler',
		resourcesFiles: 'Dosyalar',
		pinTitle: 'Sabitle',
		unPinTitle: 'Sabitlemeyi kaldır',
		deleteDialogTitle: 'Gönderi silinsin mi?',
		pinDialogDescription:
			'Zaten sabitlenmiş bir gönderi var. Bir seferde yalnızca bir gönderi sabitlenebilir. Devam ettiğinizde, önceki gönderinin sabitlemesi otomatik olarak kaldırılacaktır.',
		edited: 'Düzenlendi',
		escapeToCancel: 'iptal etmek için çıkın',
		enterToSave: 'kaydetmek için girin',
		editingState: 'Mesaj düzenleme',
		bannerModeration:
			'bu duvardaki gönderiler ve yorumlar kaliteli içerik sağlamak için denetleniyor',
		pendingApproval: 'Onay bekleyen',
		approvePost: 'Gönderiyi onayla',
		approvePostComment: 'Yorumu onayla',
		commentsPendingApproval: 'Onay bekleyen yorumlar',
	},
	networking: {
		searchInput: 'Kullanıcı ara',
		emptyNetworkingSearchResults:
			'Aradığınız kişi bu çevrimiçi etkinliğe kaydolmamış',
		emptyAuditoriumNetworkingSearchResults:
			'Aradığınız kişi bu sunuma henüz katılmadı',
		alphabetical: 'abc',
		online: 'online',
		matching: 'eşleşmeler',
		matchingAlgo:
			'Eşleştirme algoritması iş başında. En iyi eşleşmelerinizi seçerken bize bir dakika izin verin!',
		searchFilters: 'Arama Filtreleri',
		applyFilterTooltip: 'Filtre uygulamak için en az 1 seçenek belirleyin',
		chat: 'Sohbet',
	},
	closedAuditoriumDialog: {
		title: 'Konferans Salonu Bilgileri',
		contentPackage:
			'Erişim paketinizin herhangi bir konferans salonuna erişimi yoktur.',
	},
	closedShowfloorDialog: {
		title: 'Gösteri Katı Bilgileri',
		contentPackage: 'Erişim paketinizin hiçbir Sergi Salonuna erişimi yok.',
		button: 'TAMAM',
	},
	auditorium: {
		willStart: 'Sonraki oturum ... içinde başlayacak',
		noAccess: 'Erişim paketinizin bu konferans salonuna erişimi yoktur.',
		noVideos: 'Bu Konferans Salonunda oynatılması planlanan video yoktur.',
		stageTab: 'AŞAMA',
		videoWallButton: 'Video Duvarı',
		resourcesButton: 'KAYNAKLAR',
		slotEndsTextFirst: 'Bu program slotu içinde sona erecektir',
		slotEndsTextSecondUpdated: 'Sunum talep üzerine sunulacaktır.',
		slotTitleWith: 'ile',
		allAuditoriums: 'Tüm Oditoryumlar',
		onDemandButton: 'TALEP ÜZERİNE',
		hide: 'Sakla',
		leavePopupTitle: 'Sahneden ayrılıyor musunuz?',
		leavePopupDescription:
			'Oditoryumdan çıktığınızda oturumdan da çıkarılacaksınız.',
		leaveSession: 'Sahneden ayrıl',
		cancelDescription:
			'Ayrıldığınızda artık konuşmacı olmayacaksınız. Devam etmek istiyor musunuz?',
		settings: 'Ayarlar',
		minimize: 'Simge durumuna küçült',
		microphone: 'Mikrofon',
		camera: 'Kamera',
		auditoriums: 'Oditoryumlar',
	},
	auditoriumArchive: {
		title: 'Konferans Salonu Arşivi',
		titleEvent: 'Etkinlik Arşivi',
		with: 'ile',
		noVideosAvailable: 'Tüm oturumlar tamamlandı',
	},
	videoPlayer: {
		videoNotPlayingError:
			'Video Oynatımıyla ilgili sorun mu yaşıyorsunuz? Lütfen Yeniden Yükleyin.',
		videoEndedTextFirst: 'Video sona erdi. İzlediğiniz için teşekkür ederiz!',
		videoEndedTextSecond: 'Video duvarı Soru-Cevap için açık konumdadır.',
		tooltipPlay: 'Oynat',
		tooltipPause: 'Duraklat',
		tooltipMinimize: 'Simge durumuna küçült',
		tooltipFullscreen: 'Tam ekran',
		tooltipMute: 'Sessize al',
		tooltipUnmute: 'Sesi Aç',
		videoStreamNotStarted: 'Yakında yayında. Bizi izlemeye devam edin!',
	},
	showfloor: {
		allExhibitorsButton: 'TÜM SERGİCİLER',
		featuredExhibitorsText: 'ÖNE ÇIKAN SERGİCLER',
		boothLinksDialogTitle: 'STANT LİNKLERİ',
		boothFilesDialogTitle: 'STANT DOSYALARI',
		boothVideoDialogTitle: 'VİDEO',
		mobileTabChat: 'Sohbet',
		mobileTabPoster: 'POSTER',
		mobileTabFiles: 'Dosyalar',
		mobileTabLinks: 'Linkler',
		mobileTabVideo: 'VİDEO',
		noExhibitorsTextFirst: 'KATILIMCILAR STANTLARINDA ÇALIŞIYOR',
		noPresentersTextFirst: 'Sunucular stantlarında çalışıyor',
		noExhibitorsTextSecond: 'TAKİPTE KALIN',
		showShowfloorsButton: 'TÜM SERGİ STANTLARI',
		showShowfloorsPosterButton: 'TÜM POSTERLER/ÇALIŞMALAR',
		showShowfloorsExhibitorButton: 'TÜM SERGİCİLER',
		searchExhibitors: 'Sergici ara',
		searchPosters: 'Başlığa veya yazar adına göre arama yapın',
		searchExhibitorsMultiple: 'Bu sergi salonunda sergici ara',
		searchPostersMultiple:
			'Bu sergi salonunda başlığa veya yazar adına göre ara',
		showfloorsTitle: 'Sergi Salonları',
		collapseList: 'Listeyi daralt',
		seeFullList: 'Tam listeyi gör',
		emptyExhibitorsResults:
			'Aranan stant mevcut değil.\nAncak bir sonraki sefere onları davet edeceğiz',
		emptyPostersResults:
			'Aranan poster/çalışma mevcut değil.\nAncak bir sonraki sefere onları davet edeceğiz',
		posters: 'posterler',
		allPostersStudiesTitle: 'Tüm Posterler/Çalışmalar',
		authors: 'yazarlar',
		titleNotAvailable: 'Başlık mevcut değil',
		abstractNotAvailable: 'Özet mevcut değil',
		authorsNotAvailable: 'Yazarlar mevcut değil',
		openPosterFile: 'Poster dosyasını aç',
		fileIsNotAvailable: 'Dosya mevcut değil',
		posterBooth: {
			study: 'Poster/Çalışma',
			authors: 'Yazarlar',
			highlights: 'Özet',
			scrollInfoText: 'ÖZETİN TAMAMINI OKUMAK İÇİN AŞAĞI KAYDIRIN',
		},
	},
	eventArchive: {
		title: 'Video Arşivi',
		searchText: 'Aramak için dokunun',
		noArchivedEvents: 'Şu anda arşivlenmiş etkinlik yok.',
	},
	exhibitorDashboard: {
		event: 'Etkinlik',
		title: 'Stantınızı Yönetin',
		visitorsButton: 'Stant Ziyaretçileri',
		goToBoothPreview: 'Stant önizlemesi',
		previewButton: 'Ön izleme',
		tabStyle: 'Stant Türü',
		tabLogo: 'Logo',
		tabStudyDetails: 'Poster/Çalışma',
		tabFiles: 'Dosyalar',
		tabStudyFile: 'Dosya',
		tabLinks: 'Linkler',
		tabAvatars: 'Avatarlar',
		tabStudyAuthors: 'Yazarlar',
		tabVideo: 'Video',
		shareText: "URL'Yİ KOPYALA",
		theBoothUrlCopiedText: 'URL panoya kopyalandı',
		optionDisabledText: 'Bu özellik geçici olarak devre dışı bırakıldı',
		boothStyleTab: {
			optionOne: 'Seçnek 1 - İskandinav',
			optionTwo: 'Seçnek 2 - Modern',
			optionThree: 'Seçnek 1 - Dinamik',
			optionFour: 'Seçenek 4 - Özel',
			optionScholarOne: 'Seçenek 1 - Einstein',
			optionScholarTwo: 'Seçenek 2 - Curie',
			optionScholarThree: 'Seçenek 3 - da Vinci',
		},
		logoTab: {
			fileUploadButton: 'Yüklemek için buraya tıklayın',
			noFile: 'Dosya seçilmedi',
		},
		posterStudyTab: {
			posterStudyTitle: 'Başlık',
			studyTitleInputLabel: 'Poster / Çalışma Başlığı',
			highlightAbstractTitle: 'Öne çıkanlar/Özet',
			studyHighlightsInputLabel: 'Araştırma öne çıkanları/özeti',
			highlightAbstractError:
				'İzin verilen maksimum karakter sınırına ulaştınız! (500 karakter)',
			authorNameLabel: 'Yazar adı',
			authorAffiliationLabel: 'Üyelik',
		},
		filesTab: {
			title: 'Dokümanlar:',
			fileNameInput: 'Doküman ismi (tüm katılımcılar görebilir)',
			chooseFileButton: 'Dosya seçin',
			noDocAdded: 'Hiçbir belge eklenmedi',
			fileError: 'Lütfen bir doküman ismi ekleyin',
		},
		fileStudyTab: {
			title: 'pdf dosyası yükle',
			description:
				'Poster yükleyin: Standınızda yer alacak kağıt veya sunum (20 Mb. dosya limiti)',
			uploadPdfInputLabel: 'PDF dosyası yükle',
			uploadNewPdfInputLabel: 'Yeni PDF dosyası yükle',
			uploadedFile: 'Yüklendi',
			dragContainerTitle: 'Dosyayı buraya sürüklemeniz yeterli',
			dragContainerNewTitle: 'Yeni dosyayı buraya sürüklemeniz yeterli',
		},
		linksTab: {
			social: {
				title: 'Sosyal medya linkleri',
				subtitle: '(tüm katılımcılar tarafından görülebilir)',
				facebookInput: 'Facebook linkinizi girin',
				linkedinInput: 'LinkedIn linkinizi girin',
				twitterInput: 'Twitter linkinizi girin',
				googleScholarInput: 'Google Scholar linkinizi girin',
				researchGateInput: 'Research Gate linkinizi girin',
			},
			other: {
				linkInput: 'Yeni link girin',
				linkLabel: 'Yeni Link Etiketi Ekle',
			},
		},
		linksStudyTab: {
			authorProfiles: 'Yazar profilleri',
			additionalLinks: 'Ek linkler',
		},
		avatarsTab: {
			mainRepresentatives: {
				title: 'Stand Temsilcileri',
				leftRepresentative: 'Sol temsilci',
				rightRepresentative: 'Sağ temsilci',
				addDialog: {
					title: 'Avatar Ekle',
					titleScholar: 'Yazar ekle',
					editAvatarsTitle: 'Avatarı düzenle',
					editAuthorTitle: 'Yazarı düzenle',
					contentLine1: 'Aşağıdaki galeriden bir avatar seçin',
					contentLine2: 'Kendi kişiselleştirilmiş avatarınızı yükleyin.',
					contentLine3: 'Yardıma mı ihtiyacınız var? İletişime geçin:',
					assignButtonText: 'Ata',
					assignText:
						'Lüften yukarıdaki temsilciyle ilişkilendirilebilecek bir etkinlik atayın.',
					assignErrorBooth: 'Kullanıcı zaten Stant temsilcisi olarak eklendi',
					assignErrorAdditional: 'Kullanıcı zaten Ek temsilci olarak eklendi',
					input: 'Buradan isimle arayın',
				},
			},
			additionalRepresentatives: {
				title: 'Ek temsilciler',
				subtitle: '(isteğe bağlı, profil resimleri ile görüntülenecektir)',
				addDialog: {
					title: 'Ek Temsilciler Seçin',
					titleScholar: 'Ortak Yazarı Seç',
					assignText:
						'Lütfen kendisini temsilci olarak eklemek için bir etkinlik katılımcısını aramaya başlayın.',
					assignError: 'Kullanıcı, Stand temsilcisi olarak zaten eklendi!',
				},
			},
			author: {
				title: 'Sunucu Yazar',
				subtitle:
					'Yalnızca etkinliğe kayıtlı bir yazar ekleyin. 1 yazar ekleyebilirsiniz.',
				mainRepresentative: 'Ana temsilci',
			},
			coAuthors: {
				title: 'Ortak Yazarlar',
				singularTitle: 'Ortak Yazar',
				subtitle:
					'Yalnızca etkinliğe kayıtlı ortak yazarları ekleyin. En fazla 4 ortak yazar ekleyebilirsiniz.',
			},
		},
		videoTab: {
			video: {
				title: 'Stand Videosu',
				subtitle: '(Yalnızca YouTube video linki)',
				youtubeOrVimeoOnly: '(Yalnızca YouTube veya Vimeo linki)',
				input: 'YouTube URL’sini girin',
				inputYoutubeOrVimeo: "YouTube veya Vimeo URL'si girin",
			},
			image: {
				subtitle:
					'(Yukarıdaki bölüme bir video bağlantısı eklemediyseniz, resim Standınızda görüntülenir)',
			},
		},
	},
	boothDashboard: {
		event: 'Etkinlik',
		title: 'Stantınız',
		manage: 'Yönet',
		statistics: 'İstatistik',
		uniqueVisitors: 'Eşsiz Ziyaretçiler', // new translation
		currentOnlineVisitors: 'Online Ziyaretçiler', // new translation
		totalNumberOfVisits: 'Toplam Ziyaretçi Sayısı', // new translation
		totalDocumentClicks: 'Toplam Döküman Tıklaması', // new translation
		totalLinkClicks: 'Toplam Link Tıklaması', // new translation
		totalRepresentativesClicks: 'Toplam Video Görüntüleme', // new translation
		totalVideoViews: 'Toplam Video Görüntüleme', // new translation
		totalPostsCount: 'Toplam Gönderi Sayısı', // new translation
		totalLikesCount: 'Toplam Beğeni Sayısı', // new translation
		totalCommentsCount: 'Toplam Yorum Sayısı', // new translation
		showUsers: 'Kullanıcıları Göster', // new translation
		dataType: 'Veri Tipi', // new translation
		value: 'Değer', // new translation
		posts: 'Gönderiler', // new translation
		likes: 'Beğeniler', // new translation
		comments: 'Yorumlar', // new translation
		topPostLikes: 'Popüler Beğeniler', // new translation
		topPostComments: 'Popüler Yorumlar', // new translation
		totalVotes: 'Toplam Oy', // new translation
	},
	polls: {
		polls: 'ANKETLER',
		noPolls: 'Henüz hiç bir anket eklenmedi.',
		pollProgress: 'Anket yürürlükte',
		pollClosed: 'Anket sona erdi',
		pollVoted: 'katılımcı oy kullandı',
	},
	onDemandVideos: {
		sectionTitle: 'TALEBE GÖRE',
		noOnDemandVideos: 'Henüz talebe göre video eklenmedi.',
	},
	meetings: {
		meeting: 'TOPLANTI',
		createDialog: {
			createTitle: 'İle toplantı talep et',
			inputDate: 'Tarih',
			inputStart: 'Başlangıç',
			inputEnd: 'Bitiş',
			inputTimezone: 'Zaman Dilimi',
			inputNote:
				'Dikkat: Aynı kişiyle yalnızca bir tane toplantı programlanabilir.',
			meetingError:
				'Geçersiz toplantı saati veya tarihi. Lütfen tekrar kontrol edin!',
			buttonDisabled:
				'Bir sohbet başlattıktan sonra toplantıyı programlayabilirsiniz.',
		},
		banner: {
			pending: 'Toplantı talebi',
			accepted: 'Toplantı tarihine programlandı',
			progress: 'Yürürlükteki toplantı',
			cancelTitle: 'Toplantı iptal edilsin mi?',
			cancelDescription:
				'Bu toplantıyı iptal etmek istediğinizden emin misiniz?',
			startCall: 'ARAMAYI BAŞLAT',
			joinCall: 'KATIL',
			pendingOwner: 'Şunun için bekleyen toplantı talebi',
			videoCallStarted: 'Video toplantı başlatıldı',
			videoCallStartedInfo:
				'10 kişiye kadar yapılan video toplantılar en iyi sonucu verir',
		},
		title: 'Toplantılarınız',
		meetingsInfo:
			'Toplantılat sohbetler içerisinden programlanabilir. Lütfen toplantı programlamak istediğiniz kullanıcıyla bir sohbet başlatın.',
	},
	socialMediaShare: {
		event: 'Etkinlik',
		shareLabel: 'Paylaş',
		dialogTitleGeneral: 'Ağınızla paylaşın',
		dialogTitlePost: 'Gönderinizi paylaşın',
		linkedinLabel: 'LinkedIn',
		facebookLabel: 'Facebook',
		twitterLabel: 'Twitter',
		emailLabel: 'E-posta',
		clipboardLabel: 'Pano',
		clipboardSnackbarMessage: 'link kopyalandı',
		linkedinButtonTitle: "LinkedIn'de paylaşın",
		facebookButtonTitle: "Facebook'ta Paylaş",
		twitterButtonTitle: "Twitter'da paylaş",
		emailButtonTitle: 'E-posta ile paylaş',
		clipboardButtonTitle: 'Panoya kopyala',
		imAttending: 'Şu etkinliğe katılıyorum:',
		interestedClick: 'Katılmakla ilgileniyor musunuz? Buraya Tıkla:',
	},
	videoConferences: {
		fullScreen: 'TAM EKRANDA AÇ',
		minimizeScreen: 'EKRANI KÜÇÜLT',
		startedVideoChat: 'video görüşme başlattı',
		timeAgo: 'önce',
		videoCallStarted: 'Görüntülü Görüşme başladı...',
	},
	sessions: {
		edit: 'DÜZENLE',
		speakers: 'KONUŞMACILAR',
		speakerSingular: 'konuşmacı',
		moderators: 'MODERATÖRLER',
		moderatorSingular: 'moderatör',
		video: 'VİDEO',
		startStream: 'YAYINI BAŞLAT',
		stopStream: 'YAYINI DURDUR',
		sessionChat: 'OTURUM SOHBETİ',
		speakerUrl: 'Konuşmacı URL',
		moderatorUrl: 'Moderatör URL',
		copyUrl: 'URL kopyala',
		noSpeakersJoined: 'Henüz hiçbir Konuşmacı katılmadı',
		allFilter: 'Tümü',
		waitingRoom: 'Bekleme odası',
		isReady: 'Hazır',
		invitationAccepted: 'Daveti kabul etti',
		requestPending: 'İstek beklemede',
		gettingReady: 'Hazırlanıyor',
		inviteToSpeak: 'Konuşmaya davet et',
		fromAuditorium: 'Oditoryumdan',
		mic: 'Mikrofon',
		screen: 'Ekran',
		rec: 'kayıt',
		sessionHasEnded: 'Oturum sona erdi',
		sessionLinks: 'OTURUM LİNKLERİ',
		sessionSettings: 'Ayarlar',
		noAvailableChat:
			'Sohbet yok. Sohbeti görmek için lütfen bu oturumu bir zaman aralığına ayırın.',
		startLiveSessionStreamTitle: 'Canlı Oturum Yayınını Başlatın',
		startLiveSessionDescription:
			'Yayını başlattığınızda oturumun kaydı otomatik olarak başlayacaktır.',
		stopLiveSessionStreamTitle: 'Canlı Oturum Yayınını Durdur',
		stopLiveSessionStreamDescription:
			'Canlı Oturum Yayını durdurduğunuzda kayıt da otomatik olarak duracaktır. Bu Canlı Oturum Yayını yeniden başlatılamaz.',
		thumbnailImage: 'Küçük Resim',
		uploadThumbnail: 'Küçük Resim Yükle',
		clickToUpload: 'Yüklemek için buraya tıklayın',
		requestMic: 'MİKROFON İSTE',
		acceptInvitationTitle: 'Davetiye aldın',
		acceptInvitationSubTitle:
			'Moderatör sizi konuşmacı olmaya davet etti. Ne diyorsunuz?',
		acceptInvitationButton: 'Daveti kabul et',
		requestPendingTitle: 'İstek beklemede...',
		requestPendingSubTitle:
			'Lütfen moderatör yayına girmenize izin verene kadar bekleyin',
		cancelRequest: 'İsteği iptal et',
		approvedTitle:
			"Lütfen mikrofonunuzu ve videonuzu kontrol edin ve Hazırım'a tıklayın.",
		approvedSubTitle:
			'Bu oturum kaydediliyor. Bu oturuma girerek kişisel verilerinizin işlenmesini kabul etmiş olursunuz.',
		connectedToTheBackstage:
			'Artık canlı yayın yapıyorsunuz ve sahne arkasına bağlısınız.',
		removedFromTheSession:
			'Oturumdan atıldınız, lütfen bir moderatörle iletişime geçin.',
		imReady: 'Ben hazırım',
		isTryingToRegister: '.. olarak kayıt olmaya çalışıyor',
		hasLeftTheSession: 'oturumdan ayrıldı',
		stopVideo: 'Videoyu durdur',
		startVideo: 'Videoyu başlat',
		disabledScreenSharing:
			'Videonuzu başlatmadan önce ekran paylaşımını kapatın',
		disabledByModerator: 'Moderatör tarafından devre dışı bırakıldı',
		disabledUntilReady: 'Hazır olana Kadar Devre Dışı',
		stopShareScreen: 'Paylaşım ekranını durdur',
		shareScreen: 'Ekranı paylaş',
		disabledVideoSharing: 'Ekranınızı paylaşmadan önce videonuzu kapatın',
		anotherUserIsScreenSharing: 'Başka biri zaten ekranı paylaşıyor',
		removeRoleDescription: 'Kaldırmak istediğinize emin misiniz?',
		declinedInvitation: 'konuşmacı davetini reddetti',
	},
	gdpr: {
		disclaimerTextFirst:
			'Bu belge yapay zeka tarafından çevrilmiştir. Tutarsızlık olması durumunda, lütfen belgenin İngilizce versiyonuna bakın.',
		disclaimerTextSecond: 'BURADA',
		metaTitlePrivacyPolicy: 'Gizlilik Politikası',
		metaTitleCookiesPolicy: 'Çerez politikası',
		metaTitleTerms: 'Şartlar ve koşullar',
		updateCookieConsent: 'Çerez onayını güncelle',
		changeYourConsent: 'Onayınızı değiştirin',
		withdrawYourConsent: 'Onayınızı geri çekin',
		withdrawSnackbarText: 'Çerez izni başarıyla geri çekildi',
	},
	infoMessages: {
		imageExplain420x200:
			'Önerilen görüntü oranı 1.8:1 (manzara odaklı, ör. 420 x 200 piksel)',
		imageExplainTB230x320:
			'Önerilen görüntü oranı 1:1.4 (portre odaklı, ör. 230 x 320 piksel) arka plan şeffaf',
		imageExplainTB230x875:
			'Önerilen görüntü oranı 1:3.8 (portre odaklı, ör. 230 x 875 piksel) arka plan şeffaf',
	},
	matching: {
		matchingQuestionnaireTitle: 'Eşleşen anket',
		matchingQuestionnaireIntro:
			'Size en uygun kişilerle bağlantı kurmanıza yardımcı olalım! Doğru beyinler buluştuğunda harika şeyler olur, başlamak için anketi doldurun',
		matchingQuestionnaireIntroSkip:
			"Anketi atlarsanız, bugünkü etkinlikte sizi diğer katılımcılarla eşleştiremeyeceğiz. Anketi daha sonra istediğiniz zaman Networking bölümünde tamamlayabilirsiniz. Tüm cevaplar My Account'tan düzenlenebilir.",
		doItLaterBtn: 'DAHA SONRA YAP',
		nextBtn: 'Sonraki',
		backBtn: 'Geri',
		finishBtn: 'Bitir',
		seeMatchesBtn: 'TÜM EŞLEŞMELERİ GÖR',
		tryMatchingBtn: 'AKILLI EŞLEŞTİRMEYİ DENEYİN',
		optionalQuestion: 'İsteğe bağlı',
		requiredQuestion: 'Gerekli',
		singleSelect: 'Tek seçim',
		multiSelect: 'Çoklu seçim',
		mediumMatch: 'Orta Eşleşme',
		highMatch: 'Yüksek Eşleşme',
		matchLabel: 'Eşleşme',
		matchesLabel: 'Eşleşmeler',
		infoTooltipJump: 'Eşleşmelerinizi görmek için buraya atlayın',
		infoTooltipClickToSee1: 'Şuraya tıklayın:',
		infoTooltipClickToSee2: 'kiminle bağlantı kurman gerektiğini görmek için!',
		loadingAlgoText:
			'Eşleştirme algoritması iş başında. Bize bir dakika izin verin, En iyi eşleşmelerinizi seçiyoruz!',
		matchingDetailsFormTitle: 'Akıllı eşleştirme ayrıntıları',
		tryMatchingBanner:
			'Anlamlı bir bağlantı kurun, Akıllı Eşleştirme özelliğimizi deneyin!',
		matchingBannerClosed:
			'Akıllı Eşleştirme formunu her zaman Networking bölümünden Matches altında doldurabilirsiniz.',
		resultsTitle: 'İşte en iyi eşleşmeleriniz',
		resultsNoteLabel: 'NOT:',
		resultsNoteText: 'Tüm eşleşmelerinizi Networking bölümünde bulabilirsiniz.',
		resultsNoteMyAccount: 'Tüm cevaplar My Account menüsünde düzenlenebilir.',
		btwResultsResponsive:
			'Bu arada, tüm eşleşmelerinizi NETWORKING bölümünde bulabilirsiniz.',
		noMoreMatches:
			'Hay aksi! Şu anda sizin için daha fazla eşleşme bulamıyoruz. Daha fazla katılımcı katıldığında lütfen tekrar kontrol edin',
		noResultsTitle: 'Tebrikler, başardınız! En iyi eşleşmelerinizi arıyoruz!',
		noResults:
			'`Akıllı Eşleştirme işlevini denediğiniz için teşekkürler! En iyi eşleşmelerinizi arıyoruz ve etkinliğe daha fazla katılımcı katılır katılmaz sizi eşleştireceğiz. Lütfen "Networking" bölümündeki "Matches" sekmesini kontrol edin`',
		clickStartChat: 'Sohbet başlatmak için sohbete tıklayın',
		closeMatchingTitle: 'Eşleştirme formu kapatılsın mı?',
		closeMatchingTextA:
			'Formu tamamlamadan önce kapatırsanız, hiçbir ayrıntıyı saklamayacağız ve Akıllı Eşleştirme sonuçlarını göremeyeceksiniz.',
		closeMatchingTextB:
			'Profiliniz Akıllı Eşleştirme sürecinin bir parçası olmayacak ve kimse sizinle eşleştirilmeyecek.',
		matchingTooltip:
			"Kiminle bağlantı kurmanız gerektiğini görmek için Matches'a tıklayın!",
		myAccountMatchingSectionTitle: 'Akıllı eşleştirme ayrıntıları',
		matchingRequiredError: 'Tüm Gerekli soruları tamamlamanız gerekiyor',
		rejectMatchingTitle: 'Eşleştirme katılımı reddedilsin mi?',
		rejectMatchingText:
			'Cevaplarınız silinecek ve diğer kullanıcılarla eşleştirilemeyeceksiniz. Eşleştirme anketini istediğiniz zaman tekrar doldurabilirsiniz.',
		rejectMatchingBtn: 'KATILIMI RED',
	},
	welcomeScreen: {
		goToEvent: 'Etkinliğe git',
	},
	emailCodeVerification: {
		codeSent: 'E-postanıza bir doğrulama kodu gönderdik:',
		continueRegistration: 'Kayıt işlemine devam etmek için,',
		enterCodeBelow: 'doğrulama kodunu aşağıya girin:',
		codeWillExpire: 'Bu kodun süresi şu tarihte dolacak:',
		codeExpired: 'Kodun süresi doldu',
		codeNotReceived: 'Kodu almadınız mı? Spam klasörünü kontrol ettiniz mi?',
		resendTimer: 'Tekrar gönder:',
		resendLabel: 'Yeniden gönder',
		resendCodeLabel: 'Kodu tekrar gönder',
		codeError: 'Girilen kod yanlış.',
		codeErrorFix:
			'E-postanızdaki doğru kodu tekrar girin veya yeni bir kod göndertin',
		congrats: 'Tebrikler!',
		emailConfirmed: 'E-postanız onaylandı:',
	},
	privacyPolicyUpdates: {
		learnMore: 'Learn more', // new translation
		lastUpdated: 'Last updated', // new translation
		publishedBy: 'Published by', // new translation
		ppuLinkLabel: 'Updated privacy policy', // new translation
		ppuEventLinkLabel: 'Updated event privacy policy', // new translation
		acceptAllButtonLabel: 'Accept all updates', // new translation
		agreeCheckboxLabel: 'I agree with this update', // new translation
	},
	agendaTranslations: {
		myAgenda: 'AJANDAM',
		all: 'Tümü',
		sessions: 'Oturumlar',
		meetings: 'Toplantılar',
		pastSlots: 'Geçmiş',
		meetingWith: 'Toplantı',
		pending: 'Beklemede',
		accepted: 'Kabul edildi',
		requested: 'Toplantı isteği',
		canceled: 'İptal edildi',
		now: 'Şimdi',
		live: 'Yayında',
		noMeetings: 'Herhangi bir toplantı yok.',
		noSessions: 'Herhangi bir oturum yok.',
		noUpcomingSessions: 'Ayarlanmış yeni bir oturum yok.',
		noUpcomingMeetings: 'Ayarlanmış yeni bir toplantı yok.',
		noUpcomingAgenda: 'Ajanda da ayarlanmış yeni bir aktivite yok.',
		noAgendaItem: 'Ajanda da ayarlanmış herhangi bir aktivite yok.',
		meetingRequests: 'TOPLANTI İSTEKLERİ',
		finished: 'Bitti',
		past: 'Geçti',
		goToMeeting: 'TOPLANTIYA KATIL',
		addToMyAgenda: 'Ajandama ekle',
		addedToYourAgenda: 'Ajandana eklendi',
		acceptedAllMeetings: 'Tüm Toplantı İsteklerini Kabul Et',
	},
	voting: {
		votingText: 'OY VERME',
		enableVoting: 'OYLAMAYI ETKİNLEŞTİR',
		enableVotingDescription:
			'En doğru sonuçları almak için Yenile simgesine tıklayın',
		displayVotingNumbersForExhibitors:
			'KATILIMCILAR VE AFİŞ/ ÇALIŞMA İÇİN OY SAYISINI GÖSTERİN',
		displayVotingNumbersForExhibitorsDescription:
			'Katılımcı panosunda görüntülenecektir',
		displayVotingNumbersForParticipants: 'KATILIMCILAR İÇİN OY SAYISINI GÖSTER',
		displayVotingNumbersForParticipantsDescription:
			'Tüm Katılımcılar için görüntülenecektir',
		characterError80:
			'İzin verilen maksimum karakter sınırına (80 karakter) ulaştınız',
		characterDescription:
			'Metin 80 karakter ile sınırlıdır. Metin, Oylama şeridinde ana başlık olarak görünecektir. Katılımcılar için anlamlı kılın',
		deleteAllVotes: 'TÜM OYLARI SİL',
		downloadVotingResults: 'OYLAMA SONUÇLARINI İNDİR',
		voteText: 'Oy',
		votedText: 'Oy verildi',
		votesText: 'Oy',
		participant: 'katılımcı',
		participants: 'katılımcılar',
		deleteVotesTextFirst: 'UYARI:',
		deleteVotesTextSecond: 'bu geri alınamaz bir işlemdir.',
		deleteVotesTextThird:
			'Oylama sonuçlarını silmeden önce indirmeyi unutmayın.',
		checkTheBoxToCastYourVote: 'Oyunuzu kullanmak için kutucuğu işaretleyin',
		toVotePleaseEnableParticipantProfile:
			'Oy vermek için lütfen Katılımcı profilinizi etkinleştirin',
	},
};

export default tr;
