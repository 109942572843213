const en = {
	generalText: {
		upload: 'Upload',
		save: 'Save',
		add: 'Add',
		remove: 'Remove',
		close: 'Close',
		update: 'Update',
		cancel: 'Cancel',
		confirm: 'Confirm',
		download: 'Download',
		refresh: 'Refresh',
		search: 'Search',
		back: 'Back',
		submit: 'Submit',
		send: 'Send',
		leave: 'Leave',
		step1: 'Step 1',
		step2: 'Step 2',
		or: 'or',
		delete: 'Delete',
		please: 'Please',
		before: 'before',
		approve: 'Approve',
		decline: 'Decline',
		start: 'Start',
		stop: 'Stop',
		resetFilter: 'Reset Filter',
		apply: 'Apply',
		filters: 'Filters',
		unsavedChangesTitle: 'Unsaved changes',
		unsavedChangesText: 'You have unsaved changes. Do you want to save them?',
		selectAll: 'Select All',
		unselectAll: 'Unselect All',
		here: 'here', // new translation
		ok: 'ok', // new translation
		undo: 'undo', // new translation
		accept: 'accept', // new translation
	},
	time: {
		justNow: 'just now',
		minute: 'minute',
		minutes: 'minutes',
		hour: 'hour',
		sec: 'sec', // new translation
		hours: 'hours',
		day: 'day',
		days: 'days',
		month: 'month',
		months: 'months',
		yesterday: 'yesterday',
		today: 'today',
		tomorrow: 'tomorrow',
		monday: 'monday',
		tuesday: 'tuesday',
		wednesday: 'wednesday',
		thursday: 'thursday',
		friday: 'friday',
		saturday: 'saturday',
		sunday: 'sunday',
		january: 'january',
		february: 'february',
		march: 'march',
		april: 'april',
		may: 'may',
		june: 'june',
		july: 'july',
		august: 'august',
		september: 'september',
		october: 'october',
		november: 'november',
		december: 'december',
		januaryShort: 'jan',
		februaryShort: 'feb',
		marchShort: 'mar',
		aprilShort: 'apr',
		mayShort: 'may',
		juneShort: 'jun',
		julyShort: 'jul',
		augustShort: 'aug',
		septemberShort: 'sep',
		octoberShort: 'oct',
		novemberShort: 'nov',
		decemberShort: 'dec',
		years: 'years', // new translation
		year: 'year', // new translation
		secondsShort: 'sec', // new translation
	},
	errors: {
		noFile: 'No file chosen',
		required: 'Field is required',
		unique: 'Field must be unique',
		emailNotValid: 'Email not valid',
		passwordMinLength: 'Password must have min 8 characters',
		passwordMismatch: 'Password mismatch',
		passwordIsSame: 'Your new password must be different',
		noInvitationCode: 'Please enter your invitation code',
		invitationCodeInvalid: `Invitation code is invalid.`, // new translation
		invitationCodeLimitReached: 'Invitation code usage limit has been reached.', // new translation
		imageFileNotSupported:
			'File type not supported. Please use one of the following:',
		phoneInvalid: 'Please enter a valid phone number. E.g.: +447517663928',
		linkInvalid: 'Please enter a valid link (https://site.com)',
		facebookLinkInvalid: 'Please enter a valid link (https://facebook.com/)',
		linkedinLinkInvalid: 'Please enter a valid link (https://linkedin.com/)',
		twitterLinkInvalid: 'Please enter a valid link (https://twitter.com/)',
		youtubeLinkInvalid: 'Please enter a valid link (https://www.youtube.com/)',
		youtubeOrVimeoLinkInvalid:
			'Please enter a valid YouTube (https://www.youtube.com) or Vimeo (https://vimeo.com/) link',
		googleScholarLinkInvalid:
			'Please enter a valid link (https://scholar.google.com/)',
		researchGateLinkInvalid:
			'Please enter a valid link (https://www.researchgate.net/)',
		connectionProblemTextFirst:
			'There seems to be a connection problem. Please',
		connectionProblemButton: 'CLICK',
		connectionProblemTextSecond: 'or refresh the page.',
		fileTooLarge: 'File too large. 2Mb max file size.',
		fileTooLargeTextFirst: 'File too large.',
		fileTooLargeTextSecond: 'max file size.',
		fileNameTooLongTextFirst: 'File name too long. The limit is ',
		fileNameTooLongTextSecond: ' characters.',
		genericTooLongErrorFirst:
			'You have reached the maximum limit of characters allowed (',
		genericTooLongErrorSecond: ' characters)',
		maxWallInputLength: 'You reached the maximum number of 1200 characters.',
		genericTooShort: 'Field value is too short. Minimum length: ',
	},
	footer: {
		cookiesText:
			'This website is using essential cookies, which are necessary for its functioning. It also uses third-party cookies, which are necessary for the functioning of some features of our website. If you want to learn more or withdraw your consent to some cookies, please refer to our',
		cookiesLink: 'cookies policy',
		cookiesButton: 'ACCEPT',
		privacyPolicy: 'Privacy policy',
		cookies: 'Cookies',
		termsAndConditions: 'Terms and conditions',
		socialLinksTitle: 'Follow us:',
	},
	menu: {
		logIn: 'Log in',
		logOut: 'Log out',
		scholarRegistration: 'Join as poster/study',
		exhibitorRegistration: 'Join as exhibitor',
		participantRegistration: 'Join as participant',
		createEvent: 'Organize event',
		loginDisabledInfoText:
			'The organizer has closed the login. Check back later',
	},
	homepage: {
		organizedBy: 'ORGANIZED BY',
		date: 'DATE',
		location: 'DOMAIN & LOCATION',
		price: 'PRICE',
		tabIntro: 'INTRO',
		tabProgram: 'PROGRAM',
		tabSponsors: 'SPONSORS',
		tabPartners: 'PARTNERS',
		tabExhibitors: 'EXHIBITORS',
		previousEventsSectionTitle: 'PREVIOUS EVENTS',
	},
	socialButtons: {
		linkedinButton: 'Log in with Linkedin',
		loginTabLinkedinButton: 'with Linkedin',
		facebookButton: 'Log in with Facebook',
		loginTabFacebookButton: 'with Facebook',
		googleButton: 'Log in with Google',
		loginTabGoogleButton: 'with Google',
		twitterButton: 'Log in with Twitter',
		loginTabTwitterButton: 'with Twitter',
		socialButtonsLogInTitle: 'Join with your social media profile',
		socialButtonsSignUpTitle: 'Sign up with your social media profile',
	},
	login: {
		forgotPasswordText: 'No worries, we’ll send you further instructions',
		inputEmail: 'Email',
		inputPassword: 'Password',
		rememberMeButton: 'Remember\n me',
		forgotPasswordButton: 'Forgot\n password',
		loginButton: 'Log in',
		headingNoUser: 'Login',
		subHeadingNoUser:
			'Welcome to Unstoppabl.ai! In order to proceed, select a login method',
		headingUser: 'Welcome back',
		joinTheEvent: 'Join the event',
		forgotPasswordHeadline: 'Forgot password',
		checkYourEmail: 'Check your email',
		checkYourEmailText: 'We sent a password reset link to',
		createNewPassword: 'Create new password',
		createNewPasswordText:
			'Your new password must be different from previously used passwords',
		passwordReset: 'Password reset',
		passwordResetText:
			'Your password was successfully reset. Click below to log in again.',
		joinTheEventSub: 'To start your virtual online event experience',
		subHeadingUser: 'It seems that you already have a platform account',
		loginClosedButton: 'Login closed',
		loginTitleLowerCase: 'log in',
		recoverPasswordDialogTitle: 'RECOVER PASSWORD',
		recoverPasswordDialogRecoverButton: 'RECOVER',
		errorIncorrectDates: 'Incorrect email or password',
		incorrectPassword: 'Incorrect password',
		orLogInWithEmail: 'or login with email',
		logInAsParticipantSecondText: 'as participant',
		logInAsExhibitorSecondText: 'as exhibitor',
		logInAsPosterSecondText: 'as poster/study',
		errorAccountNotFound: 'We couldn’t find your Unstoppabl.ai account',
		registrationIsClosed: 'Registration for all roles is closed.',
	},
	register: {
		inputFirstName: 'First Name',
		inputLastName: 'Last Name',
		inputEmail: 'Email',
		inputPassword: 'Password',
		inputConfirmPassword: 'Confirm Password',
		gdprTextFirst: 'By proceeding, you agree to our',
		gdprTextTerms: 'Terms of Use',
		gdprTextSecond: 'and confirm you have read our',
		gdprTextCookies: 'Cookie',
		gdprTextPrivacy: 'Privacy Policy',
		gdprTextAgree: 'Please agree to privacy policy',
		registerButton: 'Sign up',
		signUpTitleLowerCase: 'sign up',
		invitationCode: 'Invitation code',
		registerNotAllowed: 'Registration closed',
		logInTitleFirst: 'Join',
		logInTitleSecond: 'event',
		signUpTitleTextFirst: 'First time at',
		signUpTitleTextSecond: 'Unstoppabl.ai',
		orFillInTheForm: 'or fill in the form below',
		uploadProfilePicture: 'Upload profile picture',
		editProfilePicture: 'Edit profile picture',
		errorAlreadyHaveMOAccount: 'You already have an account on Unstoppabl.ai',
		step1From2Text: '1/2 steps',
		step2From2Text: '2/2 steps',
		metaTitleScholarRegistration: 'Poster/Study registration',
		unregisterInProgressError:
			'Cannot register while account is still being deleted.',
		emailRestrictionTitle: "Why can't I join the event?", // new translation
		emailRestrictionInfoText:
			'Registration to this event is possible only from certain email domains confirmed by the Event Organizer.', // new translation
		tip: 'Tip', // new translation
		TipInfoText:
			'you can try using your company email or contacting the Event Organizer.', // new translation
		emailNotApprovedError: 'This domain is not approved by the Organizer', // new translation
	},
	newLoginRegistrations: {
		joinEvent: 'JOIN EVENT',
		loginWelcome:
			'Welcome to Unstoppabl.ai! In order to proceed, select a login method',
		continueButton: 'Continue',
		seemsLikeYouDontHaveMyOnventAccount:
			"Seems like you don't have a Unstoppabl.ai account",
		createPlatformAccount: 'Create Platform Account',
		uploadProfilePictureOptional: 'Upload profile picture (optional)',
		platformAccount: 'Platform account',
		selectRole: 'Select role',
		eventAccount: 'Event account',
		password: 'Password',
		confirmPassword: 'Confirm password',
		email: 'Email',
		passwordMustContain: 'Password must contain:',
		minimum8Characters: 'Minimum 8 characters',
		aLowerCaseLetter: 'A lowercase letter',
		anUppercaseLetter: 'An uppercase letter',
		aNumber: 'A number',
		aSpecialCharacter: 'A special character',
		firstName: 'First name',
		lastName: 'Last name',
		weHaveSentAVerificationCodeToYourEmail:
			"We've sent a verification code to your email",
		continueRegistration: 'To continue the registration,',
		enterCodeBelow: 'enter the verification code below:',
		codeWillExpire: 'This code will expire in',
		codeExpired: 'The code expired',
		codeNotReceived:
			'Didn’t receive the code? Have you checked the Spam folder?',
		resendTimer: 'Resend in:',
		resendLabel: 'Resend',
		resendCodeLabel: 'Resend the code',
		codeError: 'Entered code is incorrect.',
		codeErrorFix:
			'Re-enter the correct code from your email or resend a new code',
		congrats: 'Congrats!',
		emailConfirmed: 'Your email confirmed:',
		imHereAs: "I'm here as:",
		participant: 'Participant',
		participantText:
			'To watch streaming, communicate, visit exhibitors, and poster/study.',
		exhibitor: 'Exhibitor',
		exhibitorText:
			'To present your company and communicate with event attendees',
		poster: 'Poster/Study',
		posterText: 'To present research for academic or professional conference',
		emailIsNotAllowed: 'This email is not allowed to register to this event',
		didntReceiveEmail: 'Didn’t receive the email?',
		sendAgain: 'Send again',
		incorrectPassword: 'Incorrect password',
		fileTypeError:
			'File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.',
		fileSizeError: 'File too large. 2Mb max file size.',
		pleaseCompleteAllRequiredFields: 'Please complete all required fields',
		joinTheEvent: 'Join the event',
		joinTheEventSub: 'To start your virtual online event experience',
		changeEmail: 'CHANGE EMAIL',
		changeRole: 'CHANGE ROLE',
		logout: 'LOG OUT',
		headingUser: 'Welcome back',
		subHeadingUser: 'It seems that you already have a platform account',
		forgotPasswordHeadline: 'Forgot password',
		forgotPasswordText: 'No worries, we’ll send you further instructions',
		checkYourEmail: 'Check your email',
		checkYourEmailText: 'We sent a password reset link to',
		headingNoUser: 'Login',
		subHeadingNoUser:
			'Welcome to Unstoppabl.ai! In order to proceed, select a login method',
		resetPasswordButton: 'reset password',
		createNewPassword: 'Create new password',
		createNewPasswordText:
			'Your new password must be different from previously used passwords',
		passwordReset: 'Password reset',
		passwordResetText:
			'Your password was successfully reset. Click below to log in again.',
		chooseAnOption: 'Choose an option',
		yourAnswer: 'Your answer',
		firstNameError: 'First name is too short. Minimum length:  2',
		lastNameError: 'Last name is too short. Minimum length:  2',
		recoveryPassword: 'Recover password',
		incorrectPasswordError:
			'Incorrect password. Try again or click “Forgot password” to change it.',
		optional: 'Optional',
		spamEmailError:
			'There’s been an issue while creating your account. Please check back later.',
		invalidInput:
			"Invalid input. Use only letters (A-Z, a-z), numbers (0-9), period (.), apostrophe ('), hyphen (-), and spaces. First character must be a letter.",
	},
	emailUpdate: {
		changeEmail: 'Change email',
		changeEmailDescription:
			'Before we assign the new email to your account we want to be sure it’s a new email and belongs to no one. ',
		emailTakenError: 'That email is taken. Try another.',
		enterTheConfirmationCode: 'Enter the confirmation code',
		verificationCodeSent: 'Verification Code Sent:',
		checkYourSpamFolder: 'Can’t find the code? Check your spam folder',
		emailSuccess: 'Success! You changed the email assigned to your account',
		emailError: 'Error. Try again later',
		confirmPassword: 'Confirm password',
		confirmPasswordDescription:
			'In order to change the email confirm your password:',
		newEmail: 'New email',
		password: 'Password',
		confirm: 'Confirm',
		cancel: 'Cancel',
		change: 'Change',
	},
	participantRegistration: {
		metaTitle: 'Participant registration',
		choosePackageTitle: 'CHOOSE THE ACCESS PACKAGE',
		noPackageText: 'Please select a package',
		gdprTextFirst: 'By proceeding, you agree to our',
		gdprTextPrivacy: 'Event Privacy Policy',
		gdprTextSecond: 'and',
		gdprTextTerms: 'Event Terms and Conditions',
		gdprTextAgree: 'Please agree to privacy policy',
		registerButtonFree: 'Complete Registration',
		registerButtonPay: 'Proceed to payment',
	},
	exhibitorRegistration: {
		metaTitle: 'Exhibitor registration',
		choosePackageTitle: 'CHOOSE THE ACCESS PACKAGE',
		gdprTextFirst: 'By proceeding, you agree to our',
		gdprTextPrivacy: 'Event Privacy Policy',
		gdprTextSecond: 'and',
		gdprTextTerms: 'Event Terms and Conditions',
		gdprTextAgree: 'Please agree to privacy policy',
		registerButtonFree: 'Complete Registration',
		registerButtonPay: 'Proceed to payment',
	},
	stripePayment: {
		cardNumber: 'Card Number',
		expirationDate: 'MM/YY',
		cvc: 'CVC',
		disclaimerText:
			'This payment operation is encrypted end-to-end. Security & privacy are guaranteed by Stripe Services.',
		disclaimerTextLink: 'Click here to learn more.',
		cancelButton: 'Cancel',
		payButton: 'Pay',
	},
	recoverPassword: {
		recoverTextSend: 'Password recovery email sent successfully.',
	},
	unsubscribe: {
		unsubscribeTitle: 'Unsubscribe?',
		unsubscribeHomepage: 'Go to homepage',
	},
	notAvailable: {
		title: 'The event is unavailable',
		description:
			'The event you are looking for is unavailable. It has either been closed by the organizer or disabled by an admin',
		fullOverlayEvents: 'Check out your other events',
		fullOverlayNoEvents: 'No other events found',
	},
	notFound: {
		notFoundDescription: 'Oops... No event to be found here!',
		notFoundHomepage: 'Go to homepage',
	},
	eventMenu: {
		organizerButton: 'ORGANIZER',
		coOrganizerButton: 'MANAGE',
		exhibitorButton: 'EXHIBITOR',
		posterStudyButton: 'POSTER / STUDY',
		boothButton: 'BOOTH',
		boothsButton: 'BOOTHS',
		homeButton: 'HOME',
		contactsButton: 'CONTACTS',
		meetingsButton: 'MEETINGS',
		chatsButton: 'CHATS',
		programButton: 'PROGRAM',
		archiveButton: 'ARCHIVE',
		myAccountButton: 'MY ACCOUNT',
		lobbyButton: 'LOBBY',
		roomsButton: 'ROOMS',
		connectionProblemTextFirst:
			'There seems to be a connection problem. Please click',
		connectionProblemButton: 'HERE',
		connectionProblemTextSecond: 'or refresh the page.',
		sessionProblemTextFirst: 'Your session has expired. Please click',
		sessionProblemButton: 'HERE',
		sessionProblemTextSecond: 'to login again.',
		downloadProgram: 'DOWNLOAD PROGRAM', // new translation
	},
	myEventsDropdown: {
		title: 'MY EVENTS',
		createButtonMobile: 'Create',
	},
	myBoothsDropdown: {
		title: 'MY BOOTHS',
		emptyStudyTitle: 'Poster/Study booth',
	},
	contactsDropdown: {
		title: 'YOUR CONTACTS',
		requestsTitle: 'Contact Requests',
		rejectButton: 'Reject',
		acceptButton: 'Accept',
		pendingButton: 'Pending',
		addButton: 'Add',
		noContacts:
			"You don't have any contacts yet. Please visit the networking section to start building your list of contacts.",
	},
	meetingsDropdown: {
		meetingWith: 'Meeting with',
		pending: 'Pending',
		accepted: 'Accepted',
		canceled: 'Canceled',
		live: 'Live',
		noMeetings: 'No meetings scheduled yet',
		finished: 'Finished',
	},
	programDropdown: {
		stages: 'Stages',
		with: 'with',
		noProgram: 'No program entries available for the time being.',
		fullProgramButton: 'Full program', // new translation
		addToCalendarButton: 'Add to calendar', // new translation
		addedToYourAgenda: 'ADDED TO YOUR AGENDA',
		addEventButton: 'Add event', // new translation
		addToMyAgenda: 'ADD TO MY AGENDA', // new translation
		addEventToYourCalendarDialogTitle: 'Add event to your calendar', // new translation
		addTimeslotToYourCalendar: 'Add timeslot to your calendar', // new translation
		tip: 'TIP: ', // new translation
		tipDescription:
			'You can also add separate time slots to your calendar by clicking the calendar icon next to each time slot.', // new translation
		tipTimeslotDescription:
			'You can also add the whole event by clicking on the button "Add to calendar".', // new translation
		durationEventTitle: 'Event duration: ', // new translation
		timeslotTitle: 'Title: ', // new translation
		slotScheduled: 'Slot scheduled: ', // new translation
		addSlotToCalendar: 'Add slot to calendar ', // new translation
		speakers: 'Speakers', // new translation
		auditoriumName: 'Auditorium name', // new translation
		timezoneTooltipText:
			"The program is displayed in the organizer's time zone. You can change the display time zone to view the sessions in your preferred time zone", // new translation
		liveLabel: 'Live', // new translation
		liveLabelTooltipText: 'This timeslot is now live in Auditorium', // new translation
		openAuditorium: 'OPEN AUDITORIUM', // new translation
	},
	notificationsDropdown: {
		title: 'NOTIFICATIONS',
		contactRequestReceived: 'sent you a contact request. Check it out!',
		contactRequestAccepted: 'accepted your contact request. Start to chat!',
		meetingRequestReceived: 'sent you a meeting request. Check it out!',
		meetingRequestAccepted: 'accepted your meeting request. Check it out!',
		meetingRequestCanceled: 'canceled a meeting request. Check it out!',
		meetingStarting: 'A meeting will start soon. Check it out!',
		newBoothPost: 'has posted in your booth wall. Check it out!',
		noNotifications: "You don't have any notifications yet",
		seeAll: 'SEE ALL',
		hideAll: 'HIDE ALL',
		meetingWillStartTextFirst: 'A meeting with',
		meetingWillStartTextSecond: 'will start soon. Check it out!',
	},
	chatsDropdown: {
		title: 'CHATS',
		newChatProvideText:
			'Please provide a name for your chat. You will be able to edit the name afterwards:',
		noChats: 'Create at least one chat to talk to event participants',
		members: 'members',
		noOpenedChatText: 'Click on a chat to continue the conversation.',
		noMessagesInChatTextFirst: 'Break the ice.',
		noMessagesInChatTextSecond: 'Be the one to start this conversation.',
		newChatNameInput: 'Chat name (optional)',
		newChatAddMembers: 'Add contacts and other participants to this chat:',
		editChatAddMembers:
			'Please search users from this event you want to add to group chat.',
		newChatSearchInput: 'Search users',
		newChatNoUsersMessage: 'No users found',
		newChatRemoveUserButton: 'REMOVE',
		newChatCancelButton: 'CLOSE',
		newChatCreateButton: 'CREATE',
		membersLeaveButton: 'LEAVE',
		membersUpdateButton: 'UPDATE',
		membersChatButton: 'CHAT',
		chatSettingsButton: 'SETTINGS',
		newMessageInput: 'Type message here',
		newVideoCallTooltipText: 'Click here to start a video call',
		leaveVideoMeetingTitle: 'Leave video meeting',
		leaveVideoMeetingDescription:
			'You are about to leave the video meeting. Are you sure you want to proceed?',
		leaveChatConfirmationTitle: 'Leave video call',
		leaveChatConfirmationText:
			'Are you sure you want to leave the current video call?',
		deletedUserLabel: 'Deleted User', // new translation
	},
	myAccountDropdown: {
		title: 'MY ACCOUNT',
		viewProfileButton: 'View Profile',
		logoutButton: 'Log out',
	},
	myAccountPage: {
		changePasswordButton: 'CHANGE PASSWORD',
		deleteAccountButton: 'DELETE MY ACCOUNT',
		platformLanguage: 'Platform language:',
		privacyPolicyLink: 'Privacy notice / policy',
		cookiesLink: 'Cookies',
		termsAndConditionsLink: 'Terms and Conditions',
		eventProfileSectionTitle: 'Event profile',
		eventPackage: 'Event Package',
		unregisterFromEventButton: 'UNREGISTER FROM EVENT',
		unregisterFromEventDialogTitle: 'Unregister from event',
		unregisterCurrentEventButton: 'Unregister from current event',
		unregisterCurrentEventButton2: 'Unregister', // new translation
		unregisterCurrentEventConfirmation:
			'Please confirm that you want to unregister from event',
		unregisterCurrentEventConfirmation2:
			'If you unregister from the event, you will no longer be able to access the event’s content. Please note that you will lose access even in the case of paid events.', // new translation
		enableParticipantProfile: 'Enable Participant Profile',
		editUserDialogTitle: 'Edit User Details',
		editExhibitorDialogTitle: 'Edit Company Contact Person Details',
		editScholarDialogTitle: 'Edit Poster / Study Contact Person Details',
		editParticipantDialogTitle: 'Edit Participant Details',
		firstName: 'First Name',
		lastName: 'Last Name',
		uploadImage: 'Upload image',
		removeImage: 'Remove image',
		deleteTitle: 'Delete account', // new translation
		waitTitle: 'Wait!!', // new translation
		waitMessage:
			"We're a big believer in second chances, so here's an extra 30sec to reconsider. We've always got super interesting and engaging events going on - keep your account and access those whenever you want, 24/7/365. More events, more fun, more learning. You won't regret staying with us", // new translation
		deleteConfirm:
			'By clicking “Delete”, you understand and agree that your account, including contacts, chats on demand and other communication, will be irrevocably deleted. Please note that this includes any purchases associated with your account.', // new translation
		deleteError:
			"You can't delete your account. Please delete all the events created to be able to delete  your account.",
		deleteSuccess: 'Your account has been deleted.',
		reviewEventPrivacyPolicy: 'Review our Event Privacy Policy:',
		title: 'Title',
		company: 'Company',
		phone: 'Phone',
		platformProfileSectionTitleTextSecond: 'profile',
		createParticipantProfile: 'Create Participant Profile',
		reviewPlatformConditions: 'Review our platform conditions',
		reviewEventConditions: 'Review our event conditions',
	},
	changePassword: {
		title: 'Change password',
		oldPassword: 'Old Password',
		oldPasswordError: 'Old password does not match!',
		inputPassword: 'New Password',
		inputConfirmPassword: 'Confirm Password',
	},
	roomsDropdown: {
		auditoriumButton: 'AUDITORIUM',
		archiveButton: 'ARCHIVE',
		onDemandButton: 'ON-DEMAND',
	},
	sideMenu: {
		liveWall: 'LIVE WALL',
		networking: 'NETWORKING',
		eventInfo: 'EVENT INFO', // new translation
		eventDescription: 'EVENT DESCRIPTION', // new translation
		eventFiles: 'EVENT FILES', // new translation
		eventLinks: 'EVENT LINKS', // new translation
		audience: 'AUDIENCE',
		info: 'INFO',
		videoWall: 'VIDEO WALL',
		booth: 'BOOTH',
		boothWall: 'BOOTH WALL',
		networkingTooltip:
			"You don't have access to all the networking functionalities.",
		attendees: 'Attendees',
	},
	wall: {
		sortBy: 'Sort by:',
		popularity: 'POPULARITY',
		time: 'TIME',
		emptyWallText:
			'Be the first to add a post here.\nJust tell what’s on your mind or briefly introduce yourself',
		newPostInput: "What's on your mind?",
		newCommentInput: 'Comment',
		resourcesVideoDescription: 'VIDEO DESCRIPTION',
		resourcesLinks: 'Links',
		resourcesFiles: 'Files',
		pinTitle: 'Pin',
		unPinTitle: 'Unpin',
		deleteDialogTitle: 'Delete post?',
		pinDialogDescription:
			'There is already a pinned post. Only one post can be pinned at a time. By proceeding, the previous post will be automatically unpinned.',
		edited: 'Edited',
		escapeToCancel: 'escape to cancel',
		enterToSave: 'enter to save',
		editingState: 'Editing message',
		bannerModeration:
			'Posts and comments on this wall are being moderated to ensure quality content',
		pendingApproval: 'Pending approval',
		approvePost: 'Approve post',
		approvePostComment: 'Approve comment',
		commentsPendingApproval: 'Comments pending approval',
	},
	networking: {
		searchInput: 'Search by user',
		emptyNetworkingSearchResults:
			'The person you are looking for has not registered for this online event',
		emptyAuditoriumNetworkingSearchResults:
			'The person you are looking for has not attended yet this presentation',
		alphabetical: 'abc',
		online: 'online',
		matching: 'matches',
		matchingAlgo:
			'Matching algorithm in action. Give us a minute while we are curating your best matches!',
		searchFilters: 'Search Filters',
		applyFilterTooltip: 'In order to apply filters select at least 1 option',
		chat: 'Chat',
	},
	closedAuditoriumDialog: {
		title: 'Auditorium Information',
		contentPackage:
			"Your access package doesn't have access to any auditoriums.",
	},
	closedShowfloorDialog: {
		title: 'Exhibit Hall Information',
		contentPackage:
			"Your access package doesn't have access to any Exhibit Halls.",
		button: 'OK',
	},
	auditorium: {
		willStart: 'Next session will start in...',
		noAccess: "Your access package doesn't have access to this auditorium.",
		noVideos: 'No videos are scheduled to play in this Auditorium.',
		stageTab: 'STAGE',
		videoWallButton: 'VIDEO WALL',
		resourcesButton: 'RESOURCES',
		slotEndsTextFirst: 'This program slot ends in',
		slotEndsTextSecondUpdated: 'The presentation will be available on-demand.',
		slotTitleWith: 'with',
		allAuditoriums: 'All Auditoriums',
		onDemandButton: 'ON-DEMAND',
		hide: 'Hide',
		leavePopupTitle: 'Leave stage?',
		leavePopupDescription:
			'By leaving the auditorium, you will be removed from the session',
		leaveSession: 'Leave stage',
		cancelDescription:
			'By leaving, you will no longer be a speaker. Do you want to proceed?',
		settings: 'Settings',
		minimize: 'Minimize',
		microphone: 'Microphone',
		camera: 'Camera',
		auditoriums: 'Auditoriums',
	},
	auditoriumArchive: {
		title: 'Auditorium Archive',
		titleEvent: 'Event Archive',
		with: 'with',
		noVideosAvailable: 'All sessions finished',
		backToLive: 'BACK TO LIVE',
	},
	videoPlayer: {
		videoNotPlayingError:
			'Having trouble with the Video Stream? Please Reload.',
		videoEndedTextFirst: 'Video has ended. Thank you for watching!',
		videoEndedTextSecond: 'The video wall is open for Q&A.',
		tooltipPlay: 'Play',
		tooltipPause: 'Pause',
		tooltipMinimize: 'Minimize',
		tooltipFullscreen: 'Fullscreen',
		tooltipMute: 'Mute',
		tooltipUnmute: 'Unmute',
		videoStreamNotStarted: 'Going live soon. Stay tuned!',
	},
	showfloor: {
		allExhibitorsButton: 'ALL EXHIBITORS',
		featuredExhibitorsText: 'FEATURED EXHIBITORS',
		boothLinksDialogTitle: 'BOOTH LINKS',
		boothFilesDialogTitle: 'BOOTH FILES',
		boothVideoDialogTitle: 'VIDEO',
		mobileTabChat: 'CHAT',
		mobileTabPoster: 'POSTER',
		mobileTabFiles: 'FILES',
		mobileTabLinks: 'LINKS',
		mobileTabVideo: 'VIDEO',
		noExhibitorsTextFirst: 'THE EXHIBITORS ARE WORKING ON THEIR BOOTHS',
		noPresentersTextFirst: 'The presenters are working on their booths',
		noExhibitorsTextSecond: 'STAY TUNED',
		showShowfloorsButton: 'ALL EXHIBIT HALLS',
		showShowfloorsPosterButton: 'ALL POSTERS/STUDIES',
		showShowfloorsExhibitorButton: 'ALL EXHIBITORS',
		searchExhibitors: 'Search exhibitors',
		searchPosters: "Search by title or author's name",
		searchExhibitorsMultiple: 'Search exhibitors in this exhibit hall',
		searchPostersMultiple:
			"Search by title or author's name in this exhibit hall",
		showfloorsTitle: 'Exhibit Halls',
		collapseList: 'Collapse list',
		seeFullList: 'See full list',
		emptyExhibitorsResults:
			"The searched booth is not present.\n But we'll invite them next time",
		emptyPostersResults:
			"The searched poster/study is not present.\n But we'll invite them next time",
		posters: 'posters',
		allPostersStudiesTitle: 'All Posters/Studies',
		authors: 'authors',
		titleNotAvailable: 'Title not available',
		abstractNotAvailable: 'Abstract not available',
		authorsNotAvailable: 'Authors not available',
		openPosterFile: 'Open poster file',
		fileIsNotAvailable: 'File is not available',
		posterBooth: {
			study: 'Poster/Study',
			authors: 'Authors',
			highlights: 'Abstract',
			scrollInfoText: 'SCROLL DOWN TO READ THE FULL ABSTRACT',
		},
	},
	eventArchive: {
		title: 'Video Archive',
		searchText: 'Tap to search',
		noArchivedEvents: 'There are currently no archived events.',
	},
	exhibitorDashboard: {
		event: 'Event',
		title: 'Manage Your Booth',
		visitorsButton: 'Booth Visitors',
		goToBoothPreview: 'Booth preview',
		previewButton: 'Preview',
		tabStyle: 'Booth Style',
		tabLogo: 'Logo',
		tabStudyDetails: 'Poster / Study',
		tabFiles: 'Files',
		tabStudyFile: 'File',
		tabLinks: 'Links',
		tabAvatars: 'Avatars',
		tabStudyAuthors: 'Authors',
		tabVideo: 'Video',
		shareText: 'COPY URL',
		theBoothUrlCopiedText:
			'The Booth URL was copied successfully to your clipboard',
		optionDisabledText: 'This option is currently unavailable for this booth',
		boothStyleTab: {
			optionOne: 'Option 1 - Scandinavian',
			optionTwo: 'Option 2 - Modern',
			optionThree: 'Option 3 - Dynamic',
			optionFour: 'Option 4 - Custom',
			optionScholarOne: 'Option 1 - Einstein',
			optionScholarTwo: 'Option 2 - Curie',
			optionScholarThree: 'Option 3 - da Vinci',
		},
		logoTab: {
			fileUploadButton: 'Click here to upload',
			noFile: 'No file chosen',
		},
		posterStudyTab: {
			posterStudyTitle: 'Title',
			studyTitleInputLabel: 'Poster / Study Title',
			highlightAbstractTitle: 'Highlight/Abstract',
			studyHighlightsInputLabel: 'Research highlights / abstract',
			highlightAbstractError:
				'You have reached the maximum limit of characters allowed (500 characters)',
			authorNameLabel: 'Author name',
			authorAffiliationLabel: 'Affiliation',
		},
		filesTab: {
			title: 'Documents:',
			fileNameInput: 'Document name (visible to all participants)',
			chooseFileButton: 'Choose file',
			noDocAdded: 'No documents added',
			fileError: 'Please add a document name',
		},
		fileStudyTab: {
			title: 'Upload pdf file',
			description:
				'Upload poster: paper or presentation to be available in your booth (20 Mb. file limit)',
			uploadPdfInputLabel: 'Upload PDF file',
			uploadNewPdfInputLabel: 'Upload new PDF file',
			uploadedFile: 'Uploaded',
			dragContainerTitle: 'Simply drag the file here',
			dragContainerNewTitle: 'Simply drag the new file here',
		},
		linksTab: {
			social: {
				title: 'Social media links',
				subtitle: '(visible to all participants)',
				facebookInput: 'Insert your Facebook link',
				linkedinInput: 'Insert your Linkedin link',
				twitterInput: 'Insert your Twitter link',
				googleScholarInput: 'Insert your Google Scholar link',
				researchGateInput: 'Insert your Research Gate link',
			},
			other: {
				linkInput: 'Link URL',
				linkLabel: 'Link label',
			},
		},
		linksStudyTab: {
			authorProfiles: 'Author profiles',
			additionalLinks: 'Additional links',
		},
		avatarsTab: {
			mainRepresentatives: {
				title: 'Booth Representatives',
				leftRepresentative: 'Left representative',
				rightRepresentative: 'Right representative',
				addDialog: {
					title: 'Add Avatar',
					titleScholar: 'Add author',
					editAvatarsTitle: 'Edit avatar',
					editAuthorTitle: 'Edit author',
					contentLine1: 'Choose an avatar from the below gallery',
					contentLine2: 'Upload your own personalized avatar.',
					contentLine3: 'Need help? Get in touch with',
					noFile: 'Choose file:',
					assignButtonText: 'Assign',
					assignText:
						'Assign an event participant to be associated with the above representative.',
					assignErrorBooth: 'User is already added as Booth representative',
					assignErrorAdditional:
						'User is already added as Additional representative',
					input: 'Search by name',
				},
			},
			additionalRepresentatives: {
				title: 'Additional representatives',
				subtitle: '(optional, will be displayed with their profile picture)',
				addDialog: {
					title: 'Choose Additional Representative',
					titleScholar: 'Choose Co-Author',
					assignText:
						'Please start searching for an event participant to add him/her as a representative.',
					assignError: 'User is already added as Booth representative!',
				},
			},
			author: {
				title: 'Presenting Author',
				subtitle:
					'Add only an author that registered to the event. You can add 1 author.',
				mainRepresentative: 'Main representative',
			},
			coAuthors: {
				title: 'Co-Authors',
				singularTitle: 'Co-Author',
				subtitle:
					'Add only co-authors that registered to the event. You can add up to 4 co-authors.',
			},
		},
		videoTab: {
			video: {
				title: 'Booth Video',
				subtitle: '(YouTube video link only)',
				youtubeOrVimeoOnly: '(YouTube or Vimeo link only)',
				input: 'Enter YouTube URL',
				inputYoutubeOrVimeo: 'Enter YouTube or Vimeo video URL',
			},
			image: {
				subtitle:
					"(Image is displayed in your Booth in case you haven't added a video link in the above section)",
			},
		},
	},
	boothDashboard: {
		event: 'Event',
		title: 'Your Booth',
		manage: 'Manage',
		statistics: 'Statistics',
		uniqueVisitors: 'Unique Visitors', // new translation
		currentOnlineVisitors: 'Current Online Visitors', // new translation
		totalNumberOfVisits: 'Total Number Of Visits', // new translation
		totalDocumentClicks: 'Total Document Clicks', // new translation
		totalLinkClicks: 'Total Link Clicks', // new translation
		totalRepresentativesClicks: 'Total Representatives Clicks', // new translation
		totalVideoViews: 'Total Video Views', // new translation
		totalPostsCount: 'Total Posts Count', // new translation
		totalLikesCount: 'Total Likes Count', // new translation
		totalCommentsCount: 'Total Comments Count', // new translation
		showUsers: 'Show Users', // new translation
		dataType: 'Data Type', // new translation
		value: 'Value', // new translation
		posts: 'Posts', // new translation
		likes: 'Likes', // new translation
		comments: 'Comments', // new translation
		topPostLikes: 'Top Post Likes', // new translation
		topPostComments: 'Top Post Comments', // new translation
		totalVotes: 'Total Votes', // new translation,
	},
	polls: {
		polls: 'POLLS',
		noPolls: 'No polls were added yet.',
		pollProgress: 'Poll in progress',
		pollClosed: 'Poll closed',
		pollVoted: 'participants voted',
	},
	onDemandVideos: {
		sectionTitle: 'ON-DEMAND',
		noOnDemandVideos: 'No on demand videos were added yet.',
	},
	meetings: {
		meeting: 'MEETING',
		createDialog: {
			createTitle: 'Request meeting with',
			inputDate: 'Date',
			inputStart: 'Starts',
			inputEnd: 'Ends',
			inputTimezone: 'Timezone',
			inputNote:
				'Note: Only one meeting can be scheduled at a time with the same person.',
			invalidTimeFormat: 'Invalid time Format',
			meetingError: 'Invalid meeting date or time. Please recheck!',
			buttonDisabled:
				'You can schedule a meeting after you start the conversation!',
		},
		banner: {
			pending: 'Meeting request',
			accepted: 'Meeting scheduled for',
			progress: 'Meeting in progress',
			cancelTitle: 'Cancel meeting?',
			cancelDescription:
				'Confirm that you want to cancel the meeting. You can always create a new meeting with this participant later.',
			startCall: 'START CALL',
			joinCall: 'JOIN',
			pendingOwner: 'Pending meeting request for',
			videoCallStarted: 'Video meeting started',
			videoCallStartedInfo: 'Video meetings work best for up to 10 people',
		},
		title: 'Your meetings',
		meetingsInfo:
			'Meetings can be scheduled through the Chat feature. Please start a chat with the user you want to schedule a meeting with.',
	},
	socialMediaShare: {
		event: 'event',
		shareLabel: 'Share',
		dialogTitleGeneral: 'Share with your network',
		dialogTitlePost: 'Share your post',
		linkedinLabel: 'LinkedIn',
		facebookLabel: 'Facebook',
		twitterLabel: 'Twitter',
		emailLabel: 'Email',
		clipboardLabel: 'Clipboard',
		clipboardSnackbarMessage: 'Link copied to clipboard',
		linkedinButtonTitle: 'Share on LinkedIn',
		facebookButtonTitle: 'Share on Facebook',
		twitterButtonTitle: 'Share on Twitter',
		emailButtonTitle: 'Share by Email',
		clipboardButtonTitle: 'Copy to Clipboard',
		imAttending: "I'm attending the",
		interestedClick: 'Interested in attending? Click here:',
	},
	videoConferences: {
		fullScreen: 'OPEN FULL SCREEN',
		minimizeScreen: 'MINIMIZE SCREEN',
		startedVideoChat: 'started a video call',
		timeAgo: 'ago',
		videoCallStarted: 'Video Call started...',
	},
	sessions: {
		edit: 'EDIT',
		speakers: 'SPEAKERS',
		speakerSingular: 'speaker',
		moderators: 'MODERATORS',
		moderatorSingular: 'moderator',
		organizer: 'organizer',
		video: 'VIDEO',
		startStream: 'START STREAM',
		startingStream: 'STARTING STREAM', //new translation
		stopStream: 'STOP STREAM',
		sessionChat: 'SESSION CHAT',
		speakerUrl: 'Speaker URL',
		moderatorUrl: 'Moderator URL',
		copyUrl: 'copy URL',
		copy: 'copy', //new translation
		noSpeakersJoined: 'No Speakers joined yet',
		allFilter: 'all',
		waitingRoom: 'Waiting room',
		isReady: 'Is Ready',
		invitationAccepted: 'Accepted the invitation',
		requestPending: 'Request pending',
		gettingReady: 'Getting ready',
		inviteToSpeak: 'Invite to speak',
		fromAuditorium: 'from Auditorium',
		mic: 'Mic',
		screen: 'Screen',
		rec: 'rec',
		sessionHasEnded: 'The session has ended',
		sessionLinks: 'SESSION LINKS',
		sessionSettings: 'Settings',
		streamingUrl: 'Streaming URL', //new translation
		streamingKey: 'Streaming Key', //new translation
		liveStreamingPageUrl: 'Live streaming page URL', //new translation
		noAvailableChat:
			'No chat available. To see the chat please allocate this session to a timeslot.',
		startLiveSessionStreamTitle: 'Start Live Session Stream',
		startLiveSessionDescription:
			'The Recording of the session will automatically start when you start the stream',
		stopLiveSessionStreamTitle: 'Stop Live Session Stream',
		stopLiveSessionStreamDescription:
			"By stopping the Live Session Stream, the recording will also stop automatically. This Live Session Stream can't be started again.",
		thumbnailImage: 'Thumbnail Image',
		uploadThumbnail: 'Upload Thumbnail',
		clickToUpload: 'Click here to upload',
		saveImage: 'Save image', //new translation
		requestMic: 'REQUEST MICROPHONE',
		acceptInvitationTitle: "You've got the invitation",
		acceptInvitationSubTitle:
			'Moderator has invited you to be a speaker. What do you say?',
		acceptInvitationButton: 'Accept Invitation',
		requestPendingTitle: 'Request is pending...',
		requestPendingSubTitle:
			'Please wait until the moderator will let you enter to the stream',
		cancelRequest: 'Cancel request',
		approvedTitle:
			'Please check your microphone and video and click I’m Ready.',
		approvedSubTitle:
			'This session is being recorded. By entering this session you agree on the processing of your personal data.',
		connectedToTheBackstage:
			'You are now streaming live and are connected to the backstage.',
		removedFromTheSession:
			'You have been kicked from the session, please contact a moderator.',
		imReady: "I'm ready",
		isTryingToRegister: 'is trying to register as a',
		hasLeftTheSession: 'has left the session',
		stopVideo: 'Stop video',
		startVideo: 'Start video',
		disabledScreenSharing:
			'Turn off your screen share before starting your video',
		disabledByModerator: 'Disabled by Moderator',
		disabledUntilReady: 'Disabled until Ready',
		stopShareScreen: 'Stop share screen',
		shareScreen: 'Share screen',
		disabledVideoSharing: 'Turn off your video before sharing your screen',
		anotherUserIsScreenSharing: 'Someone else is already sharing the screen',
		removeRoleDescription: 'Are you sure you want to remove',
		declinedInvitation: 'declined the invitation to be a speaker',
		processingRecord:
			'Processing record, please refresh in a couple of minutes...', // new translation
		downloadRecordingInfoText: 'You can download the recording from ', // new translation
		liveStreamError: "Live stream hasn't been connected", //new translation
	},
	gdpr: {
		disclaimerTextFirst:
			'This document was translated by artificial intelligence. In case of inconsistency, please refer to the English version of the document',
		disclaimerTextSecond: 'HERE',
		metaTitlePrivacyPolicy: 'Privacy Policy',
		metaTitleCookiesPolicy: 'Cookie Policy',
		metaTitleTerms: 'Terms and Conditions',
		updateCookieConsent: 'Update cookie consent',
		changeYourConsent: 'Change your consent',
		withdrawYourConsent: 'Withdraw your consent',
		withdrawSnackbarText: 'Cookie consent successfully withdrawn',
	},
	infoMessages: {
		imageExplain420x200:
			'Recommended image ratio 1.8:1 (landscape-oriented, e.g. 420 x 200 pixels)',
		imageExplainTB230x320:
			'Recommended image ratio 1:1.4 (portrait-oriented, e.g. 230 x 320 pixels) with transparent background',
		imageExplainTB230x875:
			'Recommended image ratio 1:3.8 (portrait-oriented, e.g. 230 x 875 pixels) with transparent background',
	},
	matching: {
		matchingQuestionnaireTitle: 'Matching questionnaire',
		matchingQuestionnaireIntro:
			"Let's help you get connected to the most relevant people for you! Amazing things happen when the right minds meet, complete the questionnaire to get started",
		matchingQuestionnaireIntroSkip:
			"By skipping the questionnaire, we will not be able to match you with the rest of the attendees at today's event. You can always complete the questionnaire later in the Networking section. All answers are editable from My Account.",
		doItLaterBtn: 'DO IT LATER',
		nextBtn: 'Next',
		backBtn: 'Back',
		finishBtn: 'Finish',
		seeMatchesBtn: 'SEE ALL MATCHES',
		tryMatchingBtn: 'TRY SMART MATCHING',
		optionalQuestion: 'Optional',
		requiredQuestion: 'Required',
		singleSelect: 'Single select',
		multiSelect: ' Multi select',
		mediumMatch: 'Medium Match',
		highMatch: 'High Match',
		matchLabel: 'Match',
		matchesLabel: 'Matches',
		infoTooltipJump: 'Jump here to see your matches',
		infoTooltipClickToSee1: 'Click on',
		infoTooltipClickToSee2: 'to see who you should connect with!',
		loadingAlgoText:
			'Matching algorithm in action. Give us a minute while we are curating your best matches!',
		matchingDetailsFormTitle: 'Smart matchng details',
		tryMatchingBanner:
			'Make a meaningful connection, try our Smart Matching feature!',
		matchingBannerClosed:
			'You can always fill the Smart Matching form in Networking under Matches',
		resultsTitle: 'Here are your top matches',
		resultsNoteLabel: 'NOTE:',
		resultsNoteText: 'You can find all your matches in the Networking section.',
		resultsNoteMyAccount: 'All answers are editable in My Account menu.',
		btwResultsResponsive:
			'By the way, you can find all your matches in the NETWORKING',
		noMoreMatches:
			'Oh snap! We can’t find more matches for you at the moment. Please check back again when more participants have joined',
		noResultsTitle:
			'Congrats, you made it! We are searching for your best matches! ',
		noResults: `Thanks for trying the Smart Matching function! We are searching for your best matches and will pair you up as soon soon as more participants join the event. Please check the "Matches" tab in the "Networking" section`,
		clickStartChat: 'Click chat to start a conversation',
		closeMatchingTitle: 'Close matching form?',
		closeMatchingTextA:
			'If you close the form before completion, we will not store any details and the Smart Matching results will not be available to you. ',
		closeMatchingTextB:
			'Your profile will not part of the Smart Matching process and no one will be matched with you. ',
		matchingTooltip: 'Click on Matches to see who you should connect with!',
		myAccountMatchingSectionTitle: 'Smart matching details',
		matchingRequiredError: 'You have to complete all Required questions',
		rejectMatchingTitle: 'Reject Matching participation?',
		rejectMatchingText:
			'Your answers will be erased and you will not be matched with other users. You can retake the Matching questionnaire anytime.',
		rejectMatchingBtn: 'REJECT PARTICIPATION',
	},
	welcomeScreen: {
		goToEvent: 'Go to event',
		doNotShowAgain: "Don't show it again", // new translation
	},
	emailCodeVerification: {
		codeSent: 'We’ve sent a verification code to your email:',
		continueRegistration: 'To continue the registration,',
		enterCodeBelow: 'enter the verification code below:',
		codeWillExpire: 'This code will expire in',
		codeExpired: 'The code expired',
		codeNotReceived:
			'Didn’t receive the code? Have you checked the Spam folder?',
		resendTimer: 'Resend in:',
		resendLabel: 'Resend',
		resendCodeLabel: 'Resend the code',
		codeError: 'Entered code is incorrect.',
		codeErrorFix:
			'Re-enter the correct code from your email or resend a new code',
		congrats: 'Congrats!',
		emailConfirmed: 'Your email confirmed:',
	},
	privacyPolicyUpdates: {
		learnMore: 'Learn more', // new translation
		lastUpdated: 'Last updated', // new translation
		publishedBy: 'Published by', // new translation
		ppuLinkLabel: 'Updated privacy policy', // new translation
		ppuEventLinkLabel: 'Updated event privacy policy', // new translation
		acceptAllButtonLabel: 'Accept all updates', // new translation
		agreeCheckboxLabel: 'I agree with this update', // new translation
	},
	agendaTranslations: {
		myAgenda: 'MY AGENDA',
		all: 'All',
		sessions: 'Sessions',
		meetings: 'Meetings',
		pastSlots: 'Past slots',
		meetingWith: 'Meeting with',
		pending: 'Pending',
		accepted: 'Accepted',
		requested: 'Requested',
		canceled: 'Canceled',
		now: 'Now',
		live: 'Live',
		noMeetings: 'No meetings scheduled yet',
		noSessions: 'No sessions on your agenda yet.',
		noUpcomingSessions: 'No upcoming sessions on your agenda yet',
		noUpcomingMeetings: 'No upcoming meetings on your agenda yet.',
		noUpcomingAgenda: 'Nothing upcoming on your agenda yet.',
		noAgendaItem: 'Nothing on your agenda yet.',
		meetingRequests: 'MEETING REQUESTS',
		finished: 'Finished',
		past: 'Past',
		goToMeeting: 'GO TO MEETING',
		addToMyAgenda: 'Add to my agenda',
		addedToYourAgenda: 'Added to your agenda',
		acceptedAllMeetings: 'Accept All Meetings',
	},
	voting: {
		votingText: 'VOTING',
		enableVoting: 'ENABLE VOTING',
		enableVotingDescription:
			'Click the Refresh icon to get the most accurate results',
		displayVotingNumbersForExhibitors:
			'DISPLAY VOTING NUMBERS FOR EXHIBITORS & POSTER/STUDY',
		displayVotingNumbersForExhibitorsDescription:
			'Will be displayed in the Exhibitor dashboard',
		displayVotingNumbersForParticipants:
			'DISPLAY VOTING NUMBERS FOR PARTICIPANTS',
		displayVotingNumbersForParticipantsDescription:
			'Will be displayed for all the Participants',
		characterError80:
			'You have reached the maximum limit of characters allowed (80 characters)',
		characterDescription: `Text is limited up to 80 symbols. The text will appear as main title on the Voting ribbon. Make it meaningful for participants`,
		deleteAllVotes: 'DELETE ALL VOTES',
		downloadVotingResults: 'DOWNLOAD VOTING RESULTS',
		voteText: 'Vote',
		votedText: 'Voted',
		votesText: 'Votes',
		participant: 'participant',
		participants: 'participants',
		deleteVotesTextFirst: 'WARNING:',
		deleteVotesTextSecond: 'this is non-revertible action.',
		deleteVotesTextThird:
			'Don’t forget to download the voting results before deleting them.',
		checkTheBoxToCastYourVote: 'Check the box to cast your vote',
		toVotePleaseEnableParticipantProfile:
			'To vote, please enable your Participant profile',
	},
};

export default en;
