import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import NotFound from '../../Pages/NotFound';
import Spinner from '../../SmallLayoutComponents/FullPageSpinner';
import EventPrivateComponent from './EventPrivateComponent';
import { isMobile } from 'react-device-detect';
import AIChatbot from 'Integrations/AIChatbot/AIChatbot';

const ExhibitorRoute = ({
	component: Component,
	loggedIn,
	user,
	loadingUser,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) => {
			const eventSlug = props.match.params.eventSlug;

			if (!loggedIn && loadingUser) {
				return <Spinner />;
			}
			if (loggedIn && user) {
				const userRolesInEvent = user.eventRoles.find(
					(role) => role.event.slug === eventSlug
				);
				const userIsExhibitor = userRolesInEvent?.roles.includes('exhibitor');
				if (userIsExhibitor) {
					const exhibitorType =
						userRolesInEvent?.exhibitor.type === 'scholar'
							? 'scholar'
							: 'company';
					if (isMobile) {
						return (
							<Redirect
								to={{
									pathname: `/event/${eventSlug}/lobby`,
								}}
							/>
						);
					}
					return (
						<EventPrivateComponent {...props}>
							<Component {...props} />
							<AIChatbot userRole={exhibitorType} />
						</EventPrivateComponent>
					);
				}
				return <NotFound />;
			}
			if (!loggedIn && !loadingUser) {
				return (
					<Redirect
						to={{
							pathname: `/event/${eventSlug}`,
						}}
					/>
				);
			}
			return <NotFound />;
		}}
	/>
);

const mapStateToProps = (state) => {
	return {
		loggedIn: state.user.loggedIn,
		user: state.user.data,
		loadingUser: state.user.loading,
	};
};

export default connect(mapStateToProps)(ExhibitorRoute);
