import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Spinner from '../../SmallLayoutComponents/FullPageSpinner';
import NotAvailable from '../../Pages/NotAvailable';
import NotFound from '../../Pages/NotFound';
import { withRouter } from 'react-router-dom';
import { preventDefaultDrag } from '../../Utils/utils';

class EventInfoComponent extends React.Component {
	state = {
		eventSlug: this.props.match.params.eventSlug,
	};

	componentDidMount() {
		const { eventSlug, eventId, eventInfo, loadingEvent } = this.props;

		if (this.state.eventSlug !== eventSlug) {
			this.props.setEventSlug(this.state.eventSlug);
		}

		if (
			this.state.eventSlug === eventSlug &&
			!loadingEvent &&
			!eventId &&
			!eventInfo
		) {
			this.props.setEventIdBySlug(eventSlug);
		}

		if (!eventInfo && !loadingEvent && eventId) {
			this.props.onGetEventInfo(eventId);
		}
		if (eventInfo) {
			document.documentElement.style.setProperty(
				'--primary-color',
				`${eventInfo.brandingData.primaryColor}`
			);
			document.documentElement.style.setProperty(
				'--secondary-color',
				`${eventInfo.brandingData.secondaryColor}`
			);
			if (eventInfo.brandingData.secondaryColor !== '#007DBB') {
				document.body.classList.add('secondary-changed');
			}
		}
	}

	componentDidUpdate(prevProps) {
		const { eventId, loadingEvent, eventSlug, eventInfo } = this.props;

		if (prevProps.eventSlug !== eventSlug && !loadingEvent) {
			this.props.setEventIdBySlug(eventSlug);
		}

		if (prevProps.eventId !== eventId && !loadingEvent) {
			this.props.onGetEventInfo(this.props.eventId);
		}
		if (prevProps.eventInfo?._id !== eventInfo?._id && eventInfo) {
			document.documentElement.style.setProperty(
				'--primary-color',
				`${eventInfo.brandingData.primaryColor}`
			);
			document.documentElement.style.setProperty(
				'--secondary-color',
				`${eventInfo.brandingData.secondaryColor}`
			);
			if (eventInfo.brandingData.secondaryColor !== '#007DBB') {
				document.body.classList.add('secondary-changed');
			}
		}
	}

	componentWillUnmount() {
		// document.documentElement.style.removeProperty('--primary-color');
		// document.documentElement.style.removeProperty('--secondary-color');
	}

	render() {
		const { eventInfo, loadingEvent, eventNotFound, user, eventSlug } =
			this.props;
		const userRolesInEvent = user?.eventRoles.find(
			(role) => role.event?.slug === eventSlug
		);
		const userIsOrganizer =
			userRolesInEvent && userRolesInEvent.roles.includes('organizer');
		const userIsCoOrganizer =
			userRolesInEvent && userRolesInEvent.roles.includes('coOrganizer');

		const isPreviewMode = this.props.location.search.includes('preview=true');

		if (!eventInfo && loadingEvent) {
			return <Spinner />;
		}
		if (
			((userIsOrganizer && eventInfo?.owner.organizerStatus === 'approved') ||
				userIsCoOrganizer) &&
			isPreviewMode
		) {
			return this.props.children;
		}
		if (
			(eventInfo &&
				(eventInfo.owner.organizerStatus === 'pending' ||
					eventInfo.status === 'pending')) ||
			eventNotFound
		) {
			return <NotFound />;
		}
		if (eventInfo && eventInfo.owner.organizerStatus === 'disabled') {
			return <NotAvailable />;
		}
		if (!eventInfo) {
			return (
				<div onDragStart={preventDefaultDrag} className="reload-page-container">
					<p>Please reload the page.</p>
				</div>
			);
		}
		return this.props.children;
	}
}

const mapStateToProps = (state) => {
	return {
		eventId: state.event.eventId,
		eventSlug: state.event.eventSlug,
		eventNotFound: state.event.eventNotFound,
		eventInfo: state.event.eventInfo,
		loadingEvent: state.event.loading,
		user: state.user.data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onGetEventInfo: (eventId) => dispatch(actions.getEventInfo(eventId)),
		setEventId: (eventId) => dispatch(actions.setEventId(eventId)),
		setEventSlug: (eventSlug) => dispatch(actions.setEventSlug(eventSlug)),
		setEventIdBySlug: (eventSlug) =>
			dispatch(actions.setEventIdBySlug(eventSlug)),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EventInfoComponent)
);
