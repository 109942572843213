const pt = {
	generalText: {
		upload: 'Enviar',
		save: 'Salvar',
		add: 'Adicionar',
		remove: 'Remover',
		close: 'FECHAR',
		update: 'ATUALIZAR',
		cancel: 'CANCELAR',
		confirm: 'Confirmar',
		download: 'Baixar',
		refresh: 'Atualizar',
		back: 'Voltar',
		submit: 'Enviar',
		send: 'Enviar',
		leave: 'Sair',
		step1: 'Passo 1',
		step2: 'Passo 2',
		or: 'ou',
		delete: 'Apagar',
		please: 'Por favor',
		before: 'antes',
		approve: 'Aprovar',
		decline: 'Rejeitar',
		start: 'Iniciar',
		stop: 'Parar',
		resetFilter: 'Repor Filtro',
		apply: 'Aplicar',
		filters: 'Filtros',
		unsavedChangesTitle: 'Alterações não guardadas',
		unsavedChangesText: 'Há alterações não guardadas. Pretende guardá-las?',
		selectAll: 'Selecionar Todos',
		unselectAll: 'Desselecionar Todos',
	},
	time: {
		justNow: 'agora mesmo',
		minute: 'minuto',
		minutes: 'minutos',
		hour: 'hora',
		hours: 'horas',
		day: 'dia',
		days: 'dias',
		month: 'mês',
		months: 'meses',
		yesterday: 'ontem',
		today: 'hoje',
		tomorrow: 'amanhã',
		monday: 'segunda-feira',
		tuesday: 'terça-feira',
		wednesday: 'quarta-feira',
		thursday: 'quinta-feira',
		friday: 'sexta-feira',
		saturday: 'sábado',
		sunday: 'domingo',
		january: 'janeiro',
		february: 'fevereiro',
		march: 'março',
		april: 'abril',
		may: 'maio',
		june: 'junho',
		july: 'julho',
		august: 'agosto',
		september: 'setembro',
		october: 'outubro',
		november: 'novembro',
		december: 'dezembro',
		januaryShort: 'jan',
		februaryShort: 'fev',
		marchShort: 'mar',
		aprilShort: 'abr',
		mayShort: 'mai',
		juneShort: 'jun',
		julyShort: 'jul',
		augustShort: 'ago.',
		septemberShort: 'set',
		octoberShort: 'out',
		novemberShort: 'nov',
		decemberShort: 'dez',
	},
	errors: {
		noFile: 'Nenhum arquivo selecionado',
		required: 'O campo é obrigatório',
		emailNotValid: 'E-mail inválido',
		passwordMinLength: 'A senha deve ter no mínimo 8 caracteres',
		passwordMismatch: 'Senha incorreta',
		passwordIsSame: 'A sua nova palavra-passe tem de ser diferente',
		noInvitationCode: 'Por favor insira o seu código de convite',
		imageFileNotSupported:
			'Tipo de arquivo não suportado. Use um dos seguintes:',
		phoneInvalid: 'Insira um número de telefone válido. Por ex.: +447517663928',
		linkInvalid: 'Informe um link válido (https://site.com/)',
		facebookLinkInvalid: 'Informe um link válido (https://facebook.com/)',
		linkedinLinkInvalid: 'Informe um link válido (https://linkedin.com/)',
		twitterLinkInvalid: 'Informe um link válido (https://twitter.com/)',
		youtubeLinkInvalid: 'Informe um link válido (https://www.youtube.com/)',
		youtubeOrVimeoLinkInvalid:
			'Insira um YouTube válido (https://www.youtube.com) ou Vimeo (https://vimeo.com/) link',
		googleScholarLinkInvalid:
			'Insira uma ligação válida (https://scholar.google.com/)',
		researchGateLinkInvalid:
			'Insira uma ligação válida (https://www.researchgate.net/)',
		connectionProblemTextFirst: 'Parece haver um problema de conexão Por favor',
		connectionProblemButton: 'CLIQUE',
		connectionProblemTextSecond: 'ou atualize a página.',
		fileTooLarge: 'Arquivo é muito grande tamanho máximo do arquivo',
		fileTooLargeTextFirst: 'Ficheiro demasiado grande.',
		fileTooLargeTextSecond: 'tamanho máx. do ficheiro.',
		fileNameTooLongTextFirst: 'Nome do ficheiro demasiado longo. O limite é',
		fileNameTooLongTextSecond: 'caracteres.',
		maxWallInputLength: 'Atingiu o número máximo de 1200 caracteres.',
	},
	footer: {
		cookiesText:
			'Este site utiliza cookies essenciais, que são necessários ao seu funcionamento Ele também utiliza cookies de terceiros necessários para o funcionamento de algumas funcionalidades do nosso site. Se quiser saber mais ou suspender o seu consentimento para alguns cookies, consulte a nossa',
		cookiesLink: 'política de cookies',
		cookiesButton: 'ACEITAR',
		privacyPolicy: 'Política de privacidade',
		cookies: 'Cookies',
		termsAndConditions: 'Termos e Condições',
		socialLinksTitle: 'Siga-nos',
	},
	menu: {
		logIn: 'ENTRAR',
		logOut: 'SAIR',
		scholarRegistration: 'Aderir como cartaz/estudo',
		exhibitorRegistration: 'Aderir como expositor',
		participantRegistration: 'Aderir como participante',
		createEvent: 'Organizar evento',
		loginDisabledInfoText:
			'O organizador encerrou o início de sessão. Tente mais tarde',
	},
	homepage: {
		organizedBy: 'ORGANIZADO POR',
		date: 'DATA',
		location: 'DOMÍNIO E LOCALIZAÇÃO',
		price: 'PREÇO',
		tabIntro: 'INTRODUÇÃO',
		tabProgram: 'PROGRAMA',
		tabSponsors: 'PATROCINADORES',
		tabPartners: 'PARCEIROS',
		tabExhibitors: 'EXPOSITORES',
		previousEventsSectionTitle: 'EVENTOS ANTERIORES',
	},
	socialButtons: {
		linkedinButton: 'Entrar com o LinkedIn',
		loginTabLinkedinButton: 'com o LinkedIn',
		facebookButton: 'Entrar com o Facebook',
		loginTabFacebookButton: 'com o Facebook',
		googleButton: 'Entrar com o Google',
		loginTabGoogleButton: 'com o Google',
		twitterButton: 'Entrar com o Twitter',
		loginTabTwitterButton: 'com o Twitter',
		socialButtonsLogInTitle: 'Adira com o seu perfil das redes sociais',
		socialButtonsSignUpTitle:
			'Inicie sessão com o seu perfil das redes sociais',
	},
	login: {
		inputEmail: 'E-mail',
		inputPassword: 'Senha',
		rememberMeButton: 'Lembrar de\nmim',
		forgotPasswordButton: 'Esqueceu\na senha?',
		loginButton: 'INICIAR SESSÃO',
		loginClosedButton: 'Início de sessão fechado',
		loginTitleLowerCase: 'Iniciar sessão',
		recoverPasswordDialogTitle: 'RECUPERAR SENHA',
		recoverPasswordDialogRecoverButton: 'RECUPERAR',
		errorIncorrectDates: 'E-mail ou palavra-passe incorretos',
		orLogInWithEmail: 'ou inicie sessão com o e-mail',
		logInAsParticipantSecondText: 'como participante',
		logInAsExhibitorSecondText: 'como expositor',
		logInAsPosterSecondText: 'como cartaz/estudo',
		errorAccountNotFound: 'Não conseguimos encontrar a sua conta Unstoppabl.ai',
	},
	register: {
		inputFirstName: 'Nome',
		inputLastName: 'Sobrenome',
		inputEmail: 'E-mail',
		inputPassword: 'Senha',
		inputConfirmPassword: 'Confirmar senha',
		gdprTextFirst: 'Ao prosseguir, você concorda com nossos',
		gdprTextTerms: 'Termos de uso',
		gdprTextSecond: 'e confirma que você leu nosso',
		gdprTextCookies: 'Cookie',
		gdprTextPrivacy: 'Política de privacidade',
		gdprTextAgree: 'Concorde com a política de privacidade',
		registerButton: 'Registrar-se',
		signUpTitleLowerCase: 'registar-se',
		invitationCode: 'Código de Convite',
		registerNotAllowed: 'Registo encerrado',
		logInTitleFirst: 'Aderir',
		logInTitleSecond: 'evento',
		signUpTitleTextFirst: 'Pela primeira vez em',
		signUpTitleTextSecond: 'Unstoppabl.ai',
		orFillInTheForm: 'ou preencha o formulário abaixo',
		uploadProfilePicture: 'Carregue a imagem de perfil',
		editProfilePicture: 'Edite a imagem de perfil',
		errorAlreadyHaveMOAccount: 'Já tem uma conta em Unstoppabl.ai',
		step1From2Text: '1/2 passos',
		step2From2Text: '2/2 passos',
		metaTitleScholarRegistration: 'Registo do cartaz/estudo',
	},
	newLoginRegistrations: {
		joinEvent: 'DOŁĄCZ DO WYDARZENIA',
		loginWelcome:
			'Bem-vindo ao Unstoppabl.ai! Escolha um método de login para continuar',
		continueButton: 'Continuar',
		seemsLikeYouDontHaveMyOnventAccount:
			'Parece que você não tem uma conta no Unstoppabl.ai',
		uploadProfilePictureOptional: 'Carregar foto do perfil (opcional)',
		createPlatformAccount: 'Criar conta na plataforma',
		platformAccount: 'Conta na plataforma',
		selectRole: 'Selecione uma função',
		eventAccount: 'Conta do evento',
		password: 'Senha',
		confirmPassword: 'Confirmar senha',
		email: 'E-mail',
		passwordMustContain: 'A senha deve conter:',
		minimum8Characters: 'Mínimo de 8 caracteres',
		aLowerCaseLetter: 'Uma letra minúscula',
		anUppercaseLetter: 'Uma letra maiúscula',
		aNumber: 'Um número',
		aSpecialCharacter: 'Um caractere especial',
		firstName: 'Nome',
		lastName: 'Sobrenome',
		weHaveSentAVerificationCodeToYourEmail:
			'Enviamos um código de verificação para o seu e-mail',
		continueRegistration: 'Para continuar o registro,',
		enterCodeBelow: 'digite o código de verificação abaixo:',
		codeWillExpire: 'Este código irá expirar em',
		codeExpired: 'Código expirado',
		codeNotReceived: 'Não recebeu o código? Verifique a pasta de spam',
		resendTimer: 'Reenviar em:',
		resendLabel: 'Reenviar',
		resendCodeLabel: 'Reenviar código',
		codeError: 'O código inserido é inválido.',
		codeErrorFix:
			'Digite um código válido do e-mail ou envie um novo código novamente',
		congrats: 'Parabéns!',
		emailConfirmed: 'Seu endereço de e-mail foi confirmado:',
		imHereAs: 'Estou aqui como:',
		participant: 'Participante',
		participantText:
			'Para assistir ao streaming, se comunicar, visitar expositores e pôsteres/estudos.',
		exhibitor: 'Expositor',
		exhibitorText:
			'Para apresentar sua empresa e se comunicar com os participantes do evento',
		poster: 'Pôster/Estudo',
		posterText:
			'Para apresentar pesquisas em uma conferência acadêmica ou profissional',
		emailIsNotAllowed:
			'Este endereço de e-mail não está permitido para o registro neste evento',
		didntReceiveEmail: 'Não recebeu o e-mail?',
		sendAgain: 'Enviar novamente',
		incorrectPassword: 'Senha incorreta',
		fileTypeError:
			'Tipo de arquivo não suportado. Por favor, use um dos seguintes formatos: jpeg, jpg, jfif, gif ou png.',
		fileSizeError:
			'O arquivo é muito grande. O tamanho máximo do arquivo é 2 MB.',
		pleaseCompleteAllRequiredFields:
			'Por favor, preencha todos os campos obrigatórios',
		joinTheEvent: 'Participe do evento',
		joinTheEventSub: 'Para começar sua experiência virtual com o evento online',
		changeEmail: 'ALTERAR E-MAIL',
		changeRole: 'ALTERAR FUNÇÃO',
		logout: 'SAIR',
		headingUser: 'Bem-vindo de volta',
		subHeadingUser: 'Parece que você já possui uma conta na plataforma',
		forgotPasswordHeadline: 'Esqueceu a senha',
		forgotPasswordText: 'Não se preocupe, enviaremos instruções adicionais',
		checkYourEmail: 'Verifique seu e-mail',
		checkYourEmailText:
			'Enviamos um link para redefinir sua senha para o seguinte endereço de e-mail:',
		headingNoUser: 'Faça login',
		subHeadingNoUser:
			'Bem-vindo ao Unstoppabl.ai! Para continuar, escolha um método de login',
		resetPasswordButton: 'redefinir senha',
		createNewPassword: 'Criar nova senha',
		createNewPasswordText:
			'Sua nova senha deve ser diferente das senhas anteriores',
		passwordReset: 'Redefinição de senha',
		passwordResetText:
			'Sua senha foi redefinida com sucesso. Clique abaixo para fazer login novamente.',
		registrationIsClosed: 'O registro para todas as funções foi encerrado.',
		chooseAnOption: 'Escolha uma opção',
		yourAnswer: 'Sua resposta',
		firstNameError: 'O nome é muito curto. Mínimo de 2 caracteres',
		lastNameError: 'O sobrenome é muito curto. Mínimo de 2 caracteres',
		recoveryPassword: 'Recuperar senha',
		incorrectPasswordError:
			'Senha incorreta. Tente novamente ou clique em "Esqueci minha senha" para alterá-la.',
		optional: 'Opcional',
		spamEmailError:
			'Ocorreu um problema ao criar sua conta. Tente novamente mais tarde.',
		invalidInput:
			"Entrada inválida. Use apenas letras (A-Z, a-z), números (0-9), ponto (.), apóstrofo ('), hífen (-) e espaços. O primeiro caractere deve ser uma letra.",
	},
	emailUpdate: {
		changeEmail: 'Alterar e-mail',
		changeEmailDescription:
			'Antes de atribuirmos o novo e-mail à sua conta, queremos ter certeza de que é um novo e-mail e que não pertence a ninguém.',
		emailTakenError: 'Esse e-mail já está em uso. Tente outro.',
		enterTheConfirmationCode: 'Insira o código de confirmação',
		verificationCodeSent: 'Código de verificação enviado:',
		checkYourSpamFolder:
			'Não consegue encontrar o código? Verifique sua pasta de spam',
		emailSuccess: 'Sucesso! Você alterou o e-mail associado à sua conta',
		emailError: 'Erro. Tente novamente mais tarde',
		confirmPassword: 'Confirmar senha',
		confirmPasswordDescription: 'Para alterar o e-mail, confirme sua senha:',
		newEmail: 'Novo e-mail',
		password: 'Senha',
		confirm: 'Confirmar',
		cancel: 'Cancelar',
		change: 'Alterar',
	},

	agendaTranslations: {
		myAgenda: 'MINHA AGENDA',
		all: 'Todos',
		sessions: 'Sessões',
		meetings: 'Reuniões',
		pastSlots: 'Slots anteriores',
		meetingWith: 'Reunião com',
		pending: 'Pendente',
		accepted: 'Aceito',
		requested: 'Solicitado',
		canceled: 'Cancelado',
		now: 'Agora',
		live: 'Ao vivo',
		noMeetings: 'Nenhuma reunião agendada ainda',
		noSessions: 'Nenhuma sessão na sua agenda.',
		noUpcomingSessions: 'Nenhuma sessão futura na sua agenda',
		noUpcomingMeetings: 'Nenhuma reunião futura na sua agenda.',
		noUpcomingAgenda: 'Nada planejado ainda na sua agenda.',
		noAgendaItem: 'Nenhum item na sua agenda ainda.',
		meetingRequests: 'Solicitações de reunião',
		finished: 'Concluído',
		past: 'Passado',
		goToMeeting: 'Ir para a reunião',
		addToMyAgenda: 'Adicionar à minha agenda',
		addedToYourAgenda: 'Adicionado à sua agenda',
		acceptedAllMeetings: 'Aceitar todas as reuniões',
	},
	participantRegistration: {
		metaTitle: 'Registo do participante',
		choosePackageTitle: 'ESCOLHA O PACOTE DE ACESSO',
		noPackageText: 'Selecione um pacote',
		gdprTextFirst: 'Ao prosseguir, você concorda com nossos',
		gdprTextPrivacy: 'Política de privacidade do evento',
		gdprTextSecond: 'e',
		gdprTextTerms: 'Evento Termos e Condições',
		gdprTextAgree: 'Concorde com a política de privacidade',
		registerButtonFree: 'Finalizar registro',
		registerButtonPay: 'Prossiga para o pagamento',
	},
	exhibitorRegistration: {
		metaTitle: 'Registo do expositor',
		choosePackageTitle: 'ESCOLHA O PACOTE DE ACESSO',
		gdprTextFirst: 'Ao prosseguir, você concorda com nossos',
		gdprTextPrivacy: 'Política de privacidade do evento',
		gdprTextSecond: 'e',
		gdprTextTerms: 'Termos e Condições do Evento',
		gdprTextAgree: 'Concorde com a política de privacidade',
		registerButtonFree: 'Finalizar registro',
		registerButtonPay: 'Prossiga para o pagamento',
	},
	stripePayment: {
		cardNumber: 'Número do cartão',
		expirationDate: 'Data de vencimento (MM/AA)',
		cvc: 'Código de Segurança',
		disclaimerText:
			'Esta operação de pagamento é criptografada de ponta a ponta Segurança e privacidade são garantidas por Stripe Services',
		disclaimerTextLink: 'Clique aqui para saber mais',
		cancelButton: 'CANCELAR',
		payButton: 'Pagamento',
	},
	recoverPassword: {
		recoverTextSend: 'E-mail de recuperação de senha enviado com sucesso.',
	},
	unsubscribe: {
		unsubscribeTitle: 'Cancelar assinatura?',
		unsubscribeHomepage: 'Ir para a página inicial',
	},
	notAvailable: {
		title: 'O evento está indisponível',
		description:
			'O evento que está a procurar está indisponível. Ou foi fechado pelo organizador ou desativado por um administrador',
		fullOverlayEvents: 'Verifique os seus outros eventos',
		fullOverlayNoEvents: 'Não foram encontrados outros eventos',
	},
	notFound: {
		notFoundDescription: 'Opa... Nenhum evento pode ser encontrado aqui!',
		notFoundHomepage: 'Ir para a página inicial',
	},
	eventMenu: {
		organizerButton: 'ORGANIZADOR',
		coOrganizerButton: 'GERIR',
		exhibitorButton: 'EXPOSITOR',
		posterStudyButton: 'CARTAZ / ESTUDO',
		boothButton: 'ESTANDE',
		boothsButton: 'ESTANDES',
		homeButton: 'PÁGINA INICIAL',
		contactsButton: 'CONTATOS',
		meetingsButton: 'REUNIÕES',
		chatsButton: 'CHATS',
		programButton: 'PROGRAMA',
		archiveButton: 'ARQUIVO',
		myAccountButton: 'MINHA CONTA',
		lobbyButton: 'Lobby',
		roomsButton: 'SALAS',
		connectionProblemTextFirst:
			'Parece haver um problema de conexão Por favor clique',
		connectionProblemButton: 'AQUI!',
		connectionProblemTextSecond: 'ou atualize a página',
		sessionProblemTextFirst: 'Sua sessão expirou Por favor clique',
		sessionProblemButton: 'AQUI!',
		sessionProblemTextSecond: 'para fazer o login novamente',
	},
	myEventsDropdown: {
		title: 'MEUS EVENTOS',
		createButtonMobile: 'CRIAR',
	},
	myBoothsDropdown: {
		title: 'MEUS ESTANDES',
		emptyStudyTitle: 'Stand do Cartaz/Estudo',
	},
	contactsDropdown: {
		title: 'SEUS CONTATOS',
		requestsTitle: 'Solicitações de contato',
		rejectButton: 'REJEITAR',
		acceptButton: 'ACEITAR',
		pendingButton: 'Pendente',
		addButton: 'Adicionar',
		noContacts:
			'Você ainda não tem contatos Visite a seção de Networking para começar a construir sua lista de contatos.',
	},
	meetingsDropdown: {
		meetingWith: 'Reunião com',
		pending: 'Pendente',
		accepted: 'Aceitada',
		canceled: 'Cancelada',
		noMeetings: 'Nenhuma reunião agendada ainda',
		finished: 'Terminado',
	},
	programDropdown: {
		stages: 'Estágios',
		with: 'z',
		noProgram: 'Obecnie brak wpisów programu.',
		fullProgramButton: 'Pełny program', // new translation
		addToCalendarButton: 'Dodaj do kalendarza', // new translation
		addedToYourAgenda: 'DODANE DO TWOJEGO PROGRAMU',
		addEventButton: 'Dodaj wydarzenie', // new translation
		addToMyAgenda: 'Dodaj do mojego programu', // new translation
		addEventToYourCalendarDialogTitle: 'Dodaj wydarzenie do swojego kalendarza', // new translation
		addTimeslotToYourCalendar: 'Dodaj przedział czasowy do swojego kalendarza', // new translation
		tip: 'PORADA: ', // new translation
		tipDescription:
			'Możesz również dodawać oddzielne przedziały czasowe do swojego kalendarza, klikając na ikonę kalendarza obok każdego przedziału czasowego.', // new translation
		tipTimeslotDescription:
			'Możesz również dodać całe wydarzenie, klikając przycisk "Dodaj do kalendarza".', // new translation
		durationEventTitle: 'Czas trwania wydarzenia: ', // new translation
		timeslotTitle: 'Tytuł: ', // new translation
		slotScheduled: 'Zaplanowany przedział czasowy: ', // new translation
		addSlotToCalendar: 'Dodaj przedział czasowy do kalendarza', // new translation
		speakers: 'Prelegenci', // new translation
		auditoriumName: 'Nazwa audytorium', // new translation
		timezoneTooltipText:
			'Program jest wyświetlany w strefie czasowej organizatora. Możesz zmienić strefę czasową wyświetlania, aby zobaczyć sesje w preferowanej strefie czasowej', // new translation
		liveLabel: 'Na żywo', // new translation
		liveLabelTooltipText:
			'Ten przedział czasowy jest obecnie na żywo w audytorium', // new translation
		openAuditorium: 'OTWÓRZ AUDYTORIUM', // new translation
	},
	notificationsDropdown: {
		title: 'Notificações',
		contactRequestReceived: 'enviou-lhe uma solicitação de contato. Confira!',
		contactRequestAccepted:
			'aceitou sua solicitação de contato Comece a conversar!',
		meetingRequestReceived: 'enviou-lhe um pedido de reunião Confira!',
		meetingRequestAccepted: 'aceitou o seu pedido de reunião. Confira!',
		meetingRequestCanceled: 'cancelou um pedido de reunião. Confira!',
		meetingStarting: 'Uma reunião começará em breve. Confira!',
		newBoothPost: 'postou no mural do seu estande Confira!',
		noNotifications: 'Você ainda não tem notificações',
		seeAll: 'VER TODOS',
		hideAll: 'OCULTAR TODOS',
		meetingWillStartTextSecond: 'começará logo. Confira!',
	},
	chatsDropdown: {
		title: 'CHATS',
		newChatProvideText:
			'Indique um nome para a sua conversa. Ser-lhe-á possível posteriormente editar o nome',
		noChats:
			'Crie pelo menos um chat para conversar com os participantes do evento',
		members: 'Membros',
		noOpenedChatText: 'Clique em um chat para continuar a conversa',
		noMessagesInChatTextFirst: 'Quebrar o gelo',
		noMessagesInChatTextSecond: 'Inicie esta conversa',
		newChatNameInput: 'Nome do bate-papo (opcional)',
		newChatAddMembers: 'Adicione contatos e outros participantes a este chat',
		editChatAddMembers:
			'Pesquise os usuários deste evento que você deseja adicionar ao chat em grupo.',
		newChatSearchInput: 'Pesquisar usuários',
		newChatNoUsersMessage: 'Usuários não encontrados',
		newChatRemoveUserButton: 'Remover',
		newChatCancelButton: 'FECHAR',
		newChatCreateButton: 'CRIAR',
		membersLeaveButton: 'SAIR',
		membersUpdateButton: 'ATUALIZAR',
		membersChatButton: 'Bate-papo',
		chatSettingsButton: 'Configurações',
		newMessageInput: 'Digite a mensagem aqui',
		newVideoCallTooltipText: 'Clique aqui para iniciar um chat por vídeo',
		leaveVideoMeetingTitle: 'Sair da reunião de vídeo',
		leaveVideoMeetingDescription:
			'Estás prestes a sair da reunião de vídeo. Tem a certeza que quer continuar?',
		leaveChatConfirmationTitle: 'Sair da chamada de vídeo',
		leaveChatConfirmationText:
			'Tem a certeza que quer sair da chamada de vídeo atual?',
	},
	myAccountDropdown: {
		title: 'MINHA CONTA',
		viewProfileButton: 'Ver perfil',
		logoutButton: 'SAIR',
	},
	myAccountPage: {
		changePasswordButton: 'MUDAR SENHA',
		deleteAccountButton: 'DELETAR MINHA CONTA',
		platformLanguage: 'Linguagem da plataforma',
		privacyPolicyLink: 'Aviso / política de privacidade',
		cookiesLink: 'Cookies',
		termsAndConditionsLink: 'Termos e Condições',
		eventProfileSectionTitle: 'Perfil do evento',
		eventPackage: 'Pacote do evento',
		unregisterFromEventButton: 'CANCELAR O REGISTRO NO EVENTO',
		unregisterCurrentEventButton: 'Cancelar o registro no evento atual',
		unregisterCurrentEventConfirmation:
			'Por favor, confirme que você deseja cancelar o registro do evento',
		enableParticipantProfile: 'Habilitar perfil de participante',
		editUserDialogTitle: 'Editar informações do usuário',
		editExhibitorDialogTitle: 'Editar detalhes do contato na empresa',
		editScholarDialogTitle:
			'Editar o os detalhes da pessoa de contacto do cartaz / estudo',
		editParticipantDialogTitle: 'Editar detalhes do participante',
		firstName: 'Nome',
		lastName: 'Sobrenome',
		uploadImage: 'Carregar imagem',
		removeImage: 'Remover imagem',
		deleteError:
			'Você não pode excluir sua conta. Exclua todos os eventos criados para poder excluir sua conta.',
		deleteSuccess: 'Sua conta foi excluída.',
		reviewEventPrivacyPolicy:
			'Confira nossa Política de Privacidade do Evento:',
		title: 'Título',
		company: 'Empresa',
		phone: 'Telefone',
		platformProfileSectionTitleTextSecond: 'perfil',
		createParticipantProfile: 'Criar perfil do participante',
		reviewPlatformConditions: 'Rever as condições da nossa plataforma',
		reviewEventConditions: 'Rever as condições do nosso evento',
	},
	changePassword: {
		title: 'MUDAR SENHA',
		oldPassword: 'Senha antiga',
		oldPasswordError: 'Senha antiga não confere!',
		inputPassword: 'Nova senha',
		inputConfirmPassword: 'Confirmar senha',
	},
	roomsDropdown: {
		auditoriumButton: 'Auditório',
		archiveButton: 'ARQUIVO',
		onDemandButton: 'POR PEDIDO',
	},
	sideMenu: {
		liveWall: 'Mural ao vivo',
		networking: 'Networking',
		audience: 'AUDIÊNCIA',
		info: 'INFORMAÇÕES',
		videoWall: 'Mural de vídeo',
		booth: 'ESTANDE',
		boothWall: 'Mural do estande',
		networkingTooltip:
			'Você não tem acesso a todas as funcionalidade de Networking',
		attendees: 'Participantes',
	},
	wall: {
		sortBy: 'Ordenar por:',
		popularity: 'POPULARIDADE',
		time: 'TEMPO',
		emptyWallText:
			'Seja o primeiro a adicionar uma postagem aqui.\nDiga-nos o que está a pensar ou faça a sua apresentação resumidamente',
		newPostInput: 'No que você está pensando?',
		newCommentInput: 'Comentar',
		resourcesVideoDescription: 'DESCRIÇÃO EM VÍDEO',
		resourcesLinks: 'Links',
		resourcesFiles: 'Arquivos',
		pinTitle: 'Marcar',
		unPinTitle: 'Desmarcar',
		deleteDialogTitle: 'Apagar publicação?',
		pinDialogDescription:
			'Já existe uma publicação marcada. Só pode ser marcada uma publicação de cada vez. Se continuar, a publicação anterior será automaticamente desmarcada.',
		edited: 'Editado',
		escapeToCancel: 'escape para cancelar',
		enterToSave: 'enter para guardar',
		editingState: 'A editar a mensagem',
		bannerModeration:
			'As publicações e comentários neste mural estão a ser moderados para garantir conteúdo de qualidade',
		pendingApproval: 'Aprovação pendente',
		approvePost: 'Aprovar publicação',
		approvePostComment: 'Aprovar comentário',
		commentsPendingApproval: 'Aprovação pendente dos comentários',
	},
	networking: {
		searchInput: 'Pesquisar por usuário',
		emptyNetworkingSearchResults:
			'A pessoa que procura não se inscreveu para este evento online',
		emptyAuditoriumNetworkingSearchResults:
			'A pessoa que procura ainda não participou nesta apresentação',
		alphabetical: 'abc',
		online: 'online',
		matching: 'correspondências',
		matchingAlgo:
			'Algoritmo de correspondência em ação. Dê-nos um minuto enquanto selecionamos as suas melhores combinações!',
		searchFilters: 'Filtros de pesquisa',
		applyFilterTooltip: 'Para aplicar filtros selecione pelo menos 1 opção',
		chat: 'CONVERSA',
	},
	closedAuditoriumDialog: {
		title: 'Informações do auditório',
		contentPackage: 'Seu pacote de acesso não tem acesso a nenhum auditório',
	},
	closedShowfloorDialog: {
		title: 'Informações da Sala de exposições',
		contentPackage:
			'O seu pacote de acesso não tem acesso a quaisquer Salas de Exposições.',
		button: 'OK',
	},
	auditorium: {
		willStart: 'A próxima sessão começará em...',
		noAccess: 'Seu pacote de acesso não tem acesso a este auditório',
		noVideos: 'Nenhum vídeo está agendado neste auditório',
		stageTab: 'PALCO',
		videoWallButton: 'MURAL DE VÍDEO',
		resourcesButton: 'RECURSOS',
		slotEndsTextFirst: 'Este horário de programa termina em',
		slotEndsTextSecondUpdated: 'A apresentação estará disponível por pedido.',
		slotTitleWith: 'com',
		allAuditoriums: 'Todos os auditórios',
		onDemandButton: 'POR PEDIDO',
		hide: 'Ocultar',
		leavePopupTitle: 'Sair do palco?',
		leavePopupDescription: 'Ao sair do auditório, será removido da sessão',
		leaveSession: 'Sair do palco',
		cancelDescription: 'Ao sair, deixará de ser um orador. Deseja continuar?',
		settings: 'Definições',
		minimize: 'Minimizar',
		microphone: 'Microfone',
		camera: 'Câmara',
		auditoriums: 'Auditórios',
	},
	auditoriumArchive: {
		title: 'Arquivo de Auditórios',
		titleEvent: 'Arquivo de Eventos',
		with: 'com',
		noVideosAvailable: 'Todas as sessões terminadas',
	},
	videoPlayer: {
		videoNotPlayingError:
			'Tem problemas com a transmissão de vídeo? Recarregue.',
		videoEndedTextFirst: 'O vídeo terminou. Obrigado por ver!',
		videoEndedTextSecond:
			'O mural do vídeo está aberto para Perguntas e Respostas.',
		tooltipPlay: 'Reproduzir',
		tooltipPause: 'Pausa',
		tooltipMinimize: 'Minimizar',
		tooltipFullscreen: 'Ecrã inteiro',
		tooltipMute: 'Sem som',
		tooltipUnmute: 'Ativar som',
		videoStreamNotStarted: 'Ao vivo em breve. Fique atento!',
	},
	showfloor: {
		allExhibitorsButton: 'TODOS OS EXPOSITORES',
		featuredExhibitorsText: 'EXPOSITORES EM DESTAQUE',
		boothLinksDialogTitle: 'LIGAÇÕES DE STAND',
		boothFilesDialogTitle: 'FICHEIROS DE STAND',
		boothVideoDialogTitle: 'VÍDEO',
		mobileTabChat: 'CONVERSA',
		mobileTabPoster: 'CARTAZ',
		mobileTabFiles: 'FICHEIROS',
		mobileTabLinks: 'LIGAÇÕES',
		mobileTabVideo: 'VÍDEO',
		noExhibitorsTextFirst: 'OS EXPOSITORES ESTÃO A TRABALHAR NOS STANDS DELES',
		noPresentersTextFirst:
			'Os apresentadores estão a trabalhar nos stands deles',
		noExhibitorsTextSecond: 'FIQUE ATENTO',
		showShowfloorsButton: 'TODAS AS SALAS DE EXPOSIÇÃO',
		showShowfloorsPosterButton: 'TODOS OS CARTAZES/ESTUDOS',
		showShowfloorsExhibitorButton: 'TODOS OS EXPOSITORES',
		searchExhibitors: 'Procurar expositores',
		searchPosters: 'Procurar por título ou nome do autor',
		searchExhibitorsMultiple: 'Procurar expositores nesta sala de exposição',
		searchPostersMultiple:
			'Procurar por título ou nome do autor nesta sala de exposição',
		showfloorsTitle: 'Salas de exposição',
		collapseList: 'Fechar lista',
		seeFullList: 'Ver lista completa',
		emptyExhibitorsResults:
			'O stand procurado não está presente.\n Mas vamos convidá-los na próxima vez',
		emptyPostersResults:
			'O cartaz/estudo procurado não está presente.\n Mas vamos convidá-los na próxima vez',
		posters: 'cartazes',
		allPostersStudiesTitle: 'Todos os Cartazes/Estudos',
		authors: 'autores',
		titleNotAvailable: 'Título não disponível',
		abstractNotAvailable: 'Resumo não disponível',
		authorsNotAvailable: 'Autores não disponíveis',
		openPosterFile: 'Abrir ficheiro do cartaz',
		fileIsNotAvailable: 'Ficheiro não está disponível',
		posterBooth: {
			study: 'Cartaz/Estudo',
			authors: 'Autores',
			highlights: 'Resumo',
			scrollInfoText: 'DESLOCAR PARA BAIXO PARA LER O RESUMO COMPLETO',
		},
	},
	eventArchive: {
		title: 'Arquivo de vídeo',
		searchText: 'Tocar para procurar',
		noArchivedEvents: 'Atualmente não há eventos arquivados.',
	},
	exhibitorDashboard: {
		event: 'Evento',
		title: 'Gerir o seu stand',
		visitorsButton: 'Visitantes do stand',
		goToBoothPreview: 'Pré-visualização do stand',
		previewButton: 'Pré-visualizar',
		tabStyle: 'Estilo do stand',
		tabLogo: 'Logótipo',
		tabStudyDetails: 'Cartaz / Estudo',
		tabFiles: 'Ficheiros',
		tabStudyFile: 'Ficheiro',
		tabLinks: 'Ligações',
		tabAvatars: 'Avatars',
		tabStudyAuthors: 'Autores',
		tabVideo: 'Vídeo',
		shareText: 'KOPIUJ URL',
		theBoothUrlCopiedText:
			'Adres URL Booth został pomyślnie skopiowany do schowka',
		optionDisabledText: 'Ta opcja jest obecnie niedostępna dla tego stoiska',
		boothStyleTab: {
			optionOne: 'Opção 1 - Escandinavo',
			optionTwo: 'Opção 2 - Moderno',
			optionThree: 'Opção 3 - Dinâmico',
			optionFour: 'Opção 4 - Personalizado',
			optionScholarOne: 'Opção 1 - Einstein',
			optionScholarTwo: 'Opção 2 - Curie',
			optionScholarThree: 'Opção 3 - da Vinci',
		},
		logoTab: {
			fileUploadButton: 'Clique aqui para carregar',
			noFile: 'Nenhum ficheiro escolhido',
		},
		posterStudyTab: {
			posterStudyTitle: 'Título',
			studyTitleInputLabel: 'Título do Cartaz / Estudo',
			highlightAbstractTitle: 'Destaque/Resumo',
			studyHighlightsInputLabel: 'Pesquisar destaques / resumo',
			highlightAbstractError:
				'Atingiu o limite máximo de caracteres permitido! (500 caracteres)',
			authorNameLabel: 'Nome do autor',
			authorAffiliationLabel: 'Afiliação',
		},
		filesTab: {
			title: 'Documentos:',
			fileNameInput: 'Nome do documento (visível para todos os participantes)',
			chooseFileButton: 'Escolher ficheiro',
			noDocAdded: 'Sem documentos adicionados',
			fileError: 'Adicione um nome do documento',
		},
		fileStudyTab: {
			title: 'Carregar ficheiro pdf',
			description:
				'Carregar cartaz: papel ou apresentação para ficar disponível no seu stand (Limite do ficheiro 20 Mb)',
			uploadPdfInputLabel: 'Carregar ficheiro PDF',
			uploadNewPdfInputLabel: 'Carregar novo ficheiro PDF',
			uploadedFile: 'Carregado',
			dragContainerTitle: 'Arraste simplesmente o ficheiro para aqui',
			dragContainerNewTitle: 'Arraste simplesmente o novo ficheiro para aqui',
		},
		linksTab: {
			social: {
				title: 'Ligações das redes sociais',
				subtitle: '(visível para todos os participantes)',
				facebookInput: 'Inserir a sua ligação do Facebook',
				linkedinInput: 'Inserir a sua ligação do LinkedIn',
				twitterInput: 'Inserir a sua ligação do Twitter',
				googleScholarInput: 'Inserir a sua ligação do Google Scholar',
				researchGateInput: 'Inserir a sua ligação do Research Gate',
			},
			other: {
				linkInput: 'URL da ligação',
				linkLabel: 'Etiqueta da ligação',
			},
		},
		linksStudyTab: {
			authorProfiles: 'Perfis do autor',
			additionalLinks: 'Ligações adicionais',
		},
		avatarsTab: {
			mainRepresentatives: {
				title: 'Representantes do stand',
				leftRepresentative: 'Representante esquerdo',
				rightRepresentative: 'Representante direito',
				addDialog: {
					title: 'Adicionar Avatar',
					titleScholar: 'Adicionar autor',
					editAvatarsTitle: 'Editar Avatar',
					editAuthorTitle: 'Editar autor',
					contentLine1: 'Escolher um Avatar da galeria abaixo',
					contentLine2: 'Carregar o seu Avatar personalizado.',
					contentLine3: 'Necessita de ajuda? Entrar em contacto com',
					assignButtonText: 'Atribuir',
					assignText:
						'Atribuir um participante do evento para ser associado ao representante acima.',
					assignErrorBooth:
						'O utilizador já foi adicionado como representante do stand',
					assignErrorAdditional:
						'O utilizador já foi adicionado como representante adicional',
					input: 'Procurar por nome',
				},
			},
			additionalRepresentatives: {
				title: 'Representantes adicionais',
				subtitle: '(opcional, será exibido com a imagem de perfil deles)',
				addDialog: {
					title: 'Escolher representante adicional',
					titleScholar: 'Escolher coautor',
					assignText:
						'Comece a procurar um participante do evento para o/a adicionar como representante.',
					assignError:
						'O utilizador já foi adicionado como representante do stand!',
				},
			},
			author: {
				title: 'Apresentar o autor',
				subtitle:
					'Adicionar apenas um autor que se registou para o evento. Pode adicionar 1 autor.',
				mainRepresentative: 'Representante principal',
			},
			coAuthors: {
				title: 'Coautores',
				singularTitle: 'Coautor',
				subtitle:
					'Adicionar apenas coautores que se registaram para o evento. Pode adicionar até 4 coautores.',
			},
		},
		videoTab: {
			video: {
				title: 'Vídeo do stand',
				subtitle: '(Apenas ligação do vídeo do YouTube)',
				youtubeOrVimeoOnly: '(Apenas ligação do vídeo do YouTube ou Vimeo)',
				input: 'Inserir URL do YouTube',
				inputYoutubeOrVimeo: 'Inserir URL do YouTube ou Vimeo',
			},
			image: {
				subtitle:
					'(A imagem será exibida no seu stand no caso de não ter adicionado a ligação do vídeo na secção acima)',
			},
		},
	},
	boothDashboard: {
		event: 'Evento',
		title: 'O seu stand',
		manage: 'Gerir',
		statistics: 'Estatísticas',
		uniqueVisitors: 'Visitantes Únicos', // new translation
		currentOnlineVisitors: 'Visitantes On-line Atuais', // new translation
		totalNumberOfVisits: 'Número Total De Visitas', // new translation
		totalDocumentClicks: 'Total De Cliques No Documento', // new translation
		totalLinkClicks: 'Total De Cliques Em Links', // new translation
		totalRepresentativesClicks: 'Total De Cliques De Representantes', // new translation
		totalVideoViews: 'Total De Exibições De Vídeo', // new translation
		totalPostsCount: 'Contagem Total De Postagens', // new translation
		totalLikesCount: 'Contagem Total De Curtidas', // new translation
		totalCommentsCount: 'Contagem Total De Comentários', // new translation
		showUsers: 'Mostrar Usuários', // new translation
		dataType: 'Tipo De Dados', // new translation
		value: 'Valor', // new translation
		posts: 'Postagens', // new translation
		likes: 'Curtidas', // new translation
		comments: 'Comentários', // new translation
		topPostLikes: 'Postagens Mais Curtidas', // new translation
		topPostComments: 'Principais Comentários De Postagem', // new translation
		totalVotes: 'Votos Totais', // new translation
	},
	polls: {
		polls: 'INQUÉRITOS',
		noPolls: 'Ainda não foram adicionados inquéritos.',
		pollProgress: 'Inquérito em progresso',
		pollClosed: 'Inquérito fechado',
		pollVoted: 'participantes votados',
	},
	onDemandVideos: {
		sectionTitle: 'POR PEDIDO',
		noOnDemandVideos: 'Ainda não foram adicionados vídeos por pedido.',
	},
	meetings: {
		meeting: 'REUNIÃO',
		createDialog: {
			createTitle: 'Pedir reunião com',
			inputDate: 'Data',
			inputStart: 'Inicia',
			inputEnd: 'Termina',
			inputTimezone: 'Zona Horária',
			inputNote:
				'Nota: Apenas pode ser agendada uma reunião de cada vez com a mesma pessoa.',
			meetingError: 'Data ou hora de reunião inválida Verifique de novo!',
			buttonDisabled: 'Pode agendar uma reunião depois de iniciar a conversa!',
		},
		banner: {
			pending: 'Pedido de reunião',
			accepted: 'Reunião marcada para',
			progress: 'Reunião em progresso',
			cancelTitle: 'Cancelar reunião?',
			cancelDescription: 'Tem a certeza que pretende cancelar esta reunião?',
			startCall: 'INICIAR CHAMADA',
			joinCall: 'ADERIR',
			pendingOwner: 'Pedido de reunião pendente para',
			videoCallStarted: 'Reunião por vídeo iniciada',
			videoCallStartedInfo:
				'As reuniões por vídeo funcionam melhor para até 10 pessoas',
		},
		title: 'As suas reuniões',
		meetingsInfo:
			'As reuniões podem ser agendadas através da funcionalidade Conversa. Inicie uma conversa com o utilizador com que pretende agendar uma reunião.',
	},
	socialMediaShare: {
		event: 'evento',
		shareLabel: 'Partilhar',
		dialogTitleGeneral: 'Partilhar com a sua rede',
		dialogTitlePost: 'Partilhar a sua publicação',
		linkedinLabel: 'LinkedIn',
		facebookLabel: 'Facebook',
		twitterLabel: 'Twitter',
		emailLabel: 'E-mail',
		clipboardLabel: 'Área de transferência',
		clipboardSnackbarMessage: 'Ligação copiada para a área de transferência',
		linkedinButtonTitle: 'Partilhar no LinkedIn',
		facebookButtonTitle: 'Partilhar no Facebook',
		twitterButtonTitle: 'Partilhar no Twitter',
		emailButtonTitle: 'Partilhar por e-mail',
		clipboardButtonTitle: 'Copiar para a área de transferência',
		imAttending: 'Estou a participar em',
		interestedClick: 'Interessado em participar? Clicar aqui:',
	},
	videoConferences: {
		fullScreen: 'ABRIR ECRÃ TOTAL',
		minimizeScreen: 'MINIMIZAR ECRÃ',
		startedVideoChat: 'iniciou uma videochamada',
		timeAgo: 'atrás',
		videoCallStarted: 'Videochamada iniciada...',
	},
	sessions: {
		edit: 'EDITAR',
		speakers: 'ORADORES',
		speakerSingular: 'orador',
		moderators: 'MODERADORES',
		moderatorSingular: 'moderador',
		video: 'VÍDEO',
		startStream: 'INICIAR TRANSMISSÃO',
		stopStream: 'PARAR TRANSMISSÃO',
		sessionChat: 'CONVERSA DE SESSÃO',
		speakerUrl: 'URL do orador',
		moderatorUrl: 'URL do moderador',
		copyUrl: 'Copiar URL',
		noSpeakersJoined: 'Ainda não há oradores inscritos',
		allFilter: 'todos',
		waitingRoom: 'Sala de espera',
		isReady: 'Está pronto',
		invitationAccepted: 'Aceitou o convite',
		requestPending: 'Pedido pendente',
		gettingReady: 'A preparar-se',
		inviteToSpeak: 'Convite para falar',
		fromAuditorium: 'do Auditório',
		mic: 'Mic',
		screen: 'Ecrã',
		rec: 'grav',
		sessionHasEnded: 'A sessão terminou',
		sessionLinks: 'LIGAÇÕES DA SESSÃO',
		sessionSettings: 'Definições',
		noAvailableChat:
			'Nenhuma conversa disponível. Para ver a conversa atribua esta sessão a um período de tempo.',
		startLiveSessionStreamTitle: 'Iniciar transmissão da sessão ao vivo',
		startLiveSessionDescription:
			'A gravação da sessão começará automaticamente quando iniciar a transmissão',
		stopLiveSessionStreamTitle: 'Parar transmissão da sessão ao vivo',
		stopLiveSessionStreamDescription:
			'Ao interromper a transmissão da sessão ao vivo, a gravação também será interrompida automaticamente. Esta transmissão da sessão ao vivo não pode ser iniciada de novo.',
		thumbnailImage: 'Imagem em miniatura',
		uploadThumbnail: 'Carregar miniatura',
		clickToUpload: 'Clique aqui para carregar',
		requestMic: 'PEDIR MICROFONE',
		acceptInvitationTitle: 'Recebeu o convite',
		acceptInvitationSubTitle:
			'O moderador convidou-o para ser um orador. O que diz?',
		acceptInvitationButton: 'Aceitar convite',
		requestPendingTitle: 'Pedido está pendente...',
		requestPendingSubTitle:
			'Aguarde até que o moderador permita que entre na transmissão',
		cancelRequest: 'Cancelar pedido',
		approvedTitle:
			'Verifique o seu microfone e vídeo e clique em Estou pronto.',
		approvedSubTitle:
			'Esta sessão está a ser gravada. Ao entrar nesta sessão concorda com o tratamento dos seus dados pessoais.',
		connectedToTheBackstage:
			'Agora está a transmitir ao vivo e ligado aos bastidores.',
		removedFromTheSession: 'Foi banido da sessão, contacte um moderador.',
		imReady: 'Estou pronto',
		isTryingToRegister: 'está a tentar registar-se como um',
		hasLeftTheSession: 'saiu da sessão',
		stopVideo: 'Parar o vídeo',
		startVideo: 'Iniciar o vídeo',
		disabledScreenSharing:
			'Desativar a partilha do seu ecrã antes de iniciar o seu vídeo',
		disabledByModerator: 'Desativado pelo moderador',
		disabledUntilReady: 'Desativado até estar pronto',
		stopShareScreen: 'Deixar de partilhar o ecrã',
		shareScreen: 'Partilhar ecrã',
		disabledVideoSharing: 'Desativar o seu vídeo antes de partilhar o seu ecrã',
		anotherUserIsScreenSharing: 'Alguém já está a partilhar o ecrã',
		removeRoleDescription: 'Tem a certeza que pretende remover',
		declinedInvitation: 'recusou o convite para ser um orador',
	},
	gdpr: {
		disclaimerTextFirst:
			'Este documento foi traduzido por inteligência artificial. Em caso de inconsistência, consultar a versão em inglês do documento',
		disclaimerTextSecond: 'AQUI',
		metaTitlePrivacyPolicy: 'Política de privacidade',
		metaTitleCookiesPolicy: 'Política de cookies',
		metaTitleTerms: 'Termos e condições',
		updateCookieConsent: 'Atualizar o consentimento de cookies',
		changeYourConsent: 'Alterar o seu consentimento',
		withdrawYourConsent: 'Retirar o seu consentimento',
		withdrawSnackbarText: 'Consentimento de cookies retirado com sucesso',
	},
	infoMessages: {
		imageExplain420x200:
			'Proporção de imagem recomendada 1,8:1 (orientado para paisagem, por ex., 420 x 200 pixéis)',
		imageExplainTB230x320:
			'Proporção de imagem recomendada 1:1,4 (orientado para retrato, por ex., 230 x 320 pixéis) com fundo transparente',
		imageExplainTB230x875:
			'Proporção de imagem recomendada 1:3,8 (orientado para retrato, por ex., 230 x 875 pixéis) com fundo transparente',
	},
	matching: {
		matchingQuestionnaireTitle: 'Questionário de correspondência',
		matchingQuestionnaireIntro:
			'Vamos ajudá-lo a ligar-se com as pessoas mais relevantes para si! Acontecem coisas extraordinárias quando as mentes certas se encontram, preencha o questionário para começar',
		matchingQuestionnaireIntroSkip:
			'Ao ignorar o questionário, não o poderemos corresponder com os demais participantes do evento de hoje. Pode sempre concluir mais tarde o questionário na secção Funcionamento em rede. Todas as respostas são editáveis a partir de A minha conta.',
		doItLaterBtn: 'FAZER MAIS TARDE',
		nextBtn: 'Seguinte',
		backBtn: 'Voltar',
		finishBtn: 'Terminar',
		seeMatchesBtn: 'VER TODAS AS CORRESPONDÊNCIAS',
		tryMatchingBtn: 'TENTAR A CORRESPONDÊNCIA INTELIGENTE',
		optionalQuestion: 'Opcional',
		requiredQuestion: 'Obrigatório',
		singleSelect: 'Seleção individual',
		multiSelect: 'Seleção múltipla',
		mediumMatch: 'Correspondência média',
		highMatch: 'Correspondência elevada',
		matchLabel: 'Correspondência',
		matchesLabel: 'Correspondências',
		infoTooltipJump: 'Aceda aqui para ver as suas correspondências',
		infoTooltipClickToSee1: 'Clicar em',
		infoTooltipClickToSee2: 'para ver com quem se deve se ligar!',
		loadingAlgoText:
			'Algoritmo de correspondência em ação. Dê-nos um minuto enquanto selecionamos as suas melhores combinações!',
		matchingDetailsFormTitle: 'Detalhes da correspondência inteligente',
		tryMatchingBanner:
			'Faça uma ligação significativa, experimente a nossa funcionalidade Correspondência inteligente!',
		matchingBannerClosed:
			'Pode sempre preencher o formulário Correspondência inteligente em Funcionamento em rede em Correspondências',
		resultsTitle: 'Aqui estão suas principais correspondências',
		resultsNoteLabel: 'NOTA:',
		resultsNoteText:
			'Pode encontrar todas as suas correspondências na secção Funcionamento em rede.',
		resultsNoteMyAccount:
			'Todas as respostas são editáveis no menu A minha conta.',
		btwResultsResponsive:
			'A propósito, pode encontrar todas as suas correspondências no FUNCIONAMENTO EM REDE',
		noMoreMatches:
			'Oh, bolas! De momento não conseguimos encontrar mais correspondências para si. Verifique de novo quando mais participantes se juntarem',
		noResultsTitle:
			'Parabéns, conseguiu! Estamos à procura das suas melhores correspondências! ',
		noResults:
			'`Obrigado por experimentar a função Correspondência inteligente! Estamos a procurar as suas melhores correspondências e formaremos duplas assim que mais participantes se juntarem ao evento. Verifique o separador "Correspondências" na secção "Funcionamento em rede"`',
		clickStartChat: 'Clique em conversa para iniciar uma conversa',
		closeMatchingTitle: 'Fechar formulário de correspondência?',
		closeMatchingTextA:
			'Se fechar o formulário antes do preenchimento, não armazenaremos nenhum detalhe e os resultados da Correspondência inteligente não ficarão disponíveis para si. ',
		closeMatchingTextB:
			'O seu perfil não fará parte do processo de Correspondência inteligente e ninguém corresponderá consigo.',
		matchingTooltip:
			'Clicar em Correspondências para ver com quem se deve ligar!',
		myAccountMatchingSectionTitle: 'Detalhes da correspondência inteligente',
		matchingRequiredError: 'Tem que completar todas as perguntas obrigatórias',
		rejectMatchingTitle: 'Rejeitar a participação na Correspondência?',
		rejectMatchingText:
			'As suas respostas serão apagadas e não será correspondido com outros utilizadores. Pode refazer o questionário de correspondência a qualquer momento.',
		rejectMatchingBtn: 'REJEITAR PARTICIPAÇÃO',
	},
	welcomeScreen: {
		goToEvent: 'Ir para o evento',
	},
	emailCodeVerification: {
		codeSent: 'Enviámos um código de verificação para o seu e-mail:',
		continueRegistration: 'Para continuar o registo,',
		enterCodeBelow: 'inserir o código de verificação abaixo:',
		codeWillExpire: 'Este código vai expirar em',
		codeExpired: 'O código expirou',
		codeNotReceived: 'Não recebeu o código? Verificou a pasta de Spam?',
		resendTimer: 'Reenviar em:',
		resendLabel: 'Reenviar',
		resendCodeLabel: 'Reenviar o código',
		codeError: 'O código inserido está errado.',
		codeErrorFix:
			'Voltar a inserir o código correto do seu e-mail ou reenviar um novo código',
		congrats: 'Parabéns!',
		emailConfirmed: 'O seu e-mail confirmado:',
	},
	privacyPolicyUpdates: {
		learnMore: 'Saiba mais', // new translation
		lastUpdated: 'Última atualização', // new translation
		publishedBy: 'Publicado por', // new translation
		ppuLinkLabel: 'Política de privacidade atualizada', // new translation
		ppuEventLinkLabel: 'Política de privacidade do evento atualizada', // new translation
		acceptAllButtonLabel: 'Aceitar todas as atualizações', // new translation
	},
	voting: {
		votingText: 'VOTAÇÃO',
		enableVoting: 'ATIVAR VOTAÇÃO',
		enableVotingDescription:
			'Clique no ícone de atualização para obter resultados mais precisos',
		displayVotingNumbersForExhibitors:
			'EXIBIR NÚMEROS DE VOTAÇÃO PARA EXPOSITORES E PÔSTERES/ESTUDOS',
		displayVotingNumbersForExhibitorsDescription:
			'Será exibido no painel do expositor',
		displayVotingNumbersForParticipants:
			'EXIBIR NÚMEROS DE VOTAÇÃO PARA PARTICIPANTES',
		displayVotingNumbersForParticipantsDescription:
			'Será exibido para todos os participantes',
		characterError80:
			'Você atingiu o limite máximo de 80 caracteres permitidos',
		characterDescription:
			'O texto está limitado a 80 caracteres. O texto será exibido como título principal na faixa de votação. Certifique-se de que seja compreensível para os participantes',
		deleteAllVotes: 'EXCLUIR TODOS OS VOTOS',
		downloadVotingResults: 'BAIXAR RESULTADOS DA VOTAÇÃO',
		voteText: 'Votar',
		votedText: 'Votado',
		votesText: 'Votos',
		participant: 'Participante',
		participants: 'Participantes',
		deleteVotesTextFirst: 'ALERTA:',
		deleteVotesTextSecond: 'esta ação é irreversível.',
		deleteVotesTextThird:
			'Não se esqueça de baixar os resultados da votação antes de excluí-los.',
		checkTheBoxToCastYourVote: 'Marque a caixa para votar',
		toVotePleaseEnableParticipantProfile:
			'Para votar, ative seu perfil de participante',
	},
};

export default pt;
