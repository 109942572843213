import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { preventDefaultDrag } from '../../../Utils/utils';
import Radio from '@material-ui/core/Radio';
import axios from '../../../store/axios-instance';
import * as actions from '../../../store/actions';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import BrandingBackgroundImage from './LayoutComponents/BrandingBackgroundImage';
import PreviewComponent from '../../../SmallLayoutComponents/ImagePreview';
import Confirm from '../../../Dialogs/Confirm';
import variables from '../../../CSS/_variables.module.scss';
import {
	getSelectedLobbyOrAuditorium,
	fireClickEvent,
} from '../../../Utils/utils';
import { ReactComponent as SaveIcon } from '../../../Images/svg/save.svg';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Spinner from '../../../SmallLayoutComponents/Spinner';
import WindowView from './WindowView';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from '@material-ui/core';
import SnackbarGlobal from '../../../SmallLayoutComponents/Snackbars/SnackbarGlobal';
import CheckIcon from '@material-ui/icons/Check';

class BrandingRenders extends React.Component {
	wrapperRef = React.createRef();
	handleClickOutside = this.handleClickOutside.bind(this);
	state = {
		selectedLobby: getSelectedLobbyOrAuditorium(
			this.props.event.brandingData.lobbyRenderType
		),
		selectedAuditorium: getSelectedLobbyOrAuditorium(
			this.props.event.brandingData.auditoriumRenderType
		),
		lobbyOptions: [
			{ value: 'v1', label: 'Option 1 - Default' },
			{ value: 'v2', label: 'Option 2 - International' },
			{ value: 'v3', label: 'Option 3 - Academic' },
			{ value: 'v4', label: 'Option 4 - Green' },
			{ value: 'custom', label: 'Custom render' },
		],
		auditoriumOptions: [
			{ value: 'v1', label: 'Option 1 - Default' },
			{ value: 'v2', label: 'Option 2 - Academic' },
			{ value: 'v3', label: 'Option 3 - Green' },
			{ value: 'custom', label: 'Custom render' },
		],
		isAuditoriumPreviewOpen: false,
		isLobbyPreviewOpen: false,
		isAuditoriumSnackbarOpen: false,
		isLobbySnackbarOpen: false,
		auditoriumSnackError: false,
		lobbySnackError: false,
		openConfirmUnsavedChanges: false,
		navigationElement: null,
		loading: false,
	};

	componentDidMount() {
		this.initState();
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleAuditoriumPreviewOpen = () =>
		this.setState({ isAuditoriumPreviewOpen: true });
	handleAuditoriumPreviewClose = () =>
		this.setState({ isAuditoriumPreviewOpen: false });
	handleLobbyPreviewOpen = () => this.setState({ isLobbyPreviewOpen: true });
	handleLobbyPreviewClose = () => this.setState({ isLobbyPreviewOpen: false });
	handleOpenAuditoriumSnackbar = () =>
		this.setState({ isAuditoriumSnackbarOpen: true });
	handleCloseAuditoriumSnackbar = () =>
		this.setState({ isAuditoriumSnackbarOpen: false });
	handleOpenLobbySnackbar = () => this.setState({ isLobbySnackbarOpen: true });
	handleCloseLobbySnackbar = () =>
		this.setState({ isLobbySnackbarOpen: false });
	handleChangeAuditorium = (event) =>
		this.setState({ selectedAuditorium: event.target.value });
	handleChangeLobby = (event) =>
		this.setState({ selectedLobby: event.target.value });
	handleChangeAuditoriumOnEnter = (optionValue) =>
		this.setState({ selectedAuditorium: optionValue });
	handleChangeLobbyOnEnter = (optionValue) =>
		this.setState({ selectedLobby: optionValue });
	closeClickOutside = () => this.setState({ openConfirmUnsavedChanges: false });

	initState = () => {
		const { event } = this.props;
		this.setState({
			selectedLobby: getSelectedLobbyOrAuditorium(
				event.brandingData.lobbyRenderType
			),
			selectedAuditorium: getSelectedLobbyOrAuditorium(
				event.brandingData.auditoriumRenderType
			),
		});
	};

	handleEnterKey = (tab, optionValue) => (e) => {
		if (e.key === 'Enter') {
			if (tab === 'lobby') return this.handleChangeLobbyOnEnter(optionValue);
			if (tab === 'auditorium')
				return this.handleChangeAuditoriumOnEnter(optionValue);
		}
	};

	getNewNavigationElement = (e) => {
		const { navigationElement, openConfirmUnsavedChanges } = this.state;
		const isEventTargetNavigationELement = e.path?.find((pathElem) =>
			pathElem.getAttribute?.('data-is-navigation')
		);

		if (openConfirmUnsavedChanges) {
			return navigationElement;
		}

		if (isEventTargetNavigationELement) {
			return e.target;
		}

		return null;
	};

	handleClickOutside(e) {
		const { event } = this.props;
		const {
			selectedLobby,
			selectedAuditorium,
			isLobbyPreviewOpen,
			isAuditoriumPreviewOpen,
		} = this.state;
		if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
			if (
				(event.brandingData.lobbyRenderType !== selectedLobby &&
					selectedLobby !== 'custom' &&
					!(isLobbyPreviewOpen || isAuditoriumPreviewOpen)) ||
				(event.brandingData.auditoriumRenderType !== selectedAuditorium &&
					selectedAuditorium !== 'custom' &&
					!(isLobbyPreviewOpen || isAuditoriumPreviewOpen))
			) {
				this.setState({
					openConfirmUnsavedChanges: true,
					navigationElement: this.getNewNavigationElement(e),
				});
			}
		}
	}

	handleUnsavedChanges = async () => {
		const { navigationElement } = this.state;

		const { event } = this.props;
		const { selectedLobby, selectedAuditorium } = this.state;
		this.setState({ openConfirmUnsavedChanges: false });
		// if both lobby and auditorium have unsaved changes (save button enabled)
		if (
			event.brandingData.lobbyRenderType !== selectedLobby &&
			event.brandingData.auditoriumRenderType !== selectedAuditorium
		) {
			this.saveLobbyRender(selectedLobby)();
			this.saveAuditoriumRender(selectedAuditorium)();
		} else if (event.brandingData.lobbyRenderType !== selectedLobby) {
			// if only lobby has unsaved changes (save button enabled)
			this.saveLobbyRender(selectedLobby)();
		} else if (event.brandingData.auditoriumRenderType !== selectedAuditorium) {
			// if only auditorium has unsaved changes (save button enabled)
			this.saveAuditoriumRender(selectedAuditorium)();
		}

		if (navigationElement) {
			fireClickEvent(navigationElement);
		}
	};

	handleDiscardChanges = () => {
		const { navigationElement } = this.state;

		this.closeClickOutside();
		this.initState();

		if (navigationElement) {
			fireClickEvent(navigationElement);
		}
	};

	saveLobbyRender = (selectedRender) => async () => {
		this.setState({ loading: true });
		const { event } = this.props;
		this.setState({ lobbySnackError: false });
		// if there is a custom lobby render already uploaded, delete it then save the new option
		await axios({
			method: 'delete',
			url: `/event/${event._id}/branding/delete-image-render/`,
			data: { brandingProperty: 'lobbyRender' },
		}).then(() => this.props.onGetEvent(event._id));

		await axios({
			method: 'put',
			url: `/event/${event._id}/branding/render-types`,
			data: { renderProperty: 'lobbyRenderType', renderType: selectedRender },
		})
			.then(() => {
				this.closeClickOutside();
				this.props.onGetEvent(event._id);
				this.handleOpenLobbySnackbar();
				this.setState({ loading: false });
			})
			.catch(() => {
				this.setState({ lobbySnackError: true, loading: false }, () => {
					this.closeClickOutside();
					this.handleOpenLobbySnackbar();
				});
			});
	};

	saveAuditoriumRender = (selectedRender) => async () => {
		this.setState({ loading: true });
		const { event } = this.props;
		this.setState({ auditoriumSnackError: false });
		// if there is a custom auditorium render already uploaded, delete it then save the new option
		await axios({
			method: 'delete',
			url: `/event/${event._id}/branding/delete-image-render/`,
			data: { brandingProperty: 'auditoriumRender' },
		}).then(() => this.props.onGetEvent(event._id));

		await axios({
			method: 'put',
			url: `/event/${event._id}/branding/render-types`,
			data: {
				renderProperty: 'auditoriumRenderType',
				renderType: selectedRender,
			},
		})
			.then(() => {
				this.closeClickOutside();
				this.props.onGetEvent(event._id);
				this.handleOpenAuditoriumSnackbar();
				this.setState({ loading: false });
			})
			.catch(() => {
				this.setState({ auditoriumSnackError: true, loading: false }, () => {
					this.handleOpenLobbySnackbar();
					this.handleOpenAuditoriumSnackbar();
				});
			});
	};

	render() {
		const { event } = this.props;
		const {
			lobbyOptions,
			auditoriumOptions,
			selectedLobby,
			selectedAuditorium,
			isAuditoriumPreviewOpen,
			isLobbyPreviewOpen,
			isAuditoriumSnackbarOpen,
			isLobbySnackbarOpen,
			auditoriumSnackError,
			lobbySnackError,
			openConfirmUnsavedChanges,
			loading,
		} = this.state;

		if (loading) {
			return <Spinner />;
		}
		return (
			<>
				<div
					ref={this.wrapperRef}
					onDragStart={preventDefaultDrag}
					className="branding-lobby"
				>
					<h4 className="advanced-options-title">CUSTOM RENDERS</h4>
					<div
						onDragStart={preventDefaultDrag}
						className="advanced-options-container"
					>
						<p onDragStart={preventDefaultDrag} className="inner-options-title">
							MAIN LOBBY RENDER
						</p>
						<div className="custom-renders-wrapper">
							<FormControl component="fieldset">
								<RadioGroup
									aria-label="position"
									name="position"
									value="lobby"
									onChange={this.handleChangeLobby}
								>
									{lobbyOptions.map(({ value, label }, optionIndex) => {
										const isChecked = value === selectedLobby;
										return (
											<div
												className={
													'd-flex align-items-center custom-render-radio'
												}
											>
												<FormControlLabel
													tabIndex="0"
													onKeyDown={this.handleEnterKey('lobby', value)}
													key={optionIndex}
													data-checked={isChecked}
													value={value}
													classes={{ root: 'radio-fix' }}
													control={<Radio tabIndex="-1" checked={isChecked} />}
													label={label}
													labelPlacement="end"
												/>
												{value === 'custom' && (
													<Tooltip
														arrow
														title={
															'Custom render may not support Window view functionality, if updated Lobby design was requested from Unstoppabl.ai. \n' +
															'For more details contact: design@unstoppabl.ai'
														}
														placement="bottom-end"
														id={'custom-render-tooltip'}
													>
														<InfoIcon
															style={{ color: '#FFC107' }}
															fontSize="small"
															className={'custom-info-icon'}
														/>
													</Tooltip>
												)}
											</div>
										);
									})}
								</RadioGroup>
							</FormControl>
							{selectedLobby !== 'custom' && (
								<Button
									type="button"
									onClick={this.saveLobbyRender(selectedLobby)}
									variant={'contained'}
									disabled={
										event.brandingData.lobbyRenderType === selectedLobby
									}
									startIcon={<SaveOutlinedIcon />}
									disableElevation
									color={'secondary'}
									className={'render-save-btn'}
								>
									SAVE
								</Button>
							)}
							<div
								onDragStart={preventDefaultDrag}
								className="branding-background-image-container lobby-section"
							>
								{selectedLobby !== 'custom' ? (
									<PreviewComponent
										selectedOption={selectedLobby}
										isPreviewOpen={isLobbyPreviewOpen}
										filesUrl={process.env.REACT_APP_RENDERS_FOLDER}
										handlePreviewOpen={this.handleLobbyPreviewOpen}
										handlePreviewClose={this.handleLobbyPreviewClose}
										event={event}
										lobby={true}
									/>
								) : (
									<BrandingBackgroundImage
										hasPreview
										for={'lobbyRender'}
										label={'Lobby 3D Render'}
										description={`Upload a custom 3D rendering which will be displayed as the background of the Lobby. For a custom 3D rendering please get in touch with the ${process.env.REACT_APP_PLATFORM_NAME} team at`}
										email={variables.emailCustomRender}
										subLabel={
											'Recommended image ratio 3 : 1 (landscape-oriented, e.g. 9000 x 3000 pixels).'
										}
										imageRatio={0.33}
										image={
											'lobbyRender' in this.props.event.brandingData &&
											this.props.event.brandingData.lobbyRender.preview
												? this.props.event.brandingData.lobbyRender.preview
												: null
										}
										filesUrl={this.props.event.brandingData.filesUrl}
										openSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
										openErrorSnackbar={this.props.handleOpenErrorSnackbar}
										uploadUrl={'branding/upload-image-render'}
										deleteUrl={'branding/delete-image-render/'}
										uploadLimit={20}
										lobby={true}
									/>
								)}
							</div>
						</div>
						<WindowView
							imageLeft={this.props.event.brandingData.leftWindowView}
							imageRight={this.props.event.brandingData.rightWindowView}
							filesUrl={this.props.event.brandingData.filesUrl}
						/>
					</div>

					{event.hasVillage ? (
						<div
							onDragStart={preventDefaultDrag}
							className="advanced-options-container"
						>
							<p
								onDragStart={preventDefaultDrag}
								className="inner-options-title"
							>
								VILLAGE RENDER
							</p>
							<div
								onDragStart={preventDefaultDrag}
								className="branding-background-image-container"
							>
								<BrandingBackgroundImage
									for={'villageRender'}
									label={'Village 3D Render'}
									description={`Upload a custom 3D rendering which will be displayed as the background of the Village. For a custom 3D rendering please get in touch with the ${process.env.REACT_APP_PLATFORM_NAME} team at`}
									email={variables.emailCustomRender}
									subLabel={
										'Recommended image ratio 3 : 1 (landscape-oriented, e.g. 9000 x 3000 pixels).'
									}
									imageRatio={0.33}
									image={
										'villageRender' in this.props.event.brandingData &&
										this.props.event.brandingData.villageRender.preview
											? this.props.event.brandingData.villageRender.preview
											: null
									}
									filesUrl={this.props.event.brandingData.filesUrl}
									openSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
									openErrorSnackbar={this.props.handleOpenErrorSnackbar}
									uploadUrl={'branding/upload-image-render'}
									deleteUrl={'branding/delete-image-render/'}
									uploadLimit={20}
								/>
							</div>
						</div>
					) : (
						<div
							onDragStart={preventDefaultDrag}
							className="advanced-options-container"
						>
							<p
								onDragStart={preventDefaultDrag}
								className="inner-options-title"
							>
								AUDITORIUM RENDER
							</p>
							<div
								onDragStart={preventDefaultDrag}
								className="branding-background-image-container"
							>
								<div className="custom-renders-wrapper">
									<FormControl component="fieldset">
										<RadioGroup
											aria-label="position"
											name="position"
											value="lobby"
											onChange={this.handleChangeAuditorium}
										>
											{auditoriumOptions.map(
												({ value, label }, optionIndex) => {
													const isChecked = value === selectedAuditorium;
													return (
														<FormControlLabel
															tabIndex="0"
															onKeyDown={this.handleEnterKey(
																'auditorium',
																value
															)}
															key={optionIndex}
															data-checked={isChecked}
															value={value}
															classes={{ root: 'radio-fix' }}
															control={
																<Radio tabIndex="-1" checked={isChecked} />
															}
															label={label}
															labelPlacement="end"
														/>
													);
												}
											)}
										</RadioGroup>
									</FormControl>
									{selectedAuditorium !== 'custom' && (
										<Button
											type="button"
											onClick={this.saveAuditoriumRender(selectedAuditorium)}
											variant={'contained'}
											disabled={
												event.brandingData.auditoriumRenderType ===
												selectedAuditorium
											}
											startIcon={<SaveOutlinedIcon />}
											disableElevation
											color={'secondary'}
											className={'render-save-btn'}
										>
											SAVE
										</Button>
									)}
									<div
										onDragStart={preventDefaultDrag}
										className="branding-background-image-container auditorium-section"
									>
										{selectedAuditorium !== 'custom' ? (
											<PreviewComponent
												optionType="auditorium"
												selectedOption={selectedAuditorium}
												isPreviewOpen={isAuditoriumPreviewOpen}
												filesUrl={process.env.REACT_APP_RENDERS_FOLDER}
												handlePreviewOpen={this.handleAuditoriumPreviewOpen}
												handlePreviewClose={this.handleAuditoriumPreviewClose}
												event={event}
											/>
										) : (
											<BrandingBackgroundImage
												hasPreview
												for={'auditoriumRender'}
												label={'Auditorium 3D Render'}
												description={`Upload a custom 3D rendering which will be displayed as the background of the Auditorium. For a custom 3D rendering please get in touch with the ${process.env.REACT_APP_PLATFORM_NAME} team at`}
												email={variables.emailCustomRender}
												subLabel={
													'Recommended image ratio 3 : 1 (landscape-oriented, e.g. 9000 x 3000 pixels).'
												}
												imageRatio={0.33}
												image={
													'auditoriumRender' in this.props.event.brandingData &&
													this.props.event.brandingData.auditoriumRender.preview
														? this.props.event.brandingData.auditoriumRender
																.preview
														: null
												}
												filesUrl={this.props.event.brandingData.filesUrl}
												openSuccessSnackbar={
													this.props.handleOpenSuccessSnackbar
												}
												openErrorSnackbar={this.props.handleOpenErrorSnackbar}
												uploadUrl={'branding/upload-image-render'}
												deleteUrl={'branding/delete-image-render/'}
												uploadLimit={20}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				{openConfirmUnsavedChanges && (
					<Confirm
						open={openConfirmUnsavedChanges}
						closeConfirm={this.closeClickOutside}
						dialogTitle={'Unsaved changes'}
						dialogDescription={
							'You have unsaved changes. Do you want to save them?'
						}
						dialogConfirmButtonLabel={'Save'}
						dialogCancelButtonLabel={'Cancel'}
						handleConfirm={this.handleUnsavedChanges}
						handleDiscardChanges={this.handleDiscardChanges}
					/>
				)}

				<SnackbarGlobal
					message={'Auditorium render changed successfully.'}
					snackbarOpen={isAuditoriumSnackbarOpen}
					handleCloseSnackbar={this.handleCloseAuditoriumSnackbar}
					icon={<CheckIcon />}
				/>
				<SnackbarGlobal
					message={
						lobbySnackError
							? 'Error changing render. Please try again.'
							: 'Lobby render changed successfully.'
					}
					snackbarOpen={isLobbySnackbarOpen}
					handleCloseSnackbar={this.handleCloseLobbySnackbar}
					icon={!lobbySnackError && <CheckIcon />}
					isError={lobbySnackError}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		event: state.event.data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onGetEvent: (eventId) => dispatch(actions.getEvent(eventId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandingRenders);
