const ar = {
	generalText: {
		upload: 'تحميل',
		save: 'حفظ',
		add: 'إضافة',
		remove: 'حذف',
		close: 'غلق',
		update: 'تحديث',
		cancel: 'إلغاء',
		confirm: 'تأكيد',
		download: 'تحميل',
		refresh: 'تحديث',
		back: 'عودة إلى الخلف',
		submit: 'إرسال',
		send: 'إرسال',
		leave: 'خروج',
		step1: 'الخطوة 1',
		step2: 'الخطوة 2',
		or: 'أو ',
		delete: 'الحذف',
		please: 'رجاءً',
		before: 'قبل',
		approve: 'الموافقة',
		decline: 'رفض',
		start: 'البدء',
		stop: 'إيقاف',
		resetFilter: 'إعادة تعيين عامل التصفية',
		apply: 'تطبيق',
		filters: 'عوامل التصفية',
		unsavedChangesTitle: 'تغييرات غير محفوظة',
		unsavedChangesText: 'لديك تغييرات غير محفوظة. هل ترغب في حفظها؟',
		selectAll: 'تحديد الكل',
		unselectAll: 'إلغاء تحديد الكل',
	},
	time: {
		justNow: 'الآن',
		minute: 'دقيقة',
		minutes: 'دقائق',
		hour: 'ساعة',
		hours: 'ساعات',
		day: 'يوم',
		days: 'الأيام',
		month: 'الشهر',
		months: 'الشهور',
		yesterday: 'الأمس',
		today: 'اليوم',
		tomorrow: 'غداً',
		monday: 'الإثنين',
		tuesday: 'الثلاثاء',
		wednesday: 'الأربعاء',
		thursday: 'الخميس',
		friday: 'الجمعة',
		saturday: 'السبت',
		sunday: 'الأحد',
		january: 'يناير',
		february: 'فبراير',
		march: 'مارس',
		april: 'أبريل',
		may: 'مايو',
		june: 'يونيو',
		july: 'يوليو',
		august: 'أغسطس',
		september: 'سبتمبر',
		october: 'أكتوبر',
		november: 'نوفمبر',
		december: 'ديسمبر',
		januaryShort: 'يناير',
		februaryShort: 'فبراير',
		marchShort: 'مارس',
		aprilShort: 'أبريل',
		mayShort: 'مايو',
		juneShort: 'يونيو',
		julyShort: 'يوليو',
		augustShort: 'أغسطس',
		septemberShort: 'سبتمبر',
		octoberShort: 'أكتوبر',
		novemberShort: 'نوفمبر',
		decemberShort: 'ديسمبر',
	},
	errors: {
		noFile: 'لم يتم اختيار ملف',
		required: 'الحقل إلزامي',
		emailNotValid: 'البريد الإلكتروني غير صحيح',
		passwordMinLength: 'يجب ألا تقل كلمة المرور عن 8 أحرف',
		passwordMismatch: 'كلمة السّر غير متطابقة',
		passwordIsSame: 'كلمة السر الجديدة الخاصة بك لا بد أن تكون مختلفة',
		noInvitationCode: 'يرجى إدخال رمز الدعوة الخاص بك',
		imageFileNotSupported: 'نوع  الملف غير مدعوم. الرجاء استخدام أي مما يلي:',
		phoneInvalid: 'يرجى إدخال رقم هاتف صالح. على سبيل المثال: +447517663928',
		linkInvalid: 'يرجى إدخال رابط صالح (https://site.com)',
		facebookLinkInvalid: 'يرجى إدخال رابط صالح (https://facebook.com/)',
		linkedinLinkInvalid: 'يرجى إدخال رابط صالح (https://linkedin.com/)',
		twitterLinkInvalid: 'يرجى إدخال رابط صالح (https://twitter.com/)',
		youtubeLinkInvalid: 'يرجى إدخال رابط صالح (https://www.youtube.com/)',
		youtubeOrVimeoLinkInvalid:
			'يُرجى إدخال رابط يوتيوب صالح (https://www.youtube.com) أو رابط فيميو صالح (https://vimeo.com/)',
		googleScholarLinkInvalid:
			'يرجى إدخال رابط صالح (https://scholar.google.com/)',
		researchGateLinkInvalid:
			'يرجى إدخال رابط صالح (https://www.researchgate.net/)',
		connectionProblemTextFirst: 'يبدو أن هناك مشكلة في الاتصال. الرجاء',
		connectionProblemButton: 'النقر',
		connectionProblemTextSecond: 'أو قم بتحديث الصفحة.',
		fileTooLarge: 'الملف كبير جداً، الحد الأقصى لحجم الملف هو 2 ميغا بايت',
		fileTooLargeTextFirst: 'الملف كبير جداً',
		fileTooLargeTextSecond: 'الحد الأقصى لحجم الملف.',
		fileNameTooLongTextFirst: 'اسم الملف طويل جدًا. الحد الأقصى هو ',
		fileNameTooLongTextSecond: '.. أحرف.',
		maxWallInputLength:
			'لقد وصلت إلى الحد الأقصى لعدد الحروف البالغ 1200 حرفًا.',
	},
	footer: {
		cookiesText:
			'يستخدم هذا الموقع ملفات تعريف الارتباط الأساسية بصورة ضرورية ليعمل الموقع بطريقة صحيحة. كما أنه يستخدم ملفات تعريف الارتباط الخاصة بالطرف الثالث، والتي تُعد ضرورية لتشغيل بعض ميزات موقعنا الإلكتروني. إذا كنت ترغب بمعرفة المزيد أو سحب موافقتك على بعض ملفات تعريف الارتباط، فيرجى العودة إلى',
		cookiesLink: 'سياسة ملفات تعريف الارتباط',
		cookiesButton: 'قبول',
		privacyPolicy: 'سياسة الخصوصية',
		cookies: 'ملفات تعريف الارتباط',
		termsAndConditions: 'الشروط والأحكام',
		socialLinksTitle: 'تابعنا عبر:',
	},
	menu: {
		logIn: 'تسجيل الدخول',
		logOut: 'تسجيل الخروج',
		scholarRegistration: 'انضم كملصق/ دراسة',
		exhibitorRegistration: 'انضم كعارض',
		participantRegistration: 'التسجيل',
		createEvent: 'نظم فعالية',
		loginDisabledInfoText:
			'لقد أغلق المنظم تسجيل الدخول. تحقق مرة أخرى في وقتٍ لاحقٍ.',
	},
	homepage: {
		organizedBy: 'بتنظيم من',
		date: 'التاريخ',
		location: 'المجال والموقع',
		price: 'السعر',
		tabIntro: 'المقدمة',
		tabProgram: 'البرنامج',
		tabSponsors: 'الرعاة',
		tabPartners: 'الشركاء',
		tabExhibitors: 'العارضون:',
		previousEventsSectionTitle: 'فعاليات سابقة',
	},
	socialButtons: {
		linkedinButton: 'تسجيل الدخول عبر لينكد إن',
		loginTabLinkedinButton: 'عبر لينكد إن',
		facebookButton: 'تسجيل الدخول عبر فيسبوك',
		loginTabFacebookButton: 'عبر فيسبوك',
		googleButton: 'تسجيل الدخول عبر جوجل',
		loginTabGoogleButton: 'عبر جوجل',
		twitterButton: 'تسجيل الدخول عبر تويتر',
		loginTabTwitterButton: 'عبر تويتر',
		socialButtonsLogInTitle:
			'انضم باستخدام ملفك التعريفي على مواقع التواصل الاجتماعي',
		socialButtonsSignUpTitle:
			'سجل حسابك باستخدام ملفك التعريفي على مواقع التواصل الاجتماعي',
	},
	login: {
		inputEmail: 'البريد الإلكتروني',
		inputPassword: 'كلمة المرور',
		rememberMeButton: 'تذكرني',
		forgotPasswordButton: 'هل نسيت كلمة السر؟',
		loginButton: 'تسجيل الدخول',
		loginClosedButton: 'تم غلق تسجيل الدخول',
		loginTitleLowerCase: 'سجل الدخول',
		recoverPasswordDialogTitle: 'استعادة كلمة المرور',
		recoverPasswordDialogRecoverButton: 'استعادة',
		errorIncorrectDates: 'بريد إلكتروني غير صحيح أو كلمة مرور غير صحيحة',
		orLogInWithEmail: 'أو سجل الدخول عبر البريد الإلكتروني',
		logInAsParticipantSecondText: 'كمشارك',
		logInAsExhibitorSecondText: 'كعارض',
		logInAsPosterSecondText: 'كملصق/ دراسة',
		errorAccountNotFound: 'لا يمكننا العثور على حسابك على موقع Unstoppabl.ai',
	},
	register: {
		inputFirstName: 'الاسم الأول',
		inputLastName: 'الاسم الأخير',
		inputEmail: 'البريد الإلكتروني',
		inputPassword: 'كلمة المرور',
		inputConfirmPassword: 'تأكيد كلمة المرور',
		gdprTextFirst: 'بالمتابعة، فإنك توافق على',
		gdprTextTerms: 'تعليمات الاستخدام',
		gdprTextSecond: 'وتؤكد أنك قد قرأت',
		gdprTextCookies: 'ملفات تعريف الارتباط',
		gdprTextPrivacy: 'سياسة الخصوصية',
		gdprTextAgree: 'يرجى الموافقة على سياسة الخصوصية',
		registerButton: 'تسجيل الحساب',
		signUpTitleLowerCase: 'سجّل',
		invitationCode: 'رمز الدعوة',
		registerNotAllowed: 'تم غلق التسجيل',
		logInTitleFirst: 'انضم إلى',
		logInTitleSecond: 'الفعالية',
		signUpTitleTextFirst: 'المرة الأولى على موقع',
		signUpTitleTextSecond: 'Unstoppabl.ai',
		orFillInTheForm: 'أو املأ النموذج أدناه',
		uploadProfilePicture: 'تحميل صورة الملف التعريفي',
		editProfilePicture: 'تحرير صورة الملف التعريفي',
		errorAlreadyHaveMOAccount: 'لديك بالفعل حساب على موقع Unstoppabl.ai',
		step1From2Text: '1/2 من الخطوات',
		step2From2Text: '2/2 من الخطوات',
		metaTitleScholarRegistration: 'تسجيل ملصق/ دراسة',
	},
	newLoginRegistrations: {
		joinEvent: 'الانضمام إلى الحدث',
		loginWelcome: 'مرحبًا بك في Unstoppabl.ai! للمتابعة ، حدد طريقة الدخول',
		continueButton: 'استمر',
		seemsLikeYouDontHaveMyOnventAccount:
			'يبدو أنك لا تملك حسابًا في Unstoppabl.ai',
		uploadProfilePictureOptional: 'تحميل صورة الملف الشخصي (اختياري)',
		createPlatformAccount: 'إنشاء حساب المنصة',
		platformAccount: 'حساب المنصة',
		selectRole: 'حدد الدور',
		eventAccount: 'حساب الحدث',
		password: 'كلمة السر',
		confirmPassword: 'تأكيد كلمة المرور',
		email: 'البريد الإلكتروني',
		passwordMustContain: 'يجب أن تحتوي كلمة المرور على:',
		minimum8Characters: '8 أحرف كحد أدنى',
		aLowerCaseLetter: 'حرف صغير',
		anUppercaseLetter: 'حرف كبير',
		aNumber: 'رقم',
		aSpecialCharacter: 'حرف خاص',
		firstName: 'الاسم الأول',
		lastName: 'الاسم الأخير',
		weHaveSentAVerificationCodeToYourEmail:
			'لقد أرسلنا رمز التحقق إلى بريدك الإلكتروني',
		continueRegistration: 'لمتابعة التسجيل ،',
		enterCodeBelow: 'أدخل رمز التحقق أدناه:',
		codeWillExpire: 'سينتهي هذا الرمز في',
		codeExpired: 'انتهت صلاحية الرمز',
		codeNotReceived: 'لم تستلم الرمز؟ هل تحققت من مجلد البريد المزعج؟',
		resendTimer: 'إعادة الإرسال خلال:',
		resendLabel: 'إعادة الإرسال',
		resendCodeLabel: 'إعادة إرسال الرمز',
		codeError: 'رمز التحقق الذي أدخلته غير صحيح.',
		codeErrorFix:
			'أعد إدخال الرمز الصحيح من بريدك الإلكتروني أو أعد إرسال رمز جديد',
		congrats: 'تهانينا!',
		emailConfirmed: 'تم تأكيد بريدك الإلكتروني:',
		imHereAs: 'أنا هنا كـ:',
		participant: 'مشارك',
		participantText:
			'لمشاهدة البث المباشر، التواصل، زيارة المعارض، وعرض البوسترات / الدراسات',
		exhibitor: 'معرض',
		exhibitorText: 'لعرض شركتك والتواصل مع حضور الحدث',
		poster: 'بوستر / دراسة',
		posterText: 'لعرض الأبحاث في مؤتمر أكاديمي أو مهني',
		emailIsNotAllowed: 'هذا البريد الإلكتروني غير مسموح بالتسجيل في هذا الحدث',
		didntReceiveEmail: 'هل لم تستلم البريد الإلكتروني؟',
		sendAgain: 'أعد الإرسال ',
		incorrectPassword: 'كلمة مرور خاطئة',
		fileTypeError:
			'نوع الملف غير مدعوم. يرجى استخدام أحد الأنواع التالية: jpeg، jpg، jfif، gif أو png.',
		fileSizeError: 'حجم الملف كبير جدًا. الحد الأقصى لحجم الملف هو 2 ميغابايت.',
		pleaseCompleteAllRequiredFields: 'يرجى إكمال جميع الحقول المطلوبة',
		joinTheEvent: 'الانضمام إلى الحدث',
		joinTheEventSub: 'لبدء تجربة الحدث الافتراضي عبر الإنترنت',
		changeEmail: 'تغيير البريد الإلكتروني',
		changeRole: 'تغيير الدور',
		logout: 'تسجيل الخروج',
		headingUser: 'مرحبا بعودتك',
		subHeadingUser: 'يبدو أن لديك حساب منصة بالفعل',
		forgotPasswordHeadline: 'هل نسيت كلمة المرور؟',
		forgotPasswordText: 'لا تقلق، سنرسل لك تعليمات إضافية',
		checkYourEmail: 'تحقق من بريدك الإلكتروني',
		checkYourEmailText: 'لقد أرسلنا رابط إعادة تعيين كلمة المرور إلى',
		headingNoUser: 'تسجيل الدخول',
		subHeadingNoUser:
			'مرحبًا بك في Unstoppabl.ai! للمتابعة، حدد طريقة تسجيل الدخول',
		resetPasswordButton: 'إعادة تعيين كلمة المرور',
		createNewPassword: 'إنشاء كلمة مرور جديدة',
		createNewPasswordText:
			'يجب أن تكون كلمة المرور الجديدة مختلفة عن كلمات المرور المستخدمة سابقًا',
		passwordReset: 'إعادة تعيين كلمة المرور',
		passwordResetText:
			'تم إعادة تعيين كلمة المرور بنجاح. انقر أدناه لتسجيل الدخول مرة أخرى.',
		registrationIsClosed: 'تم إغلاق التسجيل لجميع الأدوار.',
		chooseAnOption: 'اختر خياراً',
		yourAnswer: ' إجابتك ',
		firstNameError: 'اسم الأول قصير جداً. الحد الأدنى للطول: 2',
		lastNameError: 'اسم العائلة قصير جداً. الحد الأدنى للطول: 2',
		recoveryPassword: 'استعادة كلمة المرور',
		incorrectPasswordError:
			'كلمة مرور غير صحيحة. حاول مرة أخرى أو انقر على "نسيت كلمة المرور" لتغييرها.',
		optional: 'اختياري',
		spamEmailError:
			'هناك مشكلة في إنشاء حسابك. يرجى التحقق مرة أخرى في وقت لاحق.',
		invalidInput:
			"إدخال غير صالح. استخدم فقط الحروف (A-Z، a-z)، الأرقام (0-9)، النقطة (.)، الفاصلة (')، الواصلة (-)، والمسافات. يجب أن يكون أول حرف حرفًا.",
	},
	emailUpdate: {
		changeEmail: 'تغيير البريد الإلكتروني',
		changeEmailDescription:
			'قبل أن نعين البريد الإلكتروني الجديد لحسابك، نريد التأكد من أنه بريد إلكتروني جديد ولا ينتمي لأي شخص آخر.',
		emailTakenError: 'هذا البريد الإلكتروني مُستخدم بالفعل. جرّب بريدًا آخر.',
		enterTheConfirmationCode: 'أدخل رمز التأكيد',
		verificationCodeSent: 'تم إرسال رمز التأكيد:',
		checkYourSpamFolder:
			'لا يمكنك العثور على الرمز؟ تحقق من مجلد البريد المزعج',
		emailSuccess: 'تم بنجاح! لقد قمت بتغيير البريد الإلكتروني المرتبط بحسابك',
		emailError: 'خطأ. حاول مرة أخرى في وقت لاحق',
		confirmPassword: 'تأكيد كلمة المرور',
		confirmPasswordDescription:
			'لتغيير البريد الإلكتروني، يُرجى تأكيد كلمة المرور الخاصة بك:',
		newEmail: 'البريد الإلكتروني الجديد',
		password: 'كلمة المرور',
		confirm: 'تأكيد',
		cancel: 'إلغاء',
		change: 'تغيير',
	},

	agendaTranslations: {
		myAgenda: 'جدولي',
		all: 'الكل',
		sessions: 'الجلسات',
		meetings: 'الاجتماعات',
		pastSlots: 'الفترات السابقة',
		meetingWith: 'اجتماع مع',
		pending: 'معلق',
		accepted: 'مقبول',
		requested: 'مطلوب',
		canceled: 'ملغى',
		now: 'الآن',
		live: 'مباشر',
		noMeetings: 'لا توجد اجتماعات مجدولة بعد',
		noSessions: 'لا توجد جلسات في جدولك حتى الآن.',
		noUpcomingSessions: 'لا توجد جلسات قادمة في جدولك حتى الآن.',
		noUpcomingMeetings: 'لا توجد اجتماعات قادمة في جدولك حتى الآن.',
		noUpcomingAgenda: 'لا شيء قادم في جدولك حتى الآن.',
		noAgendaItem: 'لا يوجد شيء في جدولك حتى الآن.',
		meetingRequests: 'طلبات الاجتماع',
		finished: 'منتهٍ',
		past: 'ماضٍ',
		goToMeeting: 'الانتقال إلى الاجتماع',
		addToMyAgenda: 'إضافة إلى جدولي',
		addedToYourAgenda: 'تمت الإضافة إلى جدولك',
		acceptedAllMeetings: 'قبول جميع الاجتماعات',
	},
	participantRegistration: {
		metaTitle: 'تسجيل مشارك',
		choosePackageTitle: 'اختر باقة الدخول',
		noPackageText: 'الرجاء تحديد الباقة.',
		gdprTextFirst: 'بالمتابعة، فإنك توافق على',
		gdprTextPrivacy: 'سياسة الخصوصية',
		gdprTextSecond: 'و',
		gdprTextTerms: 'شروط وأحكام الفعالية',
		gdprTextAgree: 'يرجى الموافقة على سياسة الخصوصية',
		registerButtonFree: 'إكمال التسجيل',
		registerButtonPay: 'المتابعة إلى عملية الدفع',
	},
	exhibitorRegistration: {
		metaTitle: 'تسجيل عارض',
		choosePackageTitle: 'اختر باقة الدخول',
		gdprTextFirst: 'بالمتابعة، فإنك توافق على',
		gdprTextPrivacy: 'سياسة خصوصية الفعالية',
		gdprTextSecond: 'و',
		gdprTextTerms: 'شروط وأحكام الفعالية',
		gdprTextAgree: 'يرجى الموافقة على سياسة الخصوصية',
		registerButtonFree: 'إكمال التسجيل',
		registerButtonPay: 'المتابعة إلى عملية الدفع',
	},
	stripePayment: {
		cardNumber: 'رقم البطاقة',
		expirationDate: 'تاريخ انتهاء الصلاحية الشهر / السنة)',
		cvc: 'رمز التحقق من البطاقة',
		disclaimerText:
			'عملية الدفع هذه مشفرة من طرف إلى طرف. تضمن شركة سترايب الأمان والخصوصية في خدماتها.',
		disclaimerTextLink: 'انقر هنا لمعرفة المزيد.',
		cancelButton: 'إلغاء',
		payButton: 'الدفع',
	},
	recoverPassword: {
		recoverTextSend: 'تم إرسال البريد الإلكتروني لاستعادة كلمة المرور بنجاح.',
	},
	unsubscribe: {
		unsubscribeTitle: 'إلغاء الاشتراك؟',
		unsubscribeHomepage: 'اذهب إلى الصفحة الرئيسية',
	},
	notAvailable: {
		title: 'الفعالية غير متاحة',
		description:
			'الفعالية التي تبحث عنها غير متاحة لأنها إما أن يكون المنظم قد أغلقها أو عطلها المسؤول',
		fullOverlayEvents: 'تحقق من فعالياتك الأخرى',
		fullOverlayNoEvents: 'لم يتم العثور على أي فعاليات أخرى',
	},
	notFound: {
		notFoundDescription: 'عفواً... لم يتم العثور على أي فعالية هنا!',
		notFoundHomepage: 'اذهب إلى الصفحة الرئيسية',
	},
	eventMenu: {
		organizerButton: 'المنظم',
		coOrganizerButton: 'إدارة',
		exhibitorButton: 'عارِض',
		posterStudyButton: 'ملصق/ دراسة',
		boothButton: 'منصة عرض',
		boothsButton: 'منصات عرض',
		homeButton: 'الصفحة الرئيسية',
		contactsButton: 'جهات الاتصال',
		meetingsButton: 'الاجتماعات',
		chatsButton: 'الدردشات',
		programButton: 'البرنامج',
		archiveButton: 'الأرشيف',
		myAccountButton: 'حسابي',
		lobbyButton: 'القاعة',
		roomsButton: 'الغرف',
		connectionProblemTextFirst: 'يبدو أن هناك مشكلة في الاتصال. من فضلك اضغط',
		connectionProblemButton: 'هنا',
		connectionProblemTextSecond: 'أو قم بتحديث الصفحة.',
		sessionProblemTextFirst: 'انتهت صلاحية جلسة عملك من فضلك اضغط',
		sessionProblemButton: 'هنا',
		sessionProblemTextSecond: 'لتسجيل الدخول مرة أخرى.',
	},
	myEventsDropdown: {
		title: 'فعالياتي',
		createButtonMobile: 'إنشاء',
	},
	myBoothsDropdown: {
		title: 'منصات العرض الخاصة بي',
		emptyStudyTitle: 'منصة عرض الملصق/ الدراسة',
	},
	contactsDropdown: {
		title: 'جهات الاتصال الخاصة بك',
		requestsTitle: 'طلبات الاتصال',
		rejectButton: 'رفض',
		acceptButton: 'قبول',
		pendingButton: 'قيد الانتظار',
		addButton: 'إضافة',
		noContacts:
			'ليس لديك أي جهات اتصال حتى الآن. يرجى زيارة قسم التواصل لبدء بناء قائمة جهات الاتصال الخاصة بك.',
	},
	meetingsDropdown: {
		meetingWith: 'اجتماع مع',
		pending: 'قيد الانتظار',
		accepted: 'وافقت',
		canceled: 'ألغيت',
		noMeetings: 'لا توجد اجتماعات مجدولة حتى الآن',
		finished: 'تم الانتهاء من',
	},
	programDropdown: {
		stages: 'المراحل',
		with: 'مع',
		noProgram: 'لا تتوفر إدخالات برنامج في الوقت الحالي.',
		fullProgramButton: 'البرنامج الكامل', // new translation
		addToCalendarButton: 'إضافة إلى التقويم', // new translation
		addedToYourAgenda: 'تمت الإضافة إلى جدولك الزمني',
		addEventButton: 'إضافة حدث', // new translation
		addToMyAgenda: 'إضافة إلى جدولي الزمني', // new translation
		addEventToYourCalendarDialogTitle: 'إضافة حدث إلى التقويم الخاص بك', // new translation
		addTimeslotToYourCalendar: 'إضافة فترة زمنية إلى التقويم الخاص بك', // new translation
		tip: 'نصيحة: ', // new translation
		tipDescription:
			'يمكنك أيضًا إضافة فترات زمنية منفصلة إلى التقويم الخاص بك عن طريق النقر على أيقونة التقويم المجاورة لكل فترة زمنية.', // new translation
		tipTimeslotDescription:
			'يمكنك أيضًا إضافة الحدث بأكمله عن طريق النقر على الزر "إضافة إلى التقويم".', // new translation
		durationEventTitle: 'مدة الحدث: ', // new translation
		timeslotTitle: 'العنوان: ', // new translation
		slotScheduled: 'تم جدولة الفترة: ', // new translation
		addSlotToCalendar: 'إضافة فترة زمنية إلى التقويم ', // new translation
		speakers: 'المتحدثون', // new translation
		auditoriumName: 'اسم القاعة', // new translation
		timezoneTooltipText:
			'يتم عرض البرنامج في المنطقة الزمنية للمنظم. يمكنك تغيير منطقة العرض الزمنية لعرض الجلسات في منطقة زمنية تفضيلية', // new translation
		liveLabel: 'مباشر', // new translation
		liveLabelTooltipText: 'تكون هذه الفترة الزمنية مباشرة الآن في القاعة', // new translation
		openAuditorium: 'فتح القاعة', // new translation
	},

	notificationsDropdown: {
		title: 'الاشعارات',
		contactRequestReceived: 'أرسل لك طلب اتصال. تحقق من ذلك!',
		contactRequestAccepted: 'لقد قبلت طلب الاتصال الخاص بك. ابدأ الدردشة!',
		meetingRequestReceived: 'أرسل لك طلب اجتماع. تحقق من ذلك!',
		meetingRequestAccepted: 'قبلت طلب الاجتماع الخاص بك. تحقق من ذلك!',
		meetingRequestCanceled: 'إلغاء طلب اجتماع. تحقق من ذلك!',
		meetingStarting: 'سيبدأ الاجتماع قريباً. تحقق من ذلك!',
		newBoothPost: 'تم نشره في حائط منصة العرض الخاصة بك. تحقق من ذلك!',
		noNotifications: 'ليس لديك أي إشعارات حتى الآن.',
		seeAll: 'اظهار الكل',
		hideAll: 'إخفاء الكل',
		meetingWillStartTextSecond: 'سوف يبدأ قريباً، تحقق من ذلك!',
	},
	chatsDropdown: {
		title: 'الدردشات',
		newChatProvideText:
			'يرجى تقديم اسم للدردشة الخاصة بك. ستتمكن من تعديل الاسم بعد ذلك:',
		noChats: 'أنشئ محادثة واحدة على الأقل للتحدث إلى المشاركين في الحدث',
		members: 'الأعضاء',
		noOpenedChatText: 'انقر فوق الدردشة لمتابعة المحادثة.',
		noMessagesInChatTextFirst: 'اكسر حاجز الجمود.',
		noMessagesInChatTextSecond: 'كن الشخص الذي يبدأ هذه المحادثة.',
		newChatNameInput: 'اسم الدردشة (اختياري)',
		newChatAddMembers: 'أضف جهات اتصال ومشاركين آخرين إلى هذه الدردشة:',
		editChatAddMembers:
			'يرجى البحث عن مستخدمين من هذا الحدث الذي تريد إضافته إلى الدردشة الجماعية.',
		newChatSearchInput: 'أبحث عن مستخدمين',
		newChatNoUsersMessage: 'لم يتم العثور على مستخدمين',
		newChatRemoveUserButton: 'حذف',
		newChatCancelButton: 'أغلق',
		newChatCreateButton: 'إنشاء',
		membersLeaveButton: 'غادر',
		membersUpdateButton: 'تحديث',
		membersChatButton: 'الدردشة',
		chatSettingsButton: 'الإعدادات',
		newMessageInput: 'اكتب الرسالة هنا',
		newVideoCallTooltipText: 'انقر هنا لبدء محادثة فيديو',
		leaveVideoMeetingTitle: 'مغادرة اجتماع الفيديو',
		leaveVideoMeetingDescription:
			'أنت على وشك مغادرة اجتماع الفيديو. هل أنت متأكد من أنك ترغب في المتابعة؟',
		leaveChatConfirmationTitle: 'مغادرة اتصال الفيديو',
		leaveChatConfirmationText:
			'هل أنت متأكد من أنك ترغب في مغادرة اتصال الفيديو الحالي؟',
	},
	myAccountDropdown: {
		title: 'حسابي',
		viewProfileButton: 'عرض الصفحة الشخصية',
		logoutButton: 'تسجيل الخروج',
	},
	myAccountPage: {
		changePasswordButton: 'تغيير كلمة المرور',
		deleteAccountButton: 'حذف حسابي',
		platformLanguage: 'لغة النظام:',
		privacyPolicyLink: 'إشعار الخصوصية والسياسات',
		cookiesLink: 'ملفات تعريف الارتباط',
		termsAndConditionsLink: 'الشروط والأحكام',
		eventProfileSectionTitle: 'الملف الشخصي للحدث',
		eventPackage: 'باقات الحدث',
		unregisterFromEventButton: 'إلغاء التسجيل من الحدث',
		unregisterCurrentEventButton: 'إلغاء التسجيل من الحدث الحالي',
		unregisterCurrentEventConfirmation:
			'الرجاء تأكيد رغبتك بإلغاء التسجيل من الحدث',
		enableParticipantProfile: 'قم بتمكين ملف تعريف المشارك',
		editUserDialogTitle: 'قم بتعديل معلومات المستخدم',
		editExhibitorDialogTitle: 'تحرير بيانات جهة اتصال الشركة',
		editScholarDialogTitle:
			'تحرير بيانات الشخص المعني بالتواصل فيما يتعلق بالملصق/ الدراسة',
		editParticipantDialogTitle: 'تعديل تفاصيل المشارك',
		firstName: 'الاسم الأول',
		lastName: 'الاسم الاخير',
		uploadImage: 'تحميل صورة',
		removeImage: 'إزالة صورة',
		deleteError:
			'لا يمكنك حذف حسابك. يرجى حذف جميع الفعاليات التي تم إنشاؤها لتتمكن من حذف حسابك.',
		deleteSuccess: 'لقد تم حذف حسابك.',
		reviewEventPrivacyPolicy: 'راجع سياسة خصوصية الحدث لدينا:',
		title: 'اللقب',
		company: 'اسم الجهة',
		phone: 'رقم الهاتف',
		platformProfileSectionTitleTextSecond: 'الملف',
		createParticipantProfile: 'أنشئ الملف التعريفي للمشارك',
		reviewPlatformConditions: 'راجع شروط منصتنا',
		reviewEventConditions: 'راجع شروط فعاليتنا',
	},
	changePassword: {
		title: 'تغيير كلمة المرور',
		oldPassword: 'كلمة المرور القديمة',
		oldPasswordError: 'كلمة المرور القديمة غير صحيحة!',
		inputPassword: 'كلمة المرور الجديدة',
		inputConfirmPassword: 'تأكيد كلمة المرور',
	},
	roomsDropdown: {
		auditoriumButton: 'القاعة',
		archiveButton: 'الأرشيف',
		onDemandButton: 'ON-DEMAND',
	},
	sideMenu: {
		liveWall: 'حائط العرض المباشر',
		networking: 'شبكات التواصل',
		audience: 'الجمهور',
		info: 'معلومات',
		videoWall: 'حائط عرض بالفيديو',
		booth: 'منصة العرض',
		boothWall: 'حائط منصة العرض',
		networkingTooltip: 'ليس لديك حق الوصول إلى جميع وظائف الشبكات.',
		attendees: 'الحضور',
	},
	wall: {
		sortBy: 'ترتيب حسب',
		popularity: 'الأكثر شهرة',
		time: '‫التوقيت',
		emptyWallText:
			'كن أول من يضيف منشور هنا. فقط أخبرنا بما يدور في عقلك أو قدِّم نفسك باختصار',
		newPostInput: 'ماذا يدور في عقلك؟',
		newCommentInput: 'تعليق',
		resourcesVideoDescription: 'نبذة عن الفيديو',
		resourcesLinks: 'الروابط',
		resourcesFiles: 'الملفات',
		pinTitle: 'ثَّبِت',
		unPinTitle: 'إلغاء التثبيت',
		deleteDialogTitle: 'هل ترغب في حذف المنشور؟',
		pinDialogDescription:
			'هناك بالفعل منشور مُثَبَّت. يمكن تثبيت منشور واحد فقط في كل مرة. وبمتابعتك لهذا، سيتم إلغاء تثبيت المنشور السابق تلقائيًا.',
		edited: 'تم تحريره',
		escapeToCancel: 'تراجع للإلغاء',
		enterToSave: 'أدخل للحفظ',
		editingState: 'تحرير الرسالة',
		bannerModeration:
			'تخضع المنشورات والتعليقات التي تُنشر على هذا الحائط للرقابة للتأكد من نشر محتوى عالي الجودة',
		pendingApproval: 'في انتظار الموافقة',
		approvePost: 'اعتمد المنشور',
		approvePostComment: 'اعتمد التعليق',
		commentsPendingApproval: 'التعليقات في انتظار الموافقة',
	},
	networking: {
		searchInput: 'البحث حسب المستخدم',
		emptyNetworkingSearchResults:
			'الشخص الذي تبحث عنه لم يسجل لحضور هذه الفعالية التي تُعقد عبر الإنترنت',
		emptyAuditoriumNetworkingSearchResults:
			'الشخص الذي تبحث عنه لم يحضر هذا العرض بعد',
		alphabetical: 'أبجدي',
		online: 'عبر الإنترنت',
		matching: 'التطابقات',
		matchingAlgo:
			'خوارزمية المطابقة قيد العمل. امنحنا دقيقة لكي ننسق أفضل التطابقات لك',
		searchFilters: 'تصفيات البحث',
		applyFilterTooltip: 'لكي تطبق عوامل التصفية، اختر خيارًا واحدًا على الأقل',
		chat: 'دردشة',
	},
	closedAuditoriumDialog: {
		title: 'معلومات القاعة',
		contentPackage: 'لا يمكن للباقة الخاصة بك الوصول إلى أي قاعات.',
	},
	closedShowfloorDialog: {
		title: 'معلومات العرض',
		contentPackage:
			'لا يمكن للباقة الخاصة بك الوصول إلى أي قاعة من قاعات العرض',
		button: 'نعم',
	},
	auditorium: {
		willStart: 'ستبدأ الجلسة التالية في ...',
		noAccess: 'لا يمكن للباقة الخاصة بك الوصول إلى أي قاعات.',
		noVideos: 'لا توجد مقاطع فيديو مجدولة لتشغيلها في هذه القاعة.',
		stageTab: 'المسرح',
		videoWallButton: 'حائط عرض بالفيديو',
		resourcesButton: 'المصادر',
		slotEndsTextFirst: 'ينتهي البرنامج في',
		slotEndsTextSecondUpdated: 'سيكون العرض متاحًا عند الطلب.',
		slotTitleWith: 'مع',
		allAuditoriums: 'جميع القاعات',
		onDemandButton: 'عند الطلب',
		hide: 'إخفاء',
		leavePopupTitle: 'مغادرة المرحلة؟',
		leavePopupDescription: 'بمغادرتك للقاعة، فسوف يتم إخراجك من الجلسة',
		leaveSession: 'مغادرة المرحلة',
		cancelDescription: 'بمغادرتك، لن تكون من المتحدثين. هل ترغب في المتابعة؟',
		settings: 'الإعدادات',
		minimize: 'تصغير',
		microphone: 'الميكروفون',
		camera: 'الكاميرا',
		auditoriums: 'القاعات',
	},
	auditoriumArchive: {
		title: 'أرشيف القاعة',
		titleEvent: 'أرشيف الفعالية',
		with: 'مع',
		noVideosAvailable: 'انتهت جميع الجلسات',
	},
	videoPlayer: {
		videoNotPlayingError:
			'هل تواجه مشكلة مع بث الفيديو؟ أعد تحميل الصفحة من فضلك.',
		videoEndedTextFirst: 'انتهى الفيديو. شكراً على المشاهدة!',
		videoEndedTextSecond: 'حائط الفيديو مفتوح لفقرة الأسئلة والأجوبة.',
		tooltipPlay: 'ابدأ',
		tooltipPause: 'إيقاف مؤقت',
		tooltipMinimize: 'تصغير',
		tooltipFullscreen: 'شاشة كاملة',
		tooltipMute: 'إلغاء كتم الصوت',
		tooltipUnmute: 'إعادة الصوت',
		videoStreamNotStarted: 'سيبدأ البث المباشر قريبًا. ابقوا مترقبين!',
	},
	showfloor: {
		allExhibitorsButton: 'كل العارضين',
		featuredExhibitorsText: 'العارضون المميزون',
		boothLinksDialogTitle: 'روابط منصة العرض',
		boothFilesDialogTitle: 'ملفات منصة العرض',
		boothVideoDialogTitle: 'الفيديو',
		mobileTabChat: 'الدردشة',
		mobileTabPoster: 'الملصق',
		mobileTabFiles: 'الملفات',
		mobileTabLinks: 'الروابط',
		mobileTabVideo: 'الفيديو',
		noExhibitorsTextFirst: 'العارضون يعملون على الانتهاء من تجهيز منصاتهم',
		noPresentersTextFirst: 'العارضون يعملون على الانتهاء من تجهيز منصاتهم',
		noExhibitorsTextSecond: 'ابقَ مترقباً',
		showShowfloorsButton: 'جميع قاعات العرض',
		showShowfloorsPosterButton: 'جميع الملصقات/ الدراسات',
		showShowfloorsExhibitorButton: 'جميع العارضين',
		searchExhibitors: 'ابحث عن العارضين',
		searchPosters: 'ابحث باستخدام العنوان أو اسم المؤلف',
		searchExhibitorsMultiple: 'ابحث عن العارضين في هذه القاعة المخصصة للعرض',
		searchPostersMultiple:
			'ابحث باستخدام العنوان أو اسم المؤلف في هذه القاعة المخصصة للعرض',
		showfloorsTitle: 'قاعات العرض',
		collapseList: 'طي القائمة',
		seeFullList: 'رؤية القائمة الكاملة',
		emptyExhibitorsResults:
			'منصة العرض التي جرى البحث عنها ليست موجودة، ولكننا سنوجه الدعوة لهم في المرة القادمة',
		emptyPostersResults:
			'الملصق/ الدراسة التي جرى البحث عنها ليس موجودة، ولكننا سنوجه الدعوة لهم في المرة القادمة',
		posters: 'الملصقات',
		allPostersStudiesTitle: 'جميع الملصقات/ الدراسات',
		authors: 'المؤلفون',
		titleNotAvailable: 'العنوان ليس متاحًا',
		abstractNotAvailable: 'ملخص البحث ليس متاحًا',
		authorsNotAvailable: 'المؤلفون ليسوا متاحين',
		openPosterFile: 'افتح ملف الملصق',
		fileIsNotAvailable: 'الملف ليس متاحًا',
		posterBooth: {
			study: 'الملصق/ الدراسة',
			authors: 'المؤلفون',
			highlights: 'ملخص البحث',
			scrollInfoText: 'مرر للأسفل لقراءة ملخص البحث بأكمله',
		},
	},
	eventArchive: {
		title: 'أرشيف الفيديو',
		searchText: 'انقر للبحث',
		noArchivedEvents: 'لا يوجد حالياً أي فعاليات مؤرشفة.',
	},
	exhibitorDashboard: {
		event: 'فعالية',
		title: 'إدارة منصة العرض',
		visitorsButton: 'زوار منصة العرض',
		goToBoothPreview: 'معاينة منصة العرض',
		previewButton: 'معاينة',
		tabStyle: 'نمط منصة العرض',
		tabLogo: 'الشعار',
		tabStudyDetails: 'الملصق/ الدراسة',
		tabFiles: 'الملفات',
		tabStudyFile: 'الملف',
		tabLinks: 'الروابط',
		tabAvatars: 'الصور الرمزية',
		tabStudyAuthors: 'المؤلفون',
		tabVideo: 'الفيديو',
		shareText: 'إنسخ الرابط',
		theBoothUrlCopiedText: 'تم نسخ URL Booth بنجاح إلى الحافظة الخاصة بك',
		optionDisabledText: 'هذا الخيار غير متاح حاليًا لهذه الكابينة',
		boothStyleTab: {
			optionOne: 'الخيار 1 – طراز اسكندنافي',
			optionTwo: 'الخيار 2 – طراز عصري',
			optionThree: 'الخيار 3 – طراز ديناميكي',
			optionFour: 'الخيار4 - طراز مخصص',
			optionScholarOne: 'الخيار 1 - أينشتاين',
			optionScholarTwo: 'الخيار 2 - كوري',
			optionScholarThree: 'الخيار 3 - دا فينشي',
		},
		logoTab: {
			fileUploadButton: '‫اضغط  للتحميل',
			noFile: 'لم يتم اختيار ملف',
		},
		posterStudyTab: {
			posterStudyTitle: 'العنوان',
			studyTitleInputLabel: 'عنوان الملصق/ الدراسة',
			highlightAbstractTitle: 'أبرز النقاط/ ملخص البحث',
			studyHighlightsInputLabel: 'أبرز النقاط في البحث/ ملخص البحث',
			highlightAbstractError:
				'لقد وصلت إلى الحد الأقصى لعدد الحروف المسموح بها (500 حرف).',
			authorNameLabel: 'اسم المؤلف',
			authorAffiliationLabel: 'الانتساب',
		},
		filesTab: {
			title: 'المستندات:',
			fileNameInput: 'اسم المستند (مرئي لجميع المشاركين)',
			chooseFileButton: 'اختر ملفًا',
			noDocAdded: 'لا توجد مستندات مُضافة',
			fileError: 'يرجى إضافة اسم المستند',
		},
		fileStudyTab: {
			title: 'حَمِّل ملف pdf',
			description:
				'حَمِّل ملصقًا: ورقة بحثية أو عرضًا بحيث يكون متاحًا في منصة العرض المخصصة لكم (الحد الأقصى للملف 20 ميجا بايت)',
			uploadPdfInputLabel: 'حَمِّل ملفًا جديدًا بتنسيق pdf',
			uploadNewPdfInputLabel: 'حَمِّل ملفًا جديدًا بتنسيق pdf',
			uploadedFile: 'تم التحميل',
			dragContainerTitle: 'اسحب الملف هنا ببساطة',
			dragContainerNewTitle: 'اسحب الملف الجديد هنا ببساطة',
		},
		linksTab: {
			social: {
				title: 'روابط وسائل التواصل الاجتماعي',
				subtitle: '(مرئية لجميع المشاركين)',
				facebookInput: 'أدخل رابط صفحتك على فيسبوك',
				linkedinInput: 'أدخل رابط صفحتك على لينكد إن',
				twitterInput: 'أدخل رابط صفحتك على تويتر',
				googleScholarInput: 'أدخل رابط جوجل سكولار الخاص بك',
				researchGateInput: 'أدخل رابط جوجل سكولار الخاص بك',
			},
			other: {
				linkInput: 'أدخل رابط URL',
				linkLabel: ' مسمى الرابط',
			},
		},
		linksStudyTab: {
			authorProfiles: 'الملفات التعريفية للمؤلف',
			additionalLinks: 'روابط إضافية',
		},
		avatarsTab: {
			mainRepresentatives: {
				title: 'ممثلو منصة العرض',
				leftRepresentative: 'ممثل الطرف الأيسر',
				rightRepresentative: 'ممثل الطرف الأيمن',
				addDialog: {
					title: 'أضف صورة رمزية',
					titleScholar: 'أضف مؤلف',
					editAvatarsTitle: 'حرر الصورة الرمزية',
					editAuthorTitle: 'حرر المؤلف',
					contentLine1: 'اختر صورةً رمزيةً من المعرض أدناه',
					contentLine2: 'حَمِّل صورتك الرمزية الشخصية.',
					contentLine3: 'هل تحتاج إلى مساعدة؟ تواصل مع',
					assignButtonText: 'حدد',
					assignText:
						'يرجى تحديد مشارك في الفعالية ليكون مرتبطًا بالممثل أعلاه.',
					assignErrorBooth: 'أضيف المستخدم بالفعل كممثل لمنصة العرض',
					assignErrorAdditional: 'أضيف المستخدم بالفعل كممثل إضافي',
					input: 'بحث حسب الاسم',
				},
			},
			additionalRepresentatives: {
				title: 'ممثلون إضافيين',
				subtitle: '(اختياري ، سيتم عرضه مع صورة ملفه الشخصي)',
				addDialog: {
					title: 'اختر ممثلاً إضافيًا',
					titleScholar: 'اختر مؤلفًا مشاركًا',
					assignText:
						'يرجى البدء في البحث عن مشارك في الفعالية لإضافته/ها كممثل.',
					assignError: 'أضيف المستخدم بالفعل كممثل لمنصة العرض!',
				},
			},
			author: {
				title: 'تقديم المؤلف',
				subtitle:
					'أضف مؤلفًا فقط سجل للمشاركة في الفعالية. يمكنك إضافة مؤلف واحد.',
				mainRepresentative: 'الممثل الرئيسي',
			},
			coAuthors: {
				title: 'مؤلفون مشاركون',
				singularTitle: 'مؤلف مشارك',
				subtitle:
					'أضف المؤلفيت المشاركين الذين سجلوا للمشاركة في الفعالية فقط. يمكنك إضافة حتى 4 مؤلفين مشاركين.',
			},
		},
		videoTab: {
			video: {
				title: 'فيديو منصة العرض',
				subtitle: '(رابط فيديو يوتيوب فقط)',
				youtubeOrVimeoOnly: '(رابط يوتيوب أو فيميو فقط)',
				input: 'أدخل رابط يوتيوب الخاص بك',
				inputYoutubeOrVimeo: 'أدخل رابط فيديو يوتيوب أو فيميو الخاص بك',
			},
			image: {
				subtitle:
					'(يتم عرض الصورة في منصتك في حالة عدم إضافة رابط فيديو في القسم أعلاه)',
			},
		},
	},
	boothDashboard: {
		event: 'فعالية',
		title: 'منصة العرض الخاصة بك',
		manage: 'إدارة',
		statistics: 'الإحصائيات',
		uniqueVisitors: 'زائرين فريدين من نوعهم',
		currentOnlineVisitors: 'الزوار الحاليون عبر الإنترنت',
		totalNumberOfVisits: 'العدد الإجمالي للزيارات',
		totalDocumentClicks: 'إجمالي نقرات المستند',
		totalLinkClicks: 'إجمالي نقرات الروابط',
		totalRepresentativesClicks: 'إجمالي نقرات الممثلين',
		totalVideoViews: 'إجمالي مشاهدات الفيديو',
		totalPostsCount: 'إجمالي عدد المشاركات',
		totalLikesCount: 'إجمالي عدد الإعجابات',
		totalCommentsCount: 'إجمالي عدد التعليقات',
		showUsers: 'إظهار المستخدمين',
		dataType: 'نوع البيانات',
		value: 'قيمة',
		posts: 'المشاركات',
		likes: 'الإعجابات',
		comments: 'تعليقات',
		topPostLikes: 'أفضل مشاركة لايكات',
		topPostComments: 'أهم تعليقات المنشور',
		totalVotes: 'مجموع الأصوات',
	},
	polls: {
		polls: 'استطلاعات رأي',
		noPolls: 'لم يتم إضافة استطلاعات الرأي حتى الآن.',
		pollProgress: 'الاستطلاع قيد التقدم',
		pollClosed: 'الاستطلاع مغلق',
		pollVoted: 'صوت المشاركون',
	},
	onDemandVideos: {
		sectionTitle: 'عند الطلب',
		noOnDemandVideos: 'لم يتم إضافة فيديوهات عند الطلب بعد.',
	},
	meetings: {
		meeting: 'اجتماع',
		createDialog: {
			createTitle: 'طلب اجتماع مع',
			inputDate: 'التاريخ',
			inputStart: 'يبدأ',
			inputEnd: 'ينتهي',
			inputTimezone: 'الوحدة الزمنية',
			inputNote: 'ملاحظة: يمكن جدولة اجتماع واحد فقط في كل مرة مع نفس الشخص.',
			meetingError: 'تاريخ أو وقت الاجتماع غير صالح. يرجى إعادة المراجعة!',
			buttonDisabled: 'يمكنك تحديد موعد اجتماع بعد بدء المحادثة!',
		},
		banner: {
			pending: 'طلب اجتماع',
			accepted: 'اجتماع مجدول إلى',
			progress: 'الاجتماع قيد التقدم',
			cancelTitle: 'إلغاء الاجتماع؟',
			cancelDescription: 'هل أنت متأكد أنك تريد إلغاء هذا الاجتماع؟',
			startCall: 'بدء المكالمة',
			joinCall: 'انضم',
			pendingOwner: 'طلب الاجتماع معلق لـ',
			videoCallStarted: 'بدأ الاجتماع بالفيديو',
			videoCallStartedInfo:
				'اجتماعات الفيديو تحقق أفضل نتيجة للمشاركين الذين يصل عددهم إلى 10 أشخاص',
		},
		title: 'اجتماعاتك',
		meetingsInfo:
			'يمكن جدولة الاجتماعات من المحادثات. الرجاء بدء محادثة مع المستخدم الذي تريد جدولة اجتماع معه.',
	},
	socialMediaShare: {
		event: 'الفعاليات',
		shareLabel: 'مشاركة',
		dialogTitleGeneral: 'مشاركة مع شبكتك',
		dialogTitlePost: 'شارك منشورك',
		linkedinLabel: 'لينكد إن',
		facebookLabel: 'فيسبوك',
		twitterLabel: 'تويتر',
		emailLabel: 'البريد الإلكتروني',
		clipboardLabel: 'الحافظة',
		clipboardSnackbarMessage: 'نُسخ الرابط إلى الحافظة',
		linkedinButtonTitle: 'شارك المنشور على لينكد إن',
		facebookButtonTitle: 'شارك المنشور على فيسبوك',
		twitterButtonTitle: 'شارك المنشور على تويتر',
		emailButtonTitle: 'شارك عبر البريد الإلكتروني',
		clipboardButtonTitle: 'انسخ إلى الحافظة',
		imAttending: 'أنا سأحضر الفعالية',
		interestedClick: 'هل أنت مهتم بالحضور؟ اضغط هنا:',
	},
	videoConferences: {
		fullScreen: 'فتح بنمط ملء الشاشة',
		minimizeScreen: 'تصغير الشاشة',
		startedVideoChat: 'بدأ مكالمة فيديو',
		timeAgo: 'منذ فترة',
		videoCallStarted: 'بدأت مكالمة الفيديو...',
	},
	sessions: {
		edit: 'تحرير',
		speakers: 'المتحدثون',
		speakerSingular: 'المتحدث',
		moderators: 'مدراء الجلسة',
		moderatorSingular: 'مدير الجلسة',
		video: 'فيديو',
		startStream: 'بدء البث',
		stopStream: 'إيقاف البث',
		sessionChat: 'محادثة الجلسة',
		speakerUrl: 'رابط المتحدث',
		moderatorUrl: 'رابط مدير الجلسة',
		copyUrl: 'انسخ الرابط',
		noSpeakersJoined: 'لم ينضم أي متحدث بعد',
		allFilter: 'الجميع',
		waitingRoom: 'غرفة الانتظار',
		isReady: 'جاهزة',
		invitationAccepted: 'قبلت الدعوة',
		requestPending: 'الطلب معلق',
		gettingReady: 'الاستعداد',
		inviteToSpeak: 'وجه الدعوة للتحدث',
		fromAuditorium: 'من القاعة',
		mic: 'الميكروفون',
		screen: 'الشاشة',
		rec: 'تسجيل',
		sessionHasEnded: 'لقد انتهت هذه الجلسة',
		sessionLinks: 'روابط الجلسات',
		sessionSettings: 'الإعدادات',
		noAvailableChat:
			'ليس هناك أي دردشة متاحة. لرؤية الدردسة، يُرجى تخصيص فترة زمنية لهذه الجلسة.',
		startLiveSessionStreamTitle: 'ابدأ البث المباشر للجلسة',
		startLiveSessionDescription:
			'تسجيل هذه الجلسة سيبدأ تلقائيًا عندما تبدأ البث',
		stopLiveSessionStreamTitle: 'أوقف البحث المباشر للجلسة',
		stopLiveSessionStreamDescription:
			'عبر إيقافك للبث المباشر للجلسة، سوف يتوقف التسجيل كذلك تلقائيًا. ولا يمكن لهذا البث المباشر للجلسة أن يبدأ مجددًا.',
		thumbnailImage: 'صورة مصغرة',
		uploadThumbnail: 'حَمِّل صورة مصغرة',
		clickToUpload: 'اضغط هنا للتحميل',
		requestMic: 'اطلب الميكروفون',
		acceptInvitationTitle: 'لقد تلقيت الدعوة',
		acceptInvitationSubTitle:
			'لقد دعاك مدير الجلسة لكي تكون متحدثُا. ماذا تقول؟',
		acceptInvitationButton: 'اقبل الدعوة',
		requestPendingTitle: 'الطلب معلق...',
		requestPendingSubTitle:
			'يُرجى الانتظار إلى أن يسمح لك مدير الجلسة بالدخول إلى البث',
		cancelRequest: 'قم بإلغاء الطلب',
		approvedTitle: 'يُرجى التحقق من المايكروفون والفيديو واضغط على أنا مستعد.',
		approvedSubTitle:
			'هذه الجلسة يتم تسجيلها. عبر الدخول إلى هذه الجلسة، فإنك توافق على معالجة بياناتك الشخصية.',
		connectedToTheBackstage: 'أنت الآن تبث بثًا مباشرًا ومتصل بما وراء الكواليس.',
		removedFromTheSession:
			'لقد أُخرجت من الجلسة، يُرجى التواصل مع أحد مديري الجلسة.',
		imReady: 'أنا جاهز',
		isTryingToRegister: 'أنا أحاول التسجيل بصفتي',
		hasLeftTheSession: 'قد ترك الجلسة',
		stopVideo: 'أوقف الفيديو',
		startVideo: 'ابدأ الفيديو',
		disabledScreenSharing:
			'أقفل مشاركة الشاشة قبل البدء في تشغيل الفيديو الخاص بك',
		disabledByModerator: 'عَطَّله مدير الجلسة',
		disabledUntilReady: 'عُطِّل إلى أن يكون جاهزًا',
		stopShareScreen: 'أوقف مشاركة الشاشة',
		shareScreen: 'شارك الشاشة',
		disabledVideoSharing: 'أوقف الفيديو الخاص بك قبل مشاركة شاشتك',
		anotherUserIsScreenSharing: 'هناك شخص آخر يشارك الشاشة بالفعل',
		removeRoleDescription: 'هل أنت متأكد من أنك ترغب في إزالة الشاشة؟',
		declinedInvitation: 'رفضت الدعوة لأن تكون متحدثُا',
	},
	gdpr: {
		disclaimerTextFirst:
			'هذه الوثيقة تُرجمت باستخدام أدوات الذكاء الاصطناعي. وفي حالة عدم الاتساق، يُرجى الرجوع إلى النسخة الإنجليزية من الوثيقة.',
		disclaimerTextSecond: 'هنا',
		metaTitlePrivacyPolicy: 'سياسة الخصوصية',
		metaTitleCookiesPolicy: 'سياسة ملفات تعريف الارتباط',
		metaTitleTerms: 'الشروط والأحكام',
		updateCookieConsent: 'تحديث الموافقة على ملفات تعريف الارتباط',
		changeYourConsent: 'غيِّر موافقتك',
		withdrawYourConsent: 'اسحب موافقتك',
		withdrawSnackbarText: 'سُحبت الموافقة على ملفات تعريف الارتباط بنجاح',
	},
	infoMessages: {
		imageExplain420x200:
			'المعدل الذي يُنصح به للصورة 1:8:1 (توجه - أفقي، مثلاً 420 x 200 بكسل)',
		imageExplainTB230x320:
			'المعدل الذي يُنصح به للصورة 1:1.4 (توجه - عمودي، مثلاً 230 x 320 بكسل) مع خلفية شفافة',
		imageExplainTB230x875:
			'المعدل الذي يُنصح به للصورة 1:3.8 (توجه - عمودي، مثلاً 230 x 875 بكسل) مع خلفية شفافة',
	},
	matching: {
		matchingQuestionnaireTitle: 'مطابقة الاستبيان',
		matchingQuestionnaireIntro:
			'دعنا نساعدك على توصيلك بالأشخاص الأكثر صلة بك! فإن الأشياء المذهلة تحدث عندما تلتقي العقول المناسبة، أكمل الاستبيان للبدء',
		matchingQuestionnaireIntroSkip:
			'بتخطيك للاستبيان ، لن نتمكن من مطابقتك مع بقية الحاضرين في فعالية اليوم. يمكنك دائمًا إكمال الاستبيان لاحقًا في قسم التواصل الاجتماعي. جميع الإجابات قابلة للتعديل من قائمة حسابي.',
		doItLaterBtn: 'افعلها لاحقًا',
		nextBtn: 'التالي',
		backBtn: 'تراجع',
		finishBtn: 'إنهاء',
		seeMatchesBtn: 'شاهد جميع المطابقات',
		tryMatchingBtn: 'جرب المطابقة الذكية',
		optionalQuestion: 'اختياري',
		requiredQuestion: 'مطلوب',
		singleSelect: 'اختيار فردي',
		multiSelect: 'اختيار متعدد',
		mediumMatch: 'مطابقة متوسطة',
		highMatch: 'مطابقة مرتفعة',
		matchLabel: 'مطابقة',
		matchesLabel: 'مطابقات',
		infoTooltipJump: 'تعال إلى هنا لرؤية المطابقات الخاصة بك',
		infoTooltipClickToSee1: 'اضغط هنا',
		infoTooltipClickToSee2: 'لرؤية الشخص الذي يجب أن تتواصل معه!',
		loadingAlgoText:
			'خوارزمية المطابقة قيد العمل. امنحنا دقيقة لكي ننسق أفضل التطابقات لك',
		matchingDetailsFormTitle: 'تفاصيل المطابقة الذكية',
		tryMatchingBanner: 'اصنع اتصالاً هادفًا، جرب ميزتنا للمطابقة الذكية!',
		matchingBannerClosed:
			'يمكنك دائمًا ملء نموذج المطابقة الذكية في خانة التواصل تحت المطابقات',
		resultsTitle: 'إليك أبرز المطابقات الخاصة بك',
		resultsNoteLabel: 'ملحوظة:',
		resultsNoteText:
			'يمكنك العثور على جميع المطابقات الخاصة بك في قسم التواصل.',
		resultsNoteMyAccount: 'جميع الإجابات قابلة للتحرير في قائمة حسابي.',
		btwResultsResponsive:
			'بالمناسبة، يمكنك العثور على جميع المطابقات الخاصة بك في قسم التواصل.',
		noMoreMatches:
			'يا إلهي! لا يمكننا العثور على المزيد من المطابقات لك في هذه اللحظة. يُرجى التحقق مجددًا عندما ينضم المزيد من المشاركين.',
		noResultsTitle:
			'تهانينا! لقد فعلتها! نحن نبحث عن أفضل المطابقات الخاصة بك!',
		noResults:
			'"شكرًا لك على تجربة وظيفة أفضل المطابقات! نحن نبحث عن أفضل المطابقات الخاصة بك وسوف نوصلكم ببعضكم في أقرب وقت ممكن مع انضمام المزيد من المشاركين إلى الفعالية. يُرجى مراجعة خانة "التطابقات" في قسم "التواصل"',
		clickStartChat: 'اضغط على الدردشة لبدء محادثة',
		closeMatchingTitle: 'هل ترغب في إغلاق نموذج المطابقة؟',
		closeMatchingTextA:
			'إذا أغلقت النموذج قبل إكماله، فلن نخزن أي تفاصيل ولن تكون نتائج المطابقة الذكية متاحة لك.',
		closeMatchingTextB:
			'لن يكون ملفك التعريفي جزءًا من عملية المطابقة ولن يكون أي فرد متطابقًا معك.',
		matchingTooltip: 'اضغط على التطابقات لرؤية الشخص الذي يجب أن تتواصل معه!',
		myAccountMatchingSectionTitle: 'تفاصيل المطابقة الذكية',
		matchingRequiredError: 'يتعين عليك إكمال جميع الأسئلة المطلوبة',
		rejectMatchingTitle: 'هل ترغب في رفض المشاركة في المطابقة؟',
		rejectMatchingText:
			'سوف تُمسح إجاباتك ولن يتم مطابقة ملفك التعريفي مع المستخدمين الآخرين. ويمكنك العودة لاستخدام استبيان المطابقة ي أي وقت.',
		rejectMatchingBtn: 'رفض المشاركة',
	},
	welcomeScreen: {
		goToEvent: 'اذهب إلى الفعالية',
	},
	emailCodeVerification: {
		codeSent: 'لقد أرسلنا رمز التحقيق إلى بريدك الإلكتروني:',
		continueRegistration: 'للاستمرار في التسجيل،',
		enterCodeBelow: 'أدخل رمز التحقيق أدناه:',
		codeWillExpire: 'هذا الرمز سوف تنتهي صلاحيته في',
		codeExpired: 'لقد انتهت صلاحية الرمز',
		codeNotReceived:
			'ألم تتلق الرمز؟ هل راجعت ملف الرسائل الإلكترونية المزعجة؟ ',
		resendTimer: 'إعادة الإرسال في:',
		resendLabel: 'إعادة الإرسال',
		resendCodeLabel: 'إعادة إرسال الرمز',
		codeError: 'الرمز الذي تم إدخاله غير صحيح',
		codeErrorFix:
			'أعد إدخال الرمز الصحيح من بريدك الإلكتروني أو أعد إرسال رمز جديد',
		congrats: 'تهانينا!',
		emailConfirmed: 'تم تأكيد بريدك الإلكتروني:',
	},
	privacyPolicyUpdates: {
		learnMore: 'Learn more',
		lastUpdated: 'Last updated',
		publishedBy: 'Published by',
		ppuLinkLabel: 'Updated privacy policy',
		ppuEventLinkLabel: 'Updated event privacy policy',
		acceptAllButtonLabel: 'Accept all updates',
	},
	voting: {
		votingText: 'التصويت',
		enableVoting: 'تمكين التصويت',
		enableVotingDescription:
			'انقر فوق أيقونة التحديث للحصول على أكثر النتائج دقة',
		displayVotingNumbersForExhibitors:
			'عرض أرقام التصويت للعارضين والملصق / الدراسة',
		displayVotingNumbersForExhibitorsDescription: 'سيتم عرضها في لوحة العارضين',
		displayVotingNumbersForParticipants: 'عرض أرقام التصويت للمشاركين',
		displayVotingNumbersForParticipantsDescription:
			'سيتم عرضها لجميع المشاركين',
		characterError80: 'لقد وصلت إلى الحد الأقصى المسموح به من الأحرف (80 حرفًا)',
		characterDescription: `النص محدد حتى 80 رمزًا. سيظهر النص كعنوان رئيسي على شريط التصويت. اجعلها ذات مغزى للمشاركين.`,
		deleteAllVotes: 'احذف كل الأصوات',
		downloadVotingResults: 'قم بتنزيل نتائج التصويت',
		voteText: 'تصويت',
		votedText: 'تم التصويت',
		votesText: 'الأصوات',
		participant: 'مشارك',
		participants: 'المشاركين',
		deleteVotesTextFirst: 'تحذير:',
		deleteVotesTextSecond: 'هذا إجراء غير قابل للإرجاع.',
		deleteVotesTextThird: 'لا تنس تنزيل نتائج التصويت قبل حذفها.',
		checkTheBoxToCastYourVote: 'حدد المربع للإدلاء بصوتك',
		toVotePleaseEnableParticipantProfile:
			'للتصويت ، يرجى تمكين ملف تعريف المشارك الخاص بك',
	},
};

export default ar;
